import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivityStatus } from '@extern/activities/enum/activity-status.enum';
import { FilterSessionStorageService, Filters, Reference } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';

import { GlobalVariables } from '@core/util/global-variables';
import { translateSynchronously } from '@core/util/translate.util';
import { ActivityType } from '@extern/activities/enum/activity-type.enum';
import { WorksiteStatus } from '@mes-chantiers/enum/woksite-status.model';
import { FilterName } from '../../enum/filter-name.enum';
import { MeterType } from '../../enum/meter-type.enum';
import {
    activityDashFilters,
    activityDetailsFilters,
    activityFilters,
    uexFilters,
    worksiteDashFilters,
    worksiteFilters
} from '../../util/filters-definitions-by-page';

export interface DetailFilters {
    filterName: string;
    dataName: string;
    data: Array<Reference>;
}

export interface GroupFilters {
    type: string;
    multiple: string;
    showInformation: boolean;
    textInformation: string;
    details: DetailFilters;
}

export enum InstallationStatusEnum {
    TO_PLAN = 'TO_PLAN',
    PLANNED = 'PLANNED',
    FINISHED = 'FINISHED',
    FINISHED_REMAINING_INSTALLATION = 'FINISHED_REMAINING_INSTALLATION',
    NOT_INSTALLED_YET = 'NOT_INSTALLED_YET',
    IN_PROGRESS = 'IN_PROGRESS',
    NOT_EQUIPABLE = 'NOT_EQUIPABLE'
}

@Component({
    selector: 'app-group-filters',
    templateUrl: './group-filters.component.html',
    styleUrls: ['./group-filters.component.css']
})
export class GroupFiltersComponent<T extends Filters> implements OnInit, OnChanges {
    @Input() type: string;
    @Input() propertiesReferences: ReadonlyArray<Reference>;
    @Input() addressesReferences: ReadonlyArray<Reference>;
    @Input() dwellingsReferences: ReadonlyArray<Reference> = [];
    @Input() serialNumberReferences: ReadonlyArray<Reference> = [];
    @Input() reasonReferences: ReadonlyArray<Reference> = [];
    @Input() subjectReferences: ReadonlyArray<Reference> = [];
    @Input() activeService: FilterSessionStorageService<T>;
    @Input() startDoubleDate: Date = this.globalVariables.worksiteDefaultDate();
    @Input() showEndDate = false;
    @Input() columns: ReadonlyArray<Reference> = [];
    @Input() openEndDatePicker = false;
    activeFilters: ReadonlyArray<GroupFilters>;
    minDate: Date;
    minEndDate: Date;
    startDate: Date;
    statusActivityReferences = [
        { name: 'activity.status.HELP_US', ids: [ActivityStatus.HELP_US] },
        { name: 'activity.status.TO_PLAN', ids: [ActivityStatus.TO_PLAN] },
        { name: 'activity.status.PLANNED', ids: [ActivityStatus.PLANNED] },
        { name: 'activity.status.IN_PROGRESS', ids: [ActivityStatus.IN_PROGRESS] },
        { name: 'activity.status.NOT_EQUIPABLE', ids: [ActivityStatus.NOT_EQUIPABLE] },
        { name: 'activity.status.FINISHED', ids: [ActivityStatus.FINISHED] }
    ];
    statusActivityDetailReferences = [
        { name: 'detailedActivitiesPage.maintenance.status.help_us', ids: [ActivityStatus.HELP_US] },
        { name: 'detailedActivitiesPage.maintenance.status.to_plan', ids: [ActivityStatus.TO_PLAN] },
        { name: 'detailedActivitiesPage.maintenance.status.planned', ids: [ActivityStatus.PLANNED] },
        { name: 'detailedActivitiesPage.maintenance.status.in_progress', ids: [ActivityStatus.IN_PROGRESS] },
        { name: 'detailedActivitiesPage.maintenance.status.not_equipable', ids: [ActivityStatus.NOT_EQUIPABLE] },
        { name: 'detailedActivitiesPage.maintenance.status.finished', ids: [ActivityStatus.FINISHED] }
    ];
    statusesData = [
        { name: 'datatable.worksite.status.TO_PLAN', ids: [InstallationStatusEnum.TO_PLAN] },
        { name: 'datatable.worksite.status.PLANNED', ids: [InstallationStatusEnum.PLANNED] },
        { name: 'datatable.worksite.status.IN_PROGRESS_TITLE', ids: [InstallationStatusEnum.IN_PROGRESS] },
        {
            name: 'datatable.worksite.status.FINISHED_TITLE_DWELLING',
            ids: [InstallationStatusEnum.FINISHED, InstallationStatusEnum.FINISHED_REMAINING_INSTALLATION]
        },
        { name: 'datatable.worksite.status.NOT_EQUIPABLE', ids: [InstallationStatusEnum.NOT_EQUIPABLE] },
        { name: 'datatable.worksite.status.NOT_INSTALLED_YET', ids: [InstallationStatusEnum.NOT_INSTALLED_YET] }];
    worksiteStatusesData = [
        { name: 'datatable.worksite.status.TO_PLAN', ids: [WorksiteStatus.TO_PLAN] },
        { name: 'datatable.worksite.status.PLANNED', ids: [WorksiteStatus.PLANNED] },
        { name: 'datatable.worksite.status.IN_PROGRESS_TITLE', ids: [WorksiteStatus.IN_PROGRESS] },
        { name: 'datatable.worksite.status.FINISHED_TITLE_WORKSITE', ids: [WorksiteStatus.FINISHED] }];
    productsData = [
        { name: 'datatable.worksite.product.warmWaterMeter', ids: [MeterType.EC] },
        { name: 'datatable.worksite.product.coldWaterMeter', ids: [MeterType.EF] },
        { name: 'datatable.worksite.product.heatMeter', ids: [MeterType.CET] },
        { name: 'datatable.worksite.product.heatCostAllocator', ids: [MeterType.RFC] },
        { name: 'datatable.worksite.product.temperatureSensor', ids: [MeterType.SONDE] },
        { name: 'datatable.worksite.product.smokeDetector', ids: [MeterType.DAAF] }];
    activitiesTypeData = [
        { name: 'detailedActivitiesPage.activityType.maintenance', ids: [ActivityType.MAINTENANCE] },
        { name: 'detailedActivitiesPage.activityType.installation', ids: [ActivityType.INSTALLATION] }];
    visitsCountData = [
        { name: '0', ids: ['0'] },
        { name: '1', ids: ['1'] },
        { name: '2', ids: ['2'] },
        { name: '3', ids: ['3'] },
        { name: '>3', ids: ['4'] }
    ];

    @Output() refresh: EventEmitter<string> = new EventEmitter();
    @Output() changeShowFilter: EventEmitter<boolean> = new EventEmitter();

    constructor(
        public globalVariables: GlobalVariables,
        private translate: TranslateService) {
    }

    ngOnChanges(): void {
        this.getActiveFilter();
    }

    showFilter(): void {
        this.changeShowFilter.emit(!this.showEndDate);
    }

    ngOnInit(): void {
        this.translate.setDefaultLang('fr');
        this.getActiveFilter();
        this.minDate = this.type.includes('activity') ?
            this.globalVariables.activityLimitDate() :
            this.globalVariables.worksiteLimitDate();
        this.startDate = this.type.includes('activity') ?
            this.globalVariables.activityLimitDate() :
            this.globalVariables.worksiteDefaultDate();
    }

    correctStartDate(filterName: string): Date {
        return filterName.includes('endDate') ? new Date() : this.startDate;
    }

    showDateFilter(): boolean {
        return this.showEndDate;
    }

    getMinEndDate(): Date {
        const item = this.activeService.getFiltersProperty<string>(FilterName.START_DATE);
        const filterStartDate = item ?? '';
        if (filterStartDate !== '') {
            return new Date(filterStartDate);
        } else {
            return this.minDate;
        }
    }

    getActiveFilter(): void {
        switch (this.type) {
            case 'activityDetails':
                this.statusActivityDetailReferences.forEach(p => {
                    p.name = translateSynchronously(this.translate, p.name);
                });
                this.productsData.forEach(p => {
                    p.name = translateSynchronously(this.translate, p.name);
                });
                this.activitiesTypeData.forEach(p => {
                    p.name = translateSynchronously(this.translate, p.name);
                });
                this.activeFilters = activityDetailsFilters(
                    this.propertiesReferences,
                    this.addressesReferences,
                    this.dwellingsReferences,
                    this.productsData,
                    this.serialNumberReferences,
                    this.activitiesTypeData,
                    this.reasonReferences,
                    this.subjectReferences,
                    this.statusActivityDetailReferences,
                    this.visitsCountData);
                break;
            case 'activity':
                this.statusActivityReferences.forEach(p => {
                    p.name = this.translate.instant(p.name);
                });
                this.activitiesTypeData.forEach(p => {
                    p.name = translateSynchronously(this.translate, p.name);
                });
                this.activeFilters = activityFilters(
                    this.propertiesReferences,
                    this.addressesReferences,
                    this.dwellingsReferences,
                    this.activitiesTypeData,
                    this.statusActivityReferences);
                break;
            case 'activityDash':
                this.statusActivityReferences.forEach(p => {
                    p.name = translateSynchronously(this.translate, p.name);
                });
                this.activitiesTypeData.forEach(p => {
                    p.name = translateSynchronously(this.translate, p.name);
                });
                this.activeFilters = activityDashFilters(
                    this.propertiesReferences,
                    this.addressesReferences,
                    this.activitiesTypeData,
                    this.statusActivityReferences);
                break;
            case 'worksite':
                this.minEndDate = this.getMinEndDate();
                this.productsData.forEach(p => {
                    p.name = translateSynchronously(this.translate, p.name);
                });
                this.statusesData.forEach(p => {
                    p.name = translateSynchronously(this.translate, p.name);
                });
                this.worksiteStatusesData.forEach(p => {
                    p.name = translateSynchronously(this.translate, p.name);
                });
                this.activeFilters = worksiteFilters(
                    this.propertiesReferences,
                    this.addressesReferences,
                    this.dwellingsReferences,
                    this.productsData,
                    this.statusesData,
                    this.worksiteStatusesData,
                    this.columns);
                break;
            case 'worksiteDash':
                this.minEndDate = this.getMinEndDate();
                this.productsData.forEach(p => {
                    p.name = translateSynchronously(this.translate, p.name);
                });
                this.worksiteStatusesData.forEach(p => {
                    p.name = translateSynchronously(this.translate, p.name);
                });
                this.activeFilters = worksiteDashFilters(
                    this.propertiesReferences,
                    this.addressesReferences,
                    this.productsData,
                    this.worksiteStatusesData);
                break;
            case 'uex':
            default:
                this.activeFilters = uexFilters(this.propertiesReferences);
                break;
        }
    }

    getSelectedFilters(): Array<Reference> {
        const selectedFilters = new Array<Reference>();
        this.activeFilters.forEach(f => {
            if (f.details.dataName === FilterName.DATES ||
                f.details.dataName === FilterName.START_DATE ||
                f.details.dataName === FilterName.END_DATE
            ) {
                const data = this.activeService.getFiltersProperty<string>(f.details.dataName);
                if (data && data !== '') {
                    selectedFilters.push({
                        name: translateSynchronously(this.translate, f.details.filterName),
                        ids: [data]
                    });
                }
            } else {
                const data = this.activeService.getFiltersProperty<ReadonlyArray<Reference>>(f.details.dataName);
                if (data && data.length > 0) {
                    selectedFilters.push({
                        name: translateSynchronously(this.translate, f.details.filterName),
                        ids: data.map(x => x.name)
                    });
                }
            }
        });
        return selectedFilters;
    }

    getFilterSelectedValues(filterName: string): ReadonlyArray<string> {
        const selectedFilters = this.getSelectedFilters();
        return ([] as ReadonlyArray<string>)
            .concat(...selectedFilters.filter(filter => filter.name === filterName).map(filter => filter.ids));
    }

    refreshData(data: string): void {
        this.refresh.emit(data);
    }

    getFilterTooltipLocation(filter: any): string {
        return 'right';     // Default tooltip location previously defined for ista-checkbox-filter in HTML
    }
}
