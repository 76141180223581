import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Entry } from 'src/app/core/model/entry.model';

@Component({
    selector: 'app-dashboard-resume',
    templateUrl: './dashboard-resume.component.html',
    styleUrls: ['./dashboard-resume.component.css'],
    changeDetection:ChangeDetectionStrategy.Default
})
export class DashboardResumeComponent {

    @Input() resume: ReadonlyArray<Entry<string, number>>;
    @Input() showSpinner: boolean;

    constructor() { }

}
