import { ChangeDetectorRef, Component, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { Entry } from '@core/model/entry.model';
import { Property } from '@core/model/property.model';
import { MessageService } from '@core/service/message.service';
import { PropertyService } from '@core/service/property.service';
import { GlobalVariables } from '@core/util/global-variables';
import { DwellingActivityOverview, MeterActivityOverview } from '@extern/activities/model/activity-overview.model';
import { MeterDetails } from '@extern/activities/model/meter-details.model';
import { ActivityOverviewService } from '@extern/activities/service/activity-overview.service';
import { TemplateComponent } from '@extern/components/template/template.component';
import { DwellingWorksiteOverview } from '@extern/worksites/model/worksite-overview.model';
import { OverviewMode, WorksiteDwellingService } from '@extern/worksites/service/worksite-dwelling.service';
import { AuthorizationService, Reference } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { FilterName } from '@shared/enum/filter-name.enum';
import { StorageName } from '@shared/enum/storage-name.enum';
import { handlePropertySelection } from '@shared/service/property-selection.service';
import { Subscription } from 'rxjs';
import { FilterReferencesService } from 'src/app/core/service/filter-references.service';

import { ActivityStatus } from '../../enum/activity-status.enum';
import { ActivityFilterSessionStorageService } from '../../service/activity-filter-session-storage.service';

export interface GraphElement {
    propertyId: string;
    dwellingId: string;
    status: string;
}

@Component({
    selector: 'app-activities-dashboard',
    templateUrl: './activities-dashboard.component.html',
    styleUrls: ['./activities-dashboard.component.css'],
})
export class ActivitiesDashboardComponent implements OnInit, OnDestroy {
    message: any;
    messagesSubscription: Subscription;
    showSpinner = true;
    propertyList: Array<Property> = [];
    propertyFilterData: ReadonlyArray<Reference>;
    addressFilterData: ReadonlyArray<Reference>;
    dataTotal: ReadonlyArray<MeterDetails> = [];
    filteredDataForSpinner: Array<MeterDetails> = [];

    dwellingsData: ReadonlyArray<DwellingActivityOverview> = [];
    metersData: ReadonlyArray<MeterActivityOverview> = [];
    worksitesData: ReadonlyArray<DwellingWorksiteOverview> = [];
    filteredDwellingsData: ReadonlyArray<DwellingActivityOverview> = [];
    filteredWorksitesData: ReadonlyArray<DwellingWorksiteOverview> = [];

    graphArray: GraphElement[] = [];
    newGraphData: Array<
        Entry<
            ActivityStatus,
            Array<{
                propertyId: string;
                dwellingId: string;
            }>
        >
    > = [];
    showFilter = true;
    filterIsActive = false;
    newEndpointCalled = true;
    previousGraphWidth = 0;
    graphBlockWidth = 900;
    resizedEvent: any;

    constructor(
        public filterSessionStorage: ActivityFilterSessionStorageService,
        public mainTemplate: TemplateComponent,
        private messageService: MessageService,
        private globalVariables: GlobalVariables,
        private propertyService: PropertyService,
        private activityService: ActivityOverviewService,
        private worksiteDwellingService: WorksiteDwellingService,
        private filterReferencesService: FilterReferencesService,
        private translate: TranslateService,
        private changeDetectorRef: ChangeDetectorRef,
        private isInternal: AuthorizationService
    ) {}

    ngOnInit(): void {
        this.translate.setDefaultLang('fr');
        if (!this.isInternal.isExternalAccountType()) {
            this.getProperties();
        } else {
            if (this.mainTemplate.setSelectionDisplayed()) {
                this.showSpinner = false;
            } else {
                this.getProperties();
            }
            this.messagesSubscription = this.messageService.getMessage().subscribe((message) => {
                if (message) {
                    this.message = message;
                    if (
                        this.message.text === 'refresh ActivitiesDashboardComponent' ||
                        this.message.text === 'btn-return'
                    ) {
                        this.getProperties();
                        this.messageService.clearMessage();
                    }
                }
            });
        }
        const matchMedia = window.matchMedia('(max-width: 1150px)');
        this.showFilter = !matchMedia.matches;
    }

    handleResize(event: ResizeObserverEntry): void {
        if (event && this.previousGraphWidth !== event.contentRect.width) {
            if (this.resizedEvent) {
                if (this.resizedEvent.isFirst) {
                    this.resizedEvent.isFirst = false;
                    this.previousGraphWidth = event.contentRect.width;
                    if (this.graphBlockWidth > event.contentRect.width)
                        this.graphBlockWidth = Math.round(event.contentRect.width * 0.9);
                } else if (event.contentRect.width >= 1000) {
                    this.graphBlockWidth = 900;
                } else {
                    this.graphBlockWidth = Math.round(event.contentRect.width * 0.9);
                }
                this.refreshData('');
                this.previousGraphWidth = event.contentRect.width;
            } else {
                this.resizedEvent = event;
                this.resizedEvent['isFirst'] = true;
            }
        }
    }

    getPropertiesIds(): string[] | undefined {
        const fromCache = sessionStorage.getItem('internal_client_properties')
            ? sessionStorage.getItem('internal_client_properties')?.split(',')
            : [];
        return this.isInternal.isExternalAccountType() ? [] : fromCache;
    }

    getProperties(): void {
        const list = this.getPropertiesIds();
        // Récupération par paquet des données pour une connexion interne avec beaucoup d'UEX
        // const propertyPackages: Array<string[]> = [];
        // if (list && list.length > 500) {
        //     for (var i = 0; i < list.length; i += 100) {
        //         let onePackage: string[] = [];
        //         for (var j = i; j < i + 100; j++) {
        //             if (list[j]) onePackage.push(list[j]);
        //         }
        //         propertyPackages.push(onePackage);
        //     }
        // } else {
        //     propertyPackages.push(list as string[]);
        // }
        // forkJoin(
        //     propertyPackages.map(propertiesNumbers => {
        //         return this.propertyService.fetchByIds(propertiesNumbers);
        //     })
        // ).subscribe(properties => {
        //     properties.forEach(propertiesBlock => {
        //         this.propertyList = [...this.propertyList, ...propertiesBlock] as Array<Property>;
        //     });
        // });
        // this.refreshData('');
        //
        this.propertyService.fetchByIds(list).subscribe((properties) => {
            this.propertyList = properties as Array<Property>;
            this.refreshData('');
        });
    }

    fetchApi(date: string, propertiesNumbers: ReadonlyArray<string>): Promise<any> {
        return Promise.all([
            this.activityService.fetchActivitiesOverview(OverviewMode.DWELLING, date, propertiesNumbers),
            this.activityService.fetchActivitiesOverview(OverviewMode.METER, date, propertiesNumbers),
            this.worksiteDwellingService.fetchDwellingAndMetersWorksiteOverview(
                OverviewMode.DWELLING,
                date,
                propertiesNumbers,
                true
            ),
        ]);
    }

    /**
     * Function called if filters are modified
     * @param message
     */
    refreshData(message: string): void {
        this.filteredDataForSpinner = [];
        if (message === 'filters.properties') {
            this.dataTotal = [];
            this.dwellingsData = [];
        }
        const propertiesFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.PROPERTIES);
        const addressesFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.ADDRESSES);
        const activityTypesFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.ACTIVITY_TYPES);
        const statusesFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.STATUSES);
        const dateFilter = this.filterSessionStorage.getFiltersProperty<string>(FilterName.DATES);
        let propertiesNumbers = handlePropertySelection(
            this.filterSessionStorage,
            StorageName.ACTIVITY_STORAGE_NAME,
            this.isInternal.isInternalAccountType()
        );
        if (this.isInternal.isInternalAccountType() && propertiesNumbers.length === 0) {
            const fromCache = sessionStorage.getItem('internal_client_properties')
                ? (sessionStorage.getItem('internal_client_properties')?.split(',') as ReadonlyArray<string>)
                : [];
            propertiesNumbers = propertiesFilter.length > 0 ? propertiesFilter : fromCache;
        }
        const date = this.globalVariables.activityLimitDate().toISOString().split('T')[0];
        if (!this.dwellingsData?.length) {
            this.showSpinner = true;
            this.changeDetectorRef.detectChanges();
            this.fetchApi(date, propertiesNumbers).then((res) => {
                this.dwellingsData = res[0] as DwellingActivityOverview[];
                this.metersData = res[1] as MeterActivityOverview[];
                this.worksitesData = res[2] as DwellingWorksiteOverview[];
                if (isDevMode()) {
                    this.debug();
                }
                this.newApplyFilters(
                    propertiesFilter,
                    addressesFilter,
                    activityTypesFilter,
                    statusesFilter,
                    dateFilter
                );
                this.initFiltersData(propertiesFilter, addressesFilter);
                this.showSpinner = false;
                this.changeDetectorRef.detectChanges();
            });
        } else {
            this.newApplyFilters(propertiesFilter, addressesFilter, activityTypesFilter, statusesFilter, dateFilter);
        }
        this.checkFilters();
    }

    debug() {
        console.log('signalements/overview/flat - Dwelling :>> ', this.dwellingsData);
        console.log('signalements/overview/flat - Meter :>> ', this.metersData);
        console.log('worksites/overview/flat - QDC Dwellings :>> ', this.worksitesData);
        console.log(
            'this.installations :>> ',
            this.worksitesData.flatMap((w) => w.installations)
        );
    }

    /**
     * Set up the filters fields with the data retrieved from backend
     * @param propertiesFilter
     * @param addressesFilter
     */
    initFiltersData(propertiesFilter: ReadonlyArray<string>, addressesFilter: ReadonlyArray<string>): void {
        const properties = this.propertyList;
        this.propertyFilterData = this.filterReferencesService.preparePropertyData(
            properties.filter((p) =>
                addressesFilter && addressesFilter.length > 0
                    ? addressesFilter.map((id) => id.substring(0, 8)).includes(p.id)
                    : true
            )
        );
        this.addressFilterData = this.filterReferencesService.newPrepareActivityAddressData(
            this.filteredDwellingsData.filter((d) =>
                propertiesFilter && propertiesFilter.length > 0 ? propertiesFilter.includes(d.propertyId) : true
            )
        );
    }

    /**
     * Check and Set filters if they are filled
     * @param propertiesFilter
     * @param addressesFilter
     * @param activityTypesFilter
     * @param statusesFilter
     * @param dateFilter
     * @returns array of filters
     */
    checkAndSetFilters(
        propertiesFilter: ReadonlyArray<string>,
        addressesFilter: ReadonlyArray<string>,
        activityTypesFilter: ReadonlyArray<string>,
        statusesFilter: ReadonlyArray<string>,
        dateFilter: string | undefined
    ): any[] {
        const filters = [];

        if (propertiesFilter?.length) {
            filters.push((data: DwellingActivityOverview[]) =>
                data.filter((d) => propertiesFilter.includes(d.propertyId))
            );
        }

        if (addressesFilter?.length) {
            filters.push((data: DwellingActivityOverview[]) =>
                data.filter((d) => addressesFilter.includes(d.dwellingId))
            );
        }

        if (activityTypesFilter?.length) {
            filters.push((data: DwellingActivityOverview[]) =>
                data.filter((d) => activityTypesFilter.includes(d.type.toUpperCase()))
            );
        }

        if (statusesFilter?.length) {
            filters.push((data: DwellingActivityOverview[]) =>
                data.filter((d) => d.statuses.find((s: any) => statusesFilter.includes(s.status)))
            );
        }

        if (dateFilter?.length) {
            filters.push((data: DwellingActivityOverview[]) =>
                data.filter((d) => d.lastSignalementCreationDate > dateFilter)
            );
        }

        return filters;
    }

    /**
     * Apply filters and update all datas
     * @param propertiesFilter
     * @param addressesFilter
     * @param activityTypesFilter
     * @param statusesFilter
     * @param dateFilter
     */
    newApplyFilters(
        propertiesFilter: ReadonlyArray<string>,
        addressesFilter: ReadonlyArray<string>,
        activityTypesFilter: ReadonlyArray<string>,
        statusesFilter: ReadonlyArray<string>,
        dateFilter: string | undefined
    ): void {
        const filteredDatas = this.checkAndSetFilters(
            propertiesFilter,
            addressesFilter,
            activityTypesFilter,
            statusesFilter,
            dateFilter
        );
        this.filteredDwellingsData = filteredDatas.reduce((data, filter) => filter(data), this.dwellingsData.slice());

        this.filteredWorksitesData = this.worksitesData
            .filter((w) => (propertiesFilter?.length ? propertiesFilter.includes(w.propertyId) : true))
            .filter((w) => (addressesFilter?.length ? addressesFilter.includes(w.dwellingId) : true))
            .filter((w) =>
                activityTypesFilter?.length ? activityTypesFilter.find((value) => value !== 'MAINTENANCE') : true
            )
            .filter((w) =>
                statusesFilter?.length ? w.installations.filter((i) => statusesFilter.includes(i.meterStatus)) : true
            )
            .filter((w) => (dateFilter?.length ? w.lastVisitDate > dateFilter : true));

        this.initFiltersData(propertiesFilter, addressesFilter);
        this.newBuildStatistics(this.filteredDwellingsData, statusesFilter);
    }

    /**
     * Build the Synthesis Chart
     * @param dwellings
     * @param statusesFilter
     */
    newBuildStatistics(
        dwellings: ReadonlyArray<DwellingActivityOverview>,
        statusesFilter: ReadonlyArray<string>
    ): void {
        this.graphArray = [];
        this.newGraphData = [];
        this.filteredWorksitesData.forEach((w) => {
            const uniqueInstallations: any[] = Array.from(
                w.installations.reduce((m, t) => m.set(t.id, t), new Map()).values()
            );
            uniqueInstallations.forEach((i) => {
                this.graphArray.push({
                    propertyId: w.propertyId,
                    dwellingId: w.dwellingId,
                    status: i.meterStatus,
                });
            });
        });
        Object.keys(ActivityStatus)
            .filter((item) => isNaN(Number(item)))
            .filter((status) => (statusesFilter && statusesFilter.length > 0 ? statusesFilter.includes(status) : true))
            .forEach((status) => {
                const elements = dwellings.filter((d) => d.statuses.find((s) => s.status === status));
                elements.forEach((e) => {
                    const currentElement = e.statuses.find((s) => s.status === status);
                    for (let i = 0; i < Number(currentElement?.count); i++) {
                        this.graphArray.push({
                            propertyId: e.propertyId,
                            dwellingId: e.dwellingId,
                            status: status,
                        });
                    }
                });
                const installations = this.graphArray.filter((i) => i.status === status);
                this.newGraphData.push({
                    key: status as ActivityStatus,
                    value: installations
                        .map((i) => {
                            return {
                                propertyId: i.propertyId,
                                dwellingId: i.dwellingId,
                            };
                        })
                        .flat(),
                });
            });
        console.log('this.newGraphData :>> ', this.newGraphData);
    }

    toggleFilter(): void {
        this.showFilter = !this.showFilter;
    }

    showFilterTooltip(): string {
        return this.showFilter
            ? this.translate.instant('filters.showFilters')
            : this.translate.instant('filters.hideFilters');
    }

    getActualDate(): string {
        return new Date().toLocaleDateString();
    }

    checkFilters(): void {
        const filters = this.filterSessionStorage.getFilters();
        this.filterIsActive = !(
            !filters ||
            (filters &&
                filters.addresses.length === 0 &&
                filters.properties.length === 0 &&
                filters.statuses.length === 0 &&
                filters.activityTypes.length === 0 &&
                filters.dates.length === 0)
        );
    }

    ngOnDestroy(): void {
        if (this.messagesSubscription) {
            this.messagesSubscription.unsubscribe();
        }
    }
}
