<ista-login-page [showTabs]="showTabs" [activeTab]="activeTab" firstTabKey={{firstTabKey}}
    firstTabText="{{'login.role.extern' | translate}}" secondTabKey={{secondTabKey}}
    secondTabText="{{'login.role.intern' | translate}}" usernameTitleText="{{'login.username.title' | translate}}"
    usernamePlaceholderText="{{'login.username.text.external' | translate}}"
    usernamePlaceholderSecondTabText="{{'login.username.text.internal' | translate}}"
    passwordTitleText="{{'login.password.title' | translate}}"
    passwordPlaceholderText="{{'login.password.text.external' | translate}}"
    passwordPlaceholderSecondTabText="{{'login.password.text.internal' | translate}}"
    resetButtonText="{{'login.reset.title' | translate}}" resetUrl={{externalResetUrl}}
    loginButtonText="{{'login.enter' | translate}}" loginFailedText="{{'login.bad' | translate}}"
    [defaultLandingPage]="landingPage" insufficientRightsText="{{'login.insufficientRights' | translate}}"
    internalErrorMessage="{{'login.internalErrorMessage' | translate}}" title="Mes chantiers - Mes interventions"
    colorClass="blue" imgColor="white" faq="{{'footer.faq' | translate}}" mentions="{{'footer.mentions' | translate}}"
    conditions="{{'footer.conditions' | translate}} " contact="{{'footer.contact' | translate}}">
</ista-login-page>
