import { Injectable } from '@angular/core';
import { ActivityStatus } from '@extern/activities/enum/activity-status.enum';
import { WorksiteDwellingStatus } from '@mes-chantiers/enum/worksite-period-status.enum';
import { WorksiteColorService } from '@mes-chantiers/service/worksite-color.service';

@Injectable({
    providedIn: 'root',
})
export class ActivityColorService {
    constructor(private worksiteColorService: WorksiteColorService) {}

    /**
     * Get color given customerActionRequired and status
     */
    public getColor(status: string, customerActionRequired: boolean, remainingInstallation: boolean): string {
        let color = '';
        const worksiteStatus = (<any>WorksiteDwellingStatus)[status];
        if (status === ActivityStatus.TO_PLAN) {
            return customerActionRequired || remainingInstallation ? '#E63F0C' : '#F2AF00';
        }
        switch (status) {
            case ActivityStatus.HELP_US:
                return '#e73c06';
            case ActivityStatus.TO_PLAN:
                return 'var(--ista-dark-orange)';
            case ActivityStatus.FINISHED:
                return 'var(--ista-dark-green)';
            case ActivityStatus.PLANNED:
                return '#0071ff';
            case ActivityStatus.IN_PROGRESS:
                return '#1beb83';
            case ActivityStatus.NOT_EQUIPABLE:
                return '#A9B4C0';
            default:
                return worksiteStatus ? this.worksiteColorService.getColor(worksiteStatus) : '';
        }
    }

    /**
     * Get color given status
     */
    public getColorByCode(status: ActivityStatus): string {
        const worksiteStatus = (<any>WorksiteDwellingStatus)[status];
        switch (status) {
            case ActivityStatus.HELP_US:
                return '#e73c06';
            case ActivityStatus.TO_PLAN:
                return 'var(--ista-dark-orange)';
            case ActivityStatus.FINISHED:
                return 'var(--ista-dark-green)';
            case ActivityStatus.PLANNED:
                return 'var(--ista-sky-blue)';
            case ActivityStatus.IN_PROGRESS:
                return 'var(--ista-lime-green)';
            case ActivityStatus.NOT_EQUIPABLE:
                return '#A9B4C0';
            default:
                return worksiteStatus ? this.worksiteColorService.getColor(worksiteStatus) : '';
        }
    }
}
