import {Injectable} from '@angular/core';
import {ActivityStatus} from '@extern/activities/enum/activity-status.enum';

import {WorksiteStatus} from '../enum/woksite-status.model';
import {WorksiteDwellingStatus} from '../enum/worksite-period-status.enum';
import {Installation} from '../model/installation.model';
import {WorksiteDwellingDetails} from '../model/worksite-dwelling-details.model';
import {WorksitePeriod} from '../model/worksite-period.model';
import {WorksiteVisit} from '../model/worksite-visit.model';

@Injectable({
    providedIn: 'root'
})
export class WorksiteStatusService {

    getWorksiteStatus(st: WorksiteDwellingStatus): WorksiteStatus {
        switch (st) {
            case WorksiteDwellingStatus.FINISHED:
                return WorksiteStatus.FINISHED;
            case WorksiteDwellingStatus.TO_PLAN:
                return WorksiteStatus.TO_PLAN;
            case WorksiteDwellingStatus.IN_PROGRESS:
                return WorksiteStatus.IN_PROGRESS;
            case WorksiteDwellingStatus.PLANNED:
                return WorksiteStatus.PLANNED;
            case WorksiteDwellingStatus.NOT_INSTALLED_YET:
                return WorksiteStatus.FINISHED;
            default:
                return WorksiteStatus.TO_PLAN;
        }
    }


    changeWorksiteStatus(dwellings: Array<WorksiteDwellingDetails>): Array<WorksiteDwellingDetails> {
        const data = dwellings.filter(d => d.installations.length > 0);
        const allEqual = (arr: Array<WorksitePeriod>) => arr.every(val =>
            this.getWorksiteStatus(val.status) === this.getWorksiteStatus(arr[0].status));
        const dataWorksites = data.map(d => d.worksites)
            .reduce((pre, cur) => pre.concat(cur), [])
            .filter((value, index, self) => self.findIndex(t => t.id === value.id) === index);
        const dataPeriods = data.map(d => d.periods)
            .reduce((pre, cur) => pre.concat(cur), []);
        dataWorksites.forEach(wor => {
            let status: WorksiteStatus;
            const periods = dataPeriods.filter(pe => pe.id.startsWith(wor.id));
            if (periods.length > 0 && allEqual(periods)) {
                status = this.getWorksiteStatus(periods[0].status);
            } else if (periods.some(w => this.getWorksiteStatus(w.status) === WorksiteStatus.IN_PROGRESS)) {
                status = WorksiteStatus.IN_PROGRESS;
            } else if (periods.some(w => this.getWorksiteStatus(w.status) === WorksiteStatus.FINISHED)) {
                status = WorksiteStatus.IN_PROGRESS;
            } else if (periods.some(w => this.getWorksiteStatus(w.status) === WorksiteStatus.PLANNED)) {
                status = WorksiteStatus.PLANNED;
            } else {
                status = WorksiteStatus.TO_PLAN;
            }
            data.forEach(d => {
                if (wor.propertiesIds.includes(d.property.id)) {
                    d.worksites.forEach(w => {
                        if (w.id === wor.id && w.status !== status) {
                            w.status = status;
                        }

                    });
                }
            });
        });
        return data;
    }

    getStatus(installation: Installation, visits: Array<WorksiteVisit>): ActivityStatus {

        if (installation.installed) {
            return ActivityStatus.FINISHED;
        }

        const plannedVisits = visits.filter(v => installation.id.startsWith(v.worksiteId) &&
            v.dwellingsIds.includes(installation.dwellingId)
            && new Date(v.planificationPeriod.end).getTime() >= new Date().getTime());

        //correction d'un FSD que demandait d'afficher les visites avec dates passés mais pas encore de données dans la base recyclé, ces données on le metait comme PLANNED
        //2eme FSD car il y a des cas faux due a des passages rensignées dans l'AS400 et jamais réalisés

        const inProgressVisits = visits.filter(v => installation.id.startsWith(v.worksiteId) &&
            v.dwellingsIds.includes(installation.dwellingId)
            && new Date(v.planificationPeriod.end).getTime() < new Date().getTime() && (v.installationsStatuses.length === 0 || !v.installationsStatuses[0].visitDate));

        if (inProgressVisits.length != 0) {
            return ActivityStatus.IN_PROGRESS;
        } else if (plannedVisits.length === 0) {
            return ActivityStatus.HELP_US;
        } else if (plannedVisits.length != 0) {
            return ActivityStatus.PLANNED;
        }

        return ActivityStatus.HELP_US;
    }

}
