import { NgModule } from '@angular/core';

import { ExternModule } from '@extern/extern.module';
import { InternModule } from '@intern/intern.module';
import { OopsModule } from '@oops/oops.module';

@NgModule({
    imports: [ExternModule, InternModule, OopsModule],
})
export class FeaturesModule {}
