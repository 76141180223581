import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AuthenticationService, UserInfo } from '@ista/shared-ui';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutComponent {

    redirectAfterLogout = true;
    @Input() userInfos: UserInfo;
    @Input() connectedAs: string;
    @Input() userAuthority: string;

    constructor(private authenticationService: AuthenticationService) {
    }

    logout(): void {
        this.authenticationService.logout(this.redirectAfterLogout);
    }
}
