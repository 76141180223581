import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { Reference } from '@ista/shared-ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Dwelling } from '../../../../core/model/dwelling.model';
import { DwellingService } from '../../../../core/service/dwelling.service';
import { ActivityDwellingDetails } from '../model/dwelling-activities-details.model';
import { NewDwellingActivities } from '../model/new-dwelling-activities.model';

@Injectable({
    providedIn: 'root'
})
export class ActivityDwellingService implements DwellingService {

    baseUrl: string;
    newBaseUrl: string;

    constructor(
        private httpClient: HttpClient,
        @Inject('CONTEXT_API') contextApi: string) {
        this.baseUrl = `${contextApi}/activities/dwellings`;
        this.newBaseUrl = `${contextApi}/signalements/overview`;
    }

    fetch(): Observable<ReadonlyArray<Dwelling>> {
        if (isDevMode()) {
            console.log('Using url to fetch dwellings : ' + this.baseUrl);
        }

        return this.httpClient.get<ReadonlyArray<Dwelling>>(this.baseUrl);
    }

    fetchById(id: string): Observable<Dwelling> {
        const url = `${this.baseUrl}/${id}`;

        if (isDevMode()) {
            console.log('Using url to fetch dwellings : ' + url);
        }

        return this.httpClient.get<ReadonlyArray<Dwelling>>(url).pipe(map(dwellings => dwellings[0]));
    }

    fetchDetails(id: string): Observable<ActivityDwellingDetails> {
        const url = `${this.baseUrl}/${id}/details`;

        if (isDevMode()) {
            console.log('Using url to fetch dwelling details : ' + url);
        }

        return this.httpClient.get<ReadonlyArray<ActivityDwellingDetails>>(url).pipe(map(details => details[0]));
    }

    fetchDwellingsDetails(
        propertiesNumbers: ReadonlyArray<string>,
        dwellingsIds: ReadonlyArray<string>,
        date: string): Observable<ReadonlyArray<ActivityDwellingDetails>> {
        let urlStart = `${this.baseUrl}`;
        let urlEnd = `/details`;

        let parameterAdded = false;
        if (dwellingsIds.length > 0) {
            urlStart += `/${dwellingsIds}`;
        }
        if (propertiesNumbers.length > 0) {
            urlEnd += (parameterAdded ? '&' : '?') + `propertiesIds=${propertiesNumbers}`;
            parameterAdded = true;
        }
        if (date !== undefined && date !== '') {
            urlEnd += (parameterAdded ? '&' : '?') + `date=${date}`;
            parameterAdded = true;
        }

        const url = urlStart + urlEnd;

        if (isDevMode()) {
            console.log('Using url to fetch dwelling details : ' + url);
        }

        return this.httpClient.get<ReadonlyArray<ActivityDwellingDetails>>(url);
    }

    fetchDwellingsDetailsByView(viewType: string, propertiesNumbers: ReadonlyArray<string>, date: string): Observable<ReadonlyArray<NewDwellingActivities>> {
        const urlStart = `${this.newBaseUrl}`;
        const urlEnd = `/flat?`;

        let filterUrl = '';

        if (viewType.length > 0) {
            filterUrl += (filterUrl === '' ? '' : '&') + `view=${viewType}`;
        }
        if (propertiesNumbers.length > 0) {
            filterUrl += (filterUrl === '' ? '' : '&') + `propertiesIds=${propertiesNumbers}`;
        }
        if (date !== undefined && date !== '') {
            filterUrl += (filterUrl === '' ? '' : '&') + `date=${date}`;
        }
        const url = urlStart + urlEnd + filterUrl;

        if (isDevMode()) {
            console.log('Using url to fetch installation dwelling details : ' + url);
        }
        return this.httpClient.get<ReadonlyArray<NewDwellingActivities>>(url);
    }

    fetchReferences(): Observable<ReadonlyArray<Reference>> {
        const url = `${this.baseUrl}/references`;

        if (isDevMode()) {
            console.log('Using url to fetch dwelling references : ' + url);
        }

        return this.httpClient.get<ReadonlyArray<Reference>>(url);
    }
}
