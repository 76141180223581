import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivitiesDashboardComponent } from '@extern/activities/pages/activities-dashboard/activities-dashboard.component';
import { ActivitiesComponent } from '@extern/activities/pages/activities/activities.component';
import { DwellingDetailComponent } from '@extern/activities/pages/dwelling-detail/dwelling-detail.component';
import { InstallationsDashboardComponent } from '@extern/worksites/pages/installations-dashboard/installations-dashboard.component';
import { InstallationsComponent } from '@extern/worksites/pages/installations/installations.component';
import { Role } from '@ista/shared-ui';

import { LoginGuard } from '../../core/guard/login.guard';
import { RoleGuard } from '../../core/guard/role.guard';
import { LoginComponent } from '../../shared/components/login/login.component';
import { ActivitiesDetailComponent } from '../extern/activities/pages/activities-detail/activities-detail.component';
import { CustomerUeSelectionComponent } from './customer-ue-selection/pages/customer-ue-selection.component';
import { TemplateComponent } from './pages/template/template.component';

const routes: Routes = [
    {
        path: 'interne',
        children: [
            {
                path: '', children: [
                    {
                        path: 'login',
                        component: LoginComponent,
                        canActivate: [LoginGuard]
                    },
                    {
                        path: '', component: TemplateComponent, children: [
                            {
                                path: 'selection',
                                component: CustomerUeSelectionComponent,
                                canActivate: [RoleGuard],
                                data: { roles: [Role.ROLE_MANAGER, Role.ROLE_USER] }
                            },
                            {
                                path: '',
                                redirectTo: 'selection',
                                pathMatch: 'full'
                            },
                            {
                                path: 'mes-interventions', children: [
                                    {
                                        path: 'tableau-de-bord',
                                        component: ActivitiesDashboardComponent,
                                        canActivate: [RoleGuard],
                                        data: { roles: [Role.ROLE_MANAGER, Role.ROLE_USER] }
                                    },
                                    {
                                        path: '',
                                        redirectTo: 'tableau-de-bord',
                                        pathMatch: 'full'
                                    },
                                    {
                                        path: 'liste', children: [
                                            {
                                                path: '',
                                                component: ActivitiesComponent,
                                                canActivate: [RoleGuard],
                                                data: { roles: [Role.ROLE_MANAGER, Role.ROLE_USER] }
                                            },
                                            {
                                                path: 'detaillee',
                                                component: ActivitiesDetailComponent,
                                                canActivate: [RoleGuard],
                                                data: { roles: [Role.ROLE_MANAGER, Role.ROLE_USER] }
                                            },
                                            {
                                                path: 'logement/:id',
                                                component: DwellingDetailComponent,
                                                canActivate: [RoleGuard],
                                                data: { roles: [Role.ROLE_MANAGER, Role.ROLE_USER] }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'mes-chantiers', children: [
                                    {
                                        path: 'tableau-de-bord',
                                        component: InstallationsDashboardComponent,
                                        canActivate: [RoleGuard],
                                        data: { roles: [Role.ROLE_MANAGER, Role.ROLE_USER] }
                                    },
                                    {
                                        path: '',
                                        redirectTo: 'tableau-de-bord',
                                        pathMatch: 'full'
                                    },
                                    {
                                        path: 'liste', children: [
                                            {
                                                path: '',
                                                component: InstallationsComponent,
                                                canActivate: [RoleGuard],
                                                data: { roles: [Role.ROLE_MANAGER, Role.ROLE_USER] }
                                            },
                                            {
                                                path: 'logement/:id',
                                                component: DwellingDetailComponent,
                                                canActivate: [RoleGuard],
                                                data: { roles: [Role.ROLE_MANAGER, Role.ROLE_USER] }
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    }
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    bootstrap: []
})
export class InternRoutingModule { }
