import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ListUtilService {

  constructor() { }

  public getListElementMaxPositifLength(refereceArray: ReadonlyArray<string>, minPossibleValue: number): number {
    if ((!refereceArray)
      || (refereceArray && !(refereceArray.length))
      || (refereceArray && refereceArray.length && refereceArray.length === 0)) {
        return minPossibleValue > 0 ? minPossibleValue : 0;
      }
  let maxLength = Math.max.apply(Math, refereceArray.map(ra => {return ra ? ra.length : 0;}));    
  return maxLength > minPossibleValue ? maxLength : minPossibleValue;
  }
}
