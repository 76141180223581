<ista-template
    [error]="error"
    [basePath]="basePath"
    [fullName]="fullName"
    [showHelpButton]="true"
    [sidebarItems]="defaultItems"
    colorClass="white"
    welcomeText="{{'header.welcome' | translate}}"
    showHelpText="{{'header.help.show' | translate}}"
    hideHelpText="{{'header.help.hide' | translate}}"
    imgColor="blue"
    errorMessage="{{errorMessage| translate}}"
    faq="{{'footer.faq' | translate}}"
    mentions="{{'footer.mentions' | translate}}"
    conditions="{{'footer.conditions' | translate}} "
    contact="{{'footer.contact' | translate}}"
    userGuide="{{'footer.userGuide' | translate}} "
    userGuideLink="https://www.ista.com/fileadmin/twt_customer/countries/content/France/Documents/Guides/Guide_utilisateurs-Mes-chantiers-Mes_interventions.pdf"
    (logoutEvent)="logout()"
    (refreshEvent)="refresh()" (helpStatusChange)="showHideHelp()" >

</ista-template>
