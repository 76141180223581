
export class InstallationDashboardTypeFluidSortedEnum {

    private constructor(public readonly weight: number, public readonly label: string) {

    }

    static readonly EC = new InstallationDashboardTypeFluidSortedEnum(4000, 'EC');
    static readonly EF = new InstallationDashboardTypeFluidSortedEnum(3000, 'EF');
    static readonly RFC = new InstallationDashboardTypeFluidSortedEnum(2000, 'RFC');
    static readonly CET = new InstallationDashboardTypeFluidSortedEnum(1000, 'CET');

    static getValues(): InstallationDashboardTypeFluidSortedEnum[] {

        return [
            this.EC,
            this.EF,
            this.RFC,
            this.CET
        ];
    }

    static getPositionFromLabel(label: string): number {
        const results = this.getValues().filter(e => e.label === label);
        return results.length > 0 ? results[0].weight : 0;
    }

}
