import { Location } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    isDevMode,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OperatingAgencyService } from '@core/service/operating-agency.service';
import { ActivityStatus } from '@extern/activities/enum/activity-status.enum';
import { MeterDetails } from '@extern/activities/model/meter-details.model';
import { WorksiteActivity } from '@extern/activities/model/worksite-activity.model';
import { ActivityAddRemainingInstallationService } from '@extern/activities/service/activity-add-remaining-installation.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PdfService } from '@shared/service/pdf.service';

import { DwellingDetails } from '../../../../../shared/model/dwelling-details.model';
import { WorksiteDwellingDetails } from '../../../worksites/model/worksite-dwelling-details.model';
import { WorksiteDwellingService } from '../../../worksites/service/worksite-dwelling.service';
import { ActivityVisit } from '../../model/activity-visit.model';
import { Meter } from '../../model/meter.model';
import { Reporting } from '../../model/reporting.model';
import { ActivityDwellingService } from '../../service/activity-dwelling.service';
import { ActivityPropertyService } from '../../service/activity-property.service';
import { ActivitySortingService } from '../../service/activity-sorting.service';
import { MeterService } from '../../service/activity.meter.service';

@Component({
    selector: 'app-dwelling-detail',
    templateUrl: './dwelling-detail.component.html',
    styleUrls: ['./dwelling-detail.component.css'],
})
export class DwellingDetailComponent implements OnInit, AfterViewInit {
    showSpinner = true;
    showHideDetails = false;
    activities: ReadonlyArray<Reporting> = [];
    showPage = false;
    dwellingDetails = new DwellingDetails();
    @ViewChildren('activityRef') activityRef: QueryList<ElementRef>;
    customerActionRequired = false;
    helpPhoneNumber = '-- -- -- -- --';
    dwellingDetailQuantity = 0;

    constructor(
        private router: Router,
        private location: Location,
        private route: ActivatedRoute,
        private operatingAgencyService: OperatingAgencyService,
        private meterService: MeterService,
        private dwellingService: ActivityDwellingService,
        private worksiteService: WorksiteDwellingService,
        private sortingService: ActivitySortingService,
        private propertyService: ActivityPropertyService,
        private translate: TranslateService,
        private changeDetectorRef: ChangeDetectorRef,
        private worksiteRemainingAddService: ActivityAddRemainingInstallationService,
        private pdfService: PdfService
    ) {}

    ngOnInit(): void {
        this.translate.setDefaultLang('fr');
        this.customerActionRequired = false;
    }

    ngAfterViewInit(): void {
        this.route.paramMap.subscribe((params) => {
            const dwellingId = params.get('id');
            this.meterService
                .fetchMetersDetails([], [dwellingId || ''], undefined, true)
                .toPromise()
                .then((dwellingDetails) => {
                    if (dwellingDetails) this.dwellingDetailQuantity = dwellingDetails.length;
                    if (!dwellingDetails || dwellingDetails.length === 0) {
                        this.dwellingDetails = {
                            id: '',
                            customerReference: '',
                            istaReference: '',
                            location: '',
                            owner: '',
                            tenant: '',
                            address: '',
                            postalCode: '',
                            city: '',
                            residence: '',
                            fullAddress: '',
                            propertyId: '',
                            property: {
                                id: '',
                                name: '',
                                address: '',
                                postalCode: '',
                                city: '',
                                fullAddress: '',
                            },
                            numberOfMeters: 0,
                        };
                        this.showSpinner = false;
                        this.changeDetectorRef.detectChanges();
                    } else {
                        this.findActivitiesByDwelling(dwellingDetails).then((activities) => {
                            this.showPage = activities.length > 0;
                            this.activities = this.showPage
                                ? activities.sort((a, b) => b.type.localeCompare(a.type))
                                : activities;
                            this.showSpinner = false;
                            if (dwellingDetails && dwellingDetails.length) {
                                this.dwellingDetails = {
                                    numberOfMeters: 0,
                                    property: dwellingDetails[0].property,
                                    ...dwellingDetails[0].dwelling,
                                };
                                if (!this.dwellingDetails.property.name) this.dwellingDetails.property.name = '';
                                this.dwellingDetails.residence = this.dwellingDetails.property.name;
                                if (!this.dwellingDetails.customerReference)
                                    this.dwellingDetails.customerReference = '';
                                if (!this.dwellingDetails.owner) this.dwellingDetails.owner = '';
                                if (!this.dwellingDetails.tenant) this.dwellingDetails.tenant = '';
                            }
                            this.changeDetectorRef.detectChanges();
                        });
                    }
                });
        });
    }

    exportDwelling(source: string): void {
        if (source === 'pdf') {
            this.pdfService.generateDwellingFormPdf(this.activityRef, this.dwellingDetails, 'portrait');
        }
    }

    public backToDwellingList(): void {
        this.location.back();
    }

    public isValidDwellingNumber(id: string): boolean {
        return id.length === 16 && !isNaN(+id.substring(0, 8));
    }

    public findActivitiesByDwelling(metersDetails: ReadonlyArray<MeterDetails>): Promise<Array<Reporting>> {
        if (!metersDetails || !metersDetails.length) {
            return Promise.resolve([]);
        }
        return new Promise((resolve) => {
            const promises = metersDetails
                .map((meter) => {
                    return meter.activities.map((activity) => {
                        const worksiteActivity = <WorksiteActivity>activity;
                        const currentReporting: Reporting = {
                            id: worksiteActivity.id,
                            meterId: worksiteActivity.meterId,
                            type: worksiteActivity.type,
                            product: worksiteActivity.product,
                            planificationDate: worksiteActivity.planificationDate,
                            creationDate: worksiteActivity.creationDate,
                            status: worksiteActivity.status.startsWith(ActivityStatus.NOT_EQUIPABLE.substring(0, 9))
                                ? ActivityStatus.NOT_EQUIPABLE
                                : worksiteActivity.status, // Corrige un bug PMD pour le statut "NOT_EQUIPPABLE" renvoyé
                            remainingInstallation: worksiteActivity.remainingInstallation,
                            meter: {
                                id: meter.id,
                                number: Number(meter.number),
                                serialNumber: meter.serialNumber,
                                location: meter.location,
                                stateLabel: meter.stateLabel,
                                product: meter.product,
                                productLabel: meter.productLabel,
                            },
                            reason: '',
                            showDetail: false,
                            visits: this.getVisitsFromActivity(worksiteActivity),
                            ...{
                                subject: activity.subject || "Pose d'appareil",
                                dwellingId: meter.dwellingId,
                                customerActionRequired: activity.customerActionRequired,
                            },
                        };

                        if (activity.status === ActivityStatus.HELP_US) {
                            if (!this.customerActionRequired) {
                                this.customerActionRequired = true;
                                currentReporting.customerActionRequired = true;
                            }
                        }

                        if ([ActivityStatus.PLANNED, ActivityStatus.IN_PROGRESS].includes(currentReporting.status)) {
                            currentReporting.planificationDate =
                                currentReporting.visits.map((visit) => visit.planificationDate).pop() || '';
                        }

                        if (currentReporting.status === ActivityStatus.FINISHED) {
                            currentReporting.planificationDate =
                                currentReporting.visits.map((visit) => visit.accomplishmentDate).pop() || '';
                        }

                        currentReporting.visits = currentReporting.visits.filter((v) => v.accomplishmentDate);
                        return Promise.resolve(currentReporting);
                    });
                })
                .reduce((p, c) => p.concat(c), []);
            if (this.customerActionRequired) {
                this.getOperatingAgencyPhoneNumberByPropertyId(metersDetails[0].property.id).then((agencyInfo) => {
                    for (const key in agencyInfo) {
                        this.helpPhoneNumber = agencyInfo[key] as string;
                        if (isDevMode()) {
                            console.log(
                                'Request result: operatingAgency=' + key + ' phoneNumber=' + this.helpPhoneNumber
                            );
                        }
                        break;
                    }
                    this.changeDetectorRef.detectChanges();
                });
            }
            Promise.all(promises).then((reporting) => {
                resolve(reporting);
            });
        });
    }

    private getVisitsFromActivity(activity: WorksiteActivity) {
        if (activity.type === 'INSTALLATION') {
            return (activity?.periods || [])
                .map((period) => {
                    return period.visits.map((visit) => {
                        return <ActivityVisit>{
                            id: visit.id,
                            accomplishmentDate: visit.accomplishmentDate,
                            planificationDate: visit.planificationDate,
                            notResolvedReason: visit.notInstalledLabel,
                            notResolvedReasonCode: visit.notInstalledCode,
                            comment: visit.comment,
                        };
                    });
                })
                .reduce((p, c) => p.concat(c), []);
        } else {
            return ((<any>activity)?.visits || []).map((visit: any) => {
                return <ActivityVisit>{
                    id: visit.id,
                    accomplishmentDate: visit.accomplishmentDate,
                    planificationDate: visit.planificationDate,
                    notResolvedReason: visit.notInstalledLabel,
                    notResolvedReasonCode: visit.notInstalledCode,
                    comment: visit.comment,
                };
            });
        }
    }

    fetchWorksiteData(dwellingsIds: Array<string>): Promise<ReadonlyArray<WorksiteDwellingDetails>> {
        return firstValueFrom(this.worksiteService.fetchDwellingsDetails([], dwellingsIds, [], '', true));
    }

    fetchData(dwellingsIds: Array<string>): Promise<ReadonlyArray<DwellingDetails>> {
        return firstValueFrom(this.dwellingService.fetchDwellingsDetails([], dwellingsIds, ''));
    }

    showHideAllDetails(): void {
        this.showHideDetails = !this.showHideDetails;
        this.activities.forEach((activity) => {
            activity.showDetail = this.showHideDetails;
        });
    }

    getInterventionTypeKey(): String {
        return '';
    }

    private getOperatingAgencyPhoneNumberByPropertyId(propertyId: string): Promise<ReadonlyArray<any>> {
        return firstValueFrom(this.operatingAgencyService.fetchById(propertyId));
    }

    private getMeter(item: Reporting, visits: Array<ActivityVisit>): Promise<Meter> {
        item.visits = visits;
        item.showDetail = false;
        return firstValueFrom(
            this.meterService.findMetersByMeterNumber(item.meterId).pipe(tap((meter) => (item.meter = meter)))
        );
    }
}
