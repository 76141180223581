import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ExchangeDataService {

    private data: BehaviorSubject<any> = new BehaviorSubject('');
    public dataSource$: Observable<any> = this.data.asObservable();

    constructor() { }

    changeValue(value: any): void {
        this.data.next(value);
    }
}