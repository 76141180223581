import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { formatDate } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { MeterType } from '../../../../../shared/enum/meter-type.enum';
import { ActivityStatus } from '../../enum/activity-status.enum';
import { DwellingActivities } from '../../model/dwelling-activities.model';
import { Reporting } from '../../model/reporting.model';
import { ActivityColorService } from '../../service/activity-color.service';

@Component({
    selector: 'app-activity-items',
    templateUrl: './activity-items.component.html',
    styleUrls: ['./activity-items.component.css'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class ActivityItemsComponent implements OnInit {

    dwellingsActivities: Observable<Array<DwellingActivities>>;
    defaultDatePattern = 'DD/MM/YYYY';

    @Input()
    activity: Reporting;

    constructor(
        private translate: TranslateService,
        private activityColorService: ActivityColorService) {
    }

    ngOnInit(): void {
        this.translate.setDefaultLang('fr');
    }

    public identifyMeterIcon(meterType: string): string {
        switch (meterType) {
            case MeterType.RFC:
            case MeterType.SONDE:
                return './assets/images/RFC.png';
            case MeterType.CET:
                return './assets/images/CET.png';
            case MeterType.EF:
                return './assets/images/EF.png';
            case MeterType.EC:
                return './assets/images/EC.png';
            case MeterType.DAAF:
                return './assets/images/DAAF.png';
            default:
                return '';
        }
    }

    public getTranslate(translateKey: string): string {
        let response = '';
        this.translate.get(translateKey).subscribe((translateValue: string) => {
            response = translateValue;
        });
        return response;
    }

    public identifyColor(activity: Reporting): string {
        return this.activityColorService.getColor(activity.status, activity.customerActionRequired, activity.remainingInstallation);
    }

    public showOrHideDetail(activity: Reporting): void {
        activity.showDetail = !activity.showDetail;
    }

    public transformDate(dateToTranform: string): string {
        return formatDate(dateToTranform, this.defaultDatePattern);
    }

    public hasTooltip(): string {
        if (this.activity.status === ActivityStatus.TO_PLAN && this.activity.customerActionRequired) {
            return this.getTranslate('datatable.action');
        } else {
            return '';
        }
    }

    public formatGlobalId(activity: any): string {
        const dwellindId: string = activity.dwellingId;
        const strActivityId: string = '' + activity.id;
        const strActivityMeterNumber: string = '' + activity.meter.number;
        let formatedGlobalId = '';
        if (dwellindId) {
            /* Traitement du cas d'un signalement */
            formatedGlobalId = strActivityId + '_'
                + dwellindId.substring(0, 8) + '_'
                + dwellindId.substring(8) + '_'
                + (strActivityMeterNumber.length === 1 ? '0' + strActivityMeterNumber : strActivityMeterNumber);
        } else if (strActivityId.indexOf('_') !== -1) {
            /* Traitement du cas d'un chantier */
            formatedGlobalId = strActivityId.substring(0, strActivityId.indexOf('_')) + '_'
                + strActivityId.substring(strActivityId.indexOf('_') + 1).substring(0, 8) + '_'
                + strActivityId.substring(strActivityId.indexOf('_') + 1).substring(8, strActivityId.indexOf('_') + 9) + '_'
                + (strActivityMeterNumber.length === 1 ? '0' + strActivityMeterNumber : strActivityMeterNumber);
        }
        return formatedGlobalId;
    }
}
