import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { BaseService } from '@ista/shared-ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WorksitePeriod } from '../model/worksite-period.model';
import { WorksiteUnrecycledData } from '../model/worksite-unrecycled-data.model';
import { Worksite } from '../model/worksite.model';

@Injectable({
    providedIn: 'root'
})
export class WorksiteService implements BaseService<Worksite> {
    baseUrl: string;

    constructor(private httpClient: HttpClient, @Inject('CONTEXT_API') private contextApi: string) {
        this.baseUrl = `${contextApi}/worksites`;
    }

    fetch(): Observable<readonly Worksite[]> {
        return this.fetchWorksites([], [], [], '');
    }

    fetchWorksites(propertiesNumbers: ReadonlyArray<string>,
        dwellingsIds: ReadonlyArray<string>,
        worksitesIds: ReadonlyArray<string>,
        date: string,
        remainingInstallations?: boolean): Observable<ReadonlyArray<Worksite>> {
        let url = `${this.baseUrl}`;

        let filterUrl = '';

        if (dwellingsIds.length > 0) {
            url += `?${dwellingsIds}`;
        }
        if (propertiesNumbers.length > 0) {
            filterUrl = (filterUrl === '' ? '?' : '&') + `propertiesIds=${propertiesNumbers}`;
        }
        if (worksitesIds.length > 0) {
            filterUrl = (filterUrl === '' ? '?' : '&') + `worksitesIds=${worksitesIds}`;
        }
        if (date !== undefined && date !== '') {
            filterUrl = (filterUrl === '' ? '?' : '&') + `date=${date}`;
        }
        if (remainingInstallations !== undefined && remainingInstallations !== null) {
            filterUrl = (filterUrl === '' ? '?' : '&') + `remainingInstallations=${remainingInstallations}`;
        }
        url += filterUrl;

        if (isDevMode()) {
            console.log('Using url to fetch worksites : ' + url);
        }
        return this.httpClient.get<ReadonlyArray<Worksite>>(url);
    }

    fetchById(id: string): Observable<Worksite> {
        return this.fetchWorksites([], [id], [], '').pipe(map(worksites => worksites[0]));
    }

    fetchWorksiteUnrecycledData(propertiesNumbers: ReadonlyArray<string>,
        dwellingsIds: ReadonlyArray<string>,
        worksitesIds: ReadonlyArray<string>,
        remainingInstallations?: boolean): Observable<ReadonlyArray<WorksiteUnrecycledData>> {
        let url = `${this.baseUrl}`;
        url += `/${worksitesIds}/unrecycledData`;
        let filterUrl = '';
        if (propertiesNumbers.length > 0) {
            filterUrl += (filterUrl === '' ? '?' : '&') + `propertiesIds=${propertiesNumbers}`;
        }
        if (dwellingsIds.length > 0) {
            filterUrl += (filterUrl === '' ? '?' : '&') + `dwellingsIds=${dwellingsIds}`;
        }
        if (remainingInstallations !== undefined && remainingInstallations !== null) {
            filterUrl += (filterUrl === '' ? '?' : '&') + `remainingInstallations=${remainingInstallations}`;
        }
        url += filterUrl;
        if (isDevMode()) {
            console.log('Using url to fetch worksites Unrecycled Data : ' + url);
        }
        return this.httpClient.get<ReadonlyArray<WorksiteUnrecycledData>>(url);

    }
    fetchPeriods(): Observable<ReadonlyArray<WorksitePeriod>> {
        return this.httpClient.get<ReadonlyArray<WorksitePeriod>>('assets/data/worksites-periods.json');
    }
}
