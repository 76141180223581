import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService, Reference } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { Entry } from 'src/app/core/model/entry.model';
import { Property } from 'src/app/core/model/property.model';
import { translateSynchronously } from 'src/app/core/util/translate.util';

import { WorksiteDwellingStatus } from '../../enum/worksite-period-status.enum';
import { SyntheseProduitData } from '../../pages/installations-dashboard/installations-dashboard.component';
import { WorksiteColorService } from '../../service/worksite-color.service';
import { WorksiteFilterSessionStorageService } from '../../service/worksite-filter-session-storage.service';

interface Point {
    name: string;
    y: number;
    color: string;
    filterName: Array<string>;
    references: Array<ReadonlyArray<Reference>>;
}

@Component({
    selector: 'app-worksite-synthese-graphs',
    templateUrl: './worksite-synthese-graphs.component.html',
    styleUrls: ['./worksite-synthese-graphs.component.css'],
})
export class WorksiteSyntheseGraphsComponent implements OnInit, OnChanges {
    @Input() data: Array<Entry<string, SyntheseProduitData | ReadonlyArray<Property>>>;
    @Input() prop: string;
    @Input() showSpinner: boolean;
    synthesis1: Chart;
    chartHeight = 280;
    values: Array<Point> = new Array();
    prefix = 'installations.';
    title = '';
    titleTooltipe = '';

    constructor(
        private router: Router,
        private translate: TranslateService,
        private filterSessionStorage: WorksiteFilterSessionStorageService,
        private worksiteColor: WorksiteColorService,
        private isInternal: AuthorizationService
    ) {}

    ngOnInit(): void {
        this.translate.setDefaultLang('fr');
        this.title = this.getTranslate(this.prefix + this.prop + '.title');
        if (this.prop === 'graphProperty') {
            this.titleTooltipe = this.getTranslate('installations.dashboard.propertiesGraph.tooltip.title');
        } else {
            this.titleTooltipe = this.getTranslate('installations.dashboard.metersGraph.tooltip.title');
        }
        this.initCharts(this.isInternal);
    }

    ngOnChanges(): void {
        this.initCharts(this.isInternal);
    }

    initCharts(isInternal: AuthorizationService): void {
        this.values = [];
        if (!this.data) {
            return;
        }
        this.data.forEach((x) => {
            let computedFilterName: Array<string> = [];
            let value = 0;
            const computedReferences: Array<Array<Reference>> = [[]];

            if (this.prop === 'graphProperty') {
                computedFilterName = ['properties'];
                const currentValue = x.value as ReadonlyArray<Property>;
                value = currentValue.length;
                // Add references grouping properties by their names
                currentValue.forEach((v) => {
                    const referenceMatchingWithProperty = computedReferences[0].filter((r) => r.name === v.name);
                    if (referenceMatchingWithProperty && referenceMatchingWithProperty.length === 1) {
                        const ref = computedReferences[0]
                            .filter((r) => r.name === v.name)
                            .map((r) => r.ids.concat(v.id))
                            .flatMap((id) => id);
                        const index = computedReferences[0].findIndex((el) => el.name === v.name);
                        computedReferences[0][index] = { name: v.name, ids: ref };
                    } else {
                        computedReferences[0].push({ name: v.name, ids: [v.id] });
                    }
                });
            } else if (this.prop === 'graphProduct') {
                computedFilterName = ['dwellings'];
                const currentValue = x.value as SyntheseProduitData;
                value = currentValue.value;
                const status = x.key !== 'NOT_EQUIPABLE' ? x.key : 'FINISHED';
                currentValue.dwellings.forEach((v) => computedReferences[0].push({ name: v.name, ids: v.ids }));
            }
            this.values.push({
                name: this.getTranslate(this.prefix + this.prop + '.' + x.key),
                y: value,
                color: this.worksiteColor.getColor(x.key as WorksiteDwellingStatus),
                filterName: computedFilterName,
                references: computedReferences,
            });
        });

        const bubble = this.showSpinner ? false : this.prop === 'graphProduct' ? true : false;
        const filterSessionStorage = this.filterSessionStorage;
        const router = this.router;
        const tooltip = translateSynchronously(this.translate, `${this.prefix}${this.prop}.tooltip`);
        const tooltipText = `${translateSynchronously(this.translate, `${this.prefix}dashboard.graphTooltip`)} ${tooltip}`;
        const showLoading = this.showSpinner;
        const myOption = new Chart({
            chart: {
                type: 'column',
                height: this.chartHeight,
                style: {
                    fontFamily: 'TheSansE5-SemiLight',
                    cursor: 'pointer',
                },
                marginTop: 35,
                marginBottom: 25,
                marginLeft: 55,
                spacingBottom: 25,
                events: {
                    click(): void {
                        router.navigate([
                            isInternal.isInternalAccountType() ? 'interne/mes-chantiers/liste' : 'mes-chantiers/liste',
                        ]);
                    },
                    load(): void {
                        showLoading
                            ? // this.showLoading('<i class="fas fa-spinner fa-pulse" style="color: #84B400; font-size: 40px;"></i>') :
                              this.showLoading('')
                            : this.hideLoading();
                    },
                },
            },
            accessibility: {
                announceNewData: {
                    enabled: true,
                },
            },
            title: {
                text: '',
            },
            xAxis: {
                type: 'category',
                lineWidth: 0,
            },
            yAxis: {
                labels: {
                    enabled: false,
                },
                gridLineWidth: 0,
                visible: false,
                floor: 0,
                minRange: 20,
            },
            loading: {
                labelStyle: {
                    display: 'block',
                    top: '30%',
                    opacity: 1,
                    left: '-18px',
                },
                style: {
                    display: 'block',
                    top: '30%',
                    left: '-18px',
                    opacity: 1,
                },
            },
            legend: {
                enabled: bubble,
                useHTML: true,
                align: 'left',
                layout: 'vertical',
                labelFormat: this.createLabel(),
                y: (this.getY() * this.chartHeight) / 100,
            },
            tooltip: {
                backgroundColor: '#1E293B',
                borderRadius: 10,
                borderWidth: 1,
                borderColor: '#1E293B',
                style: {
                    border: '1px solid #1E293B',
                    background: '#1E293B',
                    color: '#F4F7F9',
                    padding: '10px 10px 10px 10px',
                    fontFamily: 'TheSansE5-Plain',
                    fontSize: '12px',
                    zIndex: 10,
                },
                useHTML: true,
                formatter(): string {
                    const tooltipPrefix = `<div [tooltip]="tooltip">`;
                    const tooltipSuffix = `</div>`;
                    return tooltipPrefix.concat(tooltipText).concat(tooltipSuffix);
                },
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    data: this.values,
                    colorByPoint: true,
                    type: 'column',
                },
            ],
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        overflow: 'allow',
                        crop: false,
                        color: '#526378',
                        y: -30,
                        verticalAlign: 'top',
                        style: {
                            fontSize: '20px',
                        },
                    },
                    pointWidth: 40,
                    events: {
                        legendItemClick: () => {
                            return false;
                        },
                    },
                },
                series: {
                    stacking: 'normal',
                    cursor: 'pointer',
                    point: {
                        events: {
                            click(): void {
                                const point = this as unknown as Point;
                                for (let i = 0; i < point.filterName.length; i++) {
                                    filterSessionStorage.setFiltersProperty(point.filterName[i], point.references[i]);
                                }
                                router.navigate([
                                    isInternal.isInternalAccountType()
                                        ? 'interne/mes-chantiers/liste'
                                        : 'mes-chantiers/liste',
                                ]);
                            },
                        },
                    },
                },
            },
        });
        this.synthesis1 = myOption;
    }

    getTranslate(translateKey: string): string {
        let response = '';
        this.translate.get(translateKey).subscribe((translateValue: string) => {
            response = translateValue;
        });
        return response;
    }

    createLabel(): string {
        let retour = '';
        if (!this.showSpinner) {
            this.values.forEach((x) => {
                if (x.name === translateSynchronously(this.translate, `${this.prefix}${this.prop}.FINISHED`)) {
                    retour += '<div class="legend-item">';
                    retour += '<button class="bulle btn rounded-circle float-left"';
                    retour += 'style="background-color: var(--ista-dark-green); color: white;';
                    retour += 'font-size: 16px; font-weight: bold;width: 52px;';
                    retour +=
                        'height: 52px;">' + this.getLabelValue() + '<span style="font-size: 12px">%</span></button>';
                    retour += '</button> </div>';
                }
            });
        }
        return retour;
    }

    getLabelValue(): number {
        const sum = this.values.reduce((prev, next) => prev + next.y, 0);
        const value = this.values.filter(
            (x) => x.name === translateSynchronously(this.translate, `${this.prefix}${this.prop}.FINISHED`)
        )[0].y;
        return sum === 0 ? 0 : Math.trunc((100 * value) / sum);
    }
    getY(): number {
        let result = 0;
        const max = Math.max(...this.values.map((d) => d.y));
        const value = this.values.filter(
            (x) => x.name === translateSynchronously(this.translate, `${this.prefix}${this.prop}.FINISHED`)
        )[0].y;
        if (value === max) {
            result = value < 65 ? -value : -65;
        } else {
            result = (value * -65) / max;
        }
        return result;
    }

    customGraphClass(): string {
        if (this.prop === 'graphProduct') {
            return 'chartProductGraph';
        }

        return 'chartDwellingGraph';
    }
}
