<div class="wrapper">
    <div class="d-flex justify-content-center" style="color: #0a2864;">
        <button class="btn"><i class="fas fa-user fa-lg"></i> ({{userCount}}) {{'role.user' | translate}}</button>
        <button class="btn"><i class="fas fa-user-shield fa-lg"></i> ({{managementCount}}) {{'role.manager' |
            translate}}</button>
    </div>
    <div class="d-flex justify-content-between">
        <div class="input-group search input-icons">
            <div class="input-icons" style="max-width:400px;margin:auto">
                <i class="fas fa-search icon"></i>
                <input [(ngModel)]="searchTerm" placeholder="{{'intern.users.searchusers' | translate}}"
                    (keyup)="updateFilter()" class="input-field" type="text" placeholder="Username">
            </div>
        </div>
    </div>

    <div (onResize)="onResized()">
        <ngx-datatable #table class="material expandable striped" [rows]="data" [columns]="columns"
            [columnMode]="columnMode.force" [headerHeight]="35" [footerHeight]="35" [rowHeight]="40" [limit]="20"
            [scrollbarH]="true">
        </ngx-datatable>
    </div>
</div>