import { APP_BASE_HREF, PlatformLocation, registerLocaleData } from '@angular/common';
import {
    HttpBackend,
    HttpClientModule,
    HttpEvent,
    HttpEventType,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, ErrorHandler, Inject, Injector, isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { ApmErrorHandler, ApmModule, ApmService } from '@elastic/apm-rum-angular';
// mevodig-4062 import { AuthenticationService, CONTEXT_API, SessionStorage } from '@ista/shared-ui';
import { AuthenticationService, CONTEXT_API, IstaSpinnerModule, SessionStorage } from '@ista/shared-ui';
// mevodig-4062
import { TranslateLoader, TranslateModule, TranslateModuleConfig, TranslateService } from '@ngx-translate/core';
import { appInitializerFactory } from '@shared/function/app-initailizer-factory';
import { ssoService } from '@shared/service/sso.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FeaturesModule } from './features/features.module';

registerLocaleData(localeFr);

export class TranslateHttpBackendLoader implements TranslateLoader {
    constructor(
        private httpHandler: HttpBackend,
        public prefix: string,
        public suffix: string
    ) {}

    getTranslation(lang: string): Observable<any> {
        const httpRequest = new HttpRequest('GET', `${this.prefix}${lang}${this.suffix}`);

        return this.httpHandler.handle(httpRequest).pipe(
            filter((httpEvent: HttpEvent<any>) => httpEvent.type === HttpEventType.Response),
            map((httpEvent: HttpEvent<any>) => httpEvent as HttpResponse<any>),
            map((httpResponse: HttpResponse<any>) => httpResponse.body)
        );
    }
}

export function translateLoaderFactory(httpHandler: HttpBackend): TranslateLoader {
    return new TranslateHttpBackendLoader(httpHandler, './assets/i18n/', '.json');
}

export const TRANSLATE_MODULE_CONFIG: TranslateModuleConfig = {
    loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpBackend],
    },
};

export function getBaseHref(platformLocation: PlatformLocation): string {
    return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        HttpClientModule,
        RouterModule,
        CoreModule,
        MatIconModule,
        FeaturesModule,
        AppRoutingModule,
        TranslateModule.forRoot(TRANSLATE_MODULE_CONFIG),
        ApmModule,
        IstaSpinnerModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        {
            provide: APP_INITIALIZER,
            useFactory: ssoService,
            // mevodig-4062 deps: [AuthenticationService, Router, LocalStorage],
            deps: [AuthenticationService, Router, SessionStorage], // mevodig-4062
            multi: true,
        },
        {
            // https://github.com/ngx-translate/core/issues/517
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true,
        },
        {
            provide: APP_BASE_HREF,
            useFactory: getBaseHref,
            deps: [PlatformLocation],
        },
        {
            provide: ErrorHandler,
            useClass: ApmErrorHandler,
        },
        ApmService,
        MatIconRegistry,
    ],
})
export class AppModule {
    private readonly baseUrl: string;

    constructor(
        service: ApmService,
        private sStorage: SessionStorage,
        @Inject(CONTEXT_API) private contextApi: string
    ) {
        this.baseUrl = `${this.contextApi}`;
        if (!isDevMode()) {
            const apm = service.init({
                serviceName: 'mes-suivis-ui',
                serverUrl: `${this.baseUrl}/monitoring`,
                breakdownMetrics: true,
                apmRequest: ({ xhr }: any) => {
                    const userToken = sStorage.getItem(environment.TOKEN_KEY) as string;
                    xhr.setRequestHeader('Authorization', 'Bearer ' + `${userToken}`);
                    return true;
                },
            });
            apm.setUserContext({
                username: sStorage.getUserInfo().lastName,
                id: sStorage.getUserInfo().login,
            });
        }
    }
}
