<div class="showScrollWhenNeeded" (onResize)="handleResize($event)">
    <div class="d-flex flex-row flex-wrap container-fluid form-title pl-0">
        <div class="row ml-2">
            <div class="col-*">
                <h1 class="form-title" style="margin-top: 4px;">{{'installations.dashboard.title' | translate}}</h1>
            </div>
            <div [tooltip]="showFilterTooltip()" class="col-* filterButton" containerClass="tooltipClass" placement="auto" [ngClass]=" filterIsActive == true ? 'active' : ''">
                <ista-hide-filters [filterSessionStorage]="filterSessionStorage" (toggle)="toggleFilter()"
                    [groupFilters]="gFiltres.refresh" [ignoreFilters]="['columns']">
                </ista-hide-filters>
            </div>
        </div>
    </div>
    <div class="container-fluid p-0">
        <div class="p-2">
            <div class="d-flex flex-row flex-wrap container-fluid" style="padding-left: 20px !important;">
                <div class="container-fluid p-0">
                    <div class="row">
                        <div class="filter" [ngClass]="showFilter? 'col-11 visible': 'col-0 hidden'">
                            <app-group-filters #gFiltres type="worksiteDash" [propertiesReferences]="propertyFilterData"
                                [addressesReferences]="addressFilterData" [activeService]="filterSessionStorage"
                                (refresh)="refreshData($event)" [startDoubleDate]="dateOneYearFromNow"
                                (changeShowFilter)="changeShowFilter($event)">
                            </app-group-filters>
                        </div>
                    </div>
                    <div class="row pt-2 ml-0 justify-content-start">
                        <div class="d-flex flex-row p-1 flex-wrap align-content-around col-* align-self-start">
                            <div class="d-flex flex-column">
                                <app-dashboard-resume [resume]="details" [showSpinner]="showSpinner">
                                </app-dashboard-resume>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row {{additionalClasses}} flex-wrap container-fluid">
                <div class="row {{additionalClasses}}">
                    <div class="col-*-* mt-4 mb-0 ml-5 mr-5">
                        <app-worksite-synthese-graphs class="chartShort" [data]="dataSyntheseProperty"
                            prop="graphProperty" [showSpinner]="showSpinner">
                        </app-worksite-synthese-graphs>
                    </div>
                    <div class="col-*-* mt-4 mb-0 ml-5 mr-5">
                        <app-worksite-synthese-graphs class="chartLong" [data]="dataSyntheseProduit" prop="graphProduct"
                            [showSpinner]="showSpinner">
                        </app-worksite-synthese-graphs>
                    </div>
                </div>
                <div class="row justify-content-center w-100">
                    <div class="col-12 p-0">
                        <div class="d-flex flex-column  m-2">
                            <div class="d-flex flex-row justify-content-end">
                                <div *ngIf="table.isSorted" class="buttonTri">
                                    <button type="button" class="btn buttonClass secondaryButton"
                                        (click)="table.resetSort()">
                                        {{'datatable.buttonSort' | translate}}
                                    </button>
                                </div>
                                <!-- <div *ngIf="!excellReady; then display_spinner else display_excel_icon"></div> -->
                                <div *ngIf="excellReady; then display_excel_icon"></div>
                                <!-- <ng-template #display_spinner>
                                    <div class="flex-row align-items-end flex-column"
                                        [ngStyle]="{'height': showFilter ? 'auto' : '1rem'}"
                                        style="display: flex; flex-direction: row; color: #006EF0; font-size: 1.4rem"
                                        (click)="false; $event.stopPropagation();">
                                        <div class="spinnerText">{{'excel.installations.spinner'| translate}}</div>
                                        <span containerClass="customClass" id="exportGroup">
                                            <i class="fas fa-spinner fa-pulse" style="color: #006EF0;"></i>
                                        </span>
                                    </div>
                                </ng-template> -->
                                <ng-template #display_excel_icon>
                                    <ista-export-data class="ml-auto round-button" id="exportGroup"
                                        (export)="exportInstallationDashboardData($event)"
                                        title="{{'export.installations.excel.table' | translate}}" [showExcel]="true">
                                    </ista-export-data>
                                </ng-template>
                            </div>
                            <div class="row" style="text-align: center;">
                                <div class="title col-12">
                                    <span>{{'installations.dashboard.tableChantiers.title' | translate}} </span>
                                    <ista-interrogation-point placement="right"
                                        htmlText="{{'installations.dashboard.tableChantiers.tooltip.titleTable' | translate}}">
                                    </ista-interrogation-point>
                                </div>
                            </div>
                            <ista-spinner *ngIf="showSpinner" [showSpinner]="showSpinner" style="height: 0px;"
                                customSpinnerClass="spinnerLocation"
                                customTranslationClass="medium"
                                message="{{'filters.noWorksiteForTheSelection' | translate}}"
                                loading="{{'template.loading' | translate}}">
                            </ista-spinner>
                            <app-progress-worksite-table #table [ngStyle]="{'min-width': '800px'}"
                                [addressFilterData]="addressFilterData" (onResize)="handleResize($event)"
                                [dwellingsOverview]="filteredDwellingsData" [metersOverview]="filteredMetersData"
                                [showSpinner]="showSpinner">
                            </app-progress-worksite-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
