import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from '@ista/shared-ui';

import { RoleGuard } from '../../../core/guard/role.guard';
import { DwellingDetailComponent } from '../activities/pages/dwelling-detail/dwelling-detail.component';
import { TemplateComponent } from '../components/template/template.component';
import { InstallationsDashboardComponent } from './pages/installations-dashboard/installations-dashboard.component';
import { InstallationsComponent } from './pages/installations/installations.component';

const routes: Routes = [{
    path: 'mes-chantiers', component: TemplateComponent, children: [
        {
            path: 'tableau-de-bord',
            component: InstallationsDashboardComponent,
            canActivate: [RoleGuard],
            data: { roles: [Role.ROLE_CUSTOMER] }
        },
        {
            path: '',
            redirectTo: 'tableau-de-bord',
            pathMatch: 'full'
        },
        {
            path: 'liste', children: [
                {
                    path: '',
                    component: InstallationsComponent,
                    canActivate: [RoleGuard],
                    data: { roles: [Role.ROLE_CUSTOMER] }
                },
                {
                    path: 'logement/:id',
                    component: DwellingDetailComponent,
                    canActivate: [RoleGuard],
                    data: { roles: [Role.ROLE_CUSTOMER] }
                }
            ]
        }
    ]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class WorksitesRoutingModule { }
