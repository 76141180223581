import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { SessionStorage } from '@ista/shared-ui';

@Injectable({ providedIn: 'root' })
export class SelfcareSessionStorage extends SessionStorage {

    private readonly showDetailedActivities = 'show_detailed_activities';

    constructor(@Inject(PLATFORM_ID) platformId: string) {
        super(platformId);
    }

    isDetailedActivitiesView(): boolean {
        const result = this.getItem<boolean>(this.showDetailedActivities);
        return (result !== undefined) ? result : false;
    }

    setDetailedActivitiesView(showDetailedActivities: boolean): void {
        this.setItem(this.showDetailedActivities, showDetailedActivities);
        this.storageSub.next('sidebarChanges');
    }

}
