import {DatePipe} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {VisitStatus} from '@extern/worksites/enum/visit-status.enum';
import {AuthorizationService, CONTEXT_API, SessionStorage, convertToDateOrReturnDefault, currentDateAsString} from '@ista/shared-ui';
import {GlobalVariables} from '../../../../core/util/global-variables';
import {MeterType} from '../../../../shared/enum/meter-type.enum';
import {WorksiteDwellingStatus} from '../enum/worksite-period-status.enum';
import {InstallationLine} from '../model/installation-line.model';
import {InstallationReport} from '../model/installation-report.model';
import {Installation} from '../model/installation.model';
import {WorksiteDwellingDetails} from '../model/worksite-dwelling-details.model';
import {WorksiteVisit} from '../model/worksite-visit.model';
import {WorksiteColorService} from './worksite-color.service';
import {NewInstallationLine} from '../model/new-installation-line.model';
import {WorksiteFilterSessionStorageService} from './worksite-filter-session-storage.service';
import {FilterName} from '@shared/enum/filter-name.enum';
import {SelfcareIndexedDbService} from '@core/service/selfcare-indexed-db.service';

@Injectable({
    providedIn: 'root'
})

export class InstallationLineService {
    fluidType = [{type: 'compteurs EC', fluid: MeterType.EC}, {type: 'compteurs EF', fluid: MeterType.EF}];
    newBaseUrl: string;

    constructor(
        @Inject(CONTEXT_API) private contextApi: string,
        private globalVariables: GlobalVariables,
        private filterSessionStorage: WorksiteFilterSessionStorageService,
        private sessionStorage: SessionStorage,
        private isInternal: AuthorizationService,
        private indexedDbService: SelfcareIndexedDbService,
        private datePipe: DatePipe,
        private worksiteColor: WorksiteColorService) {
        this.newBaseUrl = `${contextApi}/worksites/overview`;
    }

    getResult(installations: Array<Installation>): ReadonlyArray<string> {
        const result = new Array<string>();
        if (installations.filter(i => i.lastVisitDate !== '').length === installations.length) {
            const installationsMap = this.transformToMap(installations);
            installationsMap.forEach(map => {
                const installation = map.find(m => Number(m.periodId) === Math.max(...map.map(m => Number(m.periodId))));
                if (installation && (installation.installed || installation.lastVisitDate !== '')) {
                    const fluid = this.fluidType.find(f => f.fluid === installation.meterType);
                    let location = installation.location === null ? 'LOC' : installation.location;
                    if (fluid !== undefined) {
                        location += ' - ' + fluid.fluid;
                    }
                    const res = installation.installed ? installation.serialNumber :
                        installation.notInstalledLabel ? installation.notInstalledLabel : '';
                    result.push(location + ' : ' + res);
                }
            });
        }
        if (result.length === 0) {
            result.push('-');
        }
        return result;
    }

    tranformToInstallations(data: ReadonlyArray<WorksiteDwellingDetails>): ReadonlyArray<InstallationLine> {
        const tempLines: Array<InstallationLine> = [];
        data.forEach(dwelling => {
            dwelling.worksites.forEach(wor => {
                const fluids = new Set(
                    dwelling.periods
                        .filter(p => p.worksiteId === wor.id)
                        .flatMap(p => p.products.flatMap(pro => pro.code))
                );
                fluids.forEach(fluid => {
                    const filteredInstallations = dwelling.installations && dwelling.installations.length > 0 ?
                        dwelling.installations.filter(i => i.worksiteId === wor.id && i.meterType === fluid) : [];

                    const filteredVisits = dwelling.visits && dwelling.visits.length > 0 ?
                        dwelling.visits.filter(v => v.status === VisitStatus.FERME && v.worksiteId === wor.id) :
                        [];
                    const periods = dwelling.periods && dwelling.periods.length > 0 ?
                        dwelling.periods.filter(p => wor.periodsIds.includes(p.id)) :
                        [];
                    let statusLine = dwelling.statusesByFluids && dwelling.statusesByFluids.length > 0 ?
                        dwelling.statusesByFluids.find(s => s.worksiteId === wor.id && s.fluid === fluid)?.status :
                        undefined;
                    statusLine = statusLine ? statusLine : WorksiteDwellingStatus.TO_PLAN;
                    if (filteredInstallations.length > 0) {
                        const line: InstallationLine = {
                            property: dwelling.property,
                            dwelling: {
                                id: dwelling.id,
                                propertyId: '',
                                istaReference: dwelling.istaReference,
                                customerReference: dwelling.customerReference,
                                address: dwelling.address,
                                postalCode: dwelling.postalCode,
                                city: dwelling.city,
                                fullAddress: dwelling.fullAddress,
                                location: dwelling.location,
                                owner: dwelling.owner,
                                tenant: dwelling.tenant,
                                residence: dwelling.residence
                            },
                            worksite: wor,
                            periods: periods.length > 0 ? periods : [],
                            visits: filteredVisits,
                            installations: filteredInstallations,
                            address: dwelling.fullAddress === '' ? dwelling.property.fullAddress : dwelling.fullAddress,
                            doneVisits: this.getDoneVisitsCount(filteredVisits, filteredInstallations),
                            lastVisitSlot: this.getLastVisitDate(filteredInstallations),
                            lastVisitResult: this.getResult(filteredInstallations),
                            nextVisitSlot: this.getNextVisitSlot(filteredInstallations, filteredVisits, statusLine),
                            status: this.getStatus(statusLine),
                            statusEnum: statusLine,
                            color: this.getColor(statusLine),
                            planned: this.getPlanned(statusLine, filteredInstallations, periods[0].products[0].planned, wor.type,
                                data.filter(d => d.periods
                                    .find(p => wor.periodsIds.includes(p.id) &&
                                        p.products.filter(pro => pro.code === fluid).length > 0))),
                            report: {} as InstallationReport
                        };
                        tempLines.push(line);
                    }
                });
            });
        });
        return tempLines;
    }

    public getLastVisitDate(installations: Array<Installation>): Array<string> {
        const response = new Array<string>();
        if (installations.filter(i => i.lastVisitDate !== '').length === installations.length) {
            const distinctVisitDates = installations.filter(installation => installation.lastVisitDate !== '').filter(
                (installation, i, arr) => arr.findIndex(t => t.lastVisitDate === installation.lastVisitDate) === i
            );
            if (distinctVisitDates.length > 0) {
                const maxDate = new Date(Math.max(...distinctVisitDates
                    .map(installation => new Date(installation.lastVisitDate).getTime())));
                const date = this.datePipe.transform(maxDate, 'yyyy-MM-dd');
                response.push(date ? date : '');
            }
        }
        return response;
    }

    public updateCachedDataByDwelling(dwellingData: NewInstallationLine): void {
        const startDate = this.filterSessionStorage.getFiltersProperty<string>(FilterName.START_DATE);
        let date = this.globalVariables.worksiteDefaultDate().toISOString().split('T')[0];
        if (startDate && startDate !== '' && new Date(startDate) < new Date(date)) {
            date = startDate;
        }
        const indexedDbKey = `${this.newBaseUrl}` + '/flat?view=DWELLING&date=' + date + '(WithProperties)';
        const accountUuid = this.isInternal.isExternalAccountType() ?
            this.sessionStorage.getExternalUserInfo().accountUuid :
            btoa(this.sessionStorage.getUserInfo().login);
        this.indexedDbService.setDbName(accountUuid);
        this.indexedDbService.get(indexedDbKey).subscribe(value => {
            if (value) {
                const currentProperties = value.properties;
                const currentDwellings = value.result as Array<NewInstallationLine>;
                const dwellingIndex = currentDwellings.findIndex(dwelling => 
                    dwelling.worksiteId === dwellingData.worksiteId
                    && dwelling.dwellingId === dwellingData.dwellingId
                    && dwelling.fluidCode === dwellingData.fluidCode);
                if (dwellingIndex > -1) {
                    currentDwellings.splice(dwellingIndex, 1, dwellingData);
                    this.indexedDbService.saveWithProperties(indexedDbKey, currentDwellings, currentProperties);
                }
            }
        });
    }
    
    private transformToMap(installations: ReadonlyArray<Installation>) {
        const ids = new Set(installations.map(i => i.id));
        const installationsMap = new Map<string, ReadonlyArray<Installation>>();
        ids.forEach(id => {
            installationsMap.set(id, installations.filter(i => i.id === id));
        });
        return installationsMap;
    }

    private getDoneVisitsCount(visits: Array<WorksiteVisit>, installations: Array<Installation>): number {
        if (!visits || visits.length == 0 || !installations || installations.length == 0) {
            return 0;
        }
        const lastPeriod = Math.max(...installations.map(i => Number(i.periodId)));
        const filtredInstallations = installations.filter(i => Number(i.periodId) === lastPeriod);
        const filtredVisits = visits.filter(v => Number(v.periodId) === lastPeriod);

        return filtredVisits && filtredVisits.length > 0 && filtredInstallations && filtredInstallations.length > 0 ?
            filtredVisits.filter(visit => visit.installationsStatuses && visit.installationsStatuses.length > 0 &&
                convertToDateOrReturnDefault(visit.installationsStatuses[0].visitDate, this.globalVariables.activityLimitDate()).getTime() <=
                convertToDateOrReturnDefault(installations[0].lastVisitDate, this.globalVariables.activityLimitDate()).getTime()).length :
            0;
    }

    private getColor(status: WorksiteDwellingStatus): string {
        return this.worksiteColor.getColor(status);
    }

    private getStatus(status: WorksiteDwellingStatus): string {
        return 'datatable.worksite.status.' + status;
    }

    private getNextVisitSlot(
        installations: Array<Installation>,
        visits: Array<WorksiteVisit>,
        periodStatus: WorksiteDwellingStatus): Array<string> {
        const response = new Array<string>();
        if (installations.length > 0
            && periodStatus === WorksiteDwellingStatus.PLANNED) {
            const selectedVisits = visits.filter(v => v.planificationPeriod.start !== null
                && v.planificationPeriod.end !== null
                && (new Date(v.planificationPeriod.start) >= new Date() ||
                    new Date(v.planificationPeriod.end) >= new Date() ||
                    v.planificationPeriod.start.split('T')[0] === currentDateAsString()
                ));
            if (selectedVisits.length > 0) {
                const lastVisit = selectedVisits[0];
                response.push(lastVisit.planificationPeriod.start.split('T')[0]);
                response.push(lastVisit.planificationPeriod.end.split('T')[0]);
            }
        }
        return response;
    }

    private getPlanned(
        status: WorksiteDwellingStatus,
        installations: Array<Installation>,
        planned: number,
        type: string,
        filtredDwellings: ReadonlyArray<WorksiteDwellingDetails>): number {
        if (['NE', 'NA'].includes(type)) {
            // Récupérer le nombre de logements concerné par les installations de la période
            // Afin de le diviser par le nombre d'installation estimé pour la période dans le cas du !WorksiteDwellingStatus.FINISHED
            const dwellings = new Set(filtredDwellings.map(d => d.id)).size;
            // Vu avec Delphine, pour le nombre d'installations estimé faut arrondir à l'entier le plus proche
            return status === (WorksiteDwellingStatus.FINISHED || WorksiteDwellingStatus.FINISHED_REMAINING_INSTALLATION) ? new Set(installations.filter(i => i.installed).map(i => i.meterId)).size :
                Math.round(planned / dwellings);
        } else {
            return new Set(installations.map(i => i.meterId)).size;
        }
    }

}
