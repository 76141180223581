import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';

import { InternRoutingModule } from '../intern/intern-routing.module';
import { IstaUsersListComponent } from './components/ista-users-list/ista-users-list.component';
import { CustomerUeSelectionComponent } from './customer-ue-selection/pages/customer-ue-selection.component';
import { TemplateComponent } from './pages/template/template.component';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { IstaSpinnerModule } from '@ista/shared-ui';

@NgModule({
    declarations: [IstaUsersListComponent, TemplateComponent, CustomerUeSelectionComponent],
    imports: [
        HttpClientModule,
        MatIconModule,
        SharedModule,
        TranslateModule,
        InternRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        IstaSpinnerModule,
    ],
    bootstrap: [TemplateComponent]
})
export class InternModule { }
