import {CONTEXT_API} from "@ista/shared-ui";
import {HttpClient} from "@angular/common/http";
import {Inject, Injectable, isDevMode} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class OperatingAgencyService {

    baseUrl: string;
    contextAPI: string;

    constructor(private httpClient: HttpClient, @Inject(CONTEXT_API) contextApi: string) {
        this.contextAPI = `${contextApi}`;
        this.baseUrl = `${contextApi}/operating-agency`;
    }

    fetch(): Observable<ReadonlyArray<any>> {
        if (isDevMode()) {
            console.log('Using url to fetch operating agencies : ' + this.baseUrl);
        }
        return this.httpClient.get<ReadonlyArray<any>>(this.baseUrl);
    }

    fetchById(id: Readonly<string>): Observable<ReadonlyArray<any>> {
        let url = this.baseUrl;
        if (id && (id.trim().length === 8)) {
            url += '?propertiesIds=' + id.trim();
        }
        if (isDevMode()) {
            console.log('Using url to fetch operating agency phone number : ' + url);
        }
        return this.httpClient.get<ReadonlyArray<any>>(url);

    }


}
