<div class="footerChart ista-dark-blue">
    <div class="container-fluid">
        <div class="row">
            <div [className]="!correctionStyle ? '' : 'paddingCorrection'" class="col-0">
                <div>{{ title | translate }}</div>
            </div>
            <div *ngFor="let entry of entries" class="col">
                <div class="d-flex row justify-content-around">
                    <div class="d-inline-flex bd-highlight">
                        <div [style.color]="entry.color" class="line">
                            <b *ngIf="!showSpinner">{{ entry.value }}</b>
                        </div>
                    </div>
                    <div *ngIf="showSpinner" class="line">
                        <ista-spinner
                            customSpinnerClass="spinner-only-small no-translation"
                            style="position: relative; left: -13px"
                            [showSpinner]="true"
                            [data]="1"
                            [spinnerDiameter]="15"
                        ></ista-spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #spinnerBlock></ng-template>
