import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductsConstants } from '@shared/constants/products.const';
import { firstValueFrom } from 'rxjs';

import { Column } from '../../../../../core/model/column.model';
import { Installation } from '../../model/installation.model';
import { NewInstallationLine } from '../../model/new-installation-line.model';
import { WorksiteUnrecycledData } from '../../model/worksite-unrecycled-data.model';
import { InstallationLineService } from '../../service/installation-line.service';
import { WorksiteService } from '../../service/worksite.service';

@Component({
    selector: 'app-installation-unrecycled-data',
    templateUrl: './installation-unrecycled-data.component.html',
    styleUrls: ['./installation-unrecycled-data.component.css'],
})
export class InstallationUnrecycledDataComponent implements OnInit {
    @Input() data: NewInstallationLine;
    @Input() columns: Column[];
    noData = this.translate.instant('filters.noFound');
    showSpinner = true;
    lines: NewInstallationLine[] = [];
    unrecycledColumns: Column[];
    constructor(
        private translate: TranslateService,
        private installationLineService: InstallationLineService,
        private worksiteService: WorksiteService
    ) {}

    ngOnInit(): void {
        this.translate.setDefaultLang('fr');
        this.getLines();
        this.noData = this.translate.instant('datatable.worksite.unrecycledBox.noData');
        this.unrecycledColumns = this.createColumns();
    }

    createColumns(): Column[] {
        return [
            new Column('datatable.worksite.columns.propertyName', 'propertyName', false, 10),
            new Column('datatable.worksite.columns.propertyAddress', 'address', false, 150),
            new Column('datatable.worksite.columns.dwellingCustomerReference', 'dwellingCustomerReference', false, 80),
            new Column('datatable.worksite.columns.owner', 'owner', false, 120),
            new Column('datatable.worksite.columns.tenant', 'tenant', false, 100),
            new Column('datatable.worksite.columns.product', 'productCode', false, 120),
            new Column('datatable.worksite.columns.visits', 'visitsCount', false, 120),
            new Column('datatable.worksite.columns.dateLastVisit', 'lastVisitDate', false, 150),
            new Column('datatable.worksite.columns.resultLastVisit', 'installations', false),
            new Column('datatable.worksite.columns.nextVisit', 'nextVisitDate', false, 120),
            new Column('datatable.worksite.columns.status', 'dwellingStatus', false, 140),
        ];
    }

    fetchWorksiteUnrecycledData(
        dwellingId: string,
        worksiteId: string
    ): Promise<ReadonlyArray<WorksiteUnrecycledData>> {
        return firstValueFrom(this.worksiteService.fetchWorksiteUnrecycledData([], [dwellingId], [worksiteId], false));
    }

    getLines(): void {
        const data = Object.assign({}, this.data);
        const unrecycled = data.installations.filter((i) => i.pdaStatus && i.pdaStatus === 'INT');
        if (unrecycled && unrecycled.length > 0) {
            let unrecycledInstallations: Installation[] = [];
            data.installations.forEach((installation) => {
                if (installation.pdaStatus === 'INT') {
                    let unrecycledInstallation: Installation = {
                        id: installation.id,
                        dwellingId: installation.dwellingId,
                        meterId: installation.meterId,
                        worksiteId: installation.worksiteId,
                        periodId: installation.periodId,
                        location: installation.pdaLocation,
                        meterLabel: installation.meterLabel,
                        meterType: ProductsConstants.getMeterType(data.productCode, data.fluidCode),
                        meterStatus: installation.meterStatus,
                        serialNumber: installation.pdaSerialNumber,
                        index: installation.index,
                        lastVisitDate: installation.pdaLastVisitDate,
                        periodCreationDate: installation.periodCreationDate,
                        periodNumber: installation.periodNumber,
                        oldDeviceIndex: installation.oldDeviceIndex,
                        oldSerialNumber: installation.pdaOldSerialNumber,
                        installed: installation.installed,
                        notInstalledCode: installation.notInstalledCode,
                        notInstalledLabel: installation.notInstalledLabel,
                        customerFault: installation.customerFault,
                        installable: installation.installable,
                        pdaStatus: '',
                        pdaNotInstalledCode: '',
                        pdaLocation: '',
                        pdaOldSerialNumber: '',
                        pdaLastVisitDate: '',
                        pdaSerialNumber: '',
                    };
                    if (unrecycledInstallation.installed) data.realMetersCount += 1;
                    if (new Date(data.lastVisitDate) < new Date(unrecycledInstallation.lastVisitDate))
                        data.lastVisitDate = unrecycledInstallation.lastVisitDate;
                    unrecycledInstallations.push(unrecycledInstallation);
                } else {
                    unrecycledInstallations.push(installation);
                }
            });
            data.installations = unrecycledInstallations;
            this.lines.push(data);
        }
        this.showSpinner = false;
    }

    getInstallations(
        data: Installation[],
        unrecycled: ReadonlyArray<WorksiteUnrecycledData>
    ): Promise<Array<Installation>> {
        return new Promise((resolve) => {
            const result = new Array<Installation>();
            data.forEach((i) => {
                const r1 = unrecycled.filter((u) => u.meterId === i.meterId && u.periodId === i.periodId);
                if (r1.length > 0) {
                    const r = r1[0];
                    i.serialNumber = r.serialNumber;
                    i.installed = r.serialNumber !== '' ? true : i.installed;
                    i.lastVisitDate = r.lastVisitDate;
                    i.notInstalledCode = r.notInstalledCode;
                    i.notInstalledLabel = r.notInstalledLabel;
                    result.push(i);
                }
            });
            resolve(result);
        });
    }
}
