import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, isDevMode} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Meter} from '../model/meter.model';
import {MeterDetails} from "@extern/activities/model/meter-details.model";

@Injectable({
    providedIn: 'root'
})
export class MeterService {

    baseUrl: string;

    constructor(
        private httpClient: HttpClient,
        @Inject('CONTEXT_API') private contextApi: string) {
        this.baseUrl = `${contextApi}/activities/meters`;
    }

    /**
     * Fetch Meters by meter numbers
     */
    public findMetersByMeterNumber(meterId: string): Observable<Meter> {
        const url = `${this.baseUrl}?metersIds=${meterId}`;
        if (isDevMode()) {
            console.log('Using url to fetch meter : ' + url);
        }
        return this.httpClient.get<ReadonlyArray<Meter>>(url).pipe(map(meters => meters[0]));
    }

    public fetchMetersDetails(
        propertiesIds?: ReadonlyArray<string>,
        dwellingsIds?: ReadonlyArray<string>,
        date?: Readonly<string>,
        remainingInstallations?: Readonly<boolean>
    ): Observable<ReadonlyArray<MeterDetails>> {
        let url = `${this.baseUrl}/details?`;
        let filterUrl = '';
        if (propertiesIds && propertiesIds.length > 0) {
            filterUrl += (filterUrl === '' ? '' : '&') + `propertiesIds=${propertiesIds}`;
        }
        if (dwellingsIds && dwellingsIds.length > 0) {
            filterUrl += (filterUrl === '' ? '' : '&') + `dwellingsIds=${dwellingsIds}`;
        }
        if (date && date !== '') {
            filterUrl += (filterUrl === '' ? '' : '&') + `date=${date}`;
        }
        if (remainingInstallations) {
            filterUrl += (filterUrl === '' ? '' : '&') + `remainingInstallations=${remainingInstallations}`;
        }
        url += filterUrl;
        if (isDevMode()) {
            console.log('Using url to fetch meters details using the url : ' + url);
        }
        return this.httpClient.get<Array<MeterDetails>>(url);
    }

}

