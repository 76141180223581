import { Injectable } from '@angular/core';

import { WorksiteDwellingStatus } from '../enum/worksite-period-status.enum';

@Injectable({
    providedIn: 'root',
})
export class WorksiteColorService {
    constructor() {}

    public getColor(status: WorksiteDwellingStatus): string {
        if (status === WorksiteDwellingStatus.TO_PLAN) {
            return 'var(--ista-dark-orange)';
        } else if (status === WorksiteDwellingStatus.HELP_US) {
            return 'var(--ista-red)';
        } else if (status === WorksiteDwellingStatus.PLANNED) {
            return 'var(--ista-sky-blue)';
        } else if (
            status === WorksiteDwellingStatus.FINISHED ||
            status === WorksiteDwellingStatus.FINISHED_REMAINING_INSTALLATION
        ) {
            return 'var(--ista-dark-green)';
        } else if (status === WorksiteDwellingStatus.NOT_INSTALLED_YET) {
            return '#E63F0C';
        } else if (status === WorksiteDwellingStatus.IN_PROGRESS) {
            return 'var(--ista-lime-green)';
        } else {
            return '#000000';
        }
    }
}
