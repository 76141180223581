import { MeterType } from '../enum/meter-type.enum';

export class ProductsConstants {

    public static products: ReadonlyArray<{ type: number; meterType: MeterType; label: string; longLabel: string; productCode: number }> = [
        { type: 1, meterType: MeterType.EC, label: 'compteurs EC', longLabel: 'Compteur eau chaude', productCode: 1 },
        { type: 1, meterType: MeterType.EF, label: 'compteurs EF', longLabel: 'Compteur eau froide', productCode: 1 },
        { type: 2, meterType: MeterType.RFC, label: 'répartiteurs', longLabel: 'Répartiteur de frais de chauffage', productCode: 7 },
        { type: 3, meterType: MeterType.CET, label: 'CET', longLabel: 'Compteur d\'énergie thermique', productCode: 3 },
        { type: 4, meterType: MeterType.DAAF, label: 'DAAF', longLabel: 'Détecteur de fumée', productCode: 36 },
        { type: 5, meterType: MeterType.SONDE, label: 'sondes', longLabel: 'Sonde', productCode: 41 }
    ] as const;

    public static getCodeFromProductMeterType(meter: string): string {
        const resp = this.products.find(x => x.meterType === meter);
        return resp ? resp.meterType : '';
    }

    public static getLabelProduct(v: string): string {
        const test = this.products.find(x => x.meterType === v);
        const label = test ? test.label : '-';
        return label;
    }

    public static getMeterLabel(productCode: string, fluidCode: string): string {
        const resp = this.products.find(x => x.meterType === ProductsConstants.getMeterType(productCode, fluidCode));
        return resp ? resp.label : '-';
    }

    public static getMeterLongLabel(productCode: string, fluidCode: string): string {
        const resp = this.products.find(x => x.meterType === ProductsConstants.getMeterType(productCode, fluidCode));
        return resp ? resp.longLabel : '-';
    }

    public static getMeterType(productCode: string, fluidCode: string): MeterType {
        let meterType: any;
        switch (productCode) {
            case '01':
                meterType = fluidCode === 'F' ? MeterType.EF : fluidCode === 'C' ? MeterType.EC : undefined;
                break;
            default:
                const product = this.products.find(x => x.productCode === Number(productCode));
                meterType = product ? product.meterType : undefined;
                break;
        }
        return meterType;
    }
}
