<div>
    <div class="title" >
        <span style="margin-right: 4px;">{{title}}</span>
        <ista-interrogation-point text={{titleTooltipe}} placement="bottom"></ista-interrogation-point>
    </div>
    <div *ngIf="showSpinner && synthesis1" style="position: relative; z-index: 10000; border-left: 2px solid #E6E9EF; border-right: 2px solid #E6E9EF;">
        <ista-spinner [showSpinner]="true"
            [data]="1"
            [spinnerDiameter]="40"
            customSpinnerClass="spinner-only-small"
            customSpinnerStyle="top: 500%; left: 46%;"></ista-spinner>
    </div>
    <div  class="chart" >
        <div [chart]="synthesis1">
        </div>
    </div>
    <div class="footerChart" *ngIf="prop === 'graphProperty'" style="height: 28px; text-align: end; margin-top: 6px;">
        <div class="container-fluid">
            <div class="row">
                <div class="col-2" style="margin-left: 20px; z-index: 10;">
                    <ista-interrogation-point placement="left"
                        text="{{'installations.dashboard.propertiesGraph.tooltip.finish' | translate}}">
                    </ista-interrogation-point>
                </div>
                <div class="col-1" style="margin-left: 40px; z-index: 10;">
                    <ista-interrogation-point placement="left"
                        text="{{'installations.dashboard.propertiesGraph.tooltip.inprogress' | translate}}">
                    </ista-interrogation-point>
                </div>
                <div class="col-1" style="margin-left: 40px; z-index: 10;">
                    <ista-interrogation-point placement="left"
                        text="{{'installations.dashboard.propertiesGraph.tooltip.planned' | translate}}">
                    </ista-interrogation-point>
                </div>
                <div class="col-1" style="margin-left: 40px; z-index: 10;">
                    <ista-interrogation-point placement="left"
                        text="{{'installations.dashboard.propertiesGraph.tooltip.toPlan' | translate}}">
                    </ista-interrogation-point>
                </div>
            </div>
        </div>
    </div>
    <div class="footerChart" *ngIf="prop === 'graphProduct'" style="height: 28px; text-align: end; margin-top: 6px;">
        <div class="container-fluid">
            <div class="row">
                <div class="col-2" style="margin-left: 15px; z-index: 10;">
                    <ista-interrogation-point  placement="left"
                        text="{{'installations.dashboard.metersGraph.tooltip.posed' | translate}}">
                    </ista-interrogation-point>
                </div>
                <div class="col-2" style="z-index: 10;">
                    <ista-interrogation-point  placement="left"
                        text="{{'installations.dashboard.metersGraph.tooltip.inprogress' | translate}}">
                    </ista-interrogation-point>
                </div>
                <div class="col-1" style="margin-left: 40px; z-index: 10;">
                    <ista-interrogation-point  placement="left"
                        text="{{'installations.dashboard.metersGraph.tooltip.planned' | translate}}">
                    </ista-interrogation-point>
                </div>
                <div class="col-1" style="margin-left: 40px; z-index: 10;">
                    <ista-interrogation-point  placement="left"
                        text="{{'installations.dashboard.metersGraph.tooltip.toPlan' | translate}}">
                    </ista-interrogation-point>
                </div>
                <div class="col-2" style=" z-index: 9;">
                    <ista-interrogation-point placement="left"
                        htmlText="{{'installations.dashboard.metersGraph.tooltip.notEquipable' | translate}}">
                    </ista-interrogation-point>
                </div>
                <div class="col-2" style="margin-left:-10px; z-index: 8;">
                    <ista-interrogation-point placement="left"
                        text="{{'installations.dashboard.metersGraph.tooltip.notInstalledYet' | translate}}">
                    </ista-interrogation-point>
                </div>
            </div>
        </div>
    </div>
</div>

