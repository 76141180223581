<router-outlet></router-outlet>
<!-- Google Tag Manager (noscript) -->
    <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PBJK3CP"
        height="0" width="0" style="display:none;visibility:hidden">
        </iframe>
    </noscript>
<!-- End Google Tag Manager (noscript) -->


<!-- <div class="fixed-bottom ">
    <ista-cookie-consent
    information="{{'cookiebar.information' | translate}}"
    redirectToTermsButton="{{'cookiebar.button1' | translate}}"
    saveConsentLegalTermsButton="{{'cookiebar.button2' | translate}}"
    phrase1="{{'cookiebar.phrase1' | translate}}"
    phrase2="{{'cookiebar.phrase2' | translate}}"
    linkToTerms="{{'cookiebar.link' | translate}}"
    ></ista-cookie-consent>
</div> -->
<!-- <div *ngIf="!consentLegalTerms"
    role="dialog" aria-live="polite"
    aria-label="cookieconsent"
    aria-describedby="cookieconsent:desc"
    class="cc-window cc-banner cc-type-info
    cc-theme-block cc-bottom cc-color-override-1302255970 ">
    <span id="cookieconsent:desc" class="cc-message">En poursuivant la navigation, vous consentez à l'utilisation de la politique de cookies sur notre site. Cela permettra de conserver les paramètres de session utilisateur.
        <a aria-label="learn more about cookies" role="button" tabindex="0"
        class="cc-link" href="/legal/terms" rel="noopener noreferrer nofollow" target="_blank">{{'footer.conditions' | translate}}</a>
    </span>
    <div class="cc-compliance" (click)="saveConsentLegalTerms()">
        <a aria-label="dismiss cookie message" role="button" tabindex="0" class="cc-btn cc-dismiss">J'ai compris</a>
    </div>
</div> -->

