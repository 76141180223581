import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthenticationInterceptor, AuthenticationService, DEFAULT_TIMEOUT, HttpErrorInterceptor, HttpTimeoutInterceptor, UserService } from '@ista/shared-ui';
import { environment } from 'src/environments/environment';

import { LoginGuard } from './guard/login.guard';
import { RoleGuard } from './guard/role.guard';
import { IndexedDbInterceptor } from './interceptor/indexed-db.interceptor';

@NgModule({
    providers: [
        LoginGuard,
        RoleGuard,
        AuthenticationService,
        UserService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
        { provide: 'CONTEXT_API', useValue: environment.CONTEXT_API },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpTimeoutInterceptor, multi: true },
        { provide: DEFAULT_TIMEOUT, useValue: 900000 },
        { provide: HTTP_INTERCEPTORS, useClass: IndexedDbInterceptor, multi: true },
        { provide: 'DB_DURATION', useValue: environment.DB_DURATION },
        { provide: 'DB_NAME_PREFIX', useValue: environment.DB_NAME_PREFIX }
    ]
})

export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(`CoreModule has already been loaded. Import CoreModule in the AppModule only.`);
        }
    }
}
