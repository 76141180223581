import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DwellingWorksiteOverview, MeterWorksiteOverview } from '@extern/worksites/model/worksite-overview.model';
import { InstallationDashboardSortingService } from '@extern/worksites/service/installation-dashboard-sorting.service';
import { WorksiteFilterSessionStorageService } from '@extern/worksites/service/worksite-filter-session-storage.service';
import { AuthorizationService, formatDate, Reference } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { FilterName } from '@shared/enum/filter-name.enum';
import { MeterType } from '@shared/enum/meter-type.enum';
import { ColumnMode, DatatableComponent } from '@siemens/ngx-datatable';
import { Column } from 'src/app/core/model/column.model';

import { RedirectLandingPage } from '../../../../../core/enum/redirect-landing-page.enum';
import { WorksiteStatus } from '../../enum/woksite-status.model';
import { WorksiteSynthesisLine } from '../../model/worksite-synthesis-line.model';
import { WorksiteSynthesisService } from '../../service/worksite-synthesis.service';

@Component({
    selector: 'app-progress-worksite-table',
    templateUrl: './progress-worksite-table.component.html',
    styleUrls: ['./progress-worksite-table.component.css'],
})
export class ProgressWorksiteTableComponent implements OnInit, OnChanges {
    messages = {
        emptyMessage: `<span class="empty">${this.translate.instant('filters.noWorksiteForTheSelection')}</span>`,
    };
    @Input() showSpinner: boolean;
    @Input() addressFilterData: ReadonlyArray<Reference>;

    @Input() dwellingsOverview: ReadonlyArray<DwellingWorksiteOverview>;
    @Input() metersOverview: ReadonlyArray<MeterWorksiteOverview>;

    defaultDatePattern = 'DD/MM/YYYY';
    rows: ReadonlyArray<WorksiteSynthesisLine>;
    columns: Array<Column>;
    ColumnMode = ColumnMode;
    isSorted = false;
    @ViewChild('table2') table2: DatatableComponent;
    productsData = [
        { name: 'datatable.worksite.product.warmWaterMeter', ids: [MeterType.EC] },
        { name: 'datatable.worksite.product.coldWaterMeter', ids: [MeterType.EF] },
        { name: 'datatable.worksite.product.heatMeter', ids: [MeterType.CET] },
        { name: 'datatable.worksite.product.heatCostAllocator', ids: [MeterType.RFC] },
        { name: 'datatable.worksite.product.temperatureSensor', ids: [MeterType.DAAF] },
        { name: 'datatable.worksite.product.smokeDetector', ids: [MeterType.SONDE] },
    ];

    constructor(
        private router: Router,
        private translate: TranslateService,
        private synthesisService: WorksiteSynthesisService,
        private sortingService: InstallationDashboardSortingService,
        private filterSessionStorage: WorksiteFilterSessionStorageService,
        private isInternal: AuthorizationService
    ) {}

    ngOnInit(): void {
        this.translate.setDefaultLang('fr');
        this.createColumns();
        this.getRows();
        this.rows = this.sortingService.sort(this.rows);
    }

    ngOnChanges(): void {
        this.getRows();
        this.rows = this.sortingService.sort(this.rows);
    }

    resetSort(): void {
        this.table2.sorts = [];
        this.table2.rows = [...this.table2.rows];
        this.isSorted = false;
    }

    viewSort(): void {
        this.isSorted = true;
    }

    createColumns(): void {
        this.columns = [
            new Column(
                'installations.columns.propertyName',
                'propertyName',
                true,
                235,
                this.comparatorNotNull.bind(this)
            ),
            new Column(
                'installations.columns.propertyAddress',
                'propertyAddress',
                true,
                280,
                this.comparatorAdress.bind(this)
            ),
            new Column('installations.columns.status', 'status', true, 180, this.comparatorStatus.bind(this)),
            new Column(
                'installations.columns.installationPeriod',
                'worksitePeriod',
                true,
                200,
                this.comparatorPeriod.bind(this)
            ),
            new Column('installations.columns.type', 'worksiteMeterType', true, 200, this.comparatorNotNull.bind(this)),
            new Column(
                'installations.columns.planned',
                'worksitePlanned',
                true,
                170,
                this.comparatorNumberInverse.bind(this)
            ),
            new Column(
                'installations.columns.done',
                'worksiteDone',
                true,
                140,
                this.comparatorNumberInverse.bind(this)
            ),
            new Column('installations.columns.rate', 'worksiteDoneRate', true, 140, this.comparatorRate.bind(this)),
        ];
    }

    handleResize(event: ResizeObserverEntry): void {
        this.createColumns();
    }

    getRows(): void {
        this.rows = this.synthesisService.getSynsthesisLine(this.dwellingsOverview, this.metersOverview);
    }

    onClickOnePropertyLine(row: WorksiteSynthesisLine): void {
        const router = this.router;
        if (this.filterSessionStorage.getItem('worksite_filters')) {
            const strPreviousFilters = this.filterSessionStorage.getItem('worksite_filters') || '';
            sessionStorage.setItem('previous_worksite_filters', JSON.stringify(strPreviousFilters));
        }
        const fluidLabel = this.productsData.filter((x) => x.ids[0] == row.worksiteMeterTypeEnum)[0].name;
        const endPeriod = row.worksitePeriodEnd ? row.worksitePeriodEnd.split('/') : undefined;
        const dateEnd = endPeriod ? endPeriod[2] + '-' + endPeriod[1] + '-' + endPeriod[0] : undefined;
        this.filterSessionStorage.setFiltersProperty(FilterName.PROPERTIES, [
            { name: row.propertyName, ids: [row.propertyId] },
        ]);
        this.filterSessionStorage.setFiltersProperty(
            FilterName.ADDRESSES,
            this.addressFilterData.filter((x) => x.ids[0].startsWith(row.propertyId))
        );
        this.filterSessionStorage.setFiltersProperty(FilterName.PRODUCTS, [
            { name: this.getTranslate(fluidLabel), ids: [row.worksiteMeterTypeEnum] },
        ]);
        router.navigate(
            [(this.isInternal.isInternalAccountType() ? 'interne' : '') + RedirectLandingPage.WORKSITES_LIST_PAGE],
            { state: { workisteId: row.worksiteId, propertyId: row.propertyId } }
        );
    }

    comparatorNotNull(
        propA: string,
        propB: string,
        rowA: WorksiteSynthesisLine,
        rowB: WorksiteSynthesisLine,
        sortDirection: string
    ): number {
        if (propA.trim() === '' || propB.trim() === '') {
            if (sortDirection === 'desc') {
                return propA.trim() === '' ? -1 : 1;
            } else {
                return propA.trim() === '' ? 1 : -1;
            }
        } else {
            const a = propA.toLocaleLowerCase().trim();
            const b = propB.toLocaleLowerCase().trim();
            return a > b ? 1 : b > a ? -1 : 0;
        }
    }

    comparatorPeriod(
        propA: string,
        propB: string,
        rowA: WorksiteSynthesisLine,
        rowB: WorksiteSynthesisLine,
        sortDirection: string
    ): number {
        if (propA.trim() === '' || propB.trim() === '') {
            if (sortDirection === 'desc') {
                return propA.trim() === '' ? -1 : 1;
            } else {
                return propA.trim() === '' ? 1 : -1;
            }
        } else {
            const a = new Date(propA.split(' ')[0].split('/').reverse().join('-'));
            const b = new Date(propB.split(' ')[2].split('/').reverse().join('-'));
            return a > b ? 1 : b > a ? -1 : 0;
        }
    }

    getStatusWeight(code: WorksiteStatus): number {
        if (code === WorksiteStatus.TO_PLAN) {
            return 1;
        } else if (code === WorksiteStatus.PLANNED) {
            return 2;
        } else if (code === WorksiteStatus.IN_PROGRESS) {
            return 3;
        } else if (code === WorksiteStatus.FINISHED) {
            return 4;
        } else {
            return 0;
        }
    }

    comparatorStatus(propA: string, propB: string, rowA: WorksiteSynthesisLine, rowB: WorksiteSynthesisLine): number {
        const a = this.getStatusWeight(rowA.statusEnum);
        const b = this.getStatusWeight(rowB.statusEnum);
        return a > b ? -1 : b > a ? 1 : 0;
    }

    comparatorAdress(
        propA: string,
        propB: string,
        rowA: WorksiteSynthesisLine,
        rowB: WorksiteSynthesisLine,
        sortDirection: string
    ): number {
        if (propA.trim() === '' || propB.trim() === '') {
            if (sortDirection === 'desc') {
                return propA.trim() === '' ? -1 : 1;
            } else {
                return propA.trim() === '' ? 1 : -1;
            }
        } else {
            const a = propA.toLocaleLowerCase().trim();
            const b = propB.toLocaleLowerCase().trim();
            const reA = /[^a-zA-Z]/g;
            const reN = /[^0-9]/g;
            const aA = a.replace(reA, '');
            const bA = b.replace(reA, '');
            if (aA === bA) {
                let aN = parseInt(a.replace(reN, ''), 10);
                let bN = parseInt(b.replace(reN, ''), 10);
                aN = aN ? aN : 0;
                bN = bN ? bN : 0;
                return aN === bN ? 0 : aN > bN ? 1 : -1;
            } else {
                return aA > bA ? 1 : -1;
            }
        }
    }

    comparatorRate(propA: string, propB: string): number {
        const a = parseInt(propA.replace('%', ''), 10);
        const b = parseInt(propB.replace('%', ''), 10);
        return a > b ? -1 : b > a ? 1 : 0;
    }

    comparatorNumberInverse(a: number, b: number): number {
        return a > b ? -1 : b > a ? 1 : 0;
    }

    public transformDate(dateToTranform: string): string {
        return formatDate(dateToTranform, this.defaultDatePattern);
    }

    public getTranslate(translateKey: string): string {
        let response = '';
        this.translate.get(translateKey).subscribe((translateValue: string) => {
            response = translateValue;
        });
        return response;
    }
}
