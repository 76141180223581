import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ActivitySynthesisFooterComponent } from '@extern/activities/components/activity-synthesis-footer/activity-synthesis-footer.component';
import { ActivitySynthesisComponent } from '@extern/activities/components/activity-synthesis/activity-synthesis.component';
import { AuthorizationService, IstaSpinnerModule } from '@ista/shared-ui';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { ChartModule } from 'angular-highcharts';

import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivitiesRoutingModule } from './activities-routing.module';
import { ActivityItemDetailsComponent } from './components/activity-item-details/activity-item-details.component';
import { ActivityItemsComponent } from './components/activity-items/activity-items.component';
import { ActivityListComponent } from './components/activity-list/activity-list.component';
import { DwellingListComponent } from './components/dwelling-list/dwelling-list.component';
import { ActivitiesDashboardComponent } from './pages/activities-dashboard/activities-dashboard.component';
import { ActivitiesDetailComponent } from './pages/activities-detail/activities-detail.component';
import { ActivitiesComponent } from './pages/activities/activities.component';
import { DwellingDetailComponent } from './pages/dwelling-detail/dwelling-detail.component';

@NgModule({
    imports: [
        ActivitiesRoutingModule,
        ChartModule,
        TranslateModule,
        SharedModule,
        RouterModule,
        IstaSpinnerModule,
        MatIconModule,
        MatProgressSpinnerModule,
    ],
    declarations: [
        ActivitiesDashboardComponent,
        DwellingListComponent,
        ActivitiesComponent,
        ActivityListComponent,
        ActivityItemsComponent,
        ActivityItemDetailsComponent,
        ActivitySynthesisComponent,
        ActivitySynthesisFooterComponent,
        ActivitiesDashboardComponent,
        DwellingDetailComponent,
        ActivitiesDetailComponent,
    ],
    providers: [AuthorizationService],
})
export class ActivitiesModule {}
