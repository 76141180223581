import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';

import { ActivitiesModule } from './activities/activities.module';
import { ExternLogoutComponent } from './components/extern-logout/extern-logout.component';
import { TemplateComponent } from './components/template/template.component';
import { ExternRoutingModule } from './extern-routing.module';
import { AppointmentsComponent } from './pages/appointments/appointments.component';
import { WorksitesModule } from './worksites/worksites.module';
import { MatIconModule } from '@angular/material/icon';
import { IstaSpinnerModule } from '@ista/shared-ui';

@NgModule({
    declarations: [TemplateComponent, AppointmentsComponent, ExternLogoutComponent],
    imports: [
        ActivitiesModule,
        BrowserAnimationsModule,
        WorksitesModule,
        BrowserAnimationsModule,
        SharedModule,
        MatIconModule,
        TranslateModule,
        RouterModule,
        ExternRoutingModule,
        IstaSpinnerModule,
    ],
    exports: [TemplateComponent],
})
export class ExternModule {}
