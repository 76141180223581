import { Optional } from '@angular/core';

export class IndexedDBValue {
    result: unknown;
    date: string;
    properties: string;
    expireAt: Date;
    constructor(result: unknown, expireAt: Date, date?: string, properties?: string) {
        this.result = result;
        this.date = date ? date : '';
        this.expireAt = expireAt;
        this.properties = properties ? properties : '';
    }
}
