<div class="d-flex flex-row  flex-wrap ">
    <div *ngFor="let a of activeFilters">
        <div *ngIf="a.type === 'multiselect'" class=" mr-1 mt-4">
            <div class="d-flex flex-row ">
                <div>
                    <ista-multiselect-filter (refresh)="refreshData(a.details.filterName)" [data]="a.details.data"
                        filterName="{{a.details.filterName | translate}}" dataName="{{a.details.dataName}}"
                        [filterSessionStorage]="activeService" validateButtonText="{{'filters.validate'  | translate}}"
                        unselectAllButtonText="{{'filters.unselectAll' | translate}}"
                        selectAllButtonText="{{'filters.selectAll' | translate}}"
                        zoneRechercheText="{{'filters.zoneRecherche' | translate}}"
                        tooltipText="{{'filters.tooltip' | translate}}"
                        allSelectedText="{{'filters.allselected' | translate}}"
                        notFoundText="{{'filters.noFound' | translate}}">
                    </ista-multiselect-filter>
                </div>
                <div *ngIf="a.showInformation" class="" style="position: relative; bottom: 28px; width: 20px;">
                    <ista-interrogation-point placement="right" htmlText="{{a.textInformation | translate}}">
                    </ista-interrogation-point>
                </div>
                <div *ngIf="!a.showInformation" class="" style="position: relative; bottom: 28px; width: 20px;">
                </div>
            </div>
        </div>
        <div *ngIf="a.type === 'checkbox'" class="mr-1 mt-4">
            <div class="d-flex flex-row ">
                <div>
                    <ista-checkbox-filter (refresh)="refreshData(a.details.filterName)" [data]="a.details.data"
                        [multiple]="a.multiple === 'true'? true : false"
                        filterName="{{a.details.filterName | translate}}" dataName="{{a.details.dataName}}"
                        [filterSessionStorage]="activeService" validateButtonText="{{'filters.validate'  | translate}}"
                        unselectAllButtonText="{{'filters.unselectAll' | translate}}"
                        selectAllButtonText="{{'filters.selectAll' | translate}}">
                    </ista-checkbox-filter>
                </div>
                <!--- TODO: Find a better way to include parameters in i18n. Right now we add the parameter even if it's not present and it works for activities filters only for now -->
                <div *ngIf="a.showInformation" style="position: relative; bottom: 28px; width: 20px;">
                    <ista-interrogation-point placement="{{getFilterTooltipLocation(a)}}"
                        htmlText="{{ a.textInformation | translate: {date: globalVariables.activityLimitDateAsLabel()} }}">
                    </ista-interrogation-point>
                </div>
                <div *ngIf="!a.showInformation" class="" style="position: relative; bottom: 28px; width: 20px;">
                </div>
            </div>

        </div>
        <div *ngIf="a.type === 'date'" class="mr-1 mt-4">
            <div class="d-flex flex-row ">
                <div>
                    <ista-date-filter (refresh)="refreshData(a.details.filterName)"
                        filterName="{{a.details.filterName | translate}}" dataName="{{a.details.dataName}}"
                        unselectDate="{{'filters.unselectDate'  | translate}}"
                        validate="{{'filters.validate'  | translate}}" [minDate]="minDate"
                        [startDate]="correctStartDate(a.details.dataName)" [filterSessionStorage]="activeService">

                    </ista-date-filter>
                </div>
                <div *ngIf="a.showInformation" class="" style="position: relative; bottom: 28px;  left: -24px; width: 20px;">
                    <!--- TODO: Find a better way to include parameters in i18n. Right now we add the parameter even if it's not present and it works for activities filters only for now -->
                    <ista-interrogation-point placement="right"
                        htmlText="{{ a.textInformation | translate: {date: globalVariables.activityLimitDateAsLabel()} }}">
                    </ista-interrogation-point>
                </div>
                <div *ngIf="!a.showInformation" class="" style="position: relative; bottom: 28px; width: 20px;">
                </div>
            </div>
        </div>

        <div *ngIf="a.type === 'endDate'" class="mr-1 mt-4">
            <div class="d-flex flex-row ">
                <div *ngIf="showDateFilter()">
                    <ista-date-end-filter (refresh)="refreshData(a.details.filterName)"
                        filterName="{{a.details.filterName | translate}}" dataName="{{a.details.dataName}}"
                        unselectDate="{{'filters.unselectDate'  | translate}}"
                        validate="{{'filters.validate'  | translate}}" [minDate]="minEndDate"
                        [filterSessionStorage]="activeService" [open]="openEndDatePicker">
                    </ista-date-end-filter>
                </div>
                <div *ngIf="!showDateFilter()" class="d-flex align-items-start"
                    tooltip="{{'filters.showEndDate'  | translate}}" container="body" containerClass="tooltipClass">
                    <div class="calendar-round-button" (click)="showFilter()" style="left: 0px;">
                        <mat-icon class="button-calendar">calendar_month</mat-icon>
                    </div>
                </div>
                <div *ngIf="a.showInformation" class="" style="position: relative; bottom: 28px; width: 20px;" [ngStyle]="{'left': !showDateFilter() ? '-10px' : '-24px'}">
                    <ista-interrogation-point placement="right" htmlText="{{a.textInformation | translate}}">
                    </ista-interrogation-point>
                </div>
                <div *ngIf="!a.showInformation" class="" style="position: relative; bottom: 28px; width: 20px;">
                </div>
            </div>
        </div>
        <div *ngIf="a.type === 'period'" class="mr-1 mt-4">
            <div class="d-flex flex-row ">
                <div>
                    <ista-double-date-filter (refresh)="refreshData(a.details.filterName)"
                        filterName="{{a.details.filterName | translate}}" dataName="{{a.details.dataName}}"
                        [minDate]="minDate" [dateStart]="startDate" validate="{{'filters.validate'  | translate}}"
                        unselectDoubleDate="{{'filters.unselectDate' | translate}}"
                        [filterSessionStorage]="activeService">
                    </ista-double-date-filter>
                </div>
                <div *ngIf="a.showInformation" class="" style="position: relative; bottom: 28px; width: 20px;">
                    <ista-interrogation-point placement="right" htmlText="{{a.textInformation | translate}}">
                    </ista-interrogation-point>
                </div>
                <div *ngIf="!a.showInformation" class="" style="position: relative; bottom: 28px; width: 20px;">
                </div>
            </div>
        </div>
    </div>
</div>