export class Dwelling {
    id: string;
    customerReference: string;
    istaReference: string;
    location: string;
    owner: string;
    tenant: string;
    address: string;
    postalCode: string;
    city: string;
    residence: string;
    fullAddress: string;
    propertyId: string;
}
