<div #containersBlock (resize)="resized()" (onResize)="handleResize($event)" style="position: relative; height: 100%;">
    <div #filtersBlock id='filtersBlock'>
        <div class="menu-title">
            <div class="row ml-2">
                <div class="col-5 ml-0 p-0 m-0" style="margin-right: 15px !important;">
                    <div class="row ml-0 p-0 m-0">
                        <div class="col-*">
                            <h1 class="form-title" style="margin-top: 4px;">{{'sidebar.menu.menuitem.activities' | translate}}</h1>
                        </div>
                        <div [tooltip]="showFilterTooltip()" class="col-* filterButton" containerClass="tooltipClass" placement="auto" [ngClass]=" filterIsActive == true ? 'active' : ''">
                            <ista-hide-filters (toggle)="toggleFilter()" [filterSessionStorage]="filterSessionStorage"
                                [groupFilters]="gFiltres.refresh" [ignoreFilters]="['columns', 'columnsDetails']">
                            </ista-hide-filters>
                        </div>
                        <div class="col-*" style="margin-left: 10px;" [tooltip]="'filters.columns'| translate"
                            containerClass="tooltipClass" placement="auto">
                            <ista-select-columns (refresh)="prepareColumns()" [data]="columnsFilterData"
                                [filterSessionStorage]="filterSessionStorage" dataName="columnsDetails"
                                selectAllButtonText="{{'filters.selectAll' | translate}}"
                                unselectAllButtonText="{{'filters.unselectAll' | translate}}"
                                validateButtonText="{{'filters.validate'  | translate}}">
                            </ista-select-columns>
                        </div>
                    </div>
                </div>

                <div class="col-* mr-auto">
                    <div *ngIf="!matchMedia.matches" class="row">
                        <button (click)="switchView()"
                            class="buttonClass secondaryButton btn mb-2 font-medium fixed-width button-rounded-left" style="margin-right: 0px !important;">
                            {{'datatable.activitiesDetailled.switchViewButton' | translate}}
                        </button>
                        <button class="buttonClass primaryButton btn mb-2 font-medium fixed-width  button-rounded-right">
                            {{'datatable.activities.switchViewButton' | translate}}
                        </button>
                    </div>
                    <div *ngIf="matchMedia.matches" class="row">
                        <button (click)="switchView()"
                            class="buttonClass secondaryButton btn mb-2 font-medium fixed-width button-rounded-left" style="margin-right: 0px !important;">
                            {{'datatable.activitiesDetailled.switchViewButtonShort' | translate}}
                        </button>
                        <button class="buttonClass primaryButton btn mb-2 font-medium fixed-width  button-rounded-right">
                            {{'datatable.activities.switchViewButtonShort' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <ista-spinner *ngIf="showSpinner" [data]="lines.length" [showSpinner]="showSpinner" class="center-text"
            customSpinnerClass="xsmall" customSpinnerTitleStyle="font-family: 'TheSansE5-SemiLight';" loading="{{'template.loading' | translate}}"
            message="{{'filters.noActivityForTheSelection' | translate}}" style="z-index: 100;">
        </ista-spinner>
        <div class="flex-row ml-2 p-0  group-filters" style="padding-left: 20px !important;">
            <div class="row">
                <div [ngClass]="showFilter? 'col-11 visible': 'col-0 hidden'" class="filter">
                    <app-group-filters #gFiltres (refresh)="refreshData($event)" [activeService]="filterSessionStorage"
                        [addressesReferences]="addressFilterData" [dwellingsReferences]="dwellingFilterData"
                        [propertiesReferences]="propertyFilterData" [serialNumberReferences]="serialNumberFilterData"
                        [reasonReferences]="reasonFilterData" [subjectReferences]="subjectFilterData"
                        type='activityDetails'>
                    </app-group-filters>
                </div>
                <!-- <div *ngIf="!showSpinner" [ngClass]="showFilter ? 'col-1': 'col-12'">
                    <div class="ml-auto alert group-filters">
                        <div [ngStyle]="{'height': showFilter ? 'auto' : '2rem' }"
                            class="flex-row align-items-end flex-column">
                            <ista-export-data (export)="exportMeters($event)" [showExcel]="true" id="exportGroup"
                                title="{{'export.activities.excel.list' | translate}}">
                            </ista-export-data>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="row">
                <div class="col-12">
                    <div *ngIf="!showSpinner && table.isSorted" class="ml-auto alert group-filters">
                        <div class="d-flex align-items-end flex-column">
                            <div class="buttonTri ">
                                <button type="button" class="btn buttonClass secondaryButton" (click)="table.resetSort()">
                                    {{'datatable.buttonSort' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wrapper">
        <app-activity-list #table [columns]="columnsFiltred" [events]="eventsSubject.asObservable()"
            [filterSessionStorage]="filterSessionStorage" [listBlockHeight]="listBlockHeight" [rows]="lines"
            [showSpinner]="showSpinner">
        </app-activity-list>
    </div>
</div>
