import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from '@ista/shared-ui';
import { LoginGuard } from 'src/app/core/guard/login.guard';
import { RoleGuard } from 'src/app/core/guard/role.guard';

import { AuthComponent } from '../../shared/components/auth/auth.component';
import { TemplateComponent } from '../intern/pages/template/template.component';
import { ExternLogoutComponent } from './components/extern-logout/extern-logout.component';
import { AppointmentsComponent } from './pages/appointments/appointments.component';

const routes: Routes = [
    {
        path: 'redirection',
        component: ExternLogoutComponent,
        canActivate: [LoginGuard],
        data: { expired: true }
    },
    {
        path: 'redirection-mon-compte-ista',
        component: ExternLogoutComponent,
        canActivate: [LoginGuard],
        data: { expired: false }
    },
    {
        path: 'extern/auth', component: AuthComponent, canActivate: [LoginGuard]
    },
    {
        path: 'extern', component: TemplateComponent, children: [
            {
                path: 'appointments', component: AppointmentsComponent, canActivate: [RoleGuard],
                data: { roles: [Role.ROLE_CUSTOMER] }
            }
        ]
    },
    {
        path: 'mes-chantiers',
        loadChildren: () => import('./worksites/worksites.module').then(m => m.WorksitesModule)
    },
    {
        path: 'mes-interventions',
        loadChildren: () => import('./activities/activities.module').then(m => m.ActivitiesModule)
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ExternRoutingModule { }
