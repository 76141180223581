<div *ngIf="showSpinner" style="position: relative; z-index: 10000; top: 40%; left: 48%;">
    <ista-spinner [showSpinner]="true" customSpinnerClass="spinner-only-small no-translation" [data]="1" [spinnerDiameter]="60"></ista-spinner>
</div>
<div class="hoverMe">
    <div (click)="redirectToList()">
        <div class="chart-title ista-dark-blue">
            <span>
                {{'dashboard.activities.chart.title' | translate}}
                <ista-interrogation-point placement="top"
                    htmlText="{{'dashboard.activities.chart.description' | translate}}">
                </ista-interrogation-point>
            </span>
        </div>
    </div>
    <div id="synthesisId" class="chart" (onResize)="resizeSynthesisBlock()">
        <div [chart]="synthesis"></div>
    </div>
</div>

<app-activity-synthesis-footer [correctionStyle]="false" [entries]="statusesByProperties" [showSpinner]="showSpinner"
    [title]="'dashboard.activities.properties'">
</app-activity-synthesis-footer>

<app-activity-synthesis-footer [correctionStyle]="true" [entries]="statusesByDwellings" [showSpinner]="showSpinner"
    [title]="'dashboard.activities.dwellings'">
</app-activity-synthesis-footer>