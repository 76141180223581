import {Filters, Reference} from '@ista/shared-ui';

export class ActivityFilters extends Filters {

    dates: string;
    statuses: ReadonlyArray<Reference>;
    columns: ReadonlyArray<Reference>;
    columnsDetails: ReadonlyArray<Reference>;
    products: ReadonlyArray<Reference>;
    serialNumbers: ReadonlyArray<Reference>;
    activityTypes: ReadonlyArray<Reference>;
    reasons: ReadonlyArray<Reference>;
    subjects: ReadonlyArray<Reference>;
    visitsCount: ReadonlyArray<Reference>;


    public constructor() {
        super();
        this.dates = '';
        this.statuses = [];
        this.columns = [];
        this.columnsDetails = [];
        this.products = [];
        this.serialNumbers = [];
        this.activityTypes = [];
        this.reasons = [];
        this.subjects = [];
        this.visitsCount = [];
    }
}
