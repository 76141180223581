import { Location } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    Injectable,
    isDevMode,
    OnChanges,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { RedirectLandingPage } from '@core/enum/redirect-landing-page.enum';
import { Property } from '@core/model/property.model';
import { FilterReferencesService } from '@core/service/filter-references.service';
import { MessageService } from '@core/service/message.service';
import { PropertyService } from '@core/service/property.service';
import { SelfcareIndexedDbService } from '@core/service/selfcare-indexed-db.service';
import { SelfcareSessionStorage } from '@core/service/selfcare-session-storage.service';
import { GlobalVariables } from '@core/util/global-variables';
import { ActivityFilterSessionStorageService } from '@extern/activities/service/activity-filter-session-storage.service';
import { WorksiteFilterSessionStorageService } from '@extern/worksites/service/worksite-filter-session-storage.service';
import {
    AuthenticationService,
    Error,
    FilterSessionStorageService,
    HandleErrorService,
    HelpStatus,
    Reference,
    SidebarItem,
    UserInfo,
    UserService,
} from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { StorageName } from '@shared/enum/storage-name.enum';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { firstValueFrom } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CustomerService } from '@core/service/customer.service';
import { ExchangeDataService } from '@shared/service/exchange-data.service';

@Injectable({
    providedIn: 'root',
})
@Component({
    selector: 'app-template',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.css'],
    providers: [ExchangeDataService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateComponent implements OnInit, OnChanges, OnDestroy {
    modalRef?: BsModalRef;
    fullName: string;
    userInfos: UserInfo;
    lastMenuItem: string;
    showSelectionSpinner = false;
    showNoSelectionText = false;
    showSelectionReturn = false;
    propertyList: ReadonlyArray<Property>;
    propertyFilterData: Array<Reference> = [];
    defaultWorksiteStorage: string;
    sessionStorageService: FilterSessionStorageService<any>;
    flagFetchActivityDataInProgress = false;
    flagFetchWorksiteDataInProgress = false;
    showNoDatasMsg: boolean;
    animation: boolean;
    contentTitle: string;
    datas: number;
    error = false;
    errorMessage = '';
    basePath = '';
    browserName = '';
    browserVersion = '';
    textNavigatorNotCompatible = '';
    colorClass = '';
    imgColor = '';
    faq = '';
    mentions = '';
    conditions = '';
    contact = '';
    @ViewChild('template', { static: true }) template: TemplateRef<any>;
    defaultItems: Array<SidebarItem> = [];

    constructor(
        public activitySessionStorage: ActivityFilterSessionStorageService,
        public worksiteSessionStorage: WorksiteFilterSessionStorageService,
        private router: Router,
        private globalVariables: GlobalVariables,
        private indexedDBService: SelfcareIndexedDbService,
        private location: Location,
        private messageService: MessageService,
        private filterReferencesService: FilterReferencesService,
        private userService: UserService,
        private sessionStorage: SelfcareSessionStorage,
        private customerService: CustomerService,
        private exchangeDataService: ExchangeDataService,
        private translate: TranslateService,
        private handleErrorService: HandleErrorService,
        private authenticationService: AuthenticationService,
        private propertyService: PropertyService,
        private modalService: BsModalService
    ) {}

    ngOnInit(): void {
        this.sidebarItems();
        this.userService
            .fetchExternalUserInfos()
            .subscribe((info) => (this.fullName = info.firstName.concat(' ', info.lastName)));
        this.getError();
        if (this.setSelectionDisplayed() && !this.error) {
            this.openModal(this.template);
        }
        this.sessionStorage.watchStorage().subscribe((event) => {
            if (event === 'sidebarChanges') {
                this.sidebarItems();
            }
        });
    }

    sidebarItems(): void {
        this.defaultItems = [
            {
                id: 'dashboard',
                label: this.translate.instant('sidebar.menu.menuitem.dashboard'),
                iconClass: 'build',
                uri: RedirectLandingPage.ACTIVITIES_LANDING_PAGE,
                displayed: true,
                active: true,
            },
            {
                id: 'activities',
                label: this.translate.instant('sidebar.menu.menuitem.activities'),
                iconClass: './assets/images/activities.svg',
                uri: RedirectLandingPage.ACTIVITIES_LIST_PAGE,
                displayed: !this.sessionStorage.isDetailedActivitiesView(),
                active: true,
            },
            {
                id: 'activitiesDetailed',
                label: this.translate.instant('sidebar.menu.menuitem.activities'),
                iconClass: './assets/images/activities.svg',
                uri: RedirectLandingPage.ACTIVITIES_DETAILED_LIST_PAGE,
                displayed: this.sessionStorage.isDetailedActivitiesView(),
                active: true,
            },
            {
                id: 'installationsDashboard',
                label: this.translate.instant('sidebar.menu.menuitem.installationsDashboard'),
                iconClass: 'home_repair_service',
                uri: RedirectLandingPage.WORKSITES_LANDING_PAGE,
                displayed: true,
                active: true,
            },
            {
                id: 'installations',
                label: this.translate.instant('sidebar.menu.menuitem.installations'),
                iconClass: './assets/images/installations.svg',
                uri: RedirectLandingPage.WORKSITES_LIST_PAGE,
                displayed: true,
                active: true,
            },
        ];
    }

    openModal(template: TemplateRef<any>): void {
        const options: ModalOptions = {
            class: 'modal-lg modal-dialog-centered',
            ignoreBackdropClick: true,
            animated: true,
            keyboard: false,
        };
        this.modalRef = this.modalService.show(template, options);
    }

    // Handle HTTP Errors
    @HostListener('document:click', ['$event'])
    documentClick(event: MouseEvent): void {
        this.handleErrorService.handleError(new Error(''));
        this.errorMessage = '';
    }

    // Handle HTTP Errors
    ngOnChanges(): void {
        this.getError();
    }

    fetchCustomerName(): void {
        sessionStorage.setItem('sidebar_show_items', 'true');
        this.userService
            .fetchExternalUserInfos()
            .pipe(mergeMap((info) => this.customerService.fetchById(info.customerNumbers[0])))
            .subscribe((customer) => {
                this.sessionStorage.setCustomer(customer);
                this.exchangeDataService.changeValue(customer);
            });
    }

    // Handle HTTP Errors
    public getError(): void {
        this.handleErrorService.observableError().subscribe((x) => {
            this.error = x.message === '' || x.status === 404 ? false : true;
            this.errorMessage = x.message;
        });
    }

    logout(): void {
        sessionStorage.removeItem('base-url-navigation-trace');
        this.authenticationService.logout(true);
        this.sessionStorage.clear();
        sessionStorage.clear();
        this.fullName = '';
    }

    refresh(): void {
        const currentURL = this.router.url;
        const ulrToRemove = this.globalVariables.urlForCurrentView;
        if (
            currentURL.startsWith(RedirectLandingPage.ACTIVITIES_LANDING_PAGE) ||
            currentURL.startsWith(RedirectLandingPage.ACTIVITIES_LIST_PAGE)
        ) {
            sessionStorage.removeItem(StorageName.ACTIVITY_STORAGE_NAME);
        } else if (
            currentURL.startsWith(RedirectLandingPage.WORKSITES_LANDING_PAGE) ||
            currentURL.startsWith(RedirectLandingPage.WORKSITES_LIST_PAGE)
        ) {
            sessionStorage.removeItem(StorageName.WORKSITE_STORAGE_NAME);
        }
        if (ulrToRemove && ulrToRemove.length > 0) {
            ulrToRemove.forEach((url) => {
                if (isDevMode()) {
                    console.log(`------- Removing ${url} data from cache -------`);
                }
                this.indexedDBService
                    .removeRecordFromIDB(url)
                    .toPromise()
                    .then(() => window.location.reload());
            });
        }
    }

    showHideHelp(): void {
        const newHelpStatus =
            this.sessionStorage.getHelpStatus() === HelpStatus.SHOW ? HelpStatus.HIDE : HelpStatus.SHOW;
        this.sessionStorage.setHelpStatus(newHelpStatus);
    }

    getHelpStatus(): HelpStatus {
        return this.sessionStorage.getHelpStatus();
    }

    fetchAllProperties(): Promise<ReadonlyArray<Property>> {
        this.flagFetchActivityDataInProgress = true;
        return firstValueFrom(this.propertyService.fetch());
    }

    setSelectionDisplayed(): boolean {
        const currentURL = this.router.url;
        const splitedBaseURL = currentURL.split('/');
        const baseURL = `/${splitedBaseURL[1]}`;
        const storage = sessionStorage.getItem('base-url-navigation-trace');
        let navigationTrace: Array<string> = [];
        if (storage) {
            navigationTrace = JSON.parse(storage);
        }
        if (navigationTrace.length > 0) {
            this.showSelectionReturn = true;
        } else {
            this.showSelectionReturn = false;
        }
        if (navigationTrace && navigationTrace.includes(baseURL)) {
            return false;
        }
        switch (currentURL) {
            case RedirectLandingPage.ACTIVITIES_LANDING_PAGE:
            case RedirectLandingPage.ACTIVITIES_LIST_PAGE:
                if (this.propertyFilterData.length === 0 && !this.flagFetchActivityDataInProgress) {
                    this.showSelectionSpinner = true;
                    this.fetchAllProperties()
                        .then((properties) => {
                            this.propertyList = properties as Array<Property>;
                            this.propertyFilterData = this.filterReferencesService.preparePropertyData(
                                properties
                            ) as Reference[];
                            this.sessionStorageService = this.activitySessionStorage;
                            this.showSelectionSpinner = false;
                            this.flagFetchActivityDataInProgress = false;
                        })
                        .catch(() => {
                            this.manageCallBackURL('', currentURL);
                            this.error = true;
                        });
                }
                break;
            case RedirectLandingPage.WORKSITES_LANDING_PAGE:
            case RedirectLandingPage.WORKSITES_LIST_PAGE:
                if (this.propertyFilterData.length === 0 && !this.flagFetchWorksiteDataInProgress) {
                    this.showSelectionSpinner = true;
                    this.fetchAllProperties()
                        .then((properties) => {
                            this.propertyFilterData = this.filterReferencesService.preparePropertyData(
                                properties
                            ) as Reference[];
                            this.sessionStorageService = this.worksiteSessionStorage;
                            this.defaultWorksiteStorage = this.sessionStorage.getItem('worksite_filters') as string;
                            this.showSelectionSpinner = false;
                            this.flagFetchWorksiteDataInProgress = false;
                        })
                        .catch(() => {
                            this.manageCallBackURL('', currentURL);
                            this.error = true;
                        });
                }
                break;
        }
        return true;
    }

    getSelectedId(event: any): string {
        let target = event.currentTarget;
        if (target) if (target.nodeName) if (target.nodeName == 'DIV') return target.id;
        target = event.target || event.srcElement || event.currentTarget;
        const idAttr = target.attributes.id || target.id;
        return idAttr.nodeValue;
    }

    onClickInSelection(event: any): void {
        const targetID = this.getSelectedId(event);
        const currentURL = this.router.url;
        switch (targetID) {
            case 'btn-return':
                this.manageCallBackURL(targetID, currentURL);
                this.location.back();
                break;
            case 'btn-validate':
                let propertiesObject = this.sessionStorageService.getFiltersProperty('properties') as [];
                if (!propertiesObject || propertiesObject.length === 0) {
                    this.showNoSelectionText = true;
                    break;
                }
                this.manageCallBackURL(targetID, currentURL);
                break;
            case 'lnk-display-all':
                this.manageCallBackURL(targetID, currentURL);
                break;
            default:
                this.showNoSelectionText = false;
                break;
        }
    }

    manageCallBackURL(button: string, callBackURL: string): void {
        const baseURL = callBackURL.substring(0, callBackURL.lastIndexOf('/'));
        const storage = sessionStorage.getItem('base-url-navigation-trace');
        let navigationTrace: Array<string> = [];
        let message = '';
        if (storage) {
            navigationTrace = JSON.parse(storage);
        }
        if (!navigationTrace.includes(baseURL) && button !== 'btn-return') {
            navigationTrace.push(baseURL);
            sessionStorage.setItem('base-url-navigation-trace', JSON.stringify(navigationTrace));
        }
        switch (callBackURL) {
            case RedirectLandingPage.ACTIVITIES_LANDING_PAGE:
                if (button === 'btn-return' || button === 'lnk-display-all') {
                    this.sessionStorage.removeItem('activity_filters');
                }
                message = 'refresh ActivitiesDashboardComponent';
                break;
            case RedirectLandingPage.ACTIVITIES_LIST_PAGE:
                if (button === 'btn-return' || button === 'lnk-display-all') {
                    this.sessionStorage.removeItem('activity_filters');
                }
                message = 'refresh ActivitiesComponent';
                break;
            case RedirectLandingPage.WORKSITES_LANDING_PAGE:
                if (button === 'btn-return' || button === 'lnk-display-all') {
                    this.sessionStorage.removeItem('worksite_filters');
                }
                message = 'refresh InstallationsDashboardComponent';
                break;
            case RedirectLandingPage.WORKSITES_LIST_PAGE:
                if (button === 'btn-return' || button === 'lnk-display-all') {
                    this.sessionStorage.removeItem('worksite_filters');
                }
                message = 'refresh InstallationsComponent';
                break;
        }
        if (button === 'btn-return') {
            message = 'btn-return';
        }
        if (message.length > 0) {
            this.messageService.sendMessage(message);
        }
        this.modalRef?.hide();
    }

    ngOnDestroy(): void {
        this.modalRef?.hide();
        this.modalService._hideModal(this.modalRef?.id);
        this.modalService._hideBackdrop();
    }
}
