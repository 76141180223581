import { FilterSessionStorageService } from '@ista/shared-ui';

import { FilterName } from '../enum/filter-name.enum';

export interface StorageWithDate{
    date: string;
    properties: ReadonlyArray<string>;
}

export function handlePropertySelection(activeService: FilterSessionStorageService<any>,
        storageName: string,  isInternal: boolean, inputDate: string = '')
    : readonly string[] {
    const getFromFilter: ReadonlyArray<string> = activeService.getFiltersPropertyIds(FilterName.PROPERTIES);
    const fromStorage = sessionStorage.getItem(storageName) || '[]' ;
    const data: Array<StorageWithDate> = JSON.parse(fromStorage);
    const dataFound = data.filter(x => x.date === inputDate);
    const internalClientProperties = isInternal ? sessionStorage.getItem('internal_client_properties')?.split(',') as ReadonlyArray<string> : [];
    const newData = !(dataFound[0] && dataFound[0].properties.length !== 0 && dataFound[0].properties[0] !== '');
    const finalData: Array<StorageWithDate> = data;
    let propertiesNumbers: ReadonlyArray<string>;
    if (newData) {
        const newProperties = isInternal && getFromFilter.length === 0? internalClientProperties :  getFromFilter;
        finalData.push({date: inputDate, properties: newProperties});
        propertiesNumbers = getFromFilter;
    } else {
        const listAlreadyVisited = dataFound[0].properties;
        let finalList: ReadonlyArray<string>;
        if (getFromFilter.length === 0) {
            if(isInternal){
                finalList =  internalClientProperties;
                propertiesNumbers = listAlreadyVisited.length === internalClientProperties.length ?
                [] : internalClientProperties;
            } else {
                finalList =  listAlreadyVisited;
                propertiesNumbers = [];
            }
        } else if (getFromFilter.every(x => listAlreadyVisited.includes(x))) {
            finalList = listAlreadyVisited;
            propertiesNumbers = finalList; // FSD-92881
        } else {
            propertiesNumbers = getFromFilter.filter(x => !listAlreadyVisited.includes(x));
            finalList = listAlreadyVisited.concat(getFromFilter)
                .filter((value, index, self) => self.indexOf(value) === index).filter(x => x !== '');
        }
        if(finalData.find(x => x.date === inputDate)) {
            finalData.forEach(x => {
                if (x.date === inputDate) {
                    x.properties = finalList;
                }
            });
        } else {
            finalData.push({date: inputDate, properties: finalList});
        }
    }
    sessionStorage.setItem(storageName, JSON.stringify(finalData));
    return propertiesNumbers;
}
