import { Optional } from '@angular/core';

export class Column {
    constructor(
        public name: string,
        public prop: string = '',
        public sortable: boolean = false,
        public width: number = 100,
        public comparator?: any,
        public show?: boolean) { }
}
