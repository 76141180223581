import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { FilterSessionStorageService } from '@ista/shared-ui';
import { WorksiteFilters } from '../model/worksite-filters.model';

@Injectable({
    providedIn: 'root'
})
export class WorksiteFilterSessionStorageService extends FilterSessionStorageService<WorksiteFilters> {

    constructor(@Inject(PLATFORM_ID) platformId: string, @Inject('DB_NAME_PREFIX') dbName: string) {
        super(platformId, 'worksite_filters', WorksiteFilters, dbName);
    }
}
