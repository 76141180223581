import { WorksiteStatus } from './woksite-status.model';

export class InstallationDashboardStatusSortedEnum {

    private constructor(public readonly weight: number, public readonly label: string) {

    }

    static readonly FINISHED = new InstallationDashboardStatusSortedEnum(20, WorksiteStatus.FINISHED);
    static readonly IN_PROGRESS = new InstallationDashboardStatusSortedEnum(19, WorksiteStatus.IN_PROGRESS);
    static readonly PLANNED = new InstallationDashboardStatusSortedEnum(18, WorksiteStatus.PLANNED);
    static readonly TO_PLAN = new InstallationDashboardStatusSortedEnum(17, WorksiteStatus.TO_PLAN);

    static getValues(): InstallationDashboardStatusSortedEnum[] {

        return [
            this.FINISHED,
            this.IN_PROGRESS,
            this.PLANNED,
            this.TO_PLAN
        ];
    }

    static getPositionFromLabel(label: string): number {
        const results = this.getValues().filter(e => e.label === label);
        return results.length > 0 ? results[0].weight : 0;
    }

}
