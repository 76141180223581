import { WorksiteStatus } from '../enum/woksite-status.model';

export const DataSynthesisProperty = [
    {
        key: WorksiteStatus.FINISHED,
        value: []
    },
    {
        key: WorksiteStatus.IN_PROGRESS,
        value: []
    },
    {
        key: WorksiteStatus.PLANNED,
        value: []
    },
    {
        key: WorksiteStatus.TO_PLAN,
        value: []
    }
];

export const DataSynthesisProduct = [{
    key: 'FINISHED',
    value: { value: 0, dwellings: [] }
},
{
    key: 'IN_PROGRESS',
    value: { value: 0, dwellings: [] }
},
{
    key: 'PLANNED',
    value: { value: 0, dwellings: [] }
},
{
    key: 'TO_PLAN',
    value: { value: 0, dwellings: [] }
},
{
    key: 'NOT_EQUIPABLE',
    value: { value: 0, dwellings: [] }
},
{
    key: 'NOT_INSTALLED_YET',
    value: { value: 0, dwellings: [] }
}
];

