import { DwellingStatus } from 'src/app/core/model/dwelling-status.model';
import { DwellingDetails } from '../../../../shared/model/dwelling-details.model';
import { ActivityVisit } from './activity-visit.model';
import { Activity } from './activity.model';

export class ActivityDwellingDetails extends DwellingDetails {
    customerActionRequired: boolean;
    status: DwellingStatus;
    visits: Array<ActivityVisit>;
    activities: Array<Activity>;
    constructor() {
        super();
    }
}
