import { Inject, Injectable, isDevMode } from '@angular/core';
import { IndexedDBService } from '@ista/shared-ui';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { IndexedDBValue } from '../model/indexed-db-value.model';

@Injectable({
    providedIn: 'root'
})
export class SelfcareIndexedDbService extends IndexedDBService<IndexedDBValue>{
    readonly storeName = 'data';

    constructor(
        @Inject('DB_NAME_PREFIX') public dbPrefix: string,
        @Inject('DB_DURATION') private dbDuration: number) {
        super();
    }

    get(path: string): Observable<IndexedDBValue | null> {
        try {
            const result$ = this.read(this.storeName, path);
            return result$.pipe(
                mergeMap(data => {
                    if (!data || this.isDataExpired(path, data)) {
                        return of(null);
                    }
                    return result$;
                }));
        } catch (error) {
            return of(null);
        }
    }
    // If request has date as parameter, this parameter is not saved in path but in date
    save(path: string, data: unknown): void {
        const finalPath = path.split('&date=')[0];
        const date = path.split('&date=')[1] === undefined ? '' : path.split('&date=')[1];
        const expirationDate = moment(new Date()).add(this.dbDuration, 'm').toDate();
        this.write(this.storeName, finalPath, new IndexedDBValue(data, expirationDate, date)).subscribe();
    }

    saveWithDate(path: string, data: unknown): void {
        const date = path.split('&date=')[1] === undefined ? '' : path.split('&date=')[1].split('&')[0];
        const expirationDate = moment(new Date()).add(this.dbDuration, 'm').toDate();
        this.write(this.storeName, path, new IndexedDBValue(data, expirationDate, date)).subscribe();
    }

    saveWithProperties(path: string, data: unknown, properties: string): void {
        const expirationDate = moment(new Date()).add(this.dbDuration, 'm').toDate();
        this.write(this.storeName, path, new IndexedDBValue(data, expirationDate, '', properties)).subscribe();
    }

    remove(): void {
        this.clear(this.storeName).subscribe();
    }

    setDbName(dbName: string): void {
        super.setDbName(this.dbPrefix + dbName);
    }

    removeRecordFromIDB(key: string): Observable<boolean> {
        let result = of(false);
        if ((key) && (key.trim().length > 0)) {
            const itemsFromStorage$ = this.delete(this.storeName, key);
            result = itemsFromStorage$.pipe(mergeMap(() => {
                const readFromStorage$ = this.read(this.storeName, key);
                return readFromStorage$.pipe(mergeMap(data => {
                    data && isDevMode() ? console.log('------- ' + key + ' not deleted -------') : console.log('------- ' + key + ' deleted -------');
                    return data ? of(false) : of(true);
                }));
            }));
        }
        return result;
    }

    private isStoreExpired(): Observable<boolean> {
        const itemsFromStorage$ = this.getAll(this.storeName);
        return itemsFromStorage$.pipe(
            mergeMap((data) => {
                const expiredData = data ? data.filter(d => d.expireAt < new Date()) : null;
                if (expiredData && expiredData.length > 0) {
                    this.clear(this.storeName).subscribe();
                    return of(true);
                }
                return of(false);
            })
        );
    }

    private isDataExpired(path: string, data: IndexedDBValue | null): boolean {
        if (data === null || data.expireAt < new Date()) {
            this.delete(this.storeName, path).subscribe();
            return true;
        }
        return false;
    }
}
