import { Injectable } from '@angular/core';
import { ActivityStatus } from '@extern/activities/enum/activity-status.enum';
import { VisitStatus } from '@extern/worksites/enum/visit-status.enum';
import { convertToDateOrReturnDefault } from '@ista/shared-ui';

import { GlobalVariables } from '../../../../core/util/global-variables';
import { Installation } from '../../worksites/model/installation.model';
import { WorksiteDwellingDetails } from '../../worksites/model/worksite-dwelling-details.model';
import { WorksiteVisit } from '../../worksites/model/worksite-visit.model';
import { WorksiteStatusService } from '../../worksites/service/worksite-status.service';
import { ActivityVisit } from '../model/activity-visit.model';
import { ActivityDwellingDetails } from '../model/dwelling-activities-details.model';
import { Reporting } from '../model/reporting.model';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class ActivityAddRemainingInstallationService {
    defaultDatePattern = 'DD/MM/YYYY';

    constructor(
        private worksiteStatusService: WorksiteStatusService,
        private globalVariables: GlobalVariables,
        private datePipe: DatePipe) {
    }

    addWorksiteData(
        lines: ReadonlyArray<ActivityDwellingDetails> = [],
        dwellingWorksites: ReadonlyArray<WorksiteDwellingDetails>): ReadonlyArray<ActivityDwellingDetails> {
        const dwellings = new Array<ActivityDwellingDetails>();
        lines.forEach(line => dwellings.push(line));
        if (dwellingWorksites.length > 0) {
            // find dwellings existing in activities list
            const sameDwelling = dwellingWorksites.filter(w => lines.map(l => l.id).includes(w.id));
            // find new dwellings to create one line
            const diffDwelling = dwellingWorksites.filter(w => lines.map(l => l.id).indexOf(w.id) === -1);

            // Procedure to add activities and visits to existing line
            sameDwelling.forEach(dwellingWorksite => {
                const index = dwellings.map(l => l.id).indexOf(dwellingWorksite.id);
                dwellingWorksite.installations.forEach(installation => {
                    const visitsInstallation = dwellingWorksite.visits
                        .filter(visit => visit.status === VisitStatus.FERME
                            && (visit.periodId === installation.periodId
                                && installation.id.startsWith(visit.worksiteId) &&
                                visit.dwellingsIds.includes(installation.dwellingId))
                            || visit.installationsStatuses.find(installationsStatuses =>
                                installationsStatuses.installationId === installation.id));
                    // Avoid inserting duplicate activities
                    const activity = this.getActivityByInstallation(installation, visitsInstallation);
                    if (dwellings[index].activities.find(dwellingActivity => dwellingActivity.id === activity.id) === undefined) {
                        dwellings[index].activities.push(activity);
                    }
                    const visits = this.getVisitByInstallation(installation, visitsInstallation);
                    if (visits.length > 0) {
                        visits.forEach(visit => {
                            if (dwellings[index].visits) dwellings[index].visits.push(visit);
                        });
                    }
                    const installationStatus = this.getActivityStatus(installation, visitsInstallation);
                    dwellings[index].status.nbItems += 1;
                    if (installationStatus === ActivityStatus.TO_PLAN) {
                        dwellings[index].customerActionRequired = true;
                        dwellings[index].status.code = dwellings[index].status.code === '5' ? '2' : '1';
                    }
                });
            });

            // Procedure to create new line with different dwellings
            diffDwelling.forEach(dwellingWorksite => {
                if (dwellingWorksite.installations.length > 0) {
                    const nbTotal = dwellingWorksite.installations.length;
                    let nbFinished = 0;
                    let nbItemsInProgress = 0;
                    let nbPlanned = 0;
                    let nbToPlan = 0;
                    let nbHelpUs = 0;
                    let nbNotEquippable = 0;
                    const dwellingActivities = new Array<Reporting>();
                    const dwellingVisits = new Array<ActivityVisit>();

                    dwellingWorksite.installations.forEach(installation => {
                        const visitsInstallation = dwellingWorksite.visits.filter(visit => visit.status === VisitStatus.FERME
                            && visit.periodId === installation.periodId
                            && installation.id.startsWith(visit.worksiteId)
                            && (visit.dwellingsIds.includes(installation.dwellingId)
                                || visit.installationsStatuses.find(installationsStatuses =>
                                    installationsStatuses.installationId === installation.id)));
                        dwellingActivities.push(this.getActivityByInstallation(installation, visitsInstallation));
                        this.getVisitByInstallation(installation, visitsInstallation).forEach(
                            visit => dwellingVisits.push(visit)
                        );
                        const status = this.getActivityStatus(installation, visitsInstallation);
                        if (status === ActivityStatus.TO_PLAN) {
                            nbToPlan++;
                        }
                        if (status === ActivityStatus.PLANNED) {
                            nbPlanned++;
                        }
                        if (status === ActivityStatus.IN_PROGRESS) {
                            nbItemsInProgress++;
                        }
                        if (status === ActivityStatus.FINISHED) {
                            nbFinished++;
                        }
                        if (status === ActivityStatus.NOT_EQUIPABLE) {
                            nbNotEquippable++;
                        }
                        if (status === ActivityStatus.HELP_US) {
                            nbHelpUs++;
                        }
                    });

                    const codeInst = nbToPlan === nbTotal ? '1' : nbToPlan === 0 ? '5' : '2';
                    const labelInst = nbToPlan === nbTotal ? 'Toutes les interventions sont à planifier' :
                        nbToPlan === 0 ? 'Toutes les interventions sont réalisées' :
                            'Au moins une intervention est à planifier';

                    const temp: ActivityDwellingDetails = {
                        id: dwellingWorksite.id,
                        propertyId: '',
                        istaReference: dwellingWorksite.istaReference,
                        customerReference: dwellingWorksite.customerReference,
                        owner: dwellingWorksite.owner,
                        tenant: dwellingWorksite.tenant,
                        address: dwellingWorksite.address,
                        city: dwellingWorksite.city,
                        fullAddress: dwellingWorksite.fullAddress,
                        residence: dwellingWorksite.property.name,
                        numberOfMeters: dwellingWorksite.numberOfMeters,
                        location: dwellingWorksite.location,
                        property: dwellingWorksite.property,
                        customerActionRequired: nbToPlan > 0 ? true : false,
                        visits: dwellingVisits,
                        activities: dwellingActivities,
                        postalCode: dwellingWorksite.postalCode,
                        status: {
                            code: codeInst,
                            label: labelInst,
                            nbItemsFinished: nbFinished,
                            nbItemsPlanned: nbPlanned,
                            nbItemsInProgress: nbItemsInProgress,
                            nbItemsToPlanNoAction: 0,
                            nbItemsToPlan: nbToPlan,
                            nbItemsToPlanAction: nbToPlan,
                            nbItems: nbTotal,
                            nbItemsNotEquippable: nbNotEquippable,
                            nbItemsHelpUs: nbHelpUs
                        }
                    };
                    dwellings.push(temp);
                }
            });
        }
        return dwellings;
    }

    // transform Installation to Activity
    getActivityByInstallation(installation: Installation, visits: Array<WorksiteVisit>): Reporting {
        visits = this.sortByDate(visits);
        const installationStatus = this.getActivityStatus(installation, visits);
        let date = '';
        if (visits.length !== 0) {
            date = visits[0].planificationPeriod.start;
        }

        let defaultDate = this.datePipe.transform(this.globalVariables.activityLimitDate(), 'yyyy-MM-dd');
        defaultDate = defaultDate ?? '';
        return {
            id: installation.id,
            creationDate: date !== '' ? date : defaultDate,
            customerActionRequired: installationStatus === ActivityStatus.TO_PLAN ? true : false,
            meterId: installation.id,
            meter: {
                id: installation.id,
                location: installation.location,
                serialNumber: !installation.installed ? '' : installation.serialNumber,
                product: installation.meterType,
                productLabel: installation.meterLabel,
                number: parseInt(installation.meterId.replace(installation.dwellingId, ''), 10),
                stateLabel: ''
            },
            planificationDate: this.getPlanificationDate(installationStatus, installation, visits),
            product: installation.meterType,
            remainingInstallation: parseInt(installation.periodId, 10) % 2 ? true : false,
            status: installationStatus,
            type: 'Installation',
            reason: '',
            subject: 'Pose d\'appareil',
            visits: this.getVisitByInstallation(installation, visits),
            showDetail: false
        };
    }

    // transform WorksiteVisit to ActivityVisit
    getVisitByInstallation(installation: Installation, visits: Array<WorksiteVisit>): Array<ActivityVisit> {
        const resultVisits = new Array<ActivityVisit>();
        const status = this.getActivityStatus(installation, visits);
        visits.forEach(visit => {
            const installStatus = visit.installationsStatuses.find(i => i.installationId === installation.id);
            if (!(status === ActivityStatus.FINISHED && installStatus === undefined)) {
                resultVisits.push(
                    {
                        accomplishmentDate: installStatus ? installStatus.visitDate : '',
                        accomplishmentEndHour: '',
                        accomplishmentStartHour: '',
                        activityId: installation.id,
                        comment: this.getCommentFiltering(visit.comment, installation.meterType),
                        customerFault: installStatus && installStatus.customerFault ? 'Oui' : 'Non',
                        feedback: '',
                        id: visit.id,
                        notResolvedReason: installStatus ? installStatus.notInstalledLabel : '',
                        notResolvedReasonCode: installStatus ? installStatus.notInstalledCode : '',
                        planificationDate: visit.planificationPeriod.start,
                        planificationEndHour: '',
                        planificationStartHour: '',
                        reason: '',
                        reasonCode: '',
                        number: 0,
                    }
                );
            }
        });

        return resultVisits;
    }

    private getPlanificationDate(status: ActivityStatus, installation: Installation, visits: Array<WorksiteVisit>): string {
        if (status === ActivityStatus.FINISHED) {
            return installation.lastVisitDate;
        }

        if (status === ActivityStatus.PLANNED) {
            const filtredVisits = visits.filter(v => installation.id.startsWith(v.worksiteId)
                && v.dwellingsIds.includes(installation.dwellingId)
                && new Date(v.planificationPeriod.end).getTime() >= new Date().getTime());
            if (filtredVisits.length > 0) {
                return filtredVisits[0].planificationPeriod.start;
            }
        }
        return '';
    }

    private getActivityStatus(installation: Installation, visits: Array<WorksiteVisit>): ActivityStatus {
        let installationStatus;
        if (!installation.installable) {
            installationStatus = ActivityStatus.NOT_EQUIPABLE;
        } else {
            installationStatus = this.worksiteStatusService.getStatus(installation, visits);
        }
        return installationStatus;
    }

    private sortByDate(visits: Array<WorksiteVisit>): Array<WorksiteVisit> {
        return visits.sort((a: WorksiteVisit, b: WorksiteVisit) => {
            return convertToDateOrReturnDefault(a.planificationPeriod.start, this.globalVariables.activityLimitDate()).getTime()
                - convertToDateOrReturnDefault(b.planificationPeriod.start, this.globalVariables.activityLimitDate()).getTime();
        });
    }

    private getCommentFiltering(originalComment: string, meterType: string): string {
        const stringToFilter = ['.', '..', '...', '*', '**', '***', 'x', 'xx', 'xxx', 'ok', 'ko', 'nok'];
        let filteredComment = originalComment ? originalComment.trim().toLowerCase() : '';
        if (filteredComment.length === 0 ||
            stringToFilter.includes(filteredComment) ||
            filteredComment.startsWith('anonyme')) {
            return '';
        }
        if (filteredComment.startsWith(meterType.toLowerCase() + ' ')) {
            filteredComment = filteredComment.replace(meterType.toLowerCase() + ' ', meterType.toUpperCase() + ' ');
        }
        if (filteredComment.endsWith(' ' + meterType.toLowerCase())) {
            filteredComment = filteredComment.replace(' ' + meterType.toLowerCase(), ' ' + meterType.toUpperCase());
        }
        if (filteredComment.indexOf(' ' + meterType.toLowerCase() + ' ') != -1) {
            filteredComment = filteredComment.replace(' ' + meterType.toLowerCase() + ' ', ' ' + meterType.toUpperCase() + ' ');
        }
        filteredComment = filteredComment.substring(0, 1).toUpperCase() + filteredComment.substring(1);
        return filteredComment;
    }
}
