import { Injectable } from '@angular/core';
import { Reference } from '@ista/shared-ui';
import { FilterName } from '@shared/enum/filter-name.enum';

import { ActivityFilterSessionStorageService } from '../../../extern/activities/service/activity-filter-session-storage.service';
import { WorksiteFilterSessionStorageService } from '../../../extern/worksites/service/worksite-filter-session-storage.service';

@Injectable({
    providedIn: 'root'
})
export class ReferenceFilterSessionStorageService {

    constructor(
        private worksiteFilterSessionStorageService: WorksiteFilterSessionStorageService,
        private activityFilterSessionStorageService: ActivityFilterSessionStorageService
    ) {

    }

    setFilterProperties(
        propertyFilterData: Array<Reference>
    ): void {
        this.worksiteFilterSessionStorageService.setFiltersProperty(FilterName.PROPERTIES, propertyFilterData);
        this.activityFilterSessionStorageService.setFiltersProperty(FilterName.PROPERTIES, propertyFilterData);
    }

    getFilterPropertyIds(): string[] {
        const activityProperties = this.activityFilterSessionStorageService.getFiltersPropertyIds(FilterName.PROPERTIES);
        const worksiteProperties = this.worksiteFilterSessionStorageService.getFiltersPropertyIds(FilterName.PROPERTIES);

        const filterProperties = [...activityProperties, ...worksiteProperties];
        return filterProperties;
    }


    removeFiltersProperties(): void {
        this.setFilterProperties([]);
    }

    clearActivityWorksiteFilters(): void {
        this.activityFilterSessionStorageService.setFiltersProperty(FilterName.PROPERTIES, []);
        this.activityFilterSessionStorageService.setFiltersProperty(FilterName.ADDRESSES, []);
        this.activityFilterSessionStorageService.setFiltersProperty(FilterName.DWELLINGS, []);
        this.activityFilterSessionStorageService.setFiltersProperty(FilterName.DATES, '');
        this.activityFilterSessionStorageService.setFiltersProperty(FilterName.START_DATE, '');
        this.activityFilterSessionStorageService.setFiltersProperty(FilterName.END_DATE, '');
        this.activityFilterSessionStorageService.setFiltersProperty(FilterName.STATUSES, []);

        this.worksiteFilterSessionStorageService.setFiltersProperty(FilterName.PROPERTIES, []);
        this.worksiteFilterSessionStorageService.setFiltersProperty(FilterName.ADDRESSES, []);
        this.worksiteFilterSessionStorageService.setFiltersProperty(FilterName.DWELLINGS, []);
        this.worksiteFilterSessionStorageService.setFiltersProperty(FilterName.PRODUCTS, []);
        this.worksiteFilterSessionStorageService.setFiltersProperty(FilterName.STATUSES, []);
        this.worksiteFilterSessionStorageService.setFiltersProperty(FilterName.WORKSITE_STATUSES, []);
        this.worksiteFilterSessionStorageService.setFiltersProperty(FilterName.PERIODS, null);
        this.worksiteFilterSessionStorageService.setFiltersProperty(FilterName.COLUMNS, []);
        this.worksiteFilterSessionStorageService.setFiltersProperty(FilterName.START_DATE, '');
        this.worksiteFilterSessionStorageService.setFiltersProperty(FilterName.END_DATE, '');
    }

    getActiveFilterSessionStorage(): ActivityFilterSessionStorageService {
        return this.activityFilterSessionStorageService;
    }

}
