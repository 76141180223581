<div class="wrapper" (onResize)="table.recalculate()">
    <ngx-datatable #table class="material expandable table" [rows]="rows" (activate)="onSelect($event)"
        [columnMode]="columnMode.flex" [headerHeight]="35" [rowHeight]="40" [footerHeight]="33"
        [loadingIndicator]="showSpinner" [scrollbarH]="false" [scrollbarV]="true" [messages]="emptyMessages"
        [style]="listBlockStyle" [selectAllRowsOnPage]="false" [sorts]="currentTableSort" (sort)="viewSort()"
        (scroll)="recalculate()" (resize)="recalculate()" (onResize)="recalculate()">

        <ngx-datatable-row-detail [rowHeight]="40" #myDetailRow>
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                <div style="padding-left:35px; font-size: 14px;">
                    <div style="display: inline-block; padding-right: 30px;">
                        <strong>{{'datatable.activities.columns.propertyNumber'| translate}}</strong>:
                        {{row.propertyId}}
                    </div>
                    <div style="display: inline-block; padding-right: 30px;">
                        <strong>{{'datatable.activities.columns.dwellingIstaReference'| translate}}</strong>:
                        {{row.beep}}
                    </div>
                    <div *ngIf="row.type.toLocaleLowerCase() === 'installation'"
                        style="display: inline-block; padding-right: 30px;">
                        <strong>{{'datatable.activities.columns.installationTypeId'| translate}}</strong>:
                        {{row.activityId}}
                    </div>
                    <div *ngIf="row.type.toLocaleLowerCase() === 'maintenance'"
                        style="display: inline-block; padding-right: 30px;">
                        <strong>{{'datatable.activities.columns.activityTypeId'| translate}}</strong>:
                        {{row.activityId}}
                    </div>
                </div>
            </ng-template>
        </ngx-datatable-row-detail>
        <ngx-datatable-column [width]="20" [resizeable]="false" [sortable]="false" [draggable]="false"
            [canAutoResize]="false">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                <a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded"
                    (click)="toggleExpandRow(row)"></a>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column *ngFor="let col of columns" [comparator]="col.comparator" [name]="col.name"
            [prop]="col.prop" [resizeable]="true" [sortable]="col.sortable" [width]="col.width">

            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" style="height: 5rem;">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">{{column.name}}</span>
                </div>
                <ista-interrogation-point *ngIf="column.prop === 'type'" placement="bottom"
                    htmlText="{{'datatable.activitiesDetailled.columns.tooltip.activityType' | translate}}">
                </ista-interrogation-point>
                <ista-interrogation-point *ngIf="column.prop === 'reason'" placement="right"
                    htmlText="{{'datatable.activitiesDetailled.columns.tooltip.reason' | translate}}">
                </ista-interrogation-point>
                <ista-interrogation-point *ngIf="column.prop === 'subject'" placement="right"
                    htmlText="{{'datatable.activitiesDetailled.columns.tooltip.subject' | translate}}">
                </ista-interrogation-point>
                <ista-interrogation-point *ngIf="column.prop === 'visitsCount'" placement="right"
                    htmlText="{{'datatable.activitiesDetailled.columns.tooltip.visits' | translate}}">
                </ista-interrogation-point>
                <ista-interrogation-point *ngIf="column.prop === 'creationDate'" placement="right"
                    htmlText="{{'datatable.activitiesDetailled.columns.tooltip.date' | translate}}">
                </ista-interrogation-point>
                <ista-interrogation-point *ngIf="column.prop === 'meterStatus'" placement="bottom"
                    htmlText="{{'datatable.activitiesDetailled.columns.tooltip.status' | translate}}">
                </ista-interrogation-point>
            </ng-template>

            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div *ngIf="row !== undefined">
                    <!-- meter type -->
                    <div *ngIf="col.prop === 'productCode'">
                        <div *ngIf="value === ''">-</div>
                        <div *ngIf="value !== ''">{{getMeterLabel(row.productCode, row.fluidCode)}}</div>
                    </div>
                    <!-- status -->
                    <div *ngIf="col.prop === 'meterStatus' " class="status-cel h-100">
                        <div *ngIf="value != '' && row.meterStatus != 'PLANNED'"
                            class="text-center text-white rounded-pill {{row.meterStatus.toLocaleLowerCase()}}">
                            <span>{{'detailedActivitiesPage.' + row.type.toLocaleLowerCase() + ".status." +
                                row.meterStatus.toLowerCase() | translate}}</span>
                        </div>
                        <div *ngIf="value != '' && row.meterStatus === 'PLANNED'"
                            class="text-center text-white rounded-pill {{row.meterStatus.toLowerCase()}}"
                            style="position: relative; display: flex; flex-direction: column; top: -6px;">
                            <span style="height: 16px;">{{'detailedActivitiesPage.' + row.type.toLocaleLowerCase() +
                                ".status." + row.meterStatus.toLowerCase() | translate}}</span>
                            <span class="planned-date"
                                style="heigh: 19px; font-size: 10px;">{{transformDate(row.planificationDate)}}</span>
                        </div>
                    </div>
                    <!-- type  -->
                    <div *ngIf="col.prop === 'type'">
                        <div *ngIf="value === ''">-</div>
                        <div *ngIf="value !== ''">{{'detailedActivitiesPage.activityType.' + row.type.toLowerCase() |
                            translate}}</div>
                    </div>
                    <!-- visits count  -->
                    <div *ngIf="col.prop === 'visitsCount'">
                        <div *ngIf="value === ''">0</div>
                        <div *ngIf="value !== ''">{{value}}</div>
                    </div>
                    <!-- creation date  -->
                    <div *ngIf="col.prop === 'creationDate'">
                        <div *ngIf="value === ''">0</div>
                        <div *ngIf="value !== ''">{{transformDate(value)}}</div>
                    </div>
                    <!-- all other columns -->
                    <div *ngIf="col.prop !== 'productCode' && col.prop !== 'meterStatus'
                        && col.prop !== 'type' && col.prop !== 'visitsCount' && col.prop !== 'creationDate'">
                        <div *ngIf="value == ''">-</div>
                        <div *ngIf="value != ''">{{value}}</div>
                    </div>
                </div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
                <div class="page-count">
                    <ng-container *ngIf="2 > rowCount">
                        {{rowCount.toLocaleString()}} {{'detailedActivitiesPage.footer.activity' |translate}}
                    </ng-container>
                    <ng-container *ngIf="rowCount > 1">
                        {{rowCount.toLocaleString()}} {{'detailedActivitiesPage.footer.activities' |translate}}
                    </ng-container>
                </div>
                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)" (change)="table.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>
</div>