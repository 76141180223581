<div #wrapper class="wrapper" (onResize)="tableIst.recalculate()">
    <div *ngIf="isModalShown">
        <div class="my-modal-content" [style.top]="coordinates.y" [style.min-width]="modalSize">
            <div class="d-flex flex-column">
                <div class="my-modal-header">
                    <div class="d-flex flex-row align-items-center">
                        <div class="p-2" style="width: 98%; text-align: center">
                            <h4>{{ 'datatable.worksite.unrecycledBox.title' | translate }}</h4>
                        </div>
                        <div class="p-2" style="cursor: pointer" (click)="hideModal()">
                            <i class="p-2 fas fa-times fa-2x"> </i>
                        </div>
                    </div>
                </div>
                <div class="my-modal-body">
                    <app-installation-unrecycled-data [data]="selected" [columns]="columns">
                    </app-installation-unrecycled-data>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isReportModalShown">
        <div
            class="modal-dialog modal-dialog-centered"
            role="document"
            style="position: absolute; z-index: 1081; top: 40%; left: 50%; transform: translate(-50%, -50%)"
        >
            <div class="">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel" style="color: #0a2864">
                            {{ 'datatable.worksite.installationReport.modal.reportDocumentNotFoundHeader' | translate }}
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            (click)="hideReportModal()"
                        >
                            <span aria-hidden="true"> <i class="p-2 fas fa-times fa-2x"> </i></span>
                        </button>
                    </div>
                    <div class="modal-body" style="text-align: center; font-size: large; color: #0a2864">
                        {{ 'datatable.worksite.installationReport.modal.reportDocumentNotFoundBody' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [ngClass]="getStyle()">
        <ngx-datatable
            (resize)="recalculate()"
            (onResize)="recalculate()"
            #tableIst
            class="material expandable table"
            [style]="listBlockStyle"
            [rows]="lines"
            [columnMode]="columnMode.flex"
            [headerHeight]="35"
            [rowHeight]="isPrincipal ? 48 : 40"
            [footerHeight]="30"
            [scrollbarH]="true"
            [messages]="emptyMessages"
            [loadingIndicator]="showSpinner"
            [scrollbarV]="isPrincipal"
            (sort)="viewSort()"
            (activate)="onSelectTest($event)"
        >
            <ngx-datatable-row-detail [rowHeight]="40" #myDetailRow>
                <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                    <div style="padding-left: 35px; font-size: 14px">
                        <div style="display: inline-block; padding-right: 30px">
                            <strong>{{ 'datatable.worksite.columns.id' | translate }}</strong
                            >:
                            {{ row.propertyId }}
                        </div>
                        <div style="display: inline-block; padding-right: 30px">
                            <strong>{{ 'datatable.worksite.columns.dwellingIstaReference' | translate }}</strong
                            >:
                            {{ getDwellingIstaReference(row) }}
                        </div>
                        <div style="display: inline-block; padding-right: 30px">
                            <strong>{{ 'datatable.worksite.columns.worksiteNumber' | translate }}</strong
                            >:
                            {{ row.worksiteId }}
                        </div>
                    </div>
                </ng-template>
            </ngx-datatable-row-detail>
            <!-- Column Templates -->
            <ngx-datatable-column
                [width]="20"
                [resizeable]="false"
                [sortable]="false"
                [draggable]="false"
                [canAutoResize]="false"
            >
                <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                    <a
                        style="cursor: pointer"
                        [class.datatable-icon-right]="!expanded"
                        [class.datatable-icon-down]="expanded"
                        (click)="toggleExpandRow(row)"
                    ></a>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
                *ngFor="let col of columns"
                [name]="col.name"
                [prop]="col.prop"
                [width]="col.width"
                [sortable]="col.sortable"
                [comparator]="col.comparator"
                [flexGrow]="flexGrow(col.prop)"
            >
                <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                    <div class="d-inline-block datatable-header-cell-wrapper" style="height: 50px">
                        <span class="datatable-header-cell-label draggable" (click)="sort()">
                            {{ column.name | translate }}
                        </span>
                    </div>
                    <ista-interrogation-point
                        *ngIf="column.prop === 'dwellingStatus' && isPrincipal"
                        placement="right"
                        htmlText="{{ 'datatable.activities.columns.tooltip.status' | translate }}"
                    >
                    </ista-interrogation-point>
                </ng-template>
                <ng-template let-row="row" let-value="value" let-event="context" ngx-datatable-cell-template>
                    <div *ngIf="row !== undefined">
                        <div
                            *ngIf="
                                col.prop !== 'address' &&
                                col.prop !== 'productCode' &&
                                col.prop !== 'lastVisitDate' &&
                                col.prop !== 'installations' &&
                                col.prop !== 'nextVisitDate' &&
                                col.prop !== 'document' &&
                                col.prop !== 'dwellingStatus'
                            "
                        >
                            <div *ngIf="value === ''">-</div>
                            <div *ngIf="value !== ''">{{ value }}</div>
                        </div>
                        <div *ngIf="col.prop === 'address'">
                            <div style="text-align: center">
                                <div *ngIf="value === ''">-</div>
                                <div *ngIf="value !== ''">{{ getAddress(row) }}</div>
                            </div>
                        </div>
                        <!--Collonne appareil ANCIEN-->
                        <div *ngIf="col.prop === 'productCode'" class="d-flex flex-row">
                            <div style="width: 100px">{{ getMeterLabel(row.productCode, row.fluidCode) }}</div>
                            <div [style.color]="row.realMetersCount !== row.plannedMetersCount ? '#000000' : '#0071ff'">
                                {{ row.realMetersCount }}
                            </div>
                            <div>/{{ row.plannedMetersCount }}</div>
                        </div>
                        <div *ngIf="col.prop === 'lastVisitDate'">
                            <div *ngIf="value === ''">-</div>
                            <div *ngIf="value !== ''">{{ transformDate(value) }}</div>
                        </div>
                        <div *ngIf="col.prop === 'installations'">
                            <div
                                *ngIf="
                                    formatLastVisitResult(row).length === 0 &&
                                    (row.dwellingStatus !== 'IN_PROGRESS' ||
                                        (row.dwellingStatus === 'IN_PROGRESS' && row.lastVisitResult !== 'O'))
                                "
                            >
                                -
                            </div>
                            <div
                                *ngIf="
                                    formatLastVisitResult(row).length === 0 &&
                                    row.dwellingStatus === 'IN_PROGRESS' &&
                                    row.lastVisitResult === 'O'
                                "
                            >
                                <i class="fas fa-angle-right"></i>
                                <div style="display: inline; cursor: pointer">Voir le détail</div>
                            </div>
                            <div *ngIf="formatLastVisitResult(row).length === 1">
                                {{ formatLastVisitResult(row)[0] }}
                            </div>
                            <div *ngIf="formatLastVisitResult(row).length > 1">
                                <div>
                                    <ng-template #popTemplate>
                                        <div *ngFor="let v of formatLastVisitResult(row)">
                                            <div style="font-size: 12px">{{ v }}</div>
                                        </div>
                                    </ng-template>
                                    <div style="cursor: pointer">
                                        <i
                                            [popover]="popTemplate"
                                            placement="right"
                                            [outsideClick]="true"
                                            class="fas fa-angle-right"
                                            #pop="bs-popover"
                                            container="body"
                                        >
                                        </i>
                                        <div style="display: inline" (click)="pop.toggle()">Voir le détail</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="col.prop === 'nextVisitDate'">
                            <div *ngIf="value === ''; then display_no_date"></div>
                            <div *ngIf="value !== ''">
                                <div *ngIf="row.dwellingStatus === 'IN_PROGRESS'">
                                    <div
                                        *ngIf="
                                            formatLastVisitResult(row).length === 0 && row.lastVisitResult === 'O';
                                            then display_no_date;
                                            else display_next_date
                                        "
                                    ></div>
                                </div>
                                <div *ngIf="row.dwellingStatus === 'PLANNED'">
                                    <div
                                        *ngIf="
                                            row.nextVisitDate === row.visitsEndDate;
                                            then display_next_date;
                                            else display_multi_date
                                        "
                                    ></div>
                                </div>
                            </div>
                            <ng-template #display_no_date>-</ng-template>
                            <ng-template #display_next_date>{{ transformDate(value) }}</ng-template>
                            <ng-template #display_multi_date>
                                <div style="width: 100px; margin-top: -10px !important">
                                    {{ writeDate[0] | translate }} {{ transformDate(row.visitsStartDate) }}<br />
                                    {{ writeDate[1] | translate }} {{ transformDate(row.visitsEndDate) }}
                                </div>
                            </ng-template>
                        </div>
                        <div *ngIf="col.prop === 'document'">
                            <div style="margin-top: -5px !important">
                                <div class="" style="text-align: center">
                                    <span *ngIf="row.report.available === undefined">
                                        <i class="fas fa-spinner fa-pulse"></i>
                                    </span>
                                    <span *ngIf="hasRelevantStatusForReport(row)">
                                        <a class="btn" *ngIf="row.report.available !== undefined">
                                            <ista-export-data
                                                id="exportGroup"
                                                (export)="fetchInstallationsReport(row)"
                                                [showExcel]="true"
                                                title="{{
                                                    'datatable.worksite.installationReport.tooltip.download' | translate
                                                }}"
                                                *ngIf="row.report.available === true && !row.report.downloaded"
                                            >
                                            </ista-export-data>
                                            <span
                                                class="downloaded"
                                                (click)="fetchInstallationsReport(row)"
                                                *ngIf="row.report.available === true && row.report.downloaded"
                                            >
                                                <i
                                                    [tooltip]="
                                                        'datatable.worksite.installationReport.tooltip.downloadAgain'
                                                            | translate
                                                    "
                                                >
                                                    <mat-icon
                                                        class="mat-icon notranslate material-symbols-outlined mat-icon-no-color"
                                                    >
                                                        download</mat-icon
                                                    >
                                                </i>
                                            </span>
                                            <span class="document-inavailable" *ngIf="!row.report.available">
                                                <mat-icon
                                                    class="mat-icon notranslate material-symbols-outlined mat-icon-no-color"
                                                >
                                                    download</mat-icon
                                                >
                                            </span>
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="col.prop === 'dwellingStatus'"
                            [style.cursor]="getCursorStyle(value)"
                            (click)="onSelect(row)"
                        >
                            <div *ngIf="value !== 'FINISHED_REMAINING_INSTALLATION'" style="text-align: center">
                                <button
                                    [style.background-color]="getStatusBackgroundColor(value)"
                                    class="text-center text-white rounded-pill"
                                    style="border: none; min-width: 75px"
                                >
                                    {{ 'datatable.worksite.status.' + value | translate }}
                                </button>
                            </div>
                            <div *ngIf="value === 'FINISHED_REMAINING_INSTALLATION'">
                                <ng-template #remainingPop>
                                    <div style="text-align: start !important">
                                        <span>{{
                                            'datatable.worksite.tooltip.FINISHED_REMAINING_INSTALLATION' | translate
                                        }}</span>
                                    </div>
                                </ng-template>
                                <div
                                    [tooltip]="remainingPop"
                                    container="body"
                                    [adaptivePosition]="true"
                                    containerClass="tooltipClass"
                                    placement="left"
                                    class="finshed-remaining"
                                >
                                    <button
                                        [style.background-color]="getStatusBackgroundColor(value)"
                                        class="button radius"
                                        style="width: 160px"
                                    >
                                        {{ 'datatable.worksite.status.' + value | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template
                    *ngIf="isPrincipal"
                    ngx-datatable-footer-template
                    let-rowCount="rowCount"
                    let-pageSize="pageSize"
                    let-selectedCount="selectedCount"
                    let-curPage="curPage"
                    let-offset="offset"
                    let-isVisible="isVisible"
                >
                    <div class="page-count">
                        {{ rowCount.toLocaleString() }} {{ 'datatable.worksite.footer' | translate }}
                    </div>
                    <datatable-pager
                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                        [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'"
                        [pagerNextIcon]="'datatable-icon-skip'"
                        [page]="curPage"
                        [size]="pageSize"
                        [count]="rowCount"
                        [hidden]="!(rowCount / pageSize > 1)"
                        (change)="tableIst.onFooterPage($event)"
                    >
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
</div>
