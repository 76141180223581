<div class="wrapper">
    <div>
        <ista-spinner
            [showSpinner]="showSpinner"
            [data]="lines.length"
            customClass="xsmall"
            message= {{noData}}
            loading="{{'template.loading' | translate}}">
        </ista-spinner>

        <div *ngIf="!showSpinner && lines.length > 0">
            <app-installations-list class="unrecycled" #tableUnrecycled [lines]="lines" [columns]="unrecycledColumns" [isPrincipal]="false">
            </app-installations-list>
        </div>
    </div>
</div>
