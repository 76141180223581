import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { IstaLegalMentionsComponent, IstaLegalTermsComponent } from '@ista/shared-ui';

import { NotFoundComponent } from './features/oops/pages/not-found/not-found.component';
import { AuthComponent } from './shared/components/auth/auth.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'redirection-mon-compte-ista',
        pathMatch: 'full',
    },
    // {
    //     path: '',
    //     redirectTo: 'interne/login',
    //     pathMatch: 'full'
    // },
    {
        path: 'auth',
        component: AuthComponent,
    },

    {
        path: 'legal/terms',
        component: IstaLegalTermsComponent,
    },
    {
        path: 'legal/imprint',
        component: IstaLegalMentionsComponent,
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading, anchorScrolling: 'enabled' })],

    exports: [RouterModule],
})
export class AppRoutingModule {}
