import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { GlobalVariables } from '@core/util/global-variables';
import { AuthorizationService, SessionStorage } from '@ista/shared-ui';
import { Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { SelfcareIndexedDbService } from '../service/selfcare-indexed-db.service';

@Injectable({
    providedIn: 'root'
})

export class IndexedDbInterceptor implements HttpInterceptor {

    urlsToNotSave = [
        '/assets/',
        '/authenticate/',
        '/user/'
    ];
    urlToCheck = [
        '/activities/dwellings/details',
        '/worksites/dwellings/details',
        '/worksites/overview/flat',
        '/activities/meters/details',
        '/worksites/visits',
        '/signalements/overview/flat'
    ];

    constructor(
        private globalVariables: GlobalVariables,
        private indexedDBService: SelfcareIndexedDbService,
        private sessionStorage: SessionStorage,
        private authorizationService: AuthorizationService) {
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const catchedUrl = req.urlWithParams.split('date=')[0];
        const catchedUrlDate = req.urlWithParams.split('date=').length > 1 ? req.urlWithParams.split('date=')[1].split('&')[0] : '';
        const accountUuid = this.authorizationService.isExternalAccountType() ?
            this.sessionStorage.getExternalUserInfo().accountUuid :
            btoa(this.sessionStorage.getUserInfo().login);

        if (this.urlsToNotSave.find(notSaveUrl => new RegExp(notSaveUrl).test(catchedUrl)) || !accountUuid) {
            return next.handle(req);
        }
        this.indexedDBService.setDbName(accountUuid);

        // MEVODIG-3035 Get data by properties IDs
        if ((req.urlWithParams.includes('propertiesIds=')
            && this.urlToCheck.find(url => new RegExp(url).test(req.urlWithParams)))) {
            const urlStart = req.urlWithParams.split('propertiesIds=')[0];
            const propertiesParam = req.urlWithParams.split('propertiesIds=')[1].split('&')[0];
            const otherParam = req.urlWithParams.split('propertiesIds=')[1].split('&');
            const urlEnd = otherParam.length === 2 ? 
                otherParam[1]
                : otherParam.length === 3 ?
                    otherParam[1] + '&' + otherParam[2]
                    : otherParam[0];
            const testUrl = urlStart.slice(0, -1)
                + (urlStart.slice(0, -1).includes('?')
                    ? urlEnd !== undefined
                        ? '&' + urlEnd
                        : ''
                    : urlEnd !== undefined
                        ? '?' + urlEnd
                        : '');

            // Verification if we have already all data
            const checkTotalItemsFromStorage$ = this.indexedDBService.get(testUrl);
            return checkTotalItemsFromStorage$.pipe(
                mergeMap((test) => {
                    if (test) {
                        return this.checkAndSaveRequest(req, next, testUrl, catchedUrlDate);
                        // If we don't have all data we have to append properties in data (WithProperties)
                    } else {
                        const finalUrl = testUrl + '(WithProperties)';

                        // Verify if there is already data for some properties
                        const itemsFromStorage$ = this.indexedDBService.get(finalUrl);
                        return itemsFromStorage$.pipe(
                            mergeMap((data) => {
                                if (data) {
                                    const propertiesRequest = [...new Set(propertiesParam.split(',').filter(x => x !== ''))];
                                    const propertiesIDb = data.properties.split(',').filter(x => x !== '');
                                    if (isDevMode()) {
                                        console.log('\npropertiesIDb => ' + data.properties);
                                        console.log('\npropertiesRequest => ' + propertiesParam);
                                    }
                                    // If there is data verify if the request properties are already in IDB
                                    if (propertiesRequest.every(x => propertiesIDb.includes(x))) {
                                        if (isDevMode()) {
                                            console.log('--- ALL properties in IDB ---');
                                        }
                                        if (!this.globalVariables.urlForCurrentView.includes(finalUrl)) {
                                            this.globalVariables.urlForCurrentView.push(finalUrl);
                                        }
                                        // Filter the returned body in order to don't have duplicated values
                                        const returnedBody = (data.result as any[]).filter(r => {
                                            if (r.propertyId) {
                                                return propertiesRequest.includes(r.propertyId);
                                            } else if (r.propertiesIds) {
                                                return (r.propertiesIds as any[]).every(x => propertiesRequest.includes(x));
                                            } else return true;
                                        });
                                        return of(new HttpResponse({
                                            body: returnedBody,
                                            status: 200,
                                            url: finalUrl
                                        }));
                                    }
                                    // Complete the missing data by recreating the request
                                    const missingProperties = propertiesRequest.filter(p => !propertiesIDb.includes(p));
                                    const newUrl = urlStart + "propertiesIds=" + missingProperties.join(',') + (urlEnd ? '&' + urlEnd : '');
                                    const newReq = new HttpRequest<unknown>(
                                        req.method,
                                        newUrl,
                                        req.body,
                                        {
                                            headers: req.headers,
                                            context: req.context,
                                            reportProgress: req.reportProgress,
                                            params: req.params,
                                            responseType: req.responseType,
                                            withCredentials: req.withCredentials
                                        });
                                    // Do request and ADD to data
                                    const response1$ = next.handle(newReq);
                                    return response1$.pipe(
                                        mergeMap((event) => {
                                            if (event instanceof HttpResponse) {
                                                const receivedData = event.body as Array<unknown>;
                                                const oldData = data.result as Array<unknown>;
                                                const newData = [...new Set([...oldData, ...receivedData])];
                                                const newProperties = data.properties.concat(',', propertiesParam);
                                                if (!this.globalVariables.urlForCurrentView.includes(finalUrl)) {
                                                    this.globalVariables.urlForCurrentView.push(finalUrl);
                                                }
                                                if (isDevMode()) {
                                                    console.log('--- SAVE properties in IDB ---');
                                                }
                                                this.indexedDBService.saveWithProperties(finalUrl, newData, newProperties);
                                                return of(new HttpResponse({
                                                    body: (newData as any[]).filter(r => propertiesRequest.includes(r.propertyId)),
                                                    status: 200,
                                                    url: finalUrl
                                                }));
                                            } else {
                                                return of(new HttpResponse({
                                                    body: null,
                                                    status: 500,
                                                    url: finalUrl
                                                }));
                                            }
                                        })
                                    );
                                } else {
                                    const response$ = next.handle(req);
                                    return response$.pipe(
                                        tap((event) => {
                                            if (event instanceof HttpResponse) {
                                                if (isDevMode()) {
                                                    console.log('--- NO DATA FOUND FOR URL in IDB ---');
                                                }
                                                if (!this.globalVariables.urlForCurrentView.includes(finalUrl)) {
                                                    this.globalVariables.urlForCurrentView.push(finalUrl);
                                                }
                                                this.indexedDBService.saveWithProperties(finalUrl, event.body, propertiesParam);
                                            }
                                        })
                                    );
                                }
                            })
                        );

                    }
                })
            );
        } else {
            return this.checkAndSaveRequest(req, next, req.urlWithParams, '');
        }
    }

    private checkAndSaveRequest(req: HttpRequest<unknown>, next: HttpHandler,
        finalUrl: string, dateUrl: string): Observable<HttpEvent<unknown>> {
        const itemsFromStorage$ = this.indexedDBService.get(finalUrl);
        return itemsFromStorage$.pipe(
            mergeMap((data) => {
                if (data) {
                    if (isDevMode()) {
                        console.log(`------- Retrieved ${finalUrl} data from cache -------`);
                    }
                    if (!this.globalVariables.urlForCurrentView.includes(finalUrl)) {
                        this.globalVariables.urlForCurrentView.push(finalUrl);
                    }
                    return of(new HttpResponse({
                        body: data.result,
                        status: 200,
                        url: finalUrl
                    }));
                }
                const response$ = next.handle(req);
                return response$.pipe(
                    tap((event) => {
                        if (event instanceof HttpResponse) {
                            if (isDevMode()) {
                                console.log(`------- Save ${finalUrl} data in cache -------`);
                            }
                            if (!this.globalVariables.urlForCurrentView.includes(finalUrl)) {
                                this.globalVariables.urlForCurrentView.push(finalUrl);
                            }
                            this.indexedDBService.saveWithDate(req.urlWithParams, event.body);
                        }
                    })
                );
            })
        );
    }

}
