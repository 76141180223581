import { Injector, isDevMode } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'fr';
      translate.setDefaultLang(langToSet);
      translate.use(langToSet).subscribe(() => {
        if(isDevMode()) {
            console.info(`Successfully initialized '${langToSet}' language.`);
        }
      }, err => {
        if (isDevMode()) {
            console.error(`Problem with '${langToSet}' language initialization.`);
            console.error(`stack error => '${err}'`);
        }
      }, () => {
        resolve(null);
      });
    });
  });
}
