import { Injectable } from '@angular/core';
import { convertToDateOrReturnDefault, SortService } from '@ista/shared-ui';

import { GlobalVariables } from '../../../../core/util/global-variables';
import { ActivityDwellingDetails } from '../model/dwelling-activities-details.model';

export enum DwellingOrderCriterias {
    CLIENT_ACTION_REQUIRED = 'RETOUR NEGATIF FAUTE CLIENT OUI',
    VISITS_COUNT = 'NOMBRE DE PASSAGES',
    ACTIVITIES_COUNT = 'NOMBRE ACTIVITES',
    STATUS = 'STATUT',
    ACTIVITY_FIRST_CREATION_DATE = 'DATE DE CREATION DU SIGNALEMENT LA PLUS ANCIENNE'
}

@Injectable({
    providedIn: 'root'
})
export class ActivityDwellingSortingService extends SortService<ActivityDwellingDetails> {

    constructor(private globalVariables: GlobalVariables) {
        super();
        this.priorities.set(DwellingOrderCriterias.STATUS, this.ONE);
        this.priorities.set(DwellingOrderCriterias.CLIENT_ACTION_REQUIRED, this.TWO);
        this.priorities.set(DwellingOrderCriterias.ACTIVITIES_COUNT, this.THREE);
        this.priorities.set(DwellingOrderCriterias.ACTIVITY_FIRST_CREATION_DATE, this.FOUR);
        this.priorities.set(DwellingOrderCriterias.VISITS_COUNT, this.FIVE);
    }

    isSortable(dwelling: ActivityDwellingDetails): boolean {
        return dwelling && dwelling.visits && dwelling.visits.length > 0;
    }

    getValue(criteria: string, dwelling: ActivityDwellingDetails): number {
        if (!criteria || !dwelling) {
            return this.ZERO;
        } else if (criteria === DwellingOrderCriterias.STATUS) {
            return this.getStatusValue(dwelling.status.code);
        } else if (criteria === DwellingOrderCriterias.VISITS_COUNT) {
            return this.getNumberValue(dwelling.visits.length + '');
        } else if (criteria === DwellingOrderCriterias.ACTIVITIES_COUNT) {
            return this.getNumberValue(dwelling.activities.length + '');
        } else if (criteria === DwellingOrderCriterias.ACTIVITY_FIRST_CREATION_DATE) {
            return this.getDateValue(this.getDwellingFirstCreationDate(dwelling));
        } else if (criteria === DwellingOrderCriterias.CLIENT_ACTION_REQUIRED) {
            const customerActionRequiredOrRemainning: boolean = dwelling.customerActionRequired
                || (dwelling.activities.filter(i => i.remainingInstallation === true).length > 0);
            return this.getClientActionRequiredValue(customerActionRequiredOrRemainning + '');
        }
        return this.ZERO;
    }

    private getStatusValue(val: string): number {
        if (!val) {
            return this.ZERO;
        } else if (val === '1' || val === '2') {
            return this.FIVE / this.PGCD;
        } else if (val === '3') {
            return this.THREE / this.PGCD;
        } else if (val === '5') {
            return this.ONE / this.PGCD;
        } else {
            return this.ZERO;
        }
    }

    private getClientActionRequiredValue(val: string): number {
        if (!val || val === 'false') {
            return this.ONE / this.PGCD;
        } else {
            return this.NINE / this.PGCD;
        }
    }

    private getDwellingFirstCreationDate(dwelling: ActivityDwellingDetails): string {
        if (!dwelling || !dwelling.activities) {
            return '';
        }
        dwelling.activities.sort((firstItem, secondItem) => {
            const firstItemDate = convertToDateOrReturnDefault(firstItem.creationDate, this.globalVariables.activityLimitDate());
            const secondItemDate = convertToDateOrReturnDefault(secondItem.creationDate, this.globalVariables.activityLimitDate());
            return (secondItemDate.getTime() - firstItemDate.getTime());
        });
        let firstCreationDate = '0';
        if (dwelling.activities[0]) {
            firstCreationDate = dwelling.activities[0].creationDate;
        }
        if (firstCreationDate !== '0') {
            firstCreationDate = firstCreationDate.split('-').join('');
        }
        return firstCreationDate;

    }
}
