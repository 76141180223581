import {isDevMode} from '@angular/core';
import {Router} from '@angular/router';
import {isAuthorizedRedirectingPath} from '@core/util/redirect.util';
import {AuthenticationService, SessionStorage} from '@ista/shared-ui';
import {firstValueFrom} from 'rxjs';

export function ssoService(
    authenticationService: AuthenticationService,
    router: Router,
    sStorage: SessionStorage
): () => void {
    return () => {
        const url = new URL(window.location.href);
        const authCode = url.searchParams.get('code');
        const state = url.searchParams.get('state');
        const redirectUrl = url.searchParams.get('redirectUrl');
        const redirectUri =
            url.origin +
            url.pathname +
            (redirectUrl && redirectUrl.length > 0 ? '?redirectUrl=' + redirectUrl?.replace(/\//g, '%2F') : '');
        const accessToken = url.searchParams.get('accessToken');
        const refreshToken = url.searchParams.get('refreshToken');

        if (isDevMode()) {
            console.log('redirectUri => ', redirectUri);
            console.log('authorizationCode received in parameters => ', authCode);
            console.log('accessToken received in parameters => ', accessToken);
            console.log('refreshToken received in parameters => ', refreshToken);
        }
        const isInternalLogin = "internal-login-workflow" === state;

        if (!isInternalLogin && authCode) {
            firstValueFrom(authenticationService.loginWithAuthCode(authCode, redirectUri, false)).then(
                (response: { accessToken: string; refreshToken: string }) => {
                    if (isDevMode()) {
                        console.log('Received AccessToken => ', response.accessToken);
                        console.log('Received RefreshToken => ', response.refreshToken);
                    }
                    const addParams = redirectUrl && isAuthorizedRedirectingPath(redirectUrl);
                    router.navigate(
                        ['extern/auth'],
                        addParams ? {queryParams: {redirectUrl: redirectUrl}} : undefined
                    );
                }
            );
        }
        if (!isInternalLogin && accessToken && refreshToken) {
            authenticationService.loginWithTokens(accessToken, refreshToken);
        }
    };
}
