import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { InternRoutes } from '@core/enum/intern-routes.enum';
import { ActivityStatus } from '@extern/activities/enum/activity-status.enum';
import { NewDwellingActivities } from '@extern/activities/model/new-dwelling-activities.model';
import { AuthorizationService } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@siemens/ngx-datatable';
import { Observable, Subscription } from 'rxjs';
import { ExternRoutes } from '@core/enum/extern-routes.enum';
import { Column } from 'src/app/core/model/column.model';

import { ActivityDwellingDetails } from '../../model/dwelling-activities-details.model';
import { ActivityColorService } from '../../service/activity-color.service';
import { ActivityFilterSessionStorageService } from '../../service/activity-filter-session-storage.service';

@Component({
    selector: 'app-dwelling-list',
    templateUrl: './dwelling-list.component.html',
    styleUrls: ['./dwelling-list.component.css'],
})
export class DwellingListComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
    @Input() rows: ReadonlyArray<NewDwellingActivities>;
    @Input() showSpinner: boolean;
    @Input() filterSessionStorage: ActivityFilterSessionStorageService;
    @Input() columns: Column[];
    emptyMessages = {
        emptyMessage: `<span class="empty">${this.translate.instant('filters.noActivityForTheSelection')}</span>`,
    };
    @Input() listBlockHeight: number;
    hasRows: boolean;
    isSorted = false;
    selected = [];
    completeRows = new Array<NewDwellingActivities>();
    contentTitle: string;
    SelectionType = SelectionType;
    ColumnMode = ColumnMode;

    @Input() events: Observable<number>;
    observer: any;
    @ViewChild('tableSig') tableSig: DatatableComponent;
    listBlockStyle: string;
    eventsSubscription: Subscription;
    previousRowAlreadyRestored: boolean = false;
    currentTableSort: any;

    constructor(
        private router: Router,
        private activityColorService: ActivityColorService,
        private translate: TranslateService,
        private isInternal: AuthorizationService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnDestroy(): void {
        this.eventsSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.translate.setDefaultLang('fr');
        this.hasRows = this.rows.length > 0;
        this.previousRowAlreadyRestored = false;
        const previousSortedColumns = sessionStorage.getItem('dwelling-list-sorted-columns');
        if (previousSortedColumns) {
            const sortedColumns = JSON.parse(previousSortedColumns);
            this.isSorted = true;
            this.currentTableSort = sortedColumns;
            sessionStorage.removeItem('dwelling-list-sorted-columns');
        }
        this.eventsSubscription = this.events.subscribe((height) => {
            this.listBlockHeight = height;
            this.recalculate();
        });
    }

    ngAfterViewChecked() {
        this.tableSig.columnMode = ColumnMode.force;
        this.changeDetectorRef.detectChanges();
    }

    recalculate(): void {
        this.listBlockStyle = `height: ${this.listBlockHeight}px;`;
        this.hasRows = this.rows.length > 0;
        this.tableSig.recalculate();
        this.rows = [...this.rows];
        this.changeDetectorRef.detectChanges();
        if (!this.previousRowAlreadyRestored && this.hasRows) {
            this.restorePreviousSelectedRow();
        }
    }

    ngOnChanges(): void {
        this.completeRows = new Array<NewDwellingActivities>();
        this.functionCompleteRows();
        this.changeDetectorRef.detectChanges();
    }

    functionCompleteRows(): void {
        this.completeRows = this.rows as NewDwellingActivities[];
        this.completeRows.sort(
            (a, b) =>
                b.status.nbItemsHelpUs - a.status.nbItemsHelpUs ||
                b.status.nbItemsToPlan - a.status.nbItemsToPlan ||
                b.status.nbItemsToPlanNoAction - a.status.nbItemsToPlanNoAction ||
                b.status.nbItemsPlanned - a.status.nbItemsPlanned ||
                b.status.nbItemsInProgress - a.status.nbItemsInProgress ||
                b.status.nbItemsNotEquippable - a.status.nbItemsNotEquippable ||
                b.status.nbItemsFinished - a.status.nbItemsFinished
        );
    }

    getColor(statusProperty: string): string {
        let status = ActivityStatus.PLANNED;
        let customerActionRequired = false;
        if (statusProperty === 'status.nbItemsFinished') {
            status = ActivityStatus.FINISHED;
        } else if (statusProperty === 'status.nbItemsToPlanNoAction') {
            status = ActivityStatus.TO_PLAN;
        } else if (statusProperty === 'status.nbItemsToPlanAction') {
            status = ActivityStatus.TO_PLAN;
            customerActionRequired = true;
        } else if (statusProperty === 'status.nbItemsInProgress') {
            status = ActivityStatus.IN_PROGRESS;
        } else if (statusProperty === 'status.nbItemsNotEquippable') {
            status = ActivityStatus.NOT_EQUIPABLE;
        } else if (statusProperty === 'status.nbItemsHelpUs') {
            status = ActivityStatus.HELP_US;
        }
        return this.activityColorService.getColor(status, customerActionRequired, false);
    }

    resetSort(): void {
        this.tableSig.sorts = [];
        this.tableSig.rows = [...this.tableSig.rows];
        this.isSorted = false;
    }

    viewSort(): void {
        this.isSorted = true;
    }

    flexGrow(col: string): number {
        switch (col) {
            case 'status.nbItemsToPlan':
            case 'status.nbItemsPlanned':
            case 'status.nbItemsInProgress':
            case 'status.nbItemsFinished':
                return 2;
            case 'owner':
                return 3;
            default:
                return 4;
        }
    }

    public getTranslate(translateKey: string): string {
        let response = '';
        this.translate.get(translateKey).subscribe((translateValue: string) => {
            response = translateValue;
        });
        return response;
    }

    identifyColor(index: string): string {
        if (this.identifyRed(index)) {
            return 'red';
        }
        if (this.identifyOrange(index)) {
            return 'red';
        }
        if (this.identifyBlue(index)) {
            return 'orange';
        }
        if (this.identifyLightGreen(index)) {
            return 'dark-green';
        }
        if (this.identifyDarkGreen(index)) {
            return 'dark-green';
        }
        return '';
    }

    getTooltip(row: ActivityDwellingDetails): string {
        if (row.status.nbItemsToPlanAction > 0) {
            return this.getTranslate('datatable.action');
        } else {
            return '';
        }
    }

    getTitle(index: string): string {
        const color = this.identifyColor(index);
        let result = '';
        if (color === 'red') {
            this.translate.get('datatable.statusColors.red').subscribe((res: string) => {
                result = res;
            });
        } else if (color === 'orange') {
            this.translate.get('datatable.statusColors.orange').subscribe((res: string) => {
                result = res;
            });
        } else if (color === 'dark-green') {
            this.translate.get('datatable.statusColors.green').subscribe((res: string) => {
                result = res;
            });
        }
        return result;
    }

    public identifyRed(index: string): boolean {
        return index !== undefined && index === '1';
    }

    public identifyOrange(index: string): boolean {
        return index !== undefined && index === '2';
    }

    public identifyBlue(index: string): boolean {
        return index !== undefined && index === '3';
    }

    public identifyLightGreen(index: string): boolean {
        return index !== undefined && index === '4';
    }

    public identifyDarkGreen(index: string): boolean {
        return index !== undefined && index === '5';
    }

    onSelect(event: any): void {
        if (event.type === 'click' && event.cellIndex !== 0) {
            const selected = event.row;
            if (this.tableSig.sorts && this.tableSig.sorts.length >= 1) {
                sessionStorage.setItem('dwelling-list-sorted-columns', JSON.stringify(this.tableSig.sorts));
            } else {
                sessionStorage.removeItem('dwelling-list-sorted-columns');
            }
            const selectedRowInfo = {
                row: selected,
                scrollTo: this.tableSig.bodyComponent.offsetY,
            };
            sessionStorage.setItem('dwelling-list-selected-row-info', JSON.stringify(selectedRowInfo));
            this.router.navigate([
                this.isInternal.isInternalAccountType() ? InternRoutes.DWELLING_ROUTE : ExternRoutes.DWELLING_ROUTE,
                selected.dwellingId,
            ]);
        }
    }

    toggleExpandRow(row: ActivityDwellingDetails): void {
        this.tableSig.rowDetail.toggleExpandRow(row);
    }

    getAddress(row: ActivityDwellingDetails): string {
        return row.fullAddress;
    }

    restorePreviousSelectedRow(): void {
        const previousSelectedRowInfo = sessionStorage.getItem('dwelling-list-selected-row-info');
        if (previousSelectedRowInfo) {
            const selectedRowInfo = JSON.parse(previousSelectedRowInfo);
            const selectedRow = Object.assign(new ActivityDwellingDetails(), selectedRowInfo.row);
            const rowHeight = this.tableSig.bodyComponent.getRowHeight(selectedRow);
            const pageRatio =
                selectedRowInfo.scrollTo / 2 < this.tableSig.bodyHeight
                    ? selectedRowInfo.scrollTo / rowHeight / this.tableSig.pageSize
                    : (selectedRowInfo.scrollTo + rowHeight) / rowHeight / this.tableSig.pageSize;
            this.tableSig.bodyComponent.updateOffsetY(pageRatio);
            const selectedRowIndex = (this.tableSig.rows as any[]).findIndex(
                (row) => JSON.stringify(row) === JSON.stringify(selectedRow)
            );
            if (selectedRowIndex > -1) {
                const rowToSelect = this.tableSig.rows[selectedRowIndex];
                this.tableSig.selected = [rowToSelect];
            } else {
                this.tableSig.selected = [];
            }
        }
        sessionStorage.removeItem('dwelling-list-selected-row-info');
        this.previousRowAlreadyRestored = true;
    }
}
