import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ExternRoutes } from '@core/enum/extern-routes.enum';
import { InternRoutes } from '@core/enum/intern-routes.enum';
import { NewActivityDetails } from '@extern/activities/model/new-activity-details.model';
import { ActivityFilterSessionStorageService } from '@extern/activities/service/activity-filter-session-storage.service';
import { AuthorizationService, formatDate } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { ProductsConstants } from '@shared/constants/products.const';
import { ColumnMode, DatatableComponent } from '@siemens/ngx-datatable';
import { Observable, Subscription } from 'rxjs';
import { Column } from 'src/app/core/model/column.model';

@Component({
    selector: 'app-activity-list',
    templateUrl: './activity-list.component.html',
    styleUrls: ['./activity-list.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityListComponent implements OnInit, OnDestroy, AfterViewChecked {
    @Input() columns: Array<Column>;
    @Input() rows: ReadonlyArray<NewActivityDetails>;
    @Input() showSpinner: boolean;
    @Input() filterSessionStorage: ActivityFilterSessionStorageService;
    @Input() listBlockHeight: number;
    @Input() events: Observable<number>;
    @ViewChild('table') table: DatatableComponent;
    emptyMessages = {
        emptyMessage: `<span class="empty">${this.translate.instant('filters.noActivityForTheSelection')}</span>`,
    };
    columnMode = ColumnMode;
    isSorted = false;
    eventsSubscription: Subscription;
    listBlockStyle: string;
    hasRows: boolean;
    previousRowAlreadyRestored: boolean = false;
    currentTableSort: any;
    defaultDatePattern = 'DD/MM/YYYY';

    constructor(
        private router: Router,
        private translate: TranslateService,
        private isInternal: AuthorizationService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.translate.setDefaultLang('fr');
        this.eventsSubscription = this.events.subscribe((height) => {
            this.listBlockHeight = height;
            this.recalculate();
        });
        this.hasRows = this.rows.length > 0;
        this.previousRowAlreadyRestored = false;
        const previousSortedColumns = sessionStorage.getItem('activity-list-sorted-columns');
        if (previousSortedColumns) {
            const sortedColumns = JSON.parse(previousSortedColumns);
            this.isSorted = true;
            this.currentTableSort = sortedColumns;
            sessionStorage.removeItem('activity-list-sorted-columns');
        }
    }

    recalculate(): void {
        this.listBlockStyle = `height: ${this.listBlockHeight}px;`;
        this.hasRows = this.rows.length > 0;
        this.table.recalculate();
        this.rows = [...this.rows];
        this.changeDetectorRef.detectChanges();
        if (!this.previousRowAlreadyRestored && this.hasRows) {
            this.restorePreviousSelectedRow();
        }
    }

    resetSort(): void {
        this.table.sorts = [];
        this.table.rows = [...this.table.rows];
        this.isSorted = false;
    }

    viewSort(): void {
        this.isSorted = true;
    }

    ngAfterViewChecked(): void {
        this.table.columnMode = ColumnMode.force;
        this.table.recalculate();
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.eventsSubscription.unsubscribe();
    }

    onSelect(event: any): void {
        if (event.type === 'click' && event.cellIndex !== 0) {
            const selected = event.row;
            if (this.table.sorts && this.table.sorts.length >= 1) {
                sessionStorage.setItem('activity-list-sorted-columns', JSON.stringify(this.table.sorts));
            } else {
                sessionStorage.removeItem('activity-list-sorted-columns');
            }
            const selectedRowInfo = {
                row: selected,
                scrollTo: this.table.bodyComponent.offsetY,
            };
            sessionStorage.setItem('activity-list-selected-row-info', JSON.stringify(selectedRowInfo));
            this.router.navigate([
                this.isInternal.isInternalAccountType() ? InternRoutes.DWELLING_ROUTE : ExternRoutes.DWELLING_ROUTE,
                selected.dwellingId,
            ]);
        }
    }

    restorePreviousSelectedRow(): void {
        const previousSelectedRowInfo = sessionStorage.getItem('activity-list-selected-row-info');
        if (previousSelectedRowInfo) {
            const selectedRowInfo = JSON.parse(previousSelectedRowInfo);
            const selectedRow = Object.assign(new NewActivityDetails(), selectedRowInfo.row);
            const rowHeight = this.table.bodyComponent.getRowHeight(selectedRow);
            const pageRatio =
                selectedRowInfo.scrollTo / 2 < this.table.bodyHeight
                    ? selectedRowInfo.scrollTo / rowHeight / this.table.pageSize
                    : (selectedRowInfo.scrollTo + rowHeight) / rowHeight / this.table.pageSize;
            this.table.bodyComponent.updateOffsetY(pageRatio);
            var selectedRowIndex = -1;
            for (var i = 0; selectedRowIndex < this.table.rows.length; i++) {
                if (JSON.stringify(this.table.rows[i]) === JSON.stringify(selectedRow)) {
                    selectedRowIndex = i;
                    break;
                }
            }
            if (selectedRowIndex > -1) {
                const rowToSelect = this.table.rows[selectedRowIndex];
                this.table.selected = [rowToSelect];
            } else {
                this.table.selected = [];
            }
        }
        sessionStorage.removeItem('activity-list-selected-row-info');
        this.previousRowAlreadyRestored = true;
    }

    toggleExpandRow(row: NewActivityDetails): void {
        this.table.rowDetail.toggleExpandRow(row);
    }

    getMeterLabel(productCode: string, meterFluid: string): string {
        return ProductsConstants.getMeterLongLabel(productCode, meterFluid);
    }

    transformDate(dateToTranform: string): string {
        return formatDate(dateToTranform, this.defaultDatePattern);
    }
}
