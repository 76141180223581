import { Inject, Injectable } from '@angular/core';
import { ActivityStatus } from '@extern/activities/enum/activity-status.enum';
import { NewDwellingActivities } from '@extern/activities/model/new-dwelling-activities.model';
import { Customer } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { ListUtilService } from '@shared/util/list-util.service';
import { Row, Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';

import { Activity } from '../../features/extern/activities/model/activity.model';
import * as excelUtil from '../../shared/util/excel-util';

/**
 * @see https://github.com/exceljs/exceljs
 * @see https://www.ngdevelop.tech/export-to-excel-in-angular-6/
 * @version exceljs 1.14.0
 */

@Injectable({
    providedIn: 'root',
})
export class ExcelService {
    constructor(
        @Inject(TranslateService) private translate: TranslateService,
        private listUtil: ListUtilService
    ) {}

    public generateExcelFromDwellingsList(
        customer: Customer,
        data: ReadonlyArray<NewDwellingActivities>,
        filteredResidences: Array<string>,
        filteredDwellings: Array<string>,
        filteredStatus: Array<string>,
        since: string,
        filteredAdresses: Array<string>
    ): void {
        const worksheetName = `${this.translate.instant('excel.activities.worksheet.names.main')}_${moment().format('YYYYMMDD')}`;

        // Define static data
        const title = this.translate.instant('excel.activities.title');
        const columns = [
            this.translate.instant('excel.activities.columns.propertyNumber'),
            this.translate.instant('excel.activities.columns.propertyName'),
            this.translate.instant('excel.activities.columns.dwellingCustomerReference'),
            this.translate.instant('excel.activities.columns.dwellingIstaReference'),
            this.translate.instant('excel.activities.columns.city'),
            this.translate.instant('excel.activities.columns.propertyAddress'),
            this.translate.instant('excel.activities.columns.owner'),
            this.translate.instant('excel.activities.columns.tenant'),
            this.translate.instant('excel.activities.columns.nbActivities'),
            this.translate.instant('excel.activities.columns.activitiesToPlan'),
            this.translate.instant('excel.activities.columns.plannedActivities'),
            this.translate.instant('excel.activities.columns.finishedActivities'),
            this.translate.instant('excel.activities.columns.customerAction'),
        ];

        const columnComplement = 0.78; // https://github.com/exceljs/exceljs/issues/744
        const worksheetColumns = [
            { key: 'A', width: 17.56 + columnComplement },
            { key: 'B', width: 14.67 + columnComplement },
            { key: 'C', width: 17.56 + columnComplement },
            { key: 'D', width: 17.56 + columnComplement },
            { key: 'E', width: 17.56 + columnComplement },
            { key: 'F', width: 17.56 + columnComplement },
            { key: 'G', width: 17.56 + columnComplement },
            { key: 'H', width: 17.56 + columnComplement },
            { key: 'I', width: 17.56 + columnComplement },
            { key: 'J', width: 15.33 + columnComplement },
            { key: 'K', width: 15.33 + columnComplement },
            { key: 'L', width: 15.33 + columnComplement },
            { key: 'M', width: 17.56 + columnComplement },
        ];

        // Create the workbook
        const workbook: Workbook = new Workbook();

        // From the workbook, create a new sheet
        const worksheet: Worksheet = this.createWorksheet(
            workbook,
            worksheetName,
            'landscape',
            data.length + excelUtil.headerlines
        );
        // Define worksheet's columns
        worksheet.columns = worksheetColumns;

        const maxLinesForSelectedFilters = 5;
        // tslint:disable-next-line:max-line-length
        const showSelectedFilters: boolean =
            filteredResidences.length <= maxLinesForSelectedFilters &&
            filteredDwellings.length <= maxLinesForSelectedFilters &&
            filteredStatus.length <= maxLinesForSelectedFilters &&
            filteredAdresses.length <= maxLinesForSelectedFilters;

        // One, insert data...
        worksheet.addRow([title]);
        worksheet.addRow([]);

        worksheet.addRow([
            `${this.translate.instant('excel.activities.headers.intendedFor')} :`,
            null,
            null,
            this.translate.instant('excel.activities.headers.appliedFilters'),
        ]);

        worksheet.addRow([
            customer.name,
            null,
            null,
            `${this.translate.instant('excel.activities.headers.since')} :`,
            null,
            `${this.translate.instant('excel.activities.headers.properties')}: ${filteredResidences.length === 0 ? 'toutes' : ''}`,
            null,
            `${this.translate.instant('excel.activities.headers.address')}: ${filteredAdresses.length === 0 ? 'toutes' : ''}`,
            null,
            `${this.translate.instant('excel.activities.headers.dwellings')}: ${filteredDwellings.length === 0 ? 'tous' : ''} `,
            null,
            `${this.translate.instant('excel.activities.headers.status')}: ${filteredStatus.length === 0 ? 'tous' : ''}`,
            null,
        ]);

        worksheet.addRow([]);
        worksheet.addRow([customer.address, null, null, null, null, null, null, null, null]);
        worksheet.addRow([]);
        const city: string = new Array(customer.postalCode, customer.city).join(' ');
        worksheet.addRow([city]);

        worksheet.addRow([]);
        worksheet.addRow([]);
        worksheet.addRow([]);
        worksheet.addRow([]);

        // Insert filters values
        const filterValueStartIndex = 5;
        const minLineNumberForHeaderBlocks = 6;
        let maxLineNumberForHeaderBlocks = minLineNumberForHeaderBlocks;

        if (showSelectedFilters) {
            //fill with empty char to prevent text overflow
            const nbResidences = filteredResidences.length;
            const nbAddress = filteredAdresses.length;
            const nbDwellings = filteredDwellings.length;
            const nbStatus = filteredStatus.length;
            const maxLines: number = Math.max(nbDwellings, nbResidences, nbStatus, nbAddress);

            for (let i = filterValueStartIndex; i < maxLines + filterValueStartIndex; i++) {
                worksheet.getCell(`F${i}`).value = '';
                worksheet.getCell(`H${i}`).value = '';
                worksheet.getCell(`J${i}`).value = '';
                worksheet.getCell(`L${i}`).value = '';
            }
            //end empty fill
            worksheet.getCell(`D${filterValueStartIndex}`).value = since;

            filteredResidences.forEach((value, index, array) => {
                const cellNumber = filterValueStartIndex + index;
                worksheet.getCell(`F${cellNumber}`).value = value;
                maxLineNumberForHeaderBlocks =
                    cellNumber > maxLineNumberForHeaderBlocks ? cellNumber : maxLineNumberForHeaderBlocks;
            });

            filteredAdresses.forEach((value, index, array) => {
                const cellNumber = filterValueStartIndex + index;
                worksheet.getCell(`H${cellNumber}`).value = value;
                maxLineNumberForHeaderBlocks =
                    cellNumber > maxLineNumberForHeaderBlocks ? cellNumber : maxLineNumberForHeaderBlocks;
            });

            filteredDwellings.forEach((value, index, array) => {
                const cellNumber = filterValueStartIndex + index;
                worksheet.getCell(`J${cellNumber}`).value = value;
                maxLineNumberForHeaderBlocks =
                    cellNumber > maxLineNumberForHeaderBlocks ? cellNumber : maxLineNumberForHeaderBlocks;
            });

            filteredStatus.forEach((value, index, array) => {
                const cellNumber = filterValueStartIndex + index;
                worksheet.getCell(`L${cellNumber}`).value = value;
                maxLineNumberForHeaderBlocks =
                    cellNumber > maxLineNumberForHeaderBlocks ? cellNumber : maxLineNumberForHeaderBlocks;
            });
        } else {
            worksheet.getCell('D4').value = worksheet.getCell('D4').text.concat(' ', since);
            worksheet.getCell('F4').value = worksheet
                .getCell('F4')
                .text.concat(' ', filteredResidences.length > 0 ? filteredResidences.length.toString() : '');
            worksheet.getCell('H4').value = worksheet
                .getCell('H4')
                .text.concat(' ', filteredAdresses.length > 0 ? filteredAdresses.length.toString() : '');
            worksheet.getCell('J4').value = worksheet
                .getCell('J4')
                .text.concat(' ', filteredDwellings.length > 0 ? filteredDwellings.length.toString() : '');
            worksheet.getCell('L4').value = worksheet
                .getCell('L4')
                .text.concat(' ', filteredStatus.length > 0 ? filteredStatus.length.toString() : '');
            // Insert filters into new worksheet
            this.createFilterWorksheet(
                workbook,
                filteredResidences,
                filteredAdresses,
                filteredDwellings,
                filteredStatus
            );
        }

        // Insert dwellings values
        const columnsTab: Row = worksheet.addRow(columns);
        data.forEach((value, index, array) => {
            const lastValue: boolean = index === array.length - 1;
            const rowValue = this.buildExcelDwellingLine(value);

            const row: Row = worksheet.addRow(rowValue);
            // We need to define borders for each cell of row
            for (let i = 1; i <= rowValue.length; i++) {
                if (lastValue) {
                    row.getCell(i).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'medium' },
                        right: { style: 'thin' },
                    };
                } else {
                    row.getCell(i).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                }
            }
        });

        // Two, formatting...
        worksheetColumns
            .map((col) => `${col.key}1`)
            .forEach((key) => {
                worksheet.getCell(key).style = {
                    fill: excelUtil.backgroundTitleFill,
                    font: excelUtil.titleFont,
                };
            });

        ['A3', 'D3'].forEach((key) => {
            worksheet.getCell(key).font = excelUtil.headerTitleFont;
        });

        const headers = [
            'A4',
            'B4',
            'D4',
            'E4',
            'F4',
            'G4',
            'H4',
            'I4',
            'J4',
            'K4',
            'L4',
            'A5',
            'B5',
            'D5',
            'E5',
            'F5',
            'G5',
            'H5',
            'I5',
            'J5',
            'K5',
            'L5',
            'A6',
            'B6',
            'D6',
            'E6',
            'F6',
            'G6',
            'H6',
            'I6',
            'J6',
            'K6',
            'L6',
            'A7',
            'B7',
            'A8',
            'B8',
            'A9',
            'B9',
        ];

        if (minLineNumberForHeaderBlocks < maxLineNumberForHeaderBlocks) {
            for (let i = minLineNumberForHeaderBlocks + 1; i <= maxLineNumberForHeaderBlocks; i++) {
                headers.push(`D${i}`);
                headers.push(`E${i}`);
                headers.push(`F${i}`);
                headers.push(`G${i}`);
                headers.push(`H${i}`);
                headers.push(`I${i}`);
                headers.push(`J${i}`);
                headers.push(`K${i}`);
                headers.push(`L${i}`);
            }
        }
        headers.forEach((key) => {
            worksheet.getCell(key).style = {
                font: excelUtil.headerValueFont,
                fill: excelUtil.backgroundHeaderValueFill,
            };
        });

        worksheetColumns.forEach((col) => {
            worksheet.mergeCells(`${col.key}11:${col.key}12`);
        });

        columnsTab.height = 50;
        columnsTab.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        columnsTab.font = { name: excelUtil.fontFamily, size: 10, bold: true };

        worksheetColumns
            .map((col) => `${col.key}13`)
            .forEach((key) => {
                worksheet.getCell(key).border = {
                    top: { style: 'medium' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
            });

        for (let i = 13; i <= 13 + data.length; i++) {
            worksheet.getCell('A' + i).border.left = { style: 'medium' };
        }

        for (let i = 13; i <= 13 + data.length; i++) {
            worksheet.getCell('K' + i).border.right = { style: 'medium' };
        }

        // Three, add ista logo
        const istaLogo = workbook.addImage({
            base64: excelUtil.istaLogo,
            extension: 'png',
        });
        worksheet.addImage(istaLogo, 'M2:M4');

        // Four, add worksheet options
        // worksheet.autoFilter = 'A13:K13'; // disable because does not working --> https://github.com/exceljs/exceljs/issues/400

        // Five, Header and footer
        this.addHeaderAndFooter(worksheet);

        // Four, export file
        const filename = `${moment().format('YYYY_MM_DD')}_${this.translate.instant('excel.activities.filename')}_${customer.name}.xlsx`;
        this.downloadWorkbook(workbook, filename);
    }

    private createWorksheet(
        workbook: Workbook,
        name: string,
        orientation: 'portrait' | 'landscape',
        rows: number
    ): Worksheet {
        return workbook.addWorksheet(name, excelUtil.getWorksheetOptions(orientation, rows));
    }

    private downloadWorkbook(workbook: Workbook, filename: string): void {
        // tslint:disable-next-line:no-any
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            saveAs(blob, filename);
        });
    }

    private createFilterWorksheet(
        workbook: Workbook,
        filteredResidences: Array<string>,
        filteredAdresses: Array<string>,
        filteredDwellings: Array<string>,
        filteredStatus: Array<string>
    ): void {
        const nbResidences = filteredResidences.length;
        const nbAddress = filteredAdresses.length;
        const nbDwellings = filteredDwellings.length;
        const nbStatus = filteredStatus.length;
        const maxLines: number = Math.max(nbDwellings, nbResidences, nbStatus, nbAddress);

        const worksheetName = this.translate.instant('excel.activities.worksheet.names.filters');
        const worksheet: Worksheet = this.createWorksheet(
            workbook,
            worksheetName,
            'portrait',
            maxLines + excelUtil.headerFilterlines
        );

        // Define static data
        const title = this.translate.instant('excel.activities.headers.appliedFilters');
        const columns = [
            this.translate.instant('excel.activities.headers.properties'),
            this.translate.instant('excel.activities.headers.address'),
            this.translate.instant('excel.activities.headers.dwellings'),
            this.translate.instant('excel.activities.headers.status'),
        ];

        // Define worksheet's columns
        const columnWidth = 35;

        let maxLengthAddress = this.listUtil.getListElementMaxPositifLength(filteredAdresses, columnWidth);
        let maxLengthResidences = this.listUtil.getListElementMaxPositifLength(filteredResidences, columnWidth);
        let maxLengthDwellings = this.listUtil.getListElementMaxPositifLength(filteredDwellings, columnWidth);
        let maxLengthStatus = this.listUtil.getListElementMaxPositifLength(filteredStatus, columnWidth);

        worksheet.columns = [
            { key: 'A', width: maxLengthResidences },
            { key: 'B', width: maxLengthAddress },
            { key: 'C', width: maxLengthDwellings },
            { key: 'D', width: maxLengthStatus },
        ];

        worksheet.addRow([title]);
        worksheet.addRow([]);

        const columnsTab: Row = worksheet.addRow(columns);
        const baseLineNumber = 4;

        for (let i = 0; i < maxLines; i++) {
            let residence = null;
            let address = null;
            let dwelling = null;
            let status = null;

            if (i < nbResidences) {
                residence = filteredResidences[i];
            }

            if (i < nbAddress) {
                address = filteredAdresses[i];
            }

            if (i < nbDwellings) {
                dwelling = filteredDwellings[i];
            }
            if (i < nbStatus) {
                status = filteredStatus[i];
            }
            worksheet.addRow([residence, address, dwelling, status]);
            const lineNumber = baseLineNumber + i;
            [`A${lineNumber}`, `B${lineNumber}`, `C${lineNumber}`, `D${lineNumber}`].forEach((key) => {
                worksheet.getCell(key).style = {
                    font: excelUtil.headerValueFont,
                    fill: excelUtil.backgroundHeaderValueFill,
                };
            });
        }

        // Formating data...
        ['A1', 'B1', 'C1', 'D1'].forEach((key) => {
            worksheet.getCell(key).style = {
                fill: excelUtil.backgroundTitleFill,
                font: excelUtil.titleFont,
            };
        });

        ['A3', 'B3', 'C3', 'D3'].forEach((key) => {
            worksheet.getCell(key).font = excelUtil.headerTitleFont;
        });

        this.addHeaderAndFooter(worksheet);
    }

    private addHeaderAndFooter(worksheet: Worksheet): void {
        const headerFooterStyle = '&K' + excelUtil.primaryColor + '&"Calibri"&11';
        // moncompte.ista.fr in center header with ista primary color and fontfamily Calibri size 11
        worksheet.headerFooter.oddHeader = `&C${headerFooterStyle}moncompte.ista.fr`;
        // date in footer left, document name in center footer, page number in footer right.
        // ALL with ista primary color and fontfamily Calibri size 11
        const footerLeft = `&L${headerFooterStyle}&D`;
        const footerCenter = `&C${headerFooterStyle}&F`;
        const footerRight = `&R${headerFooterStyle}&P/&N`;
        worksheet.headerFooter.oddFooter = `${footerLeft}${footerCenter}${footerRight}`;
    }

    private buildExcelDwellingLine(value: NewDwellingActivities): (string | number)[] {
        const customerAction: string = 'Non';
        const activityArray = [
            value.status.nbItemsHelpUs,
            value.status.nbItemsToPlan,
            value.status.nbItemsToPlanNoAction,
            value.status.nbItemsPlanned,
            value.status.nbItemsInProgress,
            value.status.nbItemsNotEquippable,
            value.status.nbItemsFinished,
        ];
        return [
            value.propertyId,
            value.propertyName,
            value.dwellingCustomerReference,
            value.dwellingId,
            value.city,
            value.address,
            value.owner,
            value.tenant,
            activityArray.reduce((a, b) => a + b, 0),
            value.status.nbItemsToPlan + value.status.nbItemsToPlanNoAction,
            value.status.nbItemsPlanned,
            value.status.nbItemsFinished,
            customerAction,
        ];
    }

    private customerActionRequired(activities: Array<Activity>): boolean {
        return (
            activities.filter(
                (a) =>
                    (a.customerActionRequired === true || a.remainingInstallation === true) &&
                    a.status === ActivityStatus.TO_PLAN
            ).length > 0
        );
    }

    private numberActivitiesByStatus(activities: Array<Activity>, status: ActivityStatus): number {
        return activities.filter((a) => a.status === status).length;
    }
}
