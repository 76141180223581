import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class GlobalVariables {

    public urlForCurrentView: Array<string> = [];

    constructor(private datePipe: DatePipe) {
    }

    public activityLimitDate(): Date {
        return new Date(new Date().getFullYear() - 2, new Date().getMonth(), new Date().getDate());
    }

    // Used for i18n
    // TODO: format date according to current locale
    public activityLimitDateAsLabel(): string {
        const activityLimitDate = this.activityLimitDate();
        const formatted = this.datePipe.transform(activityLimitDate, 'dd/MM/yyyy');
        return formatted === null ? '' : formatted;
    }

    public worksiteDefaultDate(): Date {
        return new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate() - 1);
    }

    public worksiteLimitDate(): Date {
        const worksiteDefaultDate = this.worksiteDefaultDate();
        return new Date(worksiteDefaultDate.getFullYear() - 1, worksiteDefaultDate.getMonth(), worksiteDefaultDate.getDate());
    }
}
