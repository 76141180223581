<div class="showScrollWhenNeeded">
    <div class="menu-title">
        <h1>{{ 'dwelling.contentTitle' | translate }}</h1>
    </div>
    <div class="wrapper">
        <div class="d-flex flex-column">
            <div class="backToDwellingList">
                <div class="back">
                    <a (click)="backToDwellingList()">
                        <div class="dark-blue">
                            <span class="space-btw-items font-small">{{ 'dwelling.backtolist' | translate }}</span>
                        </div>
                    </a>
                </div>
                <div *ngIf="showPage" class="export">
                    <ista-export-data class="ml-auto round-button" id="exportGroup" (export)="exportDwelling($event)" [showPdf]="true">
                    </ista-export-data>
                </div>
            </div>
            <div id="dblock" class="detail">
                <div class="col-md-12">
                    <span class="bloc-title">{{ 'dwelling.sheet' | translate }}</span>
                    <div class="dwelling responsive-text">
                        <div class="line">
                            <div class="col-md-4 col-sm-3 col-xs-2 column parameter">
                                {{ 'dwelling.reference' | translate }}
                            </div>
                            <div class="col-md-8 col-sm-9 col-xs-10 value">
                                <span
                                    *ngIf="
                                        dwellingDetails.customerReference === null ||
                                        dwellingDetails.customerReference === undefined
                                    "
                                >
                                    <ista-spinner
                                        customSpinnerClass="spinner-only-small no-translation"
                                        style="position: relative; left: -13px"
                                        [showSpinner]="true"
                                        [data]="1"
                                        [spinnerDiameter]="15"
                                    ></ista-spinner>
                                </span>
                                <div *ngIf="dwellingDetails.customerReference == ''" style="margin-left: 15px">-</div>
                                <div *ngIf="dwellingDetails.customerReference != ''">
                                    {{ dwellingDetails.customerReference }}
                                </div>
                            </div>
                        </div>
                        <div class="line">
                            <div class="col-md-4 col-sm-3 col-xs-2 parameter">
                                {{ 'dwelling.owner' | translate }}
                            </div>
                            <div class="col-md-8 col-sm-9 col-xs-10 value">
                                <span *ngIf="dwellingDetails.owner === null || dwellingDetails.owner === undefined"
                                    ><ista-spinner
                                        customSpinnerClass="spinner-only-small no-translation"
                                        style="position: relative; left: -13px"
                                        [showSpinner]="true"
                                        [data]="1"
                                        [spinnerDiameter]="15"
                                    ></ista-spinner>
                                </span>
                                <div *ngIf="dwellingDetails.owner == ''" style="margin-left: 15px">-</div>
                                <div *ngIf="dwellingDetails.owner != ''">{{ dwellingDetails.owner }}</div>
                            </div>
                        </div>
                        <div class="line">
                            <div class="col-md-4 col-sm-3 col-xs-2 parameter">
                                {{ 'dwelling.tenant' | translate }}
                            </div>
                            <div class="col-md-8 col-sm-9 col-xs-10 value">
                                <span *ngIf="dwellingDetails.tenant === null || dwellingDetails.tenant === undefined"
                                    ><ista-spinner
                                        customSpinnerClass="spinner-only-small no-translation"
                                        style="position: relative; left: -13px"
                                        [showSpinner]="true"
                                        [data]="1"
                                        [spinnerDiameter]="15"
                                    ></ista-spinner>
                                </span>
                                <div *ngIf="dwellingDetails.tenant == ''" style="margin-left: 15px">-</div>
                                <div *ngIf="dwellingDetails.tenant != ''">{{ dwellingDetails.tenant }}</div>
                            </div>
                        </div>
                        <div class="line">
                            <div class="col-md-4 col-sm-3 col-xs-2 parameter">
                                {{ 'dwelling.residence' | translate }}
                            </div>
                            <div class="col-md-8 col-sm-9 col-xs-10 value">
                                <span
                                    *ngIf="dwellingDetails.property === null || dwellingDetails.property === undefined"
                                    ><ista-spinner
                                        customSpinnerClass="spinner-only-small no-translation"
                                        style="position: relative; left: -13px"
                                        [showSpinner]="true"
                                        [data]="1"
                                        [spinnerDiameter]="15"
                                    ></ista-spinner>
                                </span>
                                <div
                                    *ngIf="dwellingDetails.property != null && dwellingDetails.property.name == ''"
                                    style="margin-left: 15px"
                                >
                                    -
                                </div>
                                <div *ngIf="dwellingDetails.property != null && dwellingDetails.property.name != ''">
                                    {{ dwellingDetails.property.name }}
                                </div>
                            </div>
                        </div>
                        <div class="line">
                            <div class="col-md-4 col-sm-3 col-xs-2 parameter">
                                {{ 'dwelling.location' | translate }}
                            </div>
                            <div class="col-md-8 col-sm-9 col-xs-10 value">
                                <span
                                    *ngIf="dwellingDetails.location === null || dwellingDetails.location === undefined"
                                    ><ista-spinner
                                        customSpinnerClass="spinner-only-small no-translation"
                                        style="position: relative; left: -13px"
                                        [showSpinner]="true"
                                        [data]="1"
                                        [spinnerDiameter]="15"
                                    ></ista-spinner>
                                </span>
                                <div *ngIf="dwellingDetails.location == ''" style="margin-left: 15px">-</div>
                                <div *ngIf="dwellingDetails.location != ''">{{ dwellingDetails.location }}</div>
                            </div>
                        </div>
                        <div class="line">
                            <div class="col-md-4 col-sm-3 col-xs-2 parameter">
                                {{ 'dwelling.address' | translate }}
                            </div>
                            <div class="col-md-8 col-sm-9 col-xs-10 value">
                                <span *ngIf="dwellingDetails.address === null || dwellingDetails.address === undefined"
                                    ><ista-spinner
                                        customSpinnerClass="spinner-only-small no-translation"
                                        style="position: relative; left: -13px"
                                        [showSpinner]="true"
                                        [data]="1"
                                        [spinnerDiameter]="15"
                                    ></ista-spinner>
                                </span>
                                <div *ngIf="dwellingDetails.address == ''" style="margin-left: 15px">-</div>
                                <div *ngIf="dwellingDetails.address != ''">{{ dwellingDetails.address }}</div>
                            </div>
                        </div>
                        <div class="line">
                            <div class="col-md-4 col-sm-3 col-xs-2 parameter">
                                {{ 'dwelling.city' | translate }}
                            </div>
                            <div class="col-md-8 col-sm-9 col-xs-10 value">
                                <span *ngIf="dwellingDetails.city === null || dwellingDetails.city === undefined"
                                    ><ista-spinner
                                        customSpinnerClass="spinner-only-small no-translation"
                                        style="position: relative; left: -13px"
                                        [showSpinner]="true"
                                        [data]="1"
                                        [spinnerDiameter]="15"
                                    ></ista-spinner
                                ></span>
                                <div *ngIf="dwellingDetails.city == ''" style="margin-left: 15px">-</div>
                                <div *ngIf="dwellingDetails.city != ''">{{ dwellingDetails.city }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <span class="bloc-title">{{ 'dwelling.activityList' | translate }}</span>
                    <ista-spinner
                        [showSpinner]="showSpinner"
                        customClass="large"
                        [data]="dwellingDetailQuantity"
                        message="{{ 'dwelling.installation-not-found' | translate }}"
                        loading="{{ 'template.loading' | translate }}"
                    >
                    </ista-spinner>
                    <div *ngIf="!showSpinner">
                        <div *ngIf="customerActionRequired && helpPhoneNumber != '-- -- -- -- --'">
                            <div class="help-text help-normal">
                                {{ 'dwelling.helpMessageBegin' | translate }} {{ helpPhoneNumber }}
                                {{ 'dwelling.helpMessageEnd' | translate }}
                            </div>
                            <div class="help-text help-italic">
                                {{ 'dwelling.helpMessageAsterix' | translate }}
                            </div>
                        </div>
                        <div class="bloc-show-hide" (click)="showHideAllDetails()">
                            <u>{{
                                showHideDetails
                                    ? ('dwelling.expandCollapseAll.collapseAll' | translate)
                                    : ('dwelling.expandCollapseAll.expandAll' | translate)
                            }}</u>
                        </div>
                        <div class="activities-rapport">
                            <div *ngFor="let activity of activities" #activityRef>
                                <app-activity-items
                                    class="responsive-text"
                                    *ngIf="activities.length > 0"
                                    [activity]="activity"
                                >
                                </app-activity-items>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
