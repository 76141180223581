import { Inject, Injectable, isDevMode } from '@angular/core';
import { formatDate } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { ProductsConstants } from '@shared/constants/products.const';

import { MeterType } from '../../../../shared/enum/meter-type.enum';
import { WorksiteStatus } from '../enum/woksite-status.model';
import { WorksiteDwellingStatus } from '../enum/worksite-period-status.enum';
import { NewInstallationLine } from '../model/new-installation-line.model';
import { DwellingWorksiteOverview, MeterWorksiteOverview } from '../model/worksite-overview.model';
import { WorksiteSynthesisLine } from '../model/worksite-synthesis-line.model';

@Injectable({
    providedIn: 'root'
})
export class WorksiteSynthesisService {
    defaultDatePattern = 'DD/MM/YYYY';

    typeArray = [{
        fluids: MeterType.RFC,
        text: 'installations.dashboard.typeU',
        excel: 'installations.dashboard.typeU',
        labelFluid: ''
    },
    {
        fluids: MeterType.EC,
        text: 'installations.dashboard.typeC',
        excel: 'installations.dashboard.typeCFExcel',
        labelFluid: 'C'
    },
    {
        fluids: MeterType.EF,
        text: 'installations.dashboard.typeF',
        excel: 'installations.dashboard.typeCFExcel',
        labelFluid: 'F'
    },
    {
        fluids: MeterType.CET,
        text: 'installations.dashboard.typeCET',
        excel: 'installations.dashboard.typeCET',
        labelFluid: ''
    },
    {
        fluids: MeterType.SONDE,
        text: 'installations.dashboard.typeSONDE',
        excel: 'installations.dashboard.typeSONDE',
        labelFluid: ''
    },
    {
        fluids: MeterType.DAAF,
        text: 'installations.dashboard.typeDAAF',
        excel: 'installations.dashboard.typeDAAF',
        labelFluid: ''
    }];

    constructor(@Inject(TranslateService) private translate: TranslateService) {
    }

    getSynthesisLineFronDwelling(dwellings: ReadonlyArray<NewInstallationLine>): Array<WorksiteSynthesisLine> {
        const meterData = this.convertDwellingsDataToMetersData(dwellings);
        return this.getSynsthesisLine(dwellings as any[], meterData);
    }

    convertDwellingsDataToMetersData(dwellingsData: ReadonlyArray<NewInstallationLine>): ReadonlyArray<MeterWorksiteOverview> {
        const metersData: Array<MeterWorksiteOverview> = [];
        if (dwellingsData && dwellingsData.length > 0) {
            dwellingsData.forEach(dwellingData => {
                let meterViewData: MeterWorksiteOverview = {
                    propertyId: dwellingData.propertyId,
                    propertyName: dwellingData.propertyName,
                    address: dwellingData.address,
                    zip: dwellingData.zip,
                    city: dwellingData.city,
                    view: dwellingData.view,
                    dwellingId: dwellingData.dwellingId,
                    dwellingLocation: dwellingData.dwellingLocation,
                    dwellingCustomerReference: dwellingData.dwellingCustomerReference,
                    owner: dwellingData.owner,
                    tenant: dwellingData.tenant,
                    worksiteId: dwellingData.worksiteId,
                    worksiteType: dwellingData.worksiteType,
                    productCode: dwellingData.productCode,
                    fluidCode: dwellingData.fluidCode,
                    visitsStartDate: dwellingData.visitsStartDate,
                    visitsEndDate: dwellingData.visitsEndDate,
                    visitsCount: dwellingData.visitsCount,
                    lastVisitDate: dwellingData.lastVisitDate,
                    lastVisitResult: dwellingData.lastVisitResult,
                    nextVisitDate: dwellingData.nextVisitDate,
                    worksiteStatus: dwellingData.worksiteStatus,
                    meterId: '',
                    meterNumber: 0,
                    meterLocation: '',
                    periodNumber: '',
                    newSerialNumber: '',
                    serialNumber: '',
                    index: 0,
                    installable: false,
                    installed: false,
                    notInstalledReason: '',
                    meterStatus: ''
                };
                if (dwellingData.installations && dwellingData.installations.length > 0) {
                    dwellingData.installations.forEach(meterInstallation => {
                        let meterData: MeterWorksiteOverview = { ...meterViewData };
                        meterData.meterId = dwellingData.worksiteId + '_' + dwellingData.dwellingId + meterInstallation.meterId;
                        meterData.meterNumber = Number(meterInstallation.meterId);
                        meterData.meterLocation = meterInstallation.location;
                        meterData.periodNumber = meterInstallation.periodNumber.toString();
                        meterData.newSerialNumber = meterInstallation.serialNumber;
                        meterData.serialNumber = meterInstallation.oldSerialNumber;
                        meterData.index = meterInstallation.index;
                        meterData.installable = meterInstallation.installable;
                        meterData.installed = meterInstallation.installed;
                        meterData.notInstalledReason = meterInstallation.notInstalledLabel;
                        meterData.meterStatus = meterInstallation.meterStatus;
                        metersData.push(meterData);
                    });
                }
            });
        }
        return metersData;
    }

    getSynsthesisLine(dwellingsData: ReadonlyArray<DwellingWorksiteOverview>, metersData: ReadonlyArray<MeterWorksiteOverview>): Array<WorksiteSynthesisLine> {
        const rows: Array<WorksiteSynthesisLine> = [];
        const allEqual = (arr: Array<string>) => arr.every(val => val === arr[0]);
        const fluidsCode = dwellingsData.map(d => d.fluidCode);
        let mapWorksites = new Map<string, Array<MeterWorksiteOverview>>();
        metersData.forEach(m => {
            if (!mapWorksites.has(m.worksiteId)) {
                mapWorksites.set(m.worksiteId, [m])
            } else {
                mapWorksites.get(m.worksiteId)?.push(m)
            }

        })
        mapWorksites.forEach((worksiteArray) => {
            let worksitesByPeriod = new Map<string, Array<MeterWorksiteOverview>>();
            worksiteArray.forEach(m => {
                if (!worksitesByPeriod.has(m.periodNumber)) {
                    worksitesByPeriod.set(m.periodNumber, [m])
                } else {
                    worksitesByPeriod.get(m.periodNumber)?.push(m)
                }
            })
            worksitesByPeriod.forEach(worksitesByPeriodArray => {
                let fluidArray: ReadonlyArray<MeterWorksiteOverview> = [...new Map(worksitesByPeriodArray.map(w => [w.fluidCode, w])).values()];
                fluidArray = allEqual(fluidsCode) ? fluidArray.filter(f => f.fluidCode === fluidsCode[0]) : fluidArray
                fluidArray.forEach(currentWorksite => {
                    const propertyId = currentWorksite.propertyId;
                    const propertyName = currentWorksite.propertyName;
                    const fullAddress = currentWorksite.address + ' ' + currentWorksite.zip + ' ' + currentWorksite.city;
                    const status = currentWorksite.worksiteStatus;
                    const startDate = worksitesByPeriodArray[0].visitsStartDate ?
                        worksitesByPeriodArray.map(m => m.visitsStartDate).sort()[0] : worksitesByPeriodArray.map(m => m.nextVisitDate).sort()[0]
                    const periodStart = startDate ? this.transformDate(startDate) : '';
                    const endDate = worksitesByPeriodArray.map(m => m.visitsEndDate).sort()[worksitesByPeriodArray.length - 1]
                    const periodEnd = endDate ? this.transformDate(endDate) : '';
                    const period = periodStart + ' - ' + periodEnd;

                    const meterType = ProductsConstants.getMeterType(currentWorksite.productCode, currentWorksite.fluidCode);
                    const type = this.typeArray.find(t => t.fluids === meterType) ?
                        this.getTranslate(this.typeArray.filter(t => t.fluids === meterType)[0].text) :
                        '';
                    const typeExcel = this.typeArray.filter(t => t.fluids === meterType)[0] ?
                        this.getTranslate(this.typeArray.filter(t => t.fluids === meterType)[0].excel) :
                        '';
                    const fluidExcel = this.typeArray.filter(t => t.fluids === meterType)[0] ?
                        this.typeArray.filter(t => t.fluids === meterType)[0].labelFluid :
                        '';

                    const worksitesPlanned = worksitesByPeriodArray.filter(w => w.fluidCode === currentWorksite.fluidCode);
                    const worksitesPosed = worksitesPlanned.filter(w => w.meterStatus === WorksiteDwellingStatus.FINISHED
                        || w.meterStatus === WorksiteDwellingStatus.FINISHED_REMAINING_INSTALLATION
                        || (w.meterStatus === WorksiteDwellingStatus.NOT_EQUIPABLE && w.installable && w.installed && w.newSerialNumber));
                    const worksitesNonInstallable = worksitesPlanned.filter(w => w.meterStatus === WorksiteDwellingStatus.NOT_EQUIPABLE && !w.installed);
                    const worksitesNotInstalledYet = worksitesPlanned.filter(w => w.meterStatus === WorksiteDwellingStatus.NOT_INSTALLED_YET);
                    const data: WorksiteSynthesisLine = {
                        propertyName: propertyName || '',
                        propertyId: propertyId || '',
                        propertyAddress: fullAddress || '',
                        status: this.getTranslate('datatable.worksite.worksiteStatus.'
                            + (status === WorksiteStatus.IN_PROGRESS ? 'IN_PROGRESS_TITLE' : status)),
                        worksiteId: currentWorksite.worksiteId || '',
                        statusEnum: status as WorksiteStatus,
                        worksiteMeterType: type,
                        worksitePeriod: status.toString() === WorksiteStatus.TO_PLAN ? '' : period,
                        worksitePeriodExcel: period,
                        worksitePlanned: worksitesPlanned.length,
                        worksiteDoneRate: worksitesPlanned.length > 0 ? Math.round((worksitesPosed.length / worksitesPlanned.length) * 100) + '%' : '',
                        worksiteDone: worksitesPosed.length,
                        worksiteInstalledRate: worksitesPlanned.length > 0 ? Math.round((worksitesPosed.length / worksitesPlanned.length) * 100) : 0,
                        worksiteInstalled: worksitesPosed.length,
                        worksiteNonInstallable: worksitesNonInstallable.length,
                        worksiteNonInstallableRate: worksitesPlanned.length > 0 ? Math.round((worksitesNonInstallable.length / worksitesPlanned.length) * 100) : 0,
                        worksiteNotInstalledYet: worksitesNotInstalledYet.length,
                        worksiteNotInstalledYetRate: worksitesPlanned.length > 0 ? Math.round((worksitesNotInstalledYet.length / worksitesPlanned.length) * 100) : 0,
                        worksiteFluid: fluidExcel,
                        worksitePeriodStart: periodStart,
                        worksitePeriodEnd: periodEnd,
                        worksiteMeterTypeExcel: typeExcel,
                        worksiteMeterTypeEnum: meterType,
                        periodeModel: {
                            startDate: startDate && startDate.trim() !== '' ? new Date(startDate).toISOString() : '',
                            endDate: endDate && endDate.trim() !== '' ? new Date(endDate).toISOString() : ''
                        },
                        worksiteType: currentWorksite.worksiteType,
                    };
                    rows.push(data);
                })
            })
        })
        if (isDevMode()) {
            console.log('rows :>> ', rows);
        }
        return rows;
    }

    public getTranslate(translateKey: string): string {
        let response = '';
        this.translate.get(translateKey).subscribe((translateValue: string) => {
            response = translateValue;
        });
        return response;
    }

    public transformDate(dateToTranform: string): string {
        return formatDate(dateToTranform, this.defaultDatePattern);
    }

}
