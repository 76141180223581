export enum WorksiteDwellingStatus {
    TO_PLAN = 'TO_PLAN',
    PLANNED = 'PLANNED',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    NOT_INSTALLED_YET = 'NOT_INSTALLED_YET',
    FINISHED_REMAINING_INSTALLATION = 'FINISHED_REMAINING_INSTALLATION',
    NOT_EQUIPABLE = 'NOT_EQUIPABLE',
    HELP_US = 'HELP_US'
}
