import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService, TokenManagerService } from '@ista/shared-ui';
import { Observable, of } from 'rxjs';

import { RedirectLandingPage } from '../enum/redirect-landing-page.enum';
import { isAuthorizedRedirectingPath } from '../util/redirect.util';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard  {

    constructor(
        private authenticationService: TokenManagerService,
        private authorizationService: AuthorizationService,
        private router: Router) {
    }

    canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        if (this.authenticationService.isValidAuthentication()) {

            if (this.authorizationService.isAuthorizedToAccessAtLeastOneModule()) {

                let landingPage = RedirectLandingPage.NULL.valueOf();

                if (this.authorizationService.isAuthorizedToAccessCustomerModule()) {

                    landingPage = router.queryParams.redirectUrl && isAuthorizedRedirectingPath(router.queryParams.redirectUrl) ?
                        router.queryParams.redirectUrl :
                        RedirectLandingPage.EXTERN_LANDING_PAGE.valueOf();
                }

                if (this.authorizationService.isAuthorizedToAccessManagementModule()) {
                    landingPage = RedirectLandingPage.INTERN_LANDING_PAGE.valueOf();
                }

                if (landingPage !== RedirectLandingPage.NULL.valueOf()) {
                    this.router.navigate([landingPage]);
                }
            }
        }
        return of(true);
    }
}
