import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, isDevMode} from '@angular/core';
import {BaseService, CONTEXT_API} from '@ista/shared-ui';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Property} from 'src/app/core/model/property.model';

@Injectable({
    providedIn: 'root'
})
export class ActivityPropertyService implements BaseService<Property> {

    baseUrl: string;
    contextAPI: string;

    constructor(private httpClient: HttpClient, @Inject(CONTEXT_API) contextApi: string) {
        this.contextAPI = `${contextApi}`;
        this.baseUrl = `${contextApi}/activities/properties`;
    }

    fetch(): Observable<ReadonlyArray<Property>> {
        if (isDevMode()) {
            console.log('Using url to fetch properties : ' + this.baseUrl);
        }
        return this.httpClient.get<ReadonlyArray<Property>>(this.baseUrl);
    }

    fetchById(id: string): Observable<Property> {
        const url = `${this.baseUrl}/${id}`;

        if (isDevMode()) {
            console.log('Using url to fetch property : ' + url);
        }

        return this.httpClient.get<ReadonlyArray<Property>>(url).pipe(map(properties => properties[0]));
    }


}
