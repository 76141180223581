import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerService } from '@core/service/customer.service';
import { SelfcareIndexedDbService } from '@core/service/selfcare-indexed-db.service';
import { Customer, Reference } from '@ista/shared-ui';
import { FilterName } from '@shared/enum/filter-name.enum';
import { firstValueFrom } from 'rxjs';
import { Uex } from 'src/app/core/model/uex.model';

import { ReferenceFilterSessionStorageService } from '../service/reference-filter-session-storage.service';
import { UexFilterSessionStorageService } from '../service/uex-filter-session-storage.service';

@Component({
    selector: 'app-customer-ue-selection',
    templateUrl: './customer-ue-selection.component.html',
    styleUrls: ['./customer-ue-selection.component.css'],
})
export class CustomerUeSelectionComponent implements OnInit {
    customerList: Array<Customer> = [];
    uexList: Array<Uex> = [];
    propertyFilterData: Array<Reference> = [];
    propertyFilterDataFinal: Array<Reference> = [];
    customerCode: string;
    customerSearchForm: FormGroup;
    redirectionUrl: string;

    customerCodeStr = 'customerCode';
    customersSelectedStr = 'customersSelected';
    internalClientProperties = 'internal_client_properties';
    sidebarShowItems = 'sidebar_show_items';

    constructor(
        private customerService: CustomerService,
        private router: Router,
        public referenceFilterSessionStorageService: ReferenceFilterSessionStorageService,
        public uexFilterSessionStorageService: UexFilterSessionStorageService,
        private selfcareIndexedDbService: SelfcareIndexedDbService
    ) {}

    ngOnInit(): void {
        this.customerSearchForm = new FormGroup({
            customerCode: new FormControl(this.customerCode, [
                Validators.required,
                Validators.minLength(7),
                Validators.maxLength(7),
                Validators.pattern('^[0-9]*$'),
            ]),
        });
        this.customerSearchForm.reset();
        this.customerSearchForm.controls[this.customerCodeStr].setErrors(null);

        // Retrieve the initial customer selection if there is any
        const selectedCustomers = sessionStorage.getItem(this.customersSelectedStr) as string;
        if (selectedCustomers !== null) {
            this.customerList = JSON.parse(selectedCustomers) as Array<Customer>;
            this.fetchUexByCustomer(this.customerList.map((customer) => customer.number))
                .then((uex) => {
                    this.uexList = uex.concat();
                    this.mapUexToProperty();
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            this.flushAllLists();
        }
    }

    fetchCustomerById(customerId: string): Promise<Customer> {
        return firstValueFrom(this.customerService.fetchById(customerId));
    }

    fetchUexByCustomer(customersIds: ReadonlyArray<string>): Promise<ReadonlyArray<Uex>> {
        return firstValueFrom(this.customerService.fetchUexByCustomerIds(customersIds));
    }

    flushAllLists(): void {
        this.customerList = [];
        this.flushUexLists();
    }

    flushUexLists(): void {
        this.propertyFilterData = [];
        this.uexList = [];
        this.propertyFilterDataFinal = [];
    }

    mapUexToProperty(): void {
        this.uexList.forEach((uex) =>
            this.propertyFilterData.push({
                name: uex.code + ' ' + uex.label,
                ids: Array.of(uex.code),
            })
        );
        this.propertyFilterDataFinal = this.propertyFilterData;
    }

    onSubmitCustomerForm(): void {
        this.customerCode = this.customerSearchForm.get(this.customerCodeStr)?.value;

        // Check if the submitted customer has already been fetched
        const customerAlreadyPresent = this.customerList.find((customer) => {
            return customer.number === this.customerCode;
        });

        if (customerAlreadyPresent == null) {
            // Fetch the submitted customer
            this.fetchCustomerById(this.customerCode)
                .then((customer) => {
                    // Check if the submitted customer has been found
                    this.customerList = this.customerList.concat(customer);
                    this.flushUexLists();
                    // Fetch the uex regarding the customer
                    this.fetchUexByCustomer(this.customerList.map((cs) => cs.number))
                        .then((uex) => {
                            this.uexList = uex.concat();
                            this.mapUexToProperty();
                        })
                        .catch((error) => {
                            console.error(error);
                        });

                    this.customerSearchForm.reset();
                })
                .catch((error) => {
                    console.error(error);
                    this.customerSearchForm.controls[this.customerCodeStr].setErrors({ notFound: true });
                });
        }
    }

    onSubmitValidateForm(): void {
        this.referenceFilterSessionStorageService.removeFiltersProperties();
        this.storeItemsInSessionStorage();
        this.referenceFilterSessionStorageService.clearActivityWorksiteFilters();
        this.selfcareIndexedDbService.remove();
        const filteredUex = this.uexFilterSessionStorageService.getFiltersPropertyIds(FilterName.PROPERTIES);

        if (filteredUex.length > 0) {
            const filteredReferences = this.propertyFilterData.filter((l) =>
                filteredUex && filteredUex.length > 0 ? filteredUex.includes(l.ids[0]) : true
            );
            this.referenceFilterSessionStorageService.setFilterProperties(filteredReferences);
        } else {
            this.referenceFilterSessionStorageService.setFilterProperties([]);
        }
        this.router.navigate([this.redirectionUrl]);
    }

    removeCustomer(customerToRemove: string): void {
        this.customerList.forEach((customer, index) => {
            if (customer.number === customerToRemove) {
                this.customerList.splice(index, 1);
            }
        });

        if (this.customerList.length > 0) {
            this.flushUexLists();
            this.fetchUexByCustomer(this.customerList.map((customer) => customer.number)).then((uexs) => {
                this.uexList = uexs.concat();
                this.mapUexToProperty();
            });
        } else {
            this.flushAllLists();
        }
    }

    setRedirectionUrl(url: string): void {
        this.redirectionUrl = 'interne/' + url + '/tableau-de-bord';
    }

    storeItemsInSessionStorage(): void {
        // Store a boolean indicating that the sidebar items have to be enabled
        sessionStorage.setItem(this.sidebarShowItems, 'true');

        // Store the full UEX scope of the selected client(s)
        sessionStorage.setItem(
            this.internalClientProperties,
            this.propertyFilterDataFinal.map((property) => property.ids[0]).toString()
        );

        // Store the selected customer(s) in order to be able to display it/them again
        sessionStorage.setItem(this.customersSelectedStr, JSON.stringify(this.customerList));
    }
}
