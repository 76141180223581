import { Dwelling } from '@core/model/dwelling.model';
import { Property } from '@core/model/property.model';

export class DwellingDetails extends Dwelling {
    property: Property;
    numberOfMeters: number;
    constructor() {
        super();
    }
}
