import {Reference} from '@ista/shared-ui';

import {GroupFilters} from '../components/group-filters/group-filters.component';
import {FilterName} from '../enum/filter-name.enum';

export function activityFilters(
    properties: ReadonlyArray<Reference>,
    addresses: ReadonlyArray<Reference>,
    dwellings: ReadonlyArray<Reference>,
    activitiesType: ReadonlyArray<Reference>,
    statusActivity: ReadonlyArray<Reference>): ReadonlyArray<GroupFilters> {
    return [
        {
            type: 'multiselect',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.PROPERTIES,
            multiple: '',
            details:
                {
                    dataName: FilterName.PROPERTIES,
                    filterName: 'filters.' + FilterName.PROPERTIES,
                    data: properties as Array<Reference>
                }
        },
        {
            type: 'multiselect',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.ADDRESSES,
            details:
                {
                    dataName: FilterName.ADDRESSES,
                    filterName: 'filters.' + FilterName.ADDRESSES,
                    data: addresses as Array<Reference>
                }
        },
        {
            type: 'multiselect',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.DWELLINGS,
            details:
                {
                    dataName: FilterName.DWELLINGS,
                    filterName: 'filters.' + FilterName.DWELLINGS,
                    data: dwellings as Array<Reference>
                },
        },
        {
            type: 'checkbox',
            multiple: 'true',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.ACTIVITY_TYPES,
            details:
                {
                    dataName: FilterName.ACTIVITY_TYPES,
                    filterName: 'filters.' + FilterName.ACTIVITY_TYPES,
                    data: activitiesType as Array<Reference>
                }
        },
        {
            type: 'checkbox',
            multiple: 'true',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.STATUSES,
            details:
                {
                    dataName: FilterName.STATUSES,
                    filterName: 'filters.activity.' + FilterName.STATUSES,
                    data: statusActivity as Array<Reference>
                }
        },
        {
            type: 'date',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.DATES,
            details:
                {
                    dataName: FilterName.DATES,
                    filterName: 'filters.activity.' + FilterName.DATES,
                    data: []
                }
        }
    ];
}

export function activityDashFilters(
    properties: ReadonlyArray<Reference>,
    addresses: ReadonlyArray<Reference>,
    activitiesType: ReadonlyArray<Reference>,
    statusActivity: ReadonlyArray<Reference>): ReadonlyArray<GroupFilters> {
    return [
        {
            type: 'multiselect',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.PROPERTIES,
            details:
                {
                    dataName: FilterName.PROPERTIES,
                    filterName: 'filters.' + FilterName.PROPERTIES,
                    data: properties as Array<Reference>
                }
        },
        {
            type: 'multiselect',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.ADDRESSES,
            details:
                {
                    dataName: FilterName.ADDRESSES,
                    filterName: 'filters.' + FilterName.ADDRESSES,
                    data: addresses as Array<Reference>
                }
        },

        {
            type: 'checkbox',
            multiple: 'true',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.ACTIVITY_TYPES,
            details:
                {
                    dataName: FilterName.ACTIVITY_TYPES,
                    filterName: 'filters.' + FilterName.ACTIVITY_TYPES,
                    data: activitiesType as Array<Reference>
                }
        },
        {
            type: 'checkbox',
            multiple: 'true',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.STATUSES,
            details:
                {
                    dataName: FilterName.STATUSES,
                    filterName: 'filters.activity.' + FilterName.STATUSES,
                    data: statusActivity as Array<Reference>
                }
        },
        {
            type: 'date',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.DATES,
            details:
                {
                    dataName: FilterName.DATES,
                    filterName: 'filters.activity.' + FilterName.DATES,
                    data: []
                }
        }
    ];
}

export function activityDetailsFilters(
    properties: ReadonlyArray<Reference>,
    addresses: ReadonlyArray<Reference>,
    dwellings: ReadonlyArray<Reference>,
    products: ReadonlyArray<Reference>,
    serialNumber: ReadonlyArray<Reference>,
    activityType: ReadonlyArray<Reference>,
    reason: ReadonlyArray<Reference>,
    subject: ReadonlyArray<Reference>,
    status: ReadonlyArray<Reference>,
    visitsCount: ReadonlyArray<Reference>): ReadonlyArray<GroupFilters> {
    return [
        {
            type: 'multiselect',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.PROPERTIES,
            details:
                {
                    dataName: FilterName.PROPERTIES,
                    filterName: 'filters.' + FilterName.PROPERTIES,
                    data: properties as Array<Reference>
                }
        },
        {
            type: 'multiselect',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.ADDRESSES,
            details:
                {
                    dataName: FilterName.ADDRESSES,
                    filterName: 'filters.' + FilterName.ADDRESSES,
                    data: addresses as Array<Reference>
                }
        },
        {
            type: 'multiselect',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.DWELLINGS,
            details:
                {
                    dataName: FilterName.DWELLINGS,
                    filterName: 'filters.' + FilterName.DWELLINGS,
                    data: dwellings as Array<Reference>
                }
        },
        {
            type: 'checkbox',
            multiple: 'true',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.PRODUCTS,
            details:
                {
                    dataName: FilterName.PRODUCTS,
                    filterName: 'filters.worksite.' + FilterName.PRODUCTS,
                    data: products as Array<Reference>
                }
        },
        {
            type: 'multiselect',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.SERIAL_NUMBERS,
            details:
                {
                    dataName: FilterName.SERIAL_NUMBERS,
                    filterName: 'filters.' + FilterName.SERIAL_NUMBERS,
                    data: serialNumber as Array<Reference>
                }
        },
        {
            type: 'checkbox',
            multiple: 'true',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.ACTIVITY_TYPES,
            details:
                {
                    dataName: FilterName.ACTIVITY_TYPES,
                    filterName: 'filters.' + FilterName.ACTIVITY_TYPES,
                    data: activityType as Array<Reference>
                }
        },
        {
            type: 'checkbox',
            multiple: 'true',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.REASONS,
            details:
                {
                    dataName: FilterName.REASONS,
                    filterName: 'filters.' + FilterName.REASONS,
                    data: reason as Array<Reference>
                }
        },
        {
            type: 'checkbox',
            multiple: 'true',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.SUBJECTS,
            details:
                {
                    dataName: FilterName.SUBJECTS,
                    filterName: 'filters.' + FilterName.SUBJECTS,
                    data: subject as Array<Reference>
                }
        },
        {
            type: 'checkbox',
            multiple: 'true',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.STATUSES,
            details:
                {
                    dataName: FilterName.STATUSES,
                    filterName: 'filters.activity.' + FilterName.STATUSES,
                    data: status as Array<Reference>
                }
        },
        {
            type: 'date',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.DATES,
            details:
                {
                    dataName: FilterName.DATES,
                    filterName: 'filters.activity.' + FilterName.DATES,
                    data: []
                }
        },
        {
            type: 'checkbox',
            multiple: 'true',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.VISITS_COUNT,
            details:
                {
                    dataName: FilterName.VISITS_COUNT,
                    filterName: 'filters.' + FilterName.VISITS_COUNT,
                    data: visitsCount as Array<Reference>
                }
        }
    ];
}


export function worksiteFilters(
    properties: ReadonlyArray<Reference>,
    addresses: ReadonlyArray<Reference>,
    dwellings: ReadonlyArray<Reference>,
    products: ReadonlyArray<Reference>,
    status: ReadonlyArray<Reference>,
    worksiteStatus: ReadonlyArray<Reference>,
    columns: ReadonlyArray<Reference>): ReadonlyArray<GroupFilters> {
    return [
        {
            type: 'multiselect',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.PROPERTIES,
            details:
                {
                    dataName: FilterName.PROPERTIES,
                    filterName: 'filters.' + FilterName.PROPERTIES,
                    data: properties as Array<Reference>
                }
        },
        {
            type: 'multiselect',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.ADDRESSES,
            details:
                {
                    dataName: FilterName.ADDRESSES,
                    filterName: 'filters.' + FilterName.ADDRESSES,
                    data: addresses as Array<Reference>
                }
        },
        {
            type: 'multiselect',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.DWELLINGS,
            details:
                {
                    dataName: FilterName.DWELLINGS,
                    filterName: 'filters.' + FilterName.DWELLINGS,
                    data: dwellings as Array<Reference>
                },
        },
        {
            type: 'checkbox',
            multiple: 'true',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.PRODUCTS,
            details:
                {
                    dataName: FilterName.PRODUCTS,
                    filterName: 'filters.worksite.' + FilterName.PRODUCTS,
                    data: products as Array<Reference>
                }
        },
        {
            type: 'checkbox',
            multiple: 'true',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.WORKSITE_STATUSES,
            details:
                {
                    dataName: FilterName.WORKSITE_STATUSES,
                    filterName: 'filters.worksite.' + FilterName.WORKSITE_STATUSES,
                    data: worksiteStatus as Array<Reference>
                }
        },
        {
            type: 'checkbox',
            multiple: 'true',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.STATUSES,
            details:
                {
                    dataName: FilterName.STATUSES,
                    filterName: 'filters.worksite.' + FilterName.STATUSES,
                    data: status as Array<Reference>
                }
        },
        /* {
            type: 'period',
            multiple: '',
             showInformation: true,
            textInformation: 'installations.dashboard.filter.tooltip.period',
            details:
            {
                dataName: FilterName.PERIOD,
                filterName: 'filters.worksite.' + FilterName.PERIOD,
                data: []
            }
        } */
        {
            type: 'date',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.START_DATE,
            details:
                {
                    dataName: FilterName.START_DATE,
                    filterName: 'filters.activity.' + FilterName.START_DATE,
                    data: [],
                }
        },
        /* {
            type: 'checkbox',
            multiple: 'true',
            showInformation: true,
            textInformation: 'installations.dashboard.filter.tooltip.column',
            details:
            {
                dataName: FilterName.COLUMNS,
                filterName: 'filters.worksite.' + FilterName.COLUMNS,
                data: columns as Array<Reference>
            }
        } */
    ];
}

export function worksiteDashFilters(
    properties: ReadonlyArray<Reference>,
    addresses: ReadonlyArray<Reference>,
    products: ReadonlyArray<Reference>,
    worksiteStatus: ReadonlyArray<Reference>): ReadonlyArray<GroupFilters> {
    return [
        {
            type: 'multiselect',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.PROPERTIES,
            details:
                {
                    dataName: FilterName.PROPERTIES,
                    filterName: 'filters.' + FilterName.PROPERTIES,
                    data: properties as Array<Reference>
                }
        },
        {
            type: 'multiselect',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.ADDRESSES,
            details:
                {
                    dataName: FilterName.ADDRESSES,
                    filterName: 'filters.' + FilterName.ADDRESSES,
                    data: addresses as Array<Reference>
                }
        },
        {
            type: 'checkbox',
            multiple: 'true',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.PRODUCTS,
            details:
                {
                    dataName: FilterName.PRODUCTS,
                    filterName: 'filters.worksite.' + FilterName.PRODUCTS,
                    data: products as Array<Reference>
                }
        },
        {
            type: 'checkbox',
            multiple: 'true',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.WORKSITE_STATUSES,
            details:
                {
                    dataName: FilterName.WORKSITE_STATUSES,
                    filterName: 'filters.worksite.' + FilterName.WORKSITE_STATUSES,
                    data: worksiteStatus as Array<Reference>
                }
        },
        /* {
            type: 'period',
            multiple: '',
             showInformation: true,
            textInformation: 'installations.dashboard.filter.tooltip.period',
            details:
            {
                dataName: FilterName.PERIOD,
                filterName: 'filters.worksite.' + FilterName.PERIOD,
                data: []
            }
        } */
        {
            type: 'date',
            multiple: '',
            showInformation: true,
            textInformation: 'filters.tooltip.' + FilterName.START_DATE,
            details:
                {
                    dataName: FilterName.START_DATE,
                    filterName: 'filters.activity.' + FilterName.START_DATE,
                    data: [],
                }
        },
    ];
}

export function uexFilters(
    properties: ReadonlyArray<Reference>): ReadonlyArray<GroupFilters> {
    return [
        {
            type: 'multiselect',
            showInformation: false,
            textInformation: '',
            multiple: '',
            details:
                {
                    dataName: FilterName.PROPERTIES,
                    filterName: 'intern.selection.uex.filter',
                    data: properties as Array<Reference>
                }
        }
    ];
}
