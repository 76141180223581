import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
// Filtre DATE needs fr local date
import { Environment, IstaSpinnerModule, SharedUiModule } from '@ista/shared-ui';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@siemens/ngx-datatable';
// Filtre DATE needs fr local date
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {NgxResizeObserverModule} from 'ngx-resize-observer';
import {environment} from 'src/environments/environment';

import { AuthComponent } from './components/auth/auth.component';
import { GroupFiltersComponent } from './components/group-filters/group-filters.component';
import { IstaCardComponent } from './components/ista-card/ista-card.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';

export interface SharedUiConfig {
    tokenKey: string;
}

@NgModule({
    declarations: [IstaCardComponent, LogoutComponent, LoginComponent, GroupFiltersComponent, AuthComponent],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        RouterModule,
        NgxDatatableModule,
        TooltipModule.forRoot(),
        BsDatepickerModule.forRoot(),
        PopoverModule.forRoot(),
        ModalModule.forRoot(),
        SharedUiModule.forRoot({
            environment: new Environment(
                environment.production,
                environment.CONTEXT_API,
                environment.TOKEN_KEY,
                environment.REFRESH_TOKEN_KEY,
                environment.ISP_BASE_URL,
                environment.LOGOUT_REDIRECT_URL,
                environment.APPLICATION_URL,
                environment.APPLICATION_HREF_URL,
                environment.INTERNAL_REDIRECT_URL,
                environment.INTERNAL_LOGIN_REDIRECT_URL,
                environment.DEMO_CUSTOMER_NUMBERS,
                environment.DEMO_BASE_HREF
            ),
        }),
        NgxResizeObserverModule,
        MatIconModule,
        IstaSpinnerModule,
    ],
    exports: [
        CommonModule,
        IstaCardComponent,
        LogoutComponent,
        LoginComponent,
        AuthComponent,
        FormsModule,
        NgxDatatableModule,
        TooltipModule,
        GroupFiltersComponent,
        PopoverModule,
        SharedUiModule,
        NgxResizeObserverModule,
    ],
    providers: [DatePipe, {provide: 'RESET_BASE_URL', useValue: environment.ISP_BASE_URL}],
})
export class SharedModule {
}
