import {BaseService, CONTEXT_API} from '@ista/shared-ui';
import {Observable} from 'rxjs';
import {Property} from '../model/property.model';
import {HttpClient} from "@angular/common/http";
import {Inject, Injectable, isDevMode} from "@angular/core";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class PropertyService implements BaseService<Property> {

    baseUrl: string;
    contextAPI: string;

    constructor(private httpClient: HttpClient, @Inject(CONTEXT_API) contextApi: string) {
        this.contextAPI = `${contextApi}`;
        this.baseUrl = `${contextApi}/properties`;
    }

    fetch(): Observable<ReadonlyArray<Property>> {
        if (isDevMode()) {
            console.log('Using url to fetch properties : ' + this.baseUrl);
        }
        return this.httpClient.get<ReadonlyArray<Property>>(this.baseUrl);
    }

    fetchById(id: Readonly<string>): Observable<Readonly<Property>> {
        return this.fetchByIds([id]).pipe(map(properties => properties[0]));
    }


    fetchByIds(propertiesNumbers?: Array<string>): Observable<ReadonlyArray<Property>> {
        let url = this.baseUrl;
        if (propertiesNumbers && propertiesNumbers.length > 0) {
            url += `?propertiesIds=${propertiesNumbers}`;
        }
        if (isDevMode()) {
            console.log('Using url to fetch all properties : ' + url);
        }
        return this.httpClient.get<ReadonlyArray<Property>>(url);
    }


}
