import { DatePipe, LocationStrategy } from '@angular/common';
import { ChangeDetectorRef, Component, isDevMode, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MessageService } from '@core/service/message.service';
import { PropertyService } from '@core/service/property.service';
import { GlobalVariables } from '@core/util/global-variables';
import { TemplateComponent } from '@extern/components/template/template.component';
import { DwellingWorksiteOverview, MeterWorksiteOverview } from '@extern/worksites/model/worksite-overview.model';
import { DataSynthesisProduct, DataSynthesisProperty } from '@extern/worksites/model/worksite-synthesis-data.model';
import { AuthorizationService, Customer, formatDate, Reference, SessionStorage, Slot } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { ProductsConstants } from '@shared/constants/products.const';
import { FilterName } from '@shared/enum/filter-name.enum';
import { StorageName } from '@shared/enum/storage-name.enum';
import { handlePropertySelection } from '@shared/service/property-selection.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { Entry } from 'src/app/core/model/entry.model';
import { Property } from 'src/app/core/model/property.model';
import { FilterReferencesService } from 'src/app/core/service/filter-references.service';
import { GroupFiltersComponent } from 'src/app/shared/components/group-filters/group-filters.component';
import { ExcelInstallationDashboardService } from 'src/app/shared/service/excel-installation-dashboard.service';

import { MetersWorksiteStatus, PropertyWorksiteStatus, WorksiteStatus } from '../../enum/woksite-status.model';
import { WorksiteDwellingStatus } from '../../enum/worksite-period-status.enum';
import { WorksiteDwellingDetails } from '../../model/worksite-dwelling-details.model';
import { WorksiteFilters } from '../../model/worksite-filters.model';
import { OverviewMode, WorksiteDwellingService } from '../../service/worksite-dwelling.service';
import { WorksiteFilterSessionStorageService } from '../../service/worksite-filter-session-storage.service';
import { WorksiteSynthesisService } from '../../service/worksite-synthesis.service';

export interface PropertyStatus {
    property: Property;
    status: WorksiteStatus;
}

export interface SyntheseProduitData {
    value: number;
    dwellings: Array<Reference>;
}

@Component({
    selector: 'app-installations-dashboard',
    templateUrl: './installations-dashboard.component.html',
    styleUrls: ['./installations-dashboard.component.css'],
    providers: [DatePipe],
})
export class InstallationsDashboardComponent implements OnInit, OnDestroy {
    message: any;
    messagesSubscription: Subscription;
    showSpinner = true;
    showGraphics = false;
    showEndDate = false;
    excellReady = false;
    openEndDatePicker = false;
    worksiteLimitDate: Date;
    dateOneYearFromNow: Date;
    propertyList: ReadonlyArray<Property>;

    dwellingsData: ReadonlyArray<DwellingWorksiteOverview> = [];
    metersData: ReadonlyArray<MeterWorksiteOverview> = [];
    filteredDwellingsData: ReadonlyArray<DwellingWorksiteOverview> = [];
    filteredMetersData: ReadonlyArray<MeterWorksiteOverview> = [];

    propertyData: ReadonlyArray<Property> = [];
    dataSyntheseProperty: Array<Entry<string, ReadonlyArray<Property>>> = [];
    dataSyntheseProduit: Array<Entry<string, SyntheseProduitData>> = [];
    details: Array<Entry<string, number>> = [
        { key: 'résidences', value: 0 },
        { key: 'logements', value: 0 },
        { key: 'appareils', value: 0 },
    ];

    propertyFilterData: ReadonlyArray<Reference>;
    addressFilterData: ReadonlyArray<Reference>;
    @ViewChild('gFiltres') gFiltres: GroupFiltersComponent<WorksiteFilters>;
    @ViewChild('table') table: any;
    showFilter = true;
    filterIsActive = false;
    additionalClasses = '';
    previousGraphWidth = 0;
    graphBlockWidth = 900;
    resizedEvent: any;

    constructor(
        public filterSessionStorage: WorksiteFilterSessionStorageService,
        public mainTemplate: TemplateComponent,
        private messageService: MessageService,
        private globalVariables: GlobalVariables,
        private propertyService: PropertyService,
        private worksiteDwellingService: WorksiteDwellingService,
        private filterReferencesService: FilterReferencesService,
        private dwellingService: WorksiteDwellingService,
        private translate: TranslateService,
        private excelInstallationDashboardExcelService: ExcelInstallationDashboardService,
        private sStorage: SessionStorage,
        private synthesisService: WorksiteSynthesisService,
        private isInternal: AuthorizationService,
        private changeDetectorRef: ChangeDetectorRef,
        private locationStrategy: LocationStrategy
    ) {
        this.dateOneYearFromNow = this.globalVariables.worksiteDefaultDate();
    }

    ngOnInit(): void {
        if (isDevMode()) {
            console.log('OnInit=>', new Date());
        }
        this.globalVariables.urlForCurrentView = [];
        this.translate.setDefaultLang('fr');
        this.testFilterEndDate();
        this.worksiteLimitDate = this.globalVariables.worksiteDefaultDate();
        this.filterSessionStorage.setFiltersProperty(FilterName.DWELLINGS, []);
        this.filterSessionStorage.setFiltersProperty(FilterName.STATUSES, []);
        if (!this.isInternal.isExternalAccountType()) {
            this.getProperties();
        } else {
            if (this.mainTemplate.setSelectionDisplayed()) {
                this.showSpinner = false;
                this.showGraphics = false;
            } else {
                this.showGraphics = true;
                this.getProperties();
            }
            this.messagesSubscription = this.messageService.getMessage().subscribe((message) => {
                if (message) {
                    this.message = message;
                    if (
                        this.message.text === 'refresh InstallationsDashboardComponent' ||
                        this.message.text === 'btn-return'
                    ) {
                        this.showGraphics = true;
                        this.getProperties();
                        this.messageService.clearMessage();
                    }
                }
            });
        }
        const matchMedia = window.matchMedia('(max-width: 1150px)');
        if (matchMedia.matches) {
            this.showFilter = false;
        }
    }

    checkFilters(): void {
        const filters = this.filterSessionStorage.getFilters();
        this.filterIsActive = !(
            !filters ||
            (filters &&
                filters.addresses.length === 0 &&
                filters.properties.length === 0 &&
                filters.products.length === 0 &&
                filters.worksiteStatuses.length === 0)
        );
    }

    /**
     * Initialize Synthesis Data to build charts
     */
    initializeDataSynthese(): void {
        this.dataSyntheseProperty = DataSynthesisProperty;
        this.dataSyntheseProduit = DataSynthesisProduct;
    }

    handleResize(event: ResizeObserverEntry): void {
        //if (event.contentRect.width > 1064) this.showFilter = true;
        if (event.contentRect.width < 690) this.additionalClasses = '';
        else if (event.contentRect.width > 740) this.additionalClasses = 'justify-content-center';
        this.table.rows = [...this.table.rows]; // Redraw the lines
    }

    testFilterEndDate(): void {
        const period = this.filterSessionStorage.getFiltersProperty<string>(FilterName.END_DATE);
        if (period !== undefined && period !== '') {
            this.showEndDate = true;
        }
    }

    toggleFilter(): void {
        console.log('show filter or hide');
        this.showFilter = !this.showFilter;
    }

    showFilterTooltip(): string {
        return this.showFilter
            ? this.translate.instant('filters.showFilters')
            : this.translate.instant('filters.hideFilters');
    }

    getInternalClientPropertiesFromSession(): string[] | undefined {
        return sessionStorage.getItem('internal_client_properties')
            ? sessionStorage.getItem('internal_client_properties')?.split(',')
            : [];
    }

    getProperties(): void {
        const list = this.isInternal.isExternalAccountType() ? [] : this.getInternalClientPropertiesFromSession();
        this.propertyService.fetchByIds(list).subscribe((properties) => {
            this.propertyList = properties as Array<Property>;
            this.refreshData('');
        });
    }

    fetchDwellingsDetails(
        propertiesNumbers: ReadonlyArray<string>,
        dwellingsIds: Array<string>,
        date: string
    ): Promise<ReadonlyArray<WorksiteDwellingDetails>> {
        return firstValueFrom(this.dwellingService.fetchDwellingsDetails(propertiesNumbers, dwellingsIds, [], date));
    }

    changeShowFilter(show: boolean): void {
        this.showEndDate = show;
        this.openEndDatePicker = show;
    }

    fetchApi(date: string, ids: string[] | undefined): Promise<any> {
        return Promise.all([
            this.worksiteDwellingService.fetchDwellingAndMetersWorksiteOverview(OverviewMode.DWELLING, date, ids),
            this.worksiteDwellingService.fetchDwellingAndMetersWorksiteOverview(OverviewMode.METER, date, ids),
        ]);
    }

    /**
     * Function called if filters are modified
     * @param message
     */
    refreshData(message: string): void {
        this.showSpinner = true;
        this.initializeDataSynthese();
        this.changeDetectorRef.detectChanges();
        if (!this.propertyList) {
            this.getProperties();
        }
        if (sessionStorage.getItem('previous_worksite_filters')) {
            const strPreviousFilters = sessionStorage.getItem('previous_worksite_filters') || '';
            this.filterSessionStorage.setItem('worksite_filters', JSON.parse(strPreviousFilters));
            sessionStorage.removeItem('previous_worksite_filters');
            history.pushState(null, '', location.href);
            this.locationStrategy.onPopState(() => {
                history.pushState(null, '', location.href);
            });
        }
        const propertiesFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.PROPERTIES);
        const addressesFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.ADDRESSES);
        const products = this.filterSessionStorage.getFiltersPropertyIds(FilterName.PRODUCTS);
        const statuses = this.filterSessionStorage.getFiltersPropertyIds(FilterName.WORKSITE_STATUSES);
        const startDate = this.filterSessionStorage.getFiltersProperty<string>(FilterName.START_DATE);
        const endDate = this.filterSessionStorage.getFiltersProperty<string>(FilterName.END_DATE);
        const newStatus = new Array<string>();
        statuses.forEach((x) => {
            if (x !== WorksiteDwellingStatus.NOT_INSTALLED_YET) {
                newStatus.push(x);
            }
        });
        let limitDate = this.worksiteLimitDate.toISOString().split('T')[0];
        if (startDate === limitDate) {
            const strCurrentFilters = (this.sStorage.getItem('worksite_filters') || '').toString();
            if (strCurrentFilters !== '') {
                const currentFilters = JSON.parse(strCurrentFilters);
                currentFilters.startDate = '';
                this.sStorage.setItem('worksite_filters', JSON.stringify(currentFilters));
            }
        }
        if (startDate && startDate !== '' && new Date(startDate) < new Date(limitDate)) {
            this.dwellingsData = [];
            limitDate = startDate;
        }
        if (
            message === 'filters.properties' ||
            message === 'filters.worksite.period' ||
            message === 'filters.activity.startDate'
        ) {
            this.propertyData = [];
            this.addressFilterData = [];
            this.dwellingsData = [];
            this.metersData = [];
            this.filteredDwellingsData = [];
            this.filteredMetersData = [];
        }
        let propertiesNumbers: ReadonlyArray<string> = [];
        propertiesNumbers = handlePropertySelection(
            this.filterSessionStorage,
            StorageName.WORKSITE_STORAGE_NAME,
            this.isInternal.isInternalAccountType()
        );

        if (this.isInternal.isInternalAccountType() && propertiesNumbers.length === 0) {
            propertiesNumbers =
                propertiesFilter.length > 0
                    ? propertiesFilter
                    : sessionStorage.getItem('internal_client_properties')
                      ? (sessionStorage.getItem('internal_client_properties')?.split(',') as ReadonlyArray<string>)
                      : [];
        }
        if (!this.dwellingsData?.length) {
            const ids = this.isInternal.isExternalAccountType() ? [] : this.getInternalClientPropertiesFromSession();
            this.fetchApi(limitDate, ids)
                .then((res) => {
                    this.dwellingsData = res[0] as DwellingWorksiteOverview[];
                    this.metersData = res[1] as MeterWorksiteOverview[];
                    this.newApplyFilters(propertiesFilter, addressesFilter, products, newStatus, startDate, endDate);
                    this.initFiltersData(propertiesFilter, addressesFilter);
                    this.excellReady = true;
                })
                .catch(() => {
                    if (isDevMode()) {
                        console.log('error detected');
                    }
                    this.dwellingsData = [];
                    this.metersData = [];
                    this.newApplyFilters(propertiesFilter, addressesFilter, products, newStatus, startDate, endDate);
                    this.initFiltersData(propertiesFilter, addressesFilter);
                });
        } else {
            this.newApplyFilters(propertiesFilter, addressesFilter, products, newStatus, startDate, endDate);
            this.initFiltersData(propertiesFilter, addressesFilter);
        }
        this.checkFilters();
    }

    /**
     * Set up the filters fields with the data retrieved from backend
     * @param propertiesFilter
     * @param addressesFilter
     */
    initFiltersData(propertiesFilter: ReadonlyArray<string>, addressesFilter: ReadonlyArray<string>): void {
        this.propertyFilterData = this.filterReferencesService.preparePropertyData(
            this.propertyList.filter((p) =>
                addressesFilter && addressesFilter.length > 0
                    ? addressesFilter.filter((id) => id.substring(0, 8) === p.id).length > 0
                    : true
            )
        );
        this.addressFilterData = this.filterReferencesService.newPrepareAddressData(
            this.dwellingsData.filter((d) =>
                propertiesFilter && propertiesFilter.length > 0 ? propertiesFilter.includes(d.propertyId) : true
            )
        );
    }

    /**
     * Check and Set filters if they are filled
     * @param propertiesFilter
     * @param addressesFilter
     * @param products
     * @param worksiteStatuses
     * @param startDate
     * @param endDate
     * @returns array of filters
     */
    checkAndSetFilters(
        propertiesFilter: ReadonlyArray<string>,
        addressesFilter: ReadonlyArray<string>,
        products: ReadonlyArray<string>,
        worksiteStatuses: Array<string>,
        startDate: string | undefined,
        endDate: string | undefined
    ): any[] {
        const filters = [];

        if (propertiesFilter.length > 0) {
            filters.push((data: DwellingWorksiteOverview[]) =>
                data.filter((w) => propertiesFilter.includes(w.propertyId))
            );
        }

        if (addressesFilter.length > 0) {
            filters.push((data: DwellingWorksiteOverview[]) =>
                data.filter((w) => addressesFilter.includes(w.dwellingId))
            );
        }

        if (products.length > 0) {
            filters.push((data: DwellingWorksiteOverview[]) =>
                data.filter((w) => products.includes(ProductsConstants.getMeterType(w.productCode, w.fluidCode)))
            );
        }

        if (worksiteStatuses.length > 0) {
            filters.push((data: DwellingWorksiteOverview[]) =>
                data.filter((w) => worksiteStatuses.includes(w.worksiteStatus))
            );
        }

        if (startDate) {
            const startTimestamp = new Date(startDate).getTime();
            filters.push((data: DwellingWorksiteOverview[]) =>
                data.filter((w) => {
                    if (w.visitsStartDate && w.visitsStartDate.length > 0) {
                        return startTimestamp <= new Date(w.visitsStartDate).getTime();
                    } else if (w.nextVisitDate && w.nextVisitDate.length > 0) {
                        return startTimestamp <= new Date(w.nextVisitDate).getTime();
                    } else return true;
                })
            );
        }

        if (endDate) {
            const endTimestamp = new Date(endDate).getTime();
            filters.push((data: DwellingWorksiteOverview[]) =>
                data.filter((w) => endTimestamp >= new Date(w.visitsEndDate).getTime())
            );
        }

        return filters;
    }

    /**
     * Apply filters to filteredDwellingsData and update all datas
     * @param propertiesFilter
     * @param addressesFilter
     * @param products
     * @param worksiteStatuses
     * @param startDate
     * @param endDate
     */
    newApplyFilters(
        propertiesFilter: ReadonlyArray<string>,
        addressesFilter: ReadonlyArray<string>,
        products: ReadonlyArray<string>,
        worksiteStatuses: Array<string>,
        startDate: string | undefined,
        endDate: string | undefined
    ): void {
        // Apply filters Dwellings
        const filtersDwellings = this.checkAndSetFilters(
            propertiesFilter,
            addressesFilter,
            products,
            worksiteStatuses,
            startDate,
            endDate
        );
        this.filteredDwellingsData = filtersDwellings.reduce(
            (data, filter) => filter(data),
            this.dwellingsData.slice()
        );

        // Apply filters date
        this.filteredDwellingsData = this.filteredDwellingsData
            .filter((w) =>
                startDate &&
                w.worksiteStatus === (WorksiteDwellingStatus.FINISHED || WorksiteDwellingStatus.NOT_INSTALLED_YET)
                    ? startDate <= w.lastVisitDate
                    : true
            )
            .filter((w) =>
                endDate &&
                w.worksiteStatus === (WorksiteDwellingStatus.FINISHED || WorksiteDwellingStatus.NOT_INSTALLED_YET)
                    ? endDate >= w.lastVisitDate
                    : true
            );

        // Apply filters Meters
        const filtersMeters = this.checkAndSetFilters(
            propertiesFilter,
            addressesFilter,
            products,
            worksiteStatuses,
            startDate,
            endDate
        );
        this.filteredMetersData = filtersMeters.reduce((data, filter) => filter(data), this.metersData.slice());
        // Remove duplicate meter (Not_equipable duplicates)
        this.filteredMetersData.forEach((meter) => {
            if (this.filteredMetersData.filter((m) => m.meterId === meter.meterId).length > 1) {
                this.filteredMetersData = this.filteredMetersData.filter((m) =>
                    m.meterId === meter.meterId ? m.installable : true
                );
            }
        });

        this.propertyData = this.constructPropertyData();
        this.buildResume();
        this.newConstructSynthesis();
        this.showSpinner = false;
        this.changeDetectorRef.detectChanges();
        if (isDevMode()) {
            console.log('AfterSpinner=>', new Date());
            console.log('this.dwellingsData :>> ', this.dwellingsData);
            console.log('this.metersData :>> ', this.metersData);
            console.log('-------------------------------------------');
            console.log('this.filteredDwellingsData :>> ', this.filteredDwellingsData);
            console.log('this.filteredMetersData :>> ', this.filteredMetersData);
        }
    }

    constructPropertyData(): Property[] {
        const propertyArray: Property[] = [];
        [...new Map(this.filteredDwellingsData.map((w) => [w.propertyId, w])).values()].forEach((dwelling) => {
            propertyArray.push({
                id: dwelling.propertyId,
                address: dwelling.address,
                city: dwelling.city,
                postalCode: dwelling.zip,
                fullAddress: dwelling.address + ' ' + dwelling.zip + ' ' + dwelling.city,
                name: dwelling.propertyName,
            });
        });
        return propertyArray;
    }

    /**
     * Build the resume with the number of properties, dwellings and meters
     */
    buildResume(): void {
        this.details = [];
        if (this.filteredDwellingsData && this.filteredMetersData) {
            this.details.push({
                key: 'résidences',
                value: [...new Map(this.filteredDwellingsData.map((w) => [w.propertyId, w])).values()].length,
            });
            this.details.push({
                key: 'logements',
                value: [...new Map(this.filteredDwellingsData.map((w) => [w.dwellingId, w])).values()].length,
            });
            this.details.push({ key: 'appareils', value: this.filteredMetersData.length });
        } else {
            this.details.push({ key: 'résidences', value: 0 });
            this.details.push({ key: 'logements', value: 0 });
            this.details.push({ key: 'appareils', value: 0 });
        }
    }

    /**
     * Build the two synthesis charts
     */
    newConstructSynthesis(): void {
        if (this.filteredDwellingsData && this.filteredMetersData) {
            const propertiesByStatus: Record<PropertyWorksiteStatus, Property[]> = {
                [PropertyWorksiteStatus.FINISHED]: [],
                [PropertyWorksiteStatus.IN_PROGRESS]: [],
                [PropertyWorksiteStatus.PLANNED]: [],
                [PropertyWorksiteStatus.TO_PLAN]: [],
            };
            this.propertyData.forEach((property) => {
                const worksites = this.getUniqueWorksitesByProperty(property.id);
                const status = this.getPropertyStatus(worksites) || WorksiteStatus.TO_PLAN;
                propertiesByStatus[status as PropertyWorksiteStatus].push(property);
            });

            this.constructSyntheseProperty(propertiesByStatus);
            this.constructSyntheseProduit();
        }
    }

    getPropertyStatus(worksites: DwellingWorksiteOverview[]): string {
        if (worksites.length === 0) {
            return WorksiteStatus.TO_PLAN;
        }

        if (worksites.every((w) => w.worksiteStatus === worksites[0].worksiteStatus)) {
            return worksites[0].worksiteStatus;
        }

        if (worksites.some((w) => w.worksiteStatus === WorksiteStatus.IN_PROGRESS)) {
            return WorksiteStatus.IN_PROGRESS;
        }

        if (worksites.some((w) => w.worksiteStatus === WorksiteStatus.FINISHED)) {
            return WorksiteStatus.IN_PROGRESS;
        }

        if (worksites.some((w) => w.worksiteStatus === WorksiteStatus.PLANNED)) {
            return WorksiteStatus.PLANNED;
        }

        return WorksiteStatus.TO_PLAN;
    }

    constructSyntheseProperty(propertiesByStatus: Record<PropertyWorksiteStatus, Property[]>): void {
        this.dataSyntheseProperty = Object.entries(propertiesByStatus).map(([key, value]) => ({
            key,
            value,
        }));
    }

    constructSyntheseProduit(): void {
        this.dataSyntheseProduit = Object.values(MetersWorksiteStatus).map((status) => ({
            key: status,
            value: this.createSyntheseProduitData(status),
        }));
    }

    /**
     * Return a object SyntheseProduitData depending the meter status to build the meters chart
     * @param status
     * @returns SyntheseProduitData
     */
    createSyntheseProduitData(status: MetersWorksiteStatus): SyntheseProduitData {
        const synthesisProductData: SyntheseProduitData = { value: 0, dwellings: [] };

        let meters = this.filteredMetersData.filter((m) => m.meterStatus === status);
        console.log('meters :>> ', meters);
        if (status.toString() === MetersWorksiteStatus.FINISHED) {
            // QDC regroup in FINISHED
            if (
                this.filteredMetersData.filter(
                    (m) => m.meterStatus === WorksiteDwellingStatus.FINISHED_REMAINING_INSTALLATION
                )?.length
            ) {
                meters.push(
                    ...this.filteredMetersData.filter(
                        (m) => m.meterStatus === WorksiteDwellingStatus.FINISHED_REMAINING_INSTALLATION
                    )
                );
            }
            // Meters NOT_EQUIPABLE but FINISHED
            if (this.filteredMetersData.filter((m) => m.meterStatus === WorksiteDwellingStatus.NOT_EQUIPABLE)?.length) {
                meters.push(
                    ...this.filteredMetersData
                        .filter((m) => m.meterStatus === WorksiteDwellingStatus.NOT_EQUIPABLE)
                        .filter((m) => m.installable && m.installed && m.newSerialNumber)
                );
            }
        }

        const metersWithoutDuplicates = [...new Map(meters.map((d) => [d.dwellingId, d])).values()];
        metersWithoutDuplicates.forEach((d) =>
            synthesisProductData.dwellings.push({ ids: [d.dwellingId], name: d.dwellingCustomerReference })
        );
        synthesisProductData.value = meters.length;
        return synthesisProductData;
    }

    getUniqueWorksitesByProperty(propertyId: string): DwellingWorksiteOverview[] {
        return [...new Map(this.filteredDwellingsData.map((w) => [w.worksiteId, w])).values()].filter(
            (w) => w.propertyId === propertyId
        );
    }

    export(): void {
        if (isDevMode()) {
            console.log('refreshData');
        }
    }

    exportInstallationDashboardData(source: string): void {
        if (source === 'excel') {
            const customer = this.sStorage.getCustomer();
            this.exportInstallationDashboardInExcelFormat(customer);
        }
    }

    public exportInstallationDashboardInExcelFormat(customer: Customer): void {
        const rows = this.synthesisService.getSynsthesisLine(this.filteredDwellingsData, this.filteredMetersData);

        const filteredResidences = this.gFiltres.getFilterSelectedValues(
            this.translate.instant('filters.' + FilterName.PROPERTIES)
        );
        const filteredProducts = this.gFiltres.getFilterSelectedValues(
            this.translate.instant('filters.worksite.' + FilterName.PRODUCTS)
        );
        const filteredStatuses = this.gFiltres.getFilterSelectedValues(
            this.translate.instant('filters.worksite.' + FilterName.WORKSITE_STATUSES)
        );
        const filteredAddresses = this.gFiltres.getFilterSelectedValues(
            this.translate.instant('filters.' + FilterName.ADDRESSES)
        );
        const datesPassages: string = this.extractFilterPeriodValues();

        this.excelInstallationDashboardExcelService.generateExcelFromInstallationsList(
            customer,
            rows,
            filteredResidences,
            filteredProducts,
            filteredStatuses,
            filteredAddresses,
            datesPassages
        );
    }

    private extractFilterPeriodValues(): string {
        const filteredDatesPassages: Slot | undefined = this.filterSessionStorage.getFiltersProperty<Slot>('period');
        if (filteredDatesPassages && filteredDatesPassages.start !== '' && filteredDatesPassages.end !== '') {
            return `DU ${formatDate(filteredDatesPassages.start, 'DD/MM/YYYY')} AU ${formatDate(filteredDatesPassages.end, 'DD/MM/YYYY')}`;
        } else {
            const datepipe: DatePipe = new DatePipe('en-US');
            let formattedDate = datepipe.transform(this.worksiteLimitDate, 'dd/MM/YYYY');
            return 'DU ' + formattedDate + " à aujourd'hui";
        }
    }

    ngOnDestroy(): void {
        if (this.messagesSubscription) {
            this.messagesSubscription.unsubscribe();
        }
    }
}
