import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { CONTEXT_API } from '@ista/shared-ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WorksiteDwellingStatus } from '../enum/worksite-period-status.enum';
import { InstallationReport } from '../model/installation-report.model';
import { NewInstallationLine } from '../model/new-installation-line.model';

@Injectable({
    providedIn: 'root'
})
export class InstallationReportService {

    baseUrl: string;

    constructor(private httpClient: HttpClient, @Inject(CONTEXT_API) private contextApi: string) {
        this.baseUrl = `${this.contextApi}/worksites`;
    }

    fetchReportByDwellingWorksite(dwelling: string, worksite: string): Observable<InstallationReport> {
        const url = `${this.baseUrl}/${worksite}/documents`;
        if (isDevMode()) {
            console.log('Using url to fetch installation report : ' + url);
        }
        return this.httpClient.get<Array<InstallationReport>>(url, {
            params: {
                dwellings: dwelling.length >= 8 ? dwelling.substring(dwelling.length - 8) : dwelling
            }
        }).pipe(map(cs => cs[0]));
    }

    downloadReport(link: string): Observable<Blob> {
        if (isDevMode()) {
            console.log('Using url to fetch installation report : ' + link);
        }
        return this.httpClient.get(link, { responseType: 'blob' });
    }

    hasRelevantStatusForReport(row: NewInstallationLine): boolean {
        return row.dwellingStatus !== WorksiteDwellingStatus.TO_PLAN
            && row.dwellingStatus !== WorksiteDwellingStatus.PLANNED
            && row.dwellingStatus !== WorksiteDwellingStatus.IN_PROGRESS;
    }

    setDisplayForDownloadedReports(installationLines: ReadonlyArray<NewInstallationLine>): void{
        const storedDownloadedReports = sessionStorage.getItem('downloadedReports') as string;

        if (storedDownloadedReports == null || storedDownloadedReports === undefined || storedDownloadedReports.length === 0) {
            sessionStorage.setItem('downloadedReports', '');
        } else {
            const downloadedReports = JSON.parse(storedDownloadedReports);
            installationLines.forEach(line => {
                downloadedReports.forEach((report: InstallationReport) => {
                    // Reports already downloaded
                    if (line.dwellingId === report.dwelling && line.worksiteId === report.worksiteId && report.downloaded) {
                        line.report.downloaded = true;
                    }
                    // Unavailable reports
                    if (line.dwellingId === report.dwelling && line.worksiteId === report.worksiteId && !report.available) {
                        line.report.available = false;
                    }
                });
            });
        }
    }
}
