import { DatePipe } from '@angular/common';
import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { RedirectLandingPage } from '@core/enum/redirect-landing-page.enum';
import { Column } from '@core/model/column.model';
import { Property } from '@core/model/property.model';
import { MessageService } from '@core/service/message.service';
import { PropertyService } from '@core/service/property.service';
import { SelfcareSessionStorage } from '@core/service/selfcare-session-storage.service';
import { GlobalVariables } from '@core/util/global-variables';
import { ActivityType } from '@extern/activities/enum/activity-type.enum';
import { NewDwellingActivities } from '@extern/activities/model/new-dwelling-activities.model';
import { ActivityAddRemainingInstallationService } from '@extern/activities/service/activity-add-remaining-installation.service';
import { TemplateComponent } from '@extern/components/template/template.component';
import { Installation } from '@extern/worksites/model/installation.model';
import { NewInstallationLine } from '@extern/worksites/model/new-installation-line.model';
import * as dateUtils from '@ista/shared-ui';
import { AuthorizationService, Customer, Reference } from '@ista/shared-ui';
import { WorksiteDwellingDetails } from '@mes-chantiers/model/worksite-dwelling-details.model';
import { OverviewMode, WorksiteDwellingService } from '@mes-chantiers/service/worksite-dwelling.service';
import { TranslateService } from '@ngx-translate/core';
import { FilterName } from '@shared/enum/filter-name.enum';
import { StorageName } from '@shared/enum/storage-name.enum';
import { handlePropertySelection } from '@shared/service/property-selection.service';
import { firstValueFrom, Subject, Subscription } from 'rxjs';
import { FilterReferencesService } from '@core/service/filter-references.service';
import { GroupFiltersComponent } from 'src/app/shared/components/group-filters/group-filters.component';
import { ExcelService } from '@shared/service/excel.service';

import { ActivityStatus } from '../../enum/activity-status.enum';
import { ActivityFilters } from '../../model/activity-filters.model';
import { ActivityDwellingDetails } from '../../model/dwelling-activities-details.model';
import { ActivityDwellingSortingService } from '../../service/activity-dwelling-sorting.service';
import { ActivityDwellingService } from '../../service/activity-dwelling.service';
import { ActivityFilterSessionStorageService } from '../../service/activity-filter-session-storage.service';

@Component({
    selector: 'app-activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.css'],
})
export class ActivitiesComponent implements OnInit, OnDestroy, AfterViewChecked {
    propertyList: ReadonlyArray<Property>;
    lines: ReadonlyArray<NewDwellingActivities> = [];
    emptyList: ReadonlyArray<NewDwellingActivities> = [];
    dataLines: ReadonlyArray<NewDwellingActivities> = [];
    message: any;
    messagesSubscription: Subscription;
    showSpinner = true;
    defaultDatePattern = 'DD/MM/YYYY';
    activityLimitDate: Date;
    properties: ReadonlyArray<string>;
    @ViewChild('gFiltres') gFiltres: GroupFiltersComponent<ActivityFilters>;
    propertyFilterData: ReadonlyArray<Reference>;
    addressFilterData: ReadonlyArray<Reference>;
    dwellingFilterData: ReadonlyArray<Reference>;
    @ViewChild('filtersBlock', { static: false }) filtersBlock: ElementRef;
    @ViewChild('containersBlock', { static: false }) containersBlock: ElementRef;
    showFilter = true;
    listBlockHeight: number;
    columns: Array<Column>;
    columnsFilterData: ReadonlyArray<Reference>;
    columnsFiltred: Column[];
    fixedColumns = [
        { name: 'datatable.activities.columns.dwellingCustomerReference', ids: ['dwellingCustomerReference'] },
        { name: 'datatable.activities.columns.helpUs', ids: ['status.nbItemsHelpUs'] },
        { name: 'datatable.activities.columns.toPlan', ids: ['status.nbItemsToPlanNoAction'] },
        { name: 'datatable.activities.columns.planned', ids: ['status.nbItemsPlanned'] },
        { name: 'datatable.activities.columns.in_progress', ids: ['status.nbItemsInProgress'] },
        { name: 'datatable.activities.columns.notEquippable', ids: ['status.nbItemsNotEquippable'] },
    ];
    eventsSubject: Subject<number> = new Subject<number>();
    matchMedia: MediaQueryList;
    previousMatchMediaStatus: boolean;
    filterIsActive = false;

    constructor(
        public mainTemplate: TemplateComponent,
        public filterSessionStorage: ActivityFilterSessionStorageService,
        private router: Router,
        private messageService: MessageService,
        private globalVariables: GlobalVariables,
        private datePipe: DatePipe,
        private dwellingService: ActivityDwellingService,
        private filterReferencesService: FilterReferencesService,
        private worksiteService: WorksiteDwellingService,
        private worksiteDwellingService: WorksiteDwellingService,
        private sortingService: ActivityDwellingSortingService,
        private propertyService: PropertyService,
        private sStorage: SelfcareSessionStorage,
        private excelService: ExcelService,
        private translate: TranslateService,
        private isInternal: AuthorizationService,
        private worksiteRemainingAddService: ActivityAddRemainingInstallationService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.globalVariables.urlForCurrentView = [];
        this.translate.setDefaultLang('fr');
        if (!this.isInternal.isExternalAccountType()) {
            this.getProperties();
        } else {
            if (this.mainTemplate.setSelectionDisplayed()) {
                this.showSpinner = false;
            } else {
                this.getProperties();
            }
            this.messagesSubscription = this.messageService.getMessage().subscribe((message) => {
                if (message) {
                    this.message = message;
                    if (this.message.text === 'refresh ActivitiesComponent' || this.message.text === 'btn-return') {
                        this.getProperties();
                        this.messageService.clearMessage();
                    }
                }
            });
        }
        this.matchMedia = window.matchMedia('(max-width: 1150px)');
        this.createColumns();
        this.initFilterColumns();
        this.activityLimitDate = this.globalVariables.activityLimitDate();
        this.previousMatchMediaStatus = this.matchMedia.matches;
        if (this.matchMedia.matches) {
            this.showFilter = false;
        }
    }

    checkFilters(): void {
        const filters = this.filterSessionStorage.getFilters();
        this.filterIsActive = !(
            !filters ||
            (filters &&
                filters.addresses.length === 0 &&
                filters.properties.length === 0 &&
                filters.dwellings.length === 0 &&
                filters.activityTypes.length === 0 &&
                filters.statuses.length === 0 &&
                filters.dates.length === 0
            )
        );
    }

    prepareColumns(): void {
        const columnsFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.COLUMNS);
        this.columnsFiltred = [...this.columns.filter((col) => columnsFilter.includes(col.prop))];
        if (this.columnsFiltred.length === 0) {
            this.columnsFiltred = [...this.columns];
        }
    }

    initFilterColumns(): void {
        const filterData = new Array<Reference>();
        this.columns.forEach((col) => {
            filterData.push({ name: this.translate.instant(col.name), ids: [col.prop] });
        });
        this.columnsFilterData = filterData;
        if (this.matchMedia.matches) {
            this.filterSessionStorage.setFiltersProperty(FilterName.COLUMNS, this.fixedColumns);
            this.showFilter = false;
        } else {
            this.filterSessionStorage.setFiltersProperty(FilterName.COLUMNS, this.columnsFilterData);
        }
    }

    createColumns(): void {
        this.columns = [
            new Column(
                this.translate.instant('datatable.activities.columns.propertyName'),
                'propertyName',
                true,
                120,
                this.comparatorNotNull.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.propertyAddress'),
                'address',
                true,
                120,
                this.comparatorAdress.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.dwellingCustomerReference'),
                'dwellingCustomerReference',
                true,
                60,
                this.alphanumericComparator.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.owner'),
                'owner',
                true,
                60,
                this.ownerComparator.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.tenant'),
                'tenant',
                true,
                60,
                this.ownerComparator.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.helpUs'),
                'status.nbItemsHelpUs',
                true,
                30,
                this.statusComparator.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.toPlan'),
                'status.nbItemsToPlanNoAction',
                true,
                30,
                this.statusComparator.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.planned'),
                'status.nbItemsPlanned',
                true,
                30,
                this.statusComparator.bind(this)
            ),
            new Column(
                this.translate.instant('detailedActivitiesPage.maintenance.status.in_progress'),
                'status.nbItemsInProgress',
                true,
                30,
                this.statusComparator.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.notEquippable'),
                'status.nbItemsNotEquippable',
                true,
                30,
                this.statusToPlanComparator.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.finished'),
                'status.nbItemsFinished',
                true,
                30,
                this.statusComparator.bind(this)
            ),
        ];
    }

    toggleFilter(): void {
        this.showFilter = !this.showFilter;
        this.resized();
    }

    showFilterTooltip(): string {
        return this.showFilter
            ? this.translate.instant('filters.showFilters')
            : this.translate.instant('filters.hideFilters');
    }

    resized(): void {
        this.listBlockHeight =
            this.containersBlock.nativeElement.offsetHeight - this.filtersBlock.nativeElement.offsetHeight;
        this.eventsSubject.next(this.listBlockHeight);
    }

    handleResize(event: ResizeObserverEntry): void {
        this.listBlockHeight = event.contentRect.height;
        this.eventsSubject.next(this.listBlockHeight);
        if (this.previousMatchMediaStatus !== this.matchMedia.matches) {
            this.previousMatchMediaStatus = this.matchMedia.matches;
            this.initFilterColumns();
            this.prepareColumns();
        }
        if (event.contentRect.width > 1064) this.showFilter = true;
        else if (this.previousMatchMediaStatus) this.showFilter = false;
    }

    getProperties(): void {
        const list = this.isInternal.isExternalAccountType()
            ? []
            : sessionStorage.getItem('internal_client_properties')
              ? sessionStorage.getItem('internal_client_properties')?.split(',')
              : [];
        this.showSpinner = true;
        this.propertyService.fetchByIds(list).subscribe((properties) => {
            this.propertyList = properties as Array<Property>;
            this.refreshData('');
        });
    }

    fetchWorksiteData(
        propertiesNumbers: ReadonlyArray<string>,
        dwellingsIds: Array<string>,
        date: string
    ): Promise<ReadonlyArray<WorksiteDwellingDetails>> {
        return firstValueFrom(
            this.worksiteService.fetchDwellingsDetails(propertiesNumbers, dwellingsIds, [], date, true)
        );
    }

    fetchDataNew(
        viewType: string,
        propertiesNumbers: ReadonlyArray<string>,
        date: string
    ): Promise<ReadonlyArray<NewDwellingActivities>> {
        this.lines = this.emptyList;
        return firstValueFrom(this.dwellingService.fetchDwellingsDetailsByView(viewType, propertiesNumbers, date));
    }

    fetchWorksiteDataNew(
        viewType: OverviewMode,
        propertiesNumbers: ReadonlyArray<string>,
        date: string,
        remainingInstallations?: boolean
    ): Promise<ReadonlyArray<NewInstallationLine>> {
        this.lines = this.emptyList;
        return firstValueFrom(
            this.worksiteDwellingService.fetchDwellingsDetailsByView(
                viewType,
                propertiesNumbers,
                date,
                remainingInstallations
            )
        );
    }

    refreshData(message: string): void {
        const date = this.filterSessionStorage.getFiltersProperty<string>(FilterName.DATES);
        let limitDate = this.activityLimitDate.toISOString().split('T')[0];
        if (date === limitDate) {
            const strCurrentFilters = (this.sStorage.getItem('activity_filters') || '').toString();
            if (strCurrentFilters !== '') {
                const currentFilters = JSON.parse(strCurrentFilters);
                currentFilters.Dates = '';
                this.sStorage.setItem('activity_filters', JSON.stringify(currentFilters));
            }
        }
        if (date && date !== '' && new Date(limitDate) < new Date(date)) {
            limitDate = date;
        }
        const propertiesFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.PROPERTIES);
        let propertiesNumbers: ReadonlyArray<string> = [];
        propertiesNumbers = handlePropertySelection(
            this.filterSessionStorage,
            StorageName.ACTIVITY_STORAGE_NAME,
            this.isInternal.isInternalAccountType()
        );
        if (this.isInternal.isInternalAccountType() && propertiesNumbers.length === 0) {
            propertiesNumbers =
                propertiesFilter.length > 0
                    ? propertiesFilter
                    : sessionStorage.getItem('internal_client_properties')
                      ? (sessionStorage.getItem('internal_client_properties')?.split(',') as ReadonlyArray<string>)
                      : [];
        }
        if (message === 'filters.properties') {
            this.dataLines = [];
        }
        const dwellingsIds: Array<string> = [];
        const propertyPackages: Array<string[]> = [];
        let activityArray: NewDwellingActivities[] = [];
        let worksiteArray: NewInstallationLine[] = [];
        if (this.dataLines.length === 0) {
            this.showSpinner = true;
            if (propertiesNumbers.length > 500) {
                for (var i = 0; i < propertiesNumbers.length; i += 100) {
                    let onePackage: string[] = [];
                    for (var j = i; j < i + 100; j++) {
                        if (propertiesNumbers[j]) onePackage.push(propertiesNumbers[j]);
                    }
                    propertyPackages.push(onePackage);
                }
            } else {
                propertyPackages.push(propertiesNumbers as string[]);
            }
            Promise.all(
                propertyPackages.map((propertiesNumbers) => {
                    return this.fetchDataNew(OverviewMode.DWELLING, propertiesNumbers, limitDate);
                })
            ).then((promiseActivityArray) => {
                promiseActivityArray.forEach((a) => (activityArray = activityArray.concat(a)));
                Promise.all(
                    propertyPackages.map((propertiesNumbers) => {
                        return this.fetchWorksiteDataNew(OverviewMode.DWELLING, propertiesNumbers, limitDate, true);
                    })
                ).then((promiseWorksiteArray) => {
                    promiseWorksiteArray.forEach((w) => (worksiteArray = worksiteArray.concat(w)));
                    this.dataLines = this.completeActivityData(activityArray, worksiteArray, limitDate);
                    this.prepareList();
                    this.showSpinner = false;
                    this.changeDetectorRef.detectChanges();
                });
            });
        } else {
            this.prepareList();
        }
        this.checkFilters();
    }

    completeActivityData(
        activityLines: readonly NewDwellingActivities[],
        worksiteLines: readonly NewInstallationLine[],
        limitDate: string
    ): ReadonlyArray<NewDwellingActivities> {
        const dwellingLinesForMaintenance = activityLines.map((line) => {
            return {
                propertyId: line.propertyId,
                propertyName: line.propertyName,
                address: line.address,
                postalCode: line.postalCode,
                city: line.city,
                fullAddress: line.fullAddress,
                dwellingId: line.dwellingId,
                dwellingCustomerReference: line.dwellingCustomerReference,
                dwellingLocation: line.dwellingLocation,
                beep: line.beep,
                owner: line.owner,
                tenant: line.tenant,
                view: line.view,
                type: line.type,
                lastSignalementCreationDate: line.lastSignalementCreationDate,
                lastSignalementModificationDate: line.lastSignalementModificationDate || '',
                statuses: line.statuses,
                status: {
                    nbItemsPlanned: this.getStatusQuantity(line.statuses, ActivityStatus.PLANNED),
                    nbItemsInProgress: this.getStatusQuantity(line.statuses, ActivityStatus.IN_PROGRESS),
                    nbItemsNotEquippable: this.getStatusQuantity(line.statuses, ActivityStatus.NOT_EQUIPABLE),
                    nbItemsFinished: this.getStatusQuantity(line.statuses, ActivityStatus.FINISHED),
                    nbItemsToPlan: this.getInstallationStatusQuantity(line.statuses, ActivityStatus.TO_PLAN),
                    nbItemsToPlanNoAction: this.getStatusQuantity(line.statuses, ActivityStatus.TO_PLAN),
                    nbItemsHelpUs: this.getStatusQuantity(line.statuses, ActivityStatus.HELP_US),
                },
            };
        });

        const dwellingLinesStillToBeInstalled = worksiteLines.map((line) => {
            return {
                propertyId: line.propertyId,
                propertyName: line.propertyName,
                address: line.address,
                postalCode: line.zip,
                city: line.city,
                fullAddress: line.address + ' ' + line.zip + ' ' + line.city,
                dwellingId: line.dwellingId,
                dwellingCustomerReference: line.dwellingCustomerReference,
                dwellingLocation: line.dwellingLocation,
                beep: line.dwellingId.substring(line.propertyId.length),
                owner: line.owner,
                tenant: line.tenant,
                view: line.view,
                type: ActivityType.INSTALLATION as string,
                lastSignalementCreationDate: this.getLastPeriodCreationDate(line.installations),
                lastSignalementModificationDate: '',
                statuses: this.getStatuses(line.installations as any[]) as [{ status: string; count: number }],
                status: {
                    nbItemsPlanned: this.getInstallationStatusQuantity(
                        line.installations as any[],
                        ActivityStatus.PLANNED
                    ),
                    nbItemsInProgress: this.getInstallationStatusQuantity(
                        line.installations as any[],
                        ActivityStatus.IN_PROGRESS
                    ),
                    nbItemsNotEquippable: this.getInstallationStatusQuantity(
                        line.installations as any[],
                        ActivityStatus.NOT_EQUIPABLE
                    ),
                    nbItemsFinished: this.getInstallationStatusQuantity(
                        line.installations as any[],
                        ActivityStatus.FINISHED
                    ),
                    nbItemsToPlan: this.getInstallationStatusQuantity(
                        line.installations as any[],
                        ActivityStatus.TO_PLAN
                    ),
                    nbItemsToPlanNoAction: this.getInstallationStatusQuantity(
                        line.installations as any[],
                        ActivityStatus.TO_PLAN
                    ),
                    nbItemsHelpUs: this.getInstallationStatusQuantity(
                        line.installations as any[],
                        ActivityStatus.HELP_US
                    ),
                },
            };
        });

        const allActivityData = dwellingLinesForMaintenance.concat(
            dwellingLinesStillToBeInstalled as NewDwellingActivities[]
        );
        const dwellingIds = new Set(allActivityData.map((x) => x.dwellingId) as readonly string[]);
        let activityData: NewDwellingActivities[] = [];
        dwellingIds.forEach((dwellingId) => {
            const dwellingActivities = allActivityData.filter((activity) => activity.dwellingId === dwellingId);
            let uniqueDwellingActivities = {} as NewDwellingActivities;
            if (dwellingActivities.length > 1) {
                uniqueDwellingActivities = dwellingActivities[0];
                for (var i = 1; i < dwellingActivities.length; i++) {
                    uniqueDwellingActivities.status.nbItemsPlanned += dwellingActivities[i].status.nbItemsPlanned;
                    uniqueDwellingActivities.status.nbItemsInProgress += dwellingActivities[i].status.nbItemsInProgress;
                    uniqueDwellingActivities.status.nbItemsNotEquippable +=
                        dwellingActivities[i].status.nbItemsNotEquippable;
                    uniqueDwellingActivities.status.nbItemsFinished += dwellingActivities[i].status.nbItemsFinished;
                    uniqueDwellingActivities.status.nbItemsToPlan += dwellingActivities[i].status.nbItemsToPlan;
                    uniqueDwellingActivities.status.nbItemsToPlanNoAction +=
                        dwellingActivities[i].status.nbItemsToPlanNoAction;
                    uniqueDwellingActivities.status.nbItemsHelpUs += dwellingActivities[i].status.nbItemsHelpUs;
                }
                const statuses = this.getStatusesFromNbrItems(uniqueDwellingActivities.status) as [
                    { status: string; count: number },
                ];
                uniqueDwellingActivities.statuses = statuses;
            } else if (dwellingActivities.length === 1) {
                uniqueDwellingActivities = dwellingActivities[0];
            }
            if (uniqueDwellingActivities) {
                activityData.push(uniqueDwellingActivities);
            }
        });
        return activityData;
    }

    getStatusQuantity(statuses: any[], statusToCheck: ActivityStatus): number {
        const index = statuses.findIndex((st) => st['status'] === statusToCheck);
        if (index !== -1) {
            return statuses[index]['count'] as number;
        }
        return 0;
    }

    getInstallationStatusQuantity(Installations: any[], statusToCheck: ActivityStatus): number {
        const uniqueInstallations: any[] = Array.from(
            Installations.reduce((m, t) => m.set(t.id, t), new Map()).values()
        );
        return uniqueInstallations.filter((ins) => ins['meterStatus'] === statusToCheck).length;
    }

    getStatuses(Installations: any[]): any[] {
        const statuses: any[] = [];
        for (var statusToFind in ActivityStatus) {
            const countValue = this.getInstallationStatusQuantity(Installations, statusToFind as ActivityStatus);
            if (countValue > 0) {
                statuses.push({
                    status: statusToFind,
                    count: countValue,
                });
            }
        }
        return statuses;
    }

    getStatusesFromNbrItems(dwellingStatusNbr: any): any[] {
        const statuses = [
            { nbItems: 'nbItemsPlanned', status: ActivityStatus.PLANNED },
            { nbItems: 'nbItemsInProgress', status: ActivityStatus.IN_PROGRESS },
            { nbItems: 'nbItemsNotEquippable', status: ActivityStatus.NOT_EQUIPABLE },
            { nbItems: 'nbItemsFinished', status: ActivityStatus.FINISHED },
            { nbItems: 'nbItemsToPlan', status: ActivityStatus.TO_PLAN },
            { nbItems: 'nbItemsToPlanNoAction', status: ActivityStatus.TO_PLAN },
            { nbItems: 'nbItemsHelpUs', status: ActivityStatus.HELP_US },
        ];
        const dwellingStatuses: any[] = [];
        statuses.forEach((s) => {
            const statusCount = dwellingStatusNbr[s.nbItems];
            if (statusCount > 0) {
                dwellingStatuses.push({ status: s.status, count: statusCount });
            }
        });
        return dwellingStatuses;
    }

    getLastPeriodCreationDate(installations: ReadonlyArray<Installation>): string {
        const periodCreationDates = [...new Set(installations.map((i) => i.periodCreationDate))];
        if (periodCreationDates && periodCreationDates.length > 0) {
            periodCreationDates.sort();
        }
        return periodCreationDates && periodCreationDates.length > 0
            ? periodCreationDates[periodCreationDates.length - 1]
            : '';
    }

    prepareList(): void {
        const propertiesFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.PROPERTIES);
        const addressesFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.ADDRESSES);
        const dwellingsFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.DWELLINGS);
        const activityTypesFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.ACTIVITY_TYPES);
        const statusesFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.STATUSES);
        const dateFilter = this.filterSessionStorage.getFiltersProperty<string>(FilterName.DATES);
        const filteredLines = this.filterData(
            propertiesFilter,
            addressesFilter,
            dwellingsFilter,
            activityTypesFilter,
            statusesFilter,
            dateFilter
        );
        this.lines = filteredLines;
        this.prepareFiltersData(propertiesFilter, addressesFilter);
        this.prepareColumns();
    }

    prepareFiltersData(propertiesFilter: ReadonlyArray<string>, addressesFilter: ReadonlyArray<string>): void {
        const properties = this.propertyList;
        this.propertyFilterData = this.filterReferencesService.preparePropertyData(
            properties.filter((p) =>
                addressesFilter && addressesFilter.length > 0
                    ? addressesFilter.map((id) => id.substring(0, 8)).includes(p.id)
                    : true
            )
        );
        this.addressFilterData = this.filterReferencesService.prepareAddressData(
            this.dataLines
                .filter((d) =>
                    propertiesFilter && propertiesFilter.length > 0 ? propertiesFilter.includes(d.propertyId) : true
                )
                .map((l) => {
                    return {
                        id: l.dwellingId,
                        customerReference: l.dwellingCustomerReference,
                        istaReference: l.dwellingId,
                        location: l.dwellingLocation,
                        owner: l.owner,
                        tenant: l.tenant,
                        address: l.address,
                        fullAddress: l.address,
                        postalCode: l.postalCode,
                        city: l.city,
                        residence: l.propertyName,
                        property: {
                            id: l.propertyId,
                            address: l.address,
                            city: l.city,
                            fullAddress: l.address,
                            name: l.propertyName,
                            postalCode: l.postalCode,
                        },
                        numberOfMeters: 0,
                        propertyId: '',
                    };
                })
        );
        this.dwellingFilterData = this.filterReferencesService.prepareDwellingData(
            this.dataLines
                .filter((l) => {
                    let result = true;
                    if (propertiesFilter && propertiesFilter.length > 0) {
                        result = result && propertiesFilter.includes(l.propertyId);
                    }
                    if (addressesFilter && addressesFilter.length > 0) {
                        result = result && addressesFilter.includes(l.dwellingId);
                    }
                    return result;
                })
                .map((l) => {
                    return {
                        id: l.dwellingId,
                        customerReference: l.dwellingCustomerReference,
                        istaReference: l.dwellingId,
                        address: l.address,
                        city: l.city,
                        fullAddress: l.address,
                        location: l.dwellingLocation,
                        owner: l.owner,
                        tenant: l.tenant,
                        postalCode: l.postalCode,
                        residence: l.propertyName,
                        propertyId: l.propertyId,
                    };
                })
        );
    }

    filterData(
        propertiesFilter: ReadonlyArray<string>,
        addressesFilter: ReadonlyArray<string>,
        dwellingsFilter: ReadonlyArray<string>,
        activityTypesFilter: ReadonlyArray<string>,
        statusesFilter: ReadonlyArray<string>,
        dateFilter: string | undefined
    ): ReadonlyArray<NewDwellingActivities> {
        let lines = this.dataLines;
        if (propertiesFilter && propertiesFilter.length > 0) {
            lines = lines.filter((l) => propertiesFilter.includes(l.propertyId));
        }
        if (addressesFilter && addressesFilter.length > 0) {
            lines = lines.filter((l) => addressesFilter.includes(l.dwellingId));
        }
        if (dwellingsFilter && dwellingsFilter.length > 0) {
            lines = lines.filter((l) => dwellingsFilter.includes(l.dwellingId));
        }
        if (activityTypesFilter && activityTypesFilter.length > 0) {
            lines = lines.filter((l) => activityTypesFilter.includes(l.type.toUpperCase()));
        }
        if (statusesFilter && statusesFilter.length > 0) {
            lines = lines.filter((l) => l.statuses.filter((x) => statusesFilter.includes(x.status)).length > 0);
        }
        if (dateFilter && dateFilter !== '') {
            lines = lines.filter((l) => l.lastSignalementCreationDate >= dateFilter);
        }
        return lines;
    }

    checkStatus(
        status: string,
        action: boolean,
        statusFilter: ReadonlyArray<string>,
        remainingInstallation: boolean
    ): boolean {
        let resp = false;
        statusFilter.forEach((filter) => {
            if (filter === ActivityStatus.TO_PLAN) {
                resp = status === 'TO_PLAN' && !action && !remainingInstallation ? true : resp;
            } else {
                resp = status === filter && !action ? true : resp;
            }
        });
        return resp;
    }

    exportDwellings(source: string): void {
        if (source === 'excel') {
            const customer = this.sStorage.getCustomer();
            const filters = this.gFiltres.getSelectedFilters();
            this.exportDwellingsInExcelFormat(customer, this.lines, filters);
        }
    }

    public exportDwellingsInExcelFormat(
        customer: Customer,
        dwellings: ReadonlyArray<NewDwellingActivities>,
        filters: ReadonlyArray<Reference>
    ): void {
        const filteredResidences: Array<string> = this.extractFilterValues(
            this.translate.instant('filters.' + FilterName.PROPERTIES),
            filters
        );
        const filteredDwellings: Array<string> = this.extractFilterValues(
            this.translate.instant('filters.' + FilterName.DWELLINGS),
            filters
        );
        const filteredStatus: Array<string> = this.extractFilterValues(
            this.translate.instant('filters.activity.' + FilterName.STATUSES),
            filters
        );
        const filteredDates: Array<string> = this.extractFilterValues(
            this.translate.instant('filters.activity.' + FilterName.DATES),
            filters
        );
        const filteredAdresses: Array<string> = this.extractFilterValues(
            this.translate.instant('filters.' + FilterName.ADDRESSES),
            filters
        );

        let defaultDate = this.datePipe.transform(this.globalVariables.activityLimitDate(), 'dd/MM/yyyy');
        defaultDate = defaultDate ?? '';
        let date: string = filteredDates.length > 0 ? filteredDates[0] : defaultDate;
        date = date.length > 0 ? dateUtils.formatDate(date, 'DD/MM/YYYY') : date;
        this.excelService.generateExcelFromDwellingsList(
            customer,
            dwellings,
            filteredResidences,
            filteredDwellings,
            filteredStatus,
            date,
            filteredAdresses
        );
    }

    statusComparator(
        propA: number,
        propB: number,
        rowA: ActivityDwellingDetails,
        rowB: ActivityDwellingDetails,
        sortDirection: string
    ): number {
        if (propA === 0 || propB === 0) {
            if (sortDirection === 'desc') {
                return propA === 0 ? -1 : 1;
            } else {
                return propA === 0 ? 1 : -1;
            }
        } else {
            return propA > propB ? -1 : propB > propA ? 1 : 0;
        }
    }

    statusToPlanComparator(
        propA: number,
        propB: number,
        rowA: ActivityDwellingDetails,
        rowB: ActivityDwellingDetails,
        sortDirection: string
    ): number {
        if (propA === 0 || propB === 0) {
            if (sortDirection === 'desc') {
                return propA === 0 ? -1 : 1;
            } else {
                return propA === 0 ? 1 : -1;
            }
        } else {
            const wAction = 10000;
            const wNoAction = 10;
            const a = rowA.status.nbItemsToPlanAction * wAction + rowA.status.nbItemsToPlanNoAction * wNoAction;
            const b = rowB.status.nbItemsToPlanAction * wAction + rowB.status.nbItemsToPlanNoAction * wNoAction;
            return a > b ? -1 : b > a ? 1 : 0;
        }
    }

    // As demanded by PO first Alphanumeric then Numbers
    ownerComparator(
        propA: string,
        propB: string,
        rowA: ActivityDwellingDetails,
        rowB: ActivityDwellingDetails,
        sortDirection: string
    ): number {
        const a = propA.replace(/^[^a-z]+/gi, '');
        const b = propB.replace(/^[^a-z]+/gi, '');
        if (propA.trim() === '' || propB.trim() === '') {
            if (sortDirection === 'desc') {
                return propA.trim() === '' ? -1 : 1;
            } else {
                return propA.trim() === '' ? 1 : -1;
            }
        } else {
            if (a === '' || b === '') {
                return a === '' ? 1 : -1;
            } else {
                return a > b ? 1 : b > a ? -1 : 0;
            }
        }
    }

    alphanumericComparator(
        propA: string,
        propB: string,
        rowA: ActivityDwellingDetails,
        rowB: ActivityDwellingDetails,
        sortDirection: string
    ): number {
        if (propA.trim() === '' || propB.trim() === '') {
            if (sortDirection === 'desc') {
                return propA.trim() === '' ? -1 : 1;
            } else {
                return propA.trim() === '' ? 1 : -1;
            }
        }
        const a = propA.toLocaleLowerCase().trim();
        const b = propB.toLocaleLowerCase().trim();
        const codeA = a.charCodeAt(0);
        const codeB = b.charCodeAt(0);
        if (sortDirection === 'desc') {
            if (codeA > 96) {
                return codeB > 96 ? (a > b ? 1 : b > a ? -1 : 0) : 1;
            } else {
                return codeB > 96 ? -1 : a > b ? 1 : b > a ? -1 : 0;
            }
        } else {
            if (codeA > 96) {
                return codeB > 96 ? (a > b ? 1 : b > a ? -1 : 0) : -1;
            } else {
                return codeB > 96 ? 1 : a > b ? 1 : b > a ? -1 : 0;
            }
        }
    }

    comparatorNotNull(
        propA: string,
        propB: string,
        rowA: ActivityDwellingDetails,
        rowB: ActivityDwellingDetails,
        sortDirection: string
    ): number {
        if (propA.trim() === '' || propB.trim() === '') {
            if (sortDirection === 'desc') {
                return propA.trim() === '' ? -1 : 1;
            } else {
                return propA.trim() === '' ? 1 : -1;
            }
        } else {
            const a = propA.toLocaleLowerCase().trim();
            const b = propB.toLocaleLowerCase().trim();
            return a > b ? 1 : b > a ? -1 : 0;
        }
    }

    comparatorNumericNotNull(
        propA: number,
        propB: number,
        rowA: ActivityDwellingDetails,
        rowB: ActivityDwellingDetails,
        sortDirection: string
    ): number {
        const a = Number(propA);
        const b = Number(propB);

        if (!Number.isInteger(a) || !Number.isInteger(b)) {
            if (sortDirection === 'desc') {
                return !Number.isInteger(a) ? -1 : 1;
            } else {
                return !Number.isInteger(b) ? 1 : -1;
            }
        } else {
            return a > b ? 1 : b > a ? -1 : 0;
        }
    }

    comparatorAdress(
        propA: string,
        propB: string,
        rowA: ActivityDwellingDetails,
        rowB: ActivityDwellingDetails,
        sortDirection: string
    ): number {
        if (propA.trim() === '' || propB.trim() === '') {
            if (sortDirection === 'desc') {
                return propA.trim() === '' ? -1 : 1;
            } else {
                return propA.trim() === '' ? 1 : -1;
            }
        } else {
            const a = propA.toLocaleLowerCase().trim();
            const b = propB.toLocaleLowerCase().trim();
            const reA = /[^a-zA-Z]/g;
            const reN = /\D/g;
            const aA = a.replace(reA, '');
            const bA = b.replace(reA, '');
            if (aA === bA) {
                let aN = parseInt(a.replace(reN, ''), 10);
                let bN = parseInt(b.replace(reN, ''), 10);
                aN = aN ? aN : 0;
                bN = bN ? bN : 0;
                return aN === bN ? 0 : aN > bN ? 1 : -1;
            } else {
                return aA > bA ? 1 : -1;
            }
        }
    }

    switchView(): void {
        sessionStorage.removeItem('dwelling-list-sorted-columns');
        sessionStorage.removeItem('dwelling-list-selected-row-info');
        this.sStorage.setDetailedActivitiesView(true);
        this.router.navigate([
            (this.isInternal.isInternalAccountType() ? '/interne' : '') +
                RedirectLandingPage.ACTIVITIES_DETAILED_LIST_PAGE.valueOf(),
        ]);
    }

    ngAfterViewChecked(): void {
        this.resized();
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        if (this.messagesSubscription) {
            this.messagesSubscription.unsubscribe();
        }
    }

    private extractFilterValues(filterName: string, filters: ReadonlyArray<Reference>): Array<string> {
        const values: Array<string> = [];
        filters
            .filter((filter) => filter.name.includes(filterName))
            .map((filter) => filter.ids)
            .forEach((filter) => filter.forEach((value) => values.push(value)));
        return values;
    }
}
