import { DatePipe } from '@angular/common';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, isDevMode, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Column } from '@core/model/column.model';
import { Property } from '@core/model/property.model';
import { MessageService } from '@core/service/message.service';
import { PropertyService } from "@core/service/property.service";
import { GlobalVariables } from '@core/util/global-variables';
import { TemplateComponent } from '@extern/components/template/template.component';
import { InstallationReport } from '@extern/worksites/model/installation-report.model';
import { Installation } from '@extern/worksites/model/installation.model';
import { MeterWorksiteOverview } from '@extern/worksites/model/worksite-overview.model';
import { WorksiteVisit } from '@extern/worksites/model/worksite-visit.model';
import { InstallationReportService } from '@extern/worksites/service/installation-report.service';
import { WorksiteVisitService } from '@extern/worksites/service/worksite-visit.service';
import { AuthorizationService, Customer, formatDate, Reference, SessionStorage, Slot } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { ProductsConstants } from '@shared/constants/products.const';
import { FilterName } from '@shared/enum/filter-name.enum';
import { StorageName } from '@shared/enum/storage-name.enum';
import { handlePropertySelection } from '@shared/service/property-selection.service';
import { firstValueFrom, Subject, Subscription } from 'rxjs';
import { FilterReferencesService } from 'src/app/core/service/filter-references.service';
import { GroupFiltersComponent } from 'src/app/shared/components/group-filters/group-filters.component';
import { ExcelInstallationService } from 'src/app/shared/service/excel-installation.service';

import { WorksiteDwellingStatus } from '../../enum/worksite-period-status.enum';
import { NewInstallationLine } from '../../model/new-installation-line.model';
import { WorksiteFilters } from '../../model/worksite-filters.model';
import { WorksiteSynthesisLine } from '../../model/worksite-synthesis-line.model';
import { Worksite } from '../../model/worksite.model';
import { InstallationComparatorService } from '../../service/installation.comparator.service';
import { OverviewMode, WorksiteDwellingService } from '../../service/worksite-dwelling.service';
import { WorksiteFilterSessionStorageService } from '../../service/worksite-filter-session-storage.service';
import { WorksiteSynthesisService } from '../../service/worksite-synthesis.service';

@Component({
    selector: 'app-installation',
    templateUrl: './installations.component.html',
    styleUrls: ['./installations.component.css'],
    providers: [DatePipe]
})
export class InstallationsComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
    message: any;
    messagesSubscription: Subscription;
    showSpinner = true;
    showEndDate = false;
    showGraphics = false;
    excellReady = false;
    worksiteLimitDate: Date;
    dateOneYearFromNow: Date;
    propertyList: ReadonlyArray<Property>;
    worksiteData: ReadonlyArray<NewInstallationLine>;
    metersData: ReadonlyArray<MeterWorksiteOverview>
    defaultDatePattern = 'DD/MM/YYYY';
    lines: ReadonlyArray<NewInstallationLine> = [];
    emptyList: ReadonlyArray<NewInstallationLine> = [];
    allLines: ReadonlyArray<NewInstallationLine> = [];
    @ViewChild('gFiltres') gFiltres: GroupFiltersComponent<WorksiteFilters>;
    writeDate = ['datatable.worksite.dates.from', 'datatable.worksite.dates.to'];
    propertyFilterData: ReadonlyArray<Reference>;
    addressFilterData: ReadonlyArray<Reference>;
    dwellingFilterData: ReadonlyArray<Reference>;
    fetchDataSubscription: Subscription;
    worksites: ReadonlyArray<Worksite> = [];
    worksitesIds: ReadonlyArray<string>;
    columnsFilterData: ReadonlyArray<Reference>;
    columns: Column[];
    columnsFiltred: Column[];
    filterIsActive = false;
    showFilter = true;
    listBlockHeight: number;
    eventsSubject: Subject<number> = new Subject<number>();
    @ViewChild('containersBlock', { static: false }) containersBlock: ElementRef;
    @ViewChild('filtersBlock', { static: false }) filtersBlock: ElementRef;

    fixedColumns = [
        { name: 'datatable.worksite.columns.propertyName', ids: ['propertyName'] },
        { name: 'datatable.worksite.columns.dwellingCustomerReference', ids: ['dwellingCustomerReference'] },
        { name: 'datatable.worksite.columns.product', ids: ['installations'] },
        { name: 'datatable.worksite.columns.dateLastVisit', ids: ['lastVisitDate'] },
        { name: 'datatable.worksite.columns.status', ids: ['dwellingStatus'] }
    ];

    constructor(
        public filterSessionStorage: WorksiteFilterSessionStorageService,
        public mainTemplate: TemplateComponent,
        private messageService: MessageService,
        private globalVariables: GlobalVariables,
        private propertyService: PropertyService,
        private datePipe: DatePipe,
        private filterReferencesService: FilterReferencesService,
        private synthesisService: WorksiteSynthesisService,
        private worksiteDwellingService: WorksiteDwellingService,
        private sStorage: SessionStorage,
        private excelInstallationService: ExcelInstallationService,
        private translate: TranslateService,
        private isInternal: AuthorizationService,
        private visitsService: WorksiteVisitService,
        private installationReportService: InstallationReportService,
        private changeDetectorRef: ChangeDetectorRef) {
        this.dateOneYearFromNow = this.globalVariables.worksiteDefaultDate();
    }

    ngAfterViewInit(): void {
        if (isDevMode()) {
            console.log('AfterViewInit=>', new Date());
        }
        this.changeDetectorRef.detectChanges();
    }

    ngAfterViewChecked() {
        this.resized();
        this.changeDetectorRef.detectChanges();
    }

    ngOnInit(): void {
        if (isDevMode()) {
            console.log('OnInit=>', new Date());
        }

        this.globalVariables.urlForCurrentView = [];
        this.translate.setDefaultLang('fr');
        this.testFilterEndDate();
        this.showSpinner = true;
        this.createColumns();
        this.initFilterColumns();
        this.worksiteLimitDate = this.globalVariables.worksiteDefaultDate();
        if (this.isInternal.isInternalAccountType()) {
            this.showGraphics = true;
            this.getProperties();
        } else {
            if (this.mainTemplate.setSelectionDisplayed()) {
                this.showGraphics = false;
            } else {
                this.showGraphics = true;
                this.getProperties();
            }
            this.messagesSubscription = this.messageService.getMessage().subscribe(message => {
                if (message) {
                    this.message = message;
                    if (this.message.text === 'refresh InstallationsComponent'
                        || this.message.text === 'btn-return') {
                        this.showGraphics = true;
                        this.getProperties();
                        this.messageService.clearMessage();
                    }
                }
            });
        }
        const matchMedia = window.matchMedia('(max-width: 1150px)');
        if (matchMedia.matches) {
            this.showFilter = false;
        }
    }

    checkFilters(): void {
        const filters = this.filterSessionStorage.getFilters();
        this.filterIsActive = !(
            !filters ||
            (filters &&
                filters.addresses.length === 0 &&
                filters.properties.length === 0 &&
                filters.dwellings.length === 0 &&
                filters.products.length === 0 &&
                filters.worksiteStatuses.length === 0 &&
                filters.statuses.length === 0
            )
        );
    }

    changeShowFilter(show: boolean): void {
        this.showEndDate = show;
    }

    testFilterEndDate(): void {
        const period = this.filterSessionStorage.getFiltersProperty<String>(FilterName.END_DATE);
        if (period !== undefined && period !== '') {
            this.showEndDate = true;
        }
    }

    initiFiltreDoubleDate(): void {
        const arrayDate = [this.dateOneYearFromNow, new Date()];
        const period = this.filterSessionStorage.getFiltersProperty<Slot>(FilterName.PERIODS);
        if (!period || period.start === '') {
            this.filterSessionStorage.setFiltersProperty<Slot>(FilterName.PERIODS, new Slot(arrayDate, this.datePipe));
        }
    }

    initFilterColumns() {
        const filterData = new Array<Reference>();
        this.columns.forEach(col => {
            filterData.push({ name: this.translate.instant(col.name), ids: [col.prop] })
        });
        this.columnsFilterData = filterData;
        const matchMedia = window.matchMedia('(max-width: 1150px)');
        if (matchMedia.matches) {
            this.filterSessionStorage.setFiltersProperty(FilterName.COLUMNS, this.fixedColumns);
            this.showFilter = false;
        } else {
            this.filterSessionStorage.setFiltersProperty(FilterName.COLUMNS, this.columnsFilterData);
        }
    }

    public transformDate(dateToTranform: string): string {
        return formatDate(dateToTranform, this.defaultDatePattern);
    }

    getProperties(): void {
        const list = this.isInternal.isExternalAccountType() ? [] :
            sessionStorage.getItem('internal_client_properties') ? sessionStorage.getItem('internal_client_properties')?.split(',') : [];
        this.propertyService.fetchByIds(list).subscribe((properties) => {
            this.propertyList = properties as Array<Property>;
            this.refreshData('');
        });
    }

    fetchData(
        viewType: OverviewMode,
        propertiesNumbers: ReadonlyArray<string>,
        date: string,
        remainingInstallations?: boolean): Promise<any> {
        this.lines = this.emptyList;
        return Promise.all([
            this.worksiteDwellingService.fetchDwellingsDetailsByView(viewType, propertiesNumbers, date, remainingInstallations).toPromise(),
            this.worksiteDwellingService.fetchDwellingAndMetersWorksiteOverview(OverviewMode.METER, date, propertiesNumbers)
        ]);
    }

    fetchVisitsData(worksites: ReadonlyArray<string>, properties: ReadonlyArray<string>): Promise<ReadonlyArray<WorksiteVisit>> {
        return firstValueFrom(this.visitsService.fetchByWorksiteId(worksites, properties));
    }

    refreshData(message: string): void {
        if (message !== 'filters.worksite.column') {
            const startDate = this.filterSessionStorage.getFiltersProperty<string>(FilterName.START_DATE);
            let limitDate = this.worksiteLimitDate.toISOString().split('T')[0];
            if (startDate === limitDate) {
                const strCurrentFilters = (this.sStorage.getItem('worksite_filters') || '').toString();
                if (strCurrentFilters !== '') {
                    const currentFilters = JSON.parse(strCurrentFilters);
                    currentFilters.startDate = '';
                    this.sStorage.setItem('worksite_filters', JSON.stringify(currentFilters));
                }
            }
            if (startDate && startDate !== '' && new Date(startDate) < new Date(limitDate)) {
                limitDate = startDate;
            }
            if (message === 'filters.properties' || message === 'filters.activity.startDate') {
                this.allLines = [];
            }
            const propertiesNumbers = this.getPropertieNumbers(this.filterSessionStorage.getFiltersPropertyIds(FilterName.PROPERTIES));
            if (!this.allLines || this.allLines.length === 0) {
                this.showSpinner = true;
                this.fetchData(OverviewMode.DWELLING, propertiesNumbers, limitDate).then(received => {
                    if (isDevMode()) {
                        console.log('ReceivedData=>', new Date());
                    }
                    this.allLines = received ? received[0] as Array<NewInstallationLine> : [];
                    this.worksitesIds = [...new Set(this.allLines.map(x => x.worksiteId))] as ReadonlyArray<string>
                    this.metersData = received[1] as MeterWorksiteOverview[];
                    if (isDevMode()) {
                        console.log('meters :>> ', this.metersData);
                    }
                    this.prepareList();
                }).then(() => {
                    this.completeExcelData();
                    this.showSpinner = false;
                    this.changeDetectorRef.detectChanges();
                    if (isDevMode()) {
                        console.log('AfterFilter=>', new Date());
                    }
                }).catch((e) => {
                    if (isDevMode()) {
                        console.log('error detected=> ', e);
                    }
                    this.allLines = [];
                    this.prepareList();
                    this.showSpinner = false;
                });
            } else {
                this.completeExcelData();
                if (isDevMode()) {
                    console.log('BeforeFilter=>', new Date());
                }
                this.prepareList();
                if (isDevMode()) {
                    console.log('AfterFilter=>', new Date());
                }
            }
        } else {
            this.prepareColumns();
        }
        this.checkFilters();
    }

    prepareColumns(): void {
        const columnsFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.COLUMNS);
        this.columnsFiltred = [...this.columns.filter(col => columnsFilter.includes(col.prop))];
        if (this.columnsFiltred.length === 0) {
            this.columnsFiltred = [...this.columns];
        }
    }

    sortDataRowsByDwellingStatus(dataRows: ReadonlyArray<NewInstallationLine>): ReadonlyArray<NewInstallationLine> {
        const statusOrder = [
            '' + WorksiteDwellingStatus.NOT_INSTALLED_YET,
            '' + WorksiteDwellingStatus.NOT_EQUIPABLE,
            '' + WorksiteDwellingStatus.FINISHED_REMAINING_INSTALLATION,
            '' + WorksiteDwellingStatus.FINISHED,
            '' + WorksiteDwellingStatus.IN_PROGRESS,
            '' + WorksiteDwellingStatus.PLANNED,
            '' + WorksiteDwellingStatus.TO_PLAN
        ];
        const outputRows = dataRows as Array<NewInstallationLine>;
        if (outputRows) {
            outputRows.sort((a, b) => {
                if (a.nextVisitDate && a.lastVisitDate && (new Date(a.nextVisitDate) <= new Date(a.lastVisitDate))) {
                    a.nextVisitDate = '';
                }
                // Compares by status
                if (statusOrder.indexOf(a.dwellingStatus) > statusOrder.indexOf(b.dwellingStatus)) {
                    return 1;
                } else if (statusOrder.indexOf(a.dwellingStatus) < statusOrder.indexOf(b.dwellingStatus)) {
                    return -1;
                    // Compares by visit number
                } else if (a.visitsCount < b.visitsCount) {
                    return 1;
                } else if (a.visitsCount > b.visitsCount) {
                    return -1;
                } else {
                    return 0;
                }
            });
        }
        return outputRows;
    }

    prepareList(): void {
        const propertiesFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.PROPERTIES);
        const addressesFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.ADDRESSES);
        const dwellingsFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.DWELLINGS);
        const products = this.filterSessionStorage.getFiltersPropertyIds(FilterName.PRODUCTS);
        const statuses = this.filterSessionStorage.getFiltersPropertyIds(FilterName.STATUSES);
        const worksiteStatuses = this.filterSessionStorage.getFiltersPropertyIds(FilterName.WORKSITE_STATUSES);
        const startDate = this.filterSessionStorage.getFiltersProperty<string>(FilterName.START_DATE);
        const endDate = this.filterSessionStorage.getFiltersProperty<string>(FilterName.END_DATE);
        this.prepareFiltersData(propertiesFilter, addressesFilter);
        const linesFiltered = this.applyFilters(
            propertiesFilter,
            addressesFilter,
            dwellingsFilter,
            products,
            statuses,
            worksiteStatuses,
            startDate,
            endDate);
        this.lines = this.sortDataRowsByDwellingStatus(linesFiltered);
        this.prepareColumns();
    }

    prepareFiltersData(propertiesFilter: ReadonlyArray<string>, addressesFilter: ReadonlyArray<string>): void {
        const properties = this.propertyList;
        this.propertyFilterData = this.filterReferencesService.preparePropertyData(
            properties
                .filter(p => addressesFilter && addressesFilter.length > 0 ?
                    addressesFilter.map(id => id.substring(0, 8)).includes(p.id) :
                    true));
        const allLines = this.allLines;
        this.addressFilterData = this.filterReferencesService.prepareAddressData(
            allLines
                .filter(l => propertiesFilter && propertiesFilter.length > 0 ? propertiesFilter.includes(l.propertyId) : true)
                .map(l => {
                    return {
                        id: l.dwellingId,
                        customerReference: l.dwellingCustomerReference,
                        istaReference: l.dwellingId,
                        location: l.dwellingLocation,
                        owner: l.owner,
                        tenant: l.tenant,
                        address: l.address,
                        fullAddress: l.address,
                        postalCode: l.zip,
                        city: l.city,
                        residence: l.propertyName,
                        property: {
                            id: l.propertyId,
                            address: l.address,
                            city: l.city,
                            fullAddress: l.address,
                            name: l.propertyName,
                            postalCode: l.zip
                        },
                        numberOfMeters: 0,
                        propertyId: ''
                    };
                }));
        this.dwellingFilterData = this.filterReferencesService.prepareDwellingData(
            allLines
                .filter(l => {
                    let result = true;
                    if (propertiesFilter && propertiesFilter.length > 0) {
                        result = result && propertiesFilter.includes(l.propertyId);
                    }
                    if (addressesFilter && addressesFilter.length > 0) {
                        result = result && addressesFilter.includes(l.dwellingId);
                    }
                    return result;
                })
                .map(l => {
                    return {
                        id: l.dwellingId,
                        customerReference: l.dwellingCustomerReference,
                        istaReference: l.dwellingId,
                        address: l.address,
                        city: l.city,
                        fullAddress: l.address,
                        location: l.dwellingLocation,
                        owner: l.owner,
                        tenant: l.tenant,
                        postalCode: l.zip,
                        residence: l.propertyName,
                        propertyId: l.propertyId
                    }
                })
        );
    }

    exportList(source: string): void {
        if (isDevMode()) {
            console.log('export List installations');
        }
        if (source === 'excel') {
            this.excellReady = false;
            this.changeDetectorRef.detectChanges();
            // Asynchronously runs the Excel export after 500ms
            setTimeout(() => {
                let rowsWorksiteSynthesisLine: Array<WorksiteSynthesisLine> = [];
                if (this.lines.length > 0) {
                    rowsWorksiteSynthesisLine = this.synthesisService.getSynthesisLineFronDwelling(this.lines)
                        .sort((propA, propB) => {
                            if (!propA.worksitePeriodStart || !propB.worksitePeriodStart) {
                                return -1;
                            } else if (propA.worksitePeriodStart.trim() === '' || propB.worksitePeriodStart.trim() === '') {
                                return propA.worksitePeriodStart.trim() === '' ? -1 : 1;
                            } else {
                                const a = new Date(propA.worksitePeriodStart.split(' ')[0].split('/').reverse().join('-'));
                                const b = new Date(propB.worksitePeriodStart.split(' ')[0].split('/').reverse().join('-'));
                                return (a > b) ? 1 : ((b > a) ? -1 : 0);
                            }
                        });
                }
                const customer = this.sStorage.getCustomer();
                this.exportInstallationsInExcelFormat(customer, this.lines, rowsWorksiteSynthesisLine);
                // Asynchronously hides the spinner after X milliseconds of downloading
                setTimeout(() => {
                    this.excellReady = true;
                    this.changeDetectorRef.detectChanges();
                }, this.lines.length * 0.2);
            }, 500);
        }
    }

    getPropertieNumbers(filters: readonly string[]): ReadonlyArray<string> {
        let propertiesNumbers: ReadonlyArray<string> = [];
        propertiesNumbers = handlePropertySelection(this.filterSessionStorage,
            StorageName.WORKSITE_STORAGE_NAME, this.isInternal.isInternalAccountType());
        if (this.isInternal.isInternalAccountType() && propertiesNumbers.length === 0) {
            propertiesNumbers = filters.length > 0 ? filters : sessionStorage.getItem('internal_client_properties') ?
                sessionStorage.getItem('internal_client_properties')?.split(',') as ReadonlyArray<string> : [];
        }
        return propertiesNumbers;
    }

    completeExcelData(): void {
        let propertiesNumbers = this.getPropertieNumbers(this.filterSessionStorage.getFiltersPropertyIds(FilterName.PROPERTIES));
        this.fetchVisitsData(this.worksitesIds, propertiesNumbers).then(wks => {
            let visits: WorksiteVisit[];
            let finalVisits: WorksiteVisit[];
            this.lines.forEach(l => {
                finalVisits = [];
                visits = [];
                let statingVisits = wks;
                let ptcNumbers = l.installations.map(x => x.id);
                visits = statingVisits.filter(x => x.worksiteId === l.worksiteId && x.dwellingsIds.includes(l.dwellingId));
                visits.forEach((x, index) => {
                    const visitActuel = {
                        id: x.id,
                        worksiteId: x.worksiteId,
                        periodId: x.periodId,
                        status: x.status,
                        comment: x.comment,
                        propertiesIds: x.propertiesIds,
                        dwellingsIds: x.dwellingsIds.filter(id => id === l.dwellingId),
                        installationsIds: x.installationsIds.filter(ids => ptcNumbers.includes(ids)),
                        installationsStatuses: x.installationsStatuses.filter(status => ptcNumbers.includes(status.installationId)),
                        planificationPeriod: x.planificationPeriod,
                        accomplishmentDate: x.accomplishmentDate,
                        number: x.number,
                        planificationDate: x.planificationDate,
                        activityId: x.activityId,
                        notInstalledLabel: x.notInstalledLabel,
                        notInstalledCode: x.notInstalledCode,
                        reason: x.reason,
                        reasonCode: x.reasonCode,
                        customerFault: x.customerFault
                    };
                    finalVisits.push(visitActuel);

                });
                l.visits = finalVisits;
            });
            console.log(this.lines);
            console.log('installations :>> ', this.lines.flatMap(l => l.installations));
        }).then(() => {
            this.excellReady = true;
        });
    }

    public exportInstallationsInExcelFormat(
        customer: Customer,
        dwellingWorksite: ReadonlyArray<NewInstallationLine>,
        rowsWorksiteSynthesisLine: ReadonlyArray<WorksiteSynthesisLine>): void {
        const filteredResidences = this.gFiltres.getFilterSelectedValues(this.translate.instant('filters.' + FilterName.PROPERTIES));
        const filteredDwellings = this.gFiltres.getFilterSelectedValues(this.translate.instant('filters.' + FilterName.DWELLINGS));
        const filteredAppareils = this.gFiltres.getFilterSelectedValues(this.translate.instant('filters.worksite.' + FilterName.PRODUCTS));
        const filteredAddresses = this.gFiltres.getFilterSelectedValues(this.translate.instant('filters.' + FilterName.ADDRESSES));
        const filteredStatusLogement = this.gFiltres.getFilterSelectedValues(
            this.translate.instant('filters.worksite.' + FilterName.STATUSES)
        );
        const filteredStatusChantier = this.gFiltres.getFilterSelectedValues(
            this.translate.instant('filters.worksite.' + FilterName.WORKSITE_STATUSES)
        );
        const datesPassages: string = this.extractFilterPeriodValues();
        this.excelInstallationService.generateExcelFromInstallationsList(
            customer, dwellingWorksite, rowsWorksiteSynthesisLine,
            filteredResidences, filteredAddresses, filteredDwellings,
            filteredAppareils, filteredStatusLogement,
            filteredStatusChantier, datesPassages);
    }

    ngOnDestroy(): void {
        if (this.messagesSubscription) {
            this.messagesSubscription.unsubscribe();
        }
    }

    createColumns(): void {
        this.columns = [
            new Column('datatable.worksite.columns.propertyName',
                'propertyName', true, 80, InstallationComparatorService.comparatorNotNull.bind(this)),
            new Column('datatable.worksite.columns.propertyAddress',
                'address', true, 150, InstallationComparatorService.adressComparator.bind(this)),
            new Column('datatable.worksite.columns.dwellingCustomerReference',
                'dwellingCustomerReference', true, 80, InstallationComparatorService.alphanumericComparator.bind(this)),
            new Column('datatable.worksite.columns.owner',
                'owner', true, 120, InstallationComparatorService.alphaComparator.bind(this)),
            new Column('datatable.worksite.columns.tenant',
                'tenant', true, 100, InstallationComparatorService.alphaComparator.bind(this)),
            new Column('datatable.worksite.columns.product',
                'productCode', true, 120, InstallationComparatorService.productsComparator.bind(this)),
            new Column('datatable.worksite.columns.visits',
                'visitsCount', true, 120),
            new Column('datatable.worksite.columns.dateLastVisit',
                'lastVisitDate', true, 150, InstallationComparatorService.datesComparator.bind(this)),
            new Column('datatable.worksite.columns.resultLastVisit', 'installations', false),
            new Column('datatable.worksite.columns.nextVisit',
                'nextVisitDate', true, 120, InstallationComparatorService.datesComparator.bind(this)),
            new Column('datatable.worksite.columns.document', 'document', false, 80),
            new Column('datatable.worksite.columns.status', 'dwellingStatus', true, 140, InstallationComparatorService.statusComparator.bind(this))
        ];
    }

    resized(): void {
        this.listBlockHeight = this.containersBlock.nativeElement.offsetHeight - this.filtersBlock.nativeElement.offsetHeight;
        this.eventsSubject.next(this.listBlockHeight);
    }

    handleResize(event: ResizeObserverEntry): void {
        this.listBlockHeight = event.contentRect.height;
        this.eventsSubject.next(this.listBlockHeight);
        this.initFilterColumns();
        this.prepareColumns();
        if (event.contentRect.width > 1059) {
            this.showFilter = true;
        }
    }

    toggleFilter(): void {
        this.showFilter = !this.showFilter;
        this.resized();
    }

    showFilterTooltip(): string {
        return this.showFilter ? this.translate.instant('filters.showFilters') : this.translate.instant('filters.hideFilters');
    }

    private applyFilters(
        propertiesFilter: ReadonlyArray<string>,
        addressesFilter: ReadonlyArray<string>,
        dwellingsFilter: ReadonlyArray<string>,
        products: ReadonlyArray<string>,
        statuses: ReadonlyArray<string>,
        worksiteStatuses: ReadonlyArray<string>,
        startDate: string | undefined,
        endDate: string | undefined): ReadonlyArray<NewInstallationLine> {
        let lines = this.allLines;
        if (propertiesFilter && propertiesFilter.length > 0) {
            lines = lines.filter(l => propertiesFilter.includes(l.propertyId));
        }
        if (addressesFilter && addressesFilter.length > 0) {
            lines = lines.filter(l => addressesFilter.includes(l.dwellingId));
        }
        if (dwellingsFilter && dwellingsFilter.length > 0) {
            lines = lines.filter(l => dwellingsFilter.includes(l.dwellingId));
        }
        if (products && products.length > 0) {
            lines = lines.filter(l => products.includes(ProductsConstants.getMeterType(l.productCode, l.fluidCode)));
        }
        if (worksiteStatuses && worksiteStatuses.length > 0) {
            lines = lines.filter(l => worksiteStatuses.includes(l.worksiteStatus));
        }
        if (statuses && statuses.length > 0) {
            lines = lines.filter(l => statuses.includes(l.dwellingStatus));
        }
        if (startDate && startDate !== '') {
            lines = lines.filter(l => {
                if (!l.visitsStartDate || (l.visitsStartDate && (l.visitsStartDate !== '') && (startDate <= l.visitsStartDate))
                    || ((!l.visitsStartDate || l.visitsStartDate === '') && (l.nextVisitDate && l.nextVisitDate !== '') && (startDate <= l.nextVisitDate))) {
                    return true;
                } else return false;
            });
        }
        if (endDate && endDate !== '') {
            lines = lines.filter(l => {
                if ((l.visitsEndDate && (l.visitsEndDate !== '') && (endDate >= l.visitsEndDate))
                    || ((!l.visitsEndDate || l.visitsEndDate === '') && (l.nextVisitDate && l.nextVisitDate !== '') && (endDate >= l.nextVisitDate))) {
                    return true;
                } else return false;
            });
        }
        const filteredLines: NewInstallationLine[] = [];
        lines.forEach(line => {
            if (line.dwellingStatus === WorksiteDwellingStatus.PLANNED
                && (!line.visitsStartDate || line.visitsStartDate === '')) {
                line.visitsStartDate = line.nextVisitDate;
            }
            if (line.report === undefined) {
                (line as any).report = <InstallationReport>{};
                (line.report as any).available = false;
            }
            line.report.available = this.installationReportService.hasRelevantStatusForReport(line);
            if (filteredLines.length === 0 || filteredLines.filter(l =>
                l.worksiteId === line.worksiteId
                && l.dwellingId === line.dwellingId
                && l.productCode === line.productCode
                && l.fluidCode === line.fluidCode).length === 0) {
                const installations: Installation[] = [];
                line.installations.forEach(i => {
                    if (installations.length === 0 || installations.filter(l =>
                        l.meterId === i.meterId).length === 0) {
                        i.meterStatus = this.metersData.find(m => m.meterId === i.id)?.meterStatus ?? '';
                        installations.push(i);
                    }
                });
                line.installations = installations;
                line.installations = line.installations.map(i => {
                    if (!i.serialNumber || i.serialNumber === '') i.installed = false;
                    return i;
                });
                filteredLines.push(line);
            }
        });

        this.installationReportService.setDisplayForDownloadedReports(filteredLines);
        return filteredLines;
    }

    private extractFilterPeriodValues(): string {
        const filteredDatesPassages: Slot | undefined = this.filterSessionStorage.getFiltersProperty<Slot>('period');
        if (filteredDatesPassages && filteredDatesPassages.start !== '' && filteredDatesPassages.end !== '') {
            return `DU ${formatDate(filteredDatesPassages.start, 'DD/MM/YYYY')} AU ${formatDate(filteredDatesPassages.end, 'DD/MM/YYYY')}`;
        } else {
            const datepipe: DatePipe = new DatePipe('en-US');
            const formattedDate = datepipe.transform(this.worksiteLimitDate, 'dd/MM/YYYY');
            return 'DU ' + formattedDate + ' à aujourd\'hui';
        }
    }
}

