import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-ista-card',
    templateUrl: './ista-card.component.html',
    styleUrls: ['./ista-card.component.css'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class IstaCardComponent implements OnInit {
    @Input() title: string;
    @Input() imgPath: string;
    @Input() srcPath: string;
    @Input() routePath: string;

    constructor(
        private rooter: Router,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.translate.get([this.title]).subscribe((translation) => {
            this.title = translation[this.title];
        });
    }

    redirectTo() {
        this.rooter.navigateByUrl(this.routePath);
    }
}
