import { Filters, Reference } from '@ista/shared-ui';

export class UexFilters extends Filters {
    properties: ReadonlyArray<Reference>;

    public constructor() {
        super();
        this.properties = [];
    }
}
