import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, isDevMode} from '@angular/core';
import {BaseService, CONTEXT_API, Customer} from '@ista/shared-ui';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Uex} from 'src/app/core/model/uex.model';

@Injectable({
    providedIn: 'root'
})
export class CustomerService implements BaseService<Customer> {

    baseUrl: string;

    constructor(private httpClient: HttpClient, @Inject(CONTEXT_API) private contextApi: string) {
        this.baseUrl = `${this.contextApi}/customers`;
    }

    fetch(): Observable<ReadonlyArray<Customer>> {
        return this.httpClient.get<ReadonlyArray<Customer>>(this.baseUrl).pipe(
            map(customers => this.truncateCustomersNumbers(customers))
        );
    }

    fetchById(id: string): Observable<Customer> {
        const url = `${this.baseUrl}/80${id}`;
        if (isDevMode()) {
            console.log('Using url to fetch customer informations : ' + url);
        }
        return this.httpClient.get<ReadonlyArray<Customer>>(url).pipe(
            map(customers => customers[0]),
            map(customer => this.truncateCustomerNumber(customer))
        );
    }

    fetchUexByCustomerIds(customersIds: ReadonlyArray<string>): Observable<ReadonlyArray<Uex>> {
        const url = `${this.baseUrl}/${customersIds.map(cs => '80' + cs).join(',')}/uex`;
        if (isDevMode()) {
            console.log('Using url to fetch customer uexs : ' + url);
        }
        return this.httpClient.get<ReadonlyArray<Uex>>(url);
    }

    private truncateCustomersNumbers(customers: ReadonlyArray<Customer>): ReadonlyArray<Customer> {
        return customers.map(customer => this.truncateCustomerNumber(customer));
    }

    private truncateCustomerNumber(customer: Customer): Customer {
        if (customer.number.length > 7) {
            customer.number = customer.number.substring(2, 9);
        }
        return customer;
    }
}
