import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NewInstallationLine } from '@extern/worksites/model/new-installation-line.model';
import { Customer } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { ProductsConstants } from '@shared/constants/products.const';
import { ListUtilService } from '@shared/util/list-util.service';
import { Row, Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';

import { WorksiteDwellingStatus } from '../../features/extern/worksites/enum/worksite-period-status.enum';
import { Installation } from '../../features/extern/worksites/model/installation.model';
import { WorksiteSynthesisLine } from '../../features/extern/worksites/model/worksite-synthesis-line.model';
import * as excelUtil from '../../shared/util/excel-util';

/**
 * @see https://github.com/exceljs/exceljs
 * @see https://www.ngdevelop.tech/export-to-excel-in-angular-6/
 * @version exceljs 1.14.0
 */

@Injectable({
    providedIn: 'root',
})
export class ExcelInstallationService {
    fluidType = [
        { type: 'compteurs EC', fluid: 'EC' },
        { type: 'compteurs EF', fluid: 'EF' },
    ];

    constructor(
        @Inject(TranslateService) private translate: TranslateService,
        private listUtil: ListUtilService,
        private datePipe: DatePipe
    ) {}

    public generateExcelFromInstallationsList(
        customer: Customer,
        data: ReadonlyArray<NewInstallationLine>,
        dataSummary: ReadonlyArray<WorksiteSynthesisLine>,
        filteredResidences: ReadonlyArray<string>,
        filteredAddresses: ReadonlyArray<string>,
        filteredDwellings: ReadonlyArray<string>,
        filteredAppareils: ReadonlyArray<string>,
        filteredStatusLogement: ReadonlyArray<string>,
        filteredStatusChantier: ReadonlyArray<string>,
        datePassages: string
    ): void {
        // Create the workbook
        const workbook: Workbook = new Workbook();

        // Create the Help Worksheet
        this.createHelpWorksheet(workbook);

        // Create the Synthesis Worksheet
        this.createSummaryWorksheet(workbook, customer, dataSummary);

        // Create the Details Worksheet
        this.createDetailsWorksheet(
            workbook,
            customer,
            data,
            filteredResidences,
            filteredAddresses,
            filteredDwellings,
            filteredAppareils,
            filteredStatusLogement,
            filteredStatusChantier,
            datePassages
        );

        // export file
        const filename = `${moment().format('DD_MM_YYYY')}_${this.translate.instant('excel.installations.filename')}_${customer.name}.xlsx`;

        this.setDefaultOpenedsheets(workbook, 1);

        this.downloadWorkbook(workbook, filename);
    }

    private setDefaultOpenedsheets(workbook: Workbook, activeSheetPosition: number) {
        workbook.views = [
            {
                x: 0,
                y: 0,
                width: 10000,
                height: 20000,
                firstSheet: 0,
                activeTab: activeSheetPosition,
                visibility: 'visible',
            },
        ];
    }

    private createWorksheet(
        workbook: Workbook,
        name: string,
        orientation: 'portrait' | 'landscape',
        rows: number
    ): Worksheet {
        return workbook.addWorksheet(name, excelUtil.getWorksheetOptions(orientation, rows));
    }

    private downloadWorkbook(workbook: Workbook, filename: string): void {
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            saveAs(blob, filename);
        });
    }

    private createDetailsWorksheet(
        workbook: Workbook,
        customer: Customer,
        data: ReadonlyArray<NewInstallationLine>,
        filteredResidences: ReadonlyArray<string>,
        filteredAddresses: ReadonlyArray<string>,
        filteredDwellings: ReadonlyArray<string>,
        filteredAppareils: ReadonlyArray<string>,
        filteredStatusLogement: ReadonlyArray<string>,
        filteredStatusChantier: ReadonlyArray<string>,
        datePassages: string
    ): void {
        const worksheetName = `${this.translate.instant('excel.installations.worksheet.names.main')}`;
        // Define static data
        const title = this.translate.instant('excel.installations.title');
        const columns = [
            this.translate.instant('excel.installations.columns.propertyNumber'),
            this.translate.instant('excel.installations.columns.dwellingIstaReference'),
            this.translate.instant('excel.installations.columns.siteNumber'),
            this.translate.instant('excel.installations.columns.pointCountReference'),
            this.translate.instant('excel.installations.columns.propertyName'),
            this.translate.instant('excel.installations.columns.dwellingCustomerReference'),
            this.translate.instant('excel.installations.columns.propertyAddress'),
            this.translate.instant('excel.installations.columns.city'),
            this.translate.instant('excel.installations.columns.propertyLocation'),
            this.translate.instant('excel.installations.columns.owner'),
            this.translate.instant('excel.installations.columns.tenant'),
            this.translate.instant('excel.installations.columns.deviceLocation'),
            this.translate.instant('excel.installations.columns.deviceType'),
            this.translate.instant('excel.installations.columns.fluid'),
            this.translate.instant('excel.installations.columns.status'),
            this.translate.instant('excel.installations.columns.complStatus'),
            this.translate.instant('excel.installations.columns.datePose'),
            this.translate.instant('excel.installations.columns.numNewDevice'),
            this.translate.instant('excel.installations.columns.numOldDevice'),
            this.translate.instant('excel.installations.columns.indexToDate'),
            this.translate.instant('excel.installations.columns.numPerformedPassages'),
            this.translate.instant('excel.installations.columns.lastPassageDate'),
            this.translate.instant('excel.installations.columns.reasonNotPoseLastPassage'),
            this.translate.instant('excel.installations.columns.nextPassageDate'),
            this.translate.instant('excel.installations.columns.datePassage1'),
            this.translate.instant('excel.installations.columns.reasonNotPosePassage1'),
            this.translate.instant('excel.installations.columns.datePassage2'),
            this.translate.instant('excel.installations.columns.reasonNotPosePassage2'),
            this.translate.instant('excel.installations.columns.datePassage3'),
            this.translate.instant('excel.installations.columns.reasonNotPosePassage3'),
            this.translate.instant('excel.installations.columns.datePassage4'),
            this.translate.instant('excel.installations.columns.reasonNotPosePassage4'),
            this.translate.instant('excel.installations.columns.datePassage5'),
            this.translate.instant('excel.installations.columns.reasonNotPosePassage5'),
            this.translate.instant('excel.installations.columns.datePassage6'),
            this.translate.instant('excel.installations.columns.reasonNotPosePassage6'),
            this.translate.instant('excel.installations.columns.datePassage7'),
            this.translate.instant('excel.installations.columns.reasonNotPosePassage7'),
            this.translate.instant('excel.installations.columns.datePassage8'),
            this.translate.instant('excel.installations.columns.reasonNotPosePassage8'),
        ];

        const columnComplement = 0.78; // https://github.com/exceljs/exceljs/issues/744

        //max width for columns
        const maxWidthAdress: number = this.listUtil.getListElementMaxPositifLength(
            data.map((i) => i.address),
            25 + columnComplement
        );
        const maxWidthCity: number = this.listUtil.getListElementMaxPositifLength(
            data.map((i) => i.city),
            18 + columnComplement
        );
        const maxWidthProperty: number = this.listUtil.getListElementMaxPositifLength(
            data.map((i) => i.propertyName),
            18 + columnComplement
        );
        const maxWidthCustomerReference: number = this.listUtil.getListElementMaxPositifLength(
            data.map((i) => i.dwellingCustomerReference),
            18 + columnComplement
        );

        const worksheetColumns = [
            { key: 'A', width: 22 + columnComplement },
            { key: 'B', width: 22 + columnComplement },
            { key: 'C', width: 18 + columnComplement },
            { key: 'D', width: 18 + columnComplement },
            { key: 'E', width: maxWidthProperty + columnComplement },
            { key: 'F', width: maxWidthCustomerReference + columnComplement },
            { key: 'G', width: maxWidthAdress + columnComplement },
            { key: 'H', width: maxWidthCity + columnComplement },
            { key: 'I', width: 17 + columnComplement },
            { key: 'J', width: 22 + columnComplement },
            { key: 'K', width: 17 + columnComplement },
            { key: 'L', width: 22 + columnComplement },
            { key: 'M', width: 17 + columnComplement },
            { key: 'N', width: 17 + columnComplement },
            { key: 'O', width: 24 + columnComplement },
            { key: 'P', width: 22 + columnComplement },
            { key: 'Q', width: 17 + columnComplement },
            { key: 'R', width: 17 + columnComplement },
            { key: 'S', width: 17 + columnComplement },
            { key: 'T', width: 17 + columnComplement },
            { key: 'U', width: 17 + columnComplement },
            { key: 'V', width: 17 + columnComplement },
            { key: 'W', width: 25 + columnComplement },
            { key: 'X', width: 17 + columnComplement },
            { key: 'Y', width: 17 + columnComplement },
            { key: 'Z', width: 25 + columnComplement },
            { key: 'AA', width: 17 + columnComplement },
            { key: 'AB', width: 25 + columnComplement },
            { key: 'AC', width: 17 + columnComplement },
            { key: 'AD', width: 25 + columnComplement },
            { key: 'AE', width: 17 + columnComplement },
            { key: 'AF', width: 25 + columnComplement },
            { key: 'AG', width: 17 + columnComplement },
            { key: 'AH', width: 25 + columnComplement },
            { key: 'AI', width: 17 + columnComplement },
            { key: 'AJ', width: 25 + columnComplement },
            { key: 'AK', width: 17 + columnComplement },
            { key: 'AL', width: 25 + columnComplement },
            { key: 'AM', width: 17 + columnComplement },
            { key: 'AN', width: 25 + columnComplement },
        ];
        // From the workbook, create a new sheet
        const worksheet: Worksheet = this.createWorksheet(
            workbook,
            worksheetName,
            'landscape',
            data.length + excelUtil.headerinstallationslines
        );
        // Define worksheet's columns
        worksheet.columns = worksheetColumns;

        const maxLinesForSelectedFilters = 5;
        const showSelectedFilters: boolean =
            filteredResidences.length <= maxLinesForSelectedFilters &&
            filteredAddresses.length <= maxLinesForSelectedFilters &&
            filteredDwellings.length <= maxLinesForSelectedFilters &&
            filteredAppareils.length <= maxLinesForSelectedFilters &&
            filteredStatusLogement.length <= maxLinesForSelectedFilters &&
            filteredStatusChantier.length <= maxLinesForSelectedFilters;

        // insert data...
        worksheet.addRow([title]);
        worksheet.addRow([]);
        worksheet.addRow([]);
        worksheet.addRow([]);

        worksheet.addRow([
            `${this.translate.instant('excel.installations.headers.intendedFor')} :`,
            null,
            null,
            null,
            `${this.translate.instant('excel.installations.headers.appliedFilters')} : ${this.translate.instant('excel.installations.headers.appliedFiltersDescription')}`,
        ]);

        worksheet.addRow([
            customer.name,
            null,
            null,
            null,
            `${this.translate.instant('excel.installations.headers.properties')}: ${filteredResidences.length === 0 ? 'toutes' : ''}`,
            null,
            `${this.translate.instant('excel.installations.headers.addresses')}: ${filteredAddresses.length === 0 ? 'toutes' : ''}`,
            null,
            `${this.translate.instant('excel.installations.headers.dwellings')}: ${filteredDwellings.length === 0 ? 'tous' : ''}`,
            null,
            `${this.translate.instant('excel.installations.headers.typeAppareils')}: ${filteredAppareils.length === 0 ? 'tous' : ''}`,
            null,
            `${this.translate.instant('excel.installations.headers.statusChantiers')}: ${filteredStatusChantier.length === 0 ? 'tous' : ''}`,
            null,
            `${this.translate.instant('excel.installations.headers.statusDwellings')}: ${filteredStatusLogement.length === 0 ? 'tous' : ''}`,
            null,
            `${this.translate.instant('excel.installations.headers.datePassages')}:`,
            null,
        ]);

        worksheet.addRow([customer.address, null, null, null, null, null, null, null, null, null]);
        const city: string = new Array(customer.postalCode, customer.city).join(' ');
        worksheet.addRow([city]);

        worksheet.addRow([]);
        worksheet.addRow([]);
        worksheet.addRow([]);
        worksheet.addRow([]);

        // Insert filters values
        const filterValueStartIndex = 7;
        const minLineNumberForHeaderBlocks = 6;
        let maxLineNumberForHeaderBlocks = minLineNumberForHeaderBlocks;
        if (showSelectedFilters) {
            //fill with empty char to prevent text overflow
            const nbResidences = filteredResidences.length;
            const nbAddress = filteredAddresses.length;
            const nbDwellings = filteredDwellings.length;
            const nbAppareils = filteredAppareils.length;
            const nbStatusWorksites = filteredStatusChantier.length;
            const nbStatusDwellings = filteredStatusLogement.length;

            const maxLines: number = Math.max(
                nbDwellings,
                nbResidences,
                nbAppareils,
                nbAddress,
                nbStatusWorksites,
                nbStatusDwellings
            );

            for (let i = filterValueStartIndex; i < maxLines + filterValueStartIndex; i++) {
                worksheet.getCell(`E${i}`).value = '';
                worksheet.getCell(`G${i}`).value = '';
                worksheet.getCell(`I${i}`).value = '';
                worksheet.getCell(`K${i}`).value = '';
                worksheet.getCell(`M${i}`).value = '';
                worksheet.getCell(`O${i}`).value = '';
            }
            //end empty fill

            filteredResidences.forEach((value, index, array) => {
                const cellNumber = filterValueStartIndex + index;
                worksheet.getCell(`E${cellNumber}`).value = value;
                maxLineNumberForHeaderBlocks =
                    cellNumber > maxLineNumberForHeaderBlocks ? cellNumber : maxLineNumberForHeaderBlocks;
            });

            filteredAddresses.forEach((value, index, array) => {
                const cellNumber = filterValueStartIndex + index;
                worksheet.getCell(`G${cellNumber}`).value = value;
                maxLineNumberForHeaderBlocks =
                    cellNumber > maxLineNumberForHeaderBlocks ? cellNumber : maxLineNumberForHeaderBlocks;
            });

            filteredDwellings.forEach((value, index, array) => {
                const cellNumber = filterValueStartIndex + index;
                worksheet.getCell(`I${cellNumber}`).value = value;
                maxLineNumberForHeaderBlocks =
                    cellNumber > maxLineNumberForHeaderBlocks ? cellNumber : maxLineNumberForHeaderBlocks;
            });
            filteredAppareils.forEach((value, index, array) => {
                const cellNumber = filterValueStartIndex + index;
                worksheet.getCell(`K${cellNumber}`).value = value;
                maxLineNumberForHeaderBlocks =
                    cellNumber > maxLineNumberForHeaderBlocks ? cellNumber : maxLineNumberForHeaderBlocks;
            });
            filteredStatusChantier.forEach((value, index, array) => {
                const cellNumber = filterValueStartIndex + index;
                worksheet.getCell(`M${cellNumber}`).value = value;
                maxLineNumberForHeaderBlocks =
                    cellNumber > maxLineNumberForHeaderBlocks ? cellNumber : maxLineNumberForHeaderBlocks;
            });
            filteredStatusLogement.forEach((value, index, array) => {
                const cellNumber = filterValueStartIndex + index;
                worksheet.getCell(`O${cellNumber}`).value = value;
                maxLineNumberForHeaderBlocks =
                    cellNumber > maxLineNumberForHeaderBlocks ? cellNumber : maxLineNumberForHeaderBlocks;
            });

            worksheet.getCell(`Q${filterValueStartIndex}`).value = datePassages;
        } else {
            const filterline = 6;
            worksheet.getCell('E' + filterline).value = worksheet
                .getCell('E' + filterline)
                .text.concat(' ', filteredResidences.length > 0 ? filteredResidences.length.toString() : '');
            worksheet.getCell('G' + filterline).value = worksheet
                .getCell('G' + filterline)
                .text.concat(' ', filteredAddresses.length > 0 ? filteredAddresses.length.toString() : '');
            worksheet.getCell('I' + filterline).value = worksheet
                .getCell('I' + filterline)
                .text.concat(' ', filteredDwellings.length > 0 ? filteredDwellings.length.toString() : '');
            worksheet.getCell('K' + filterline).value = worksheet
                .getCell('K' + filterline)
                .text.concat(' ', filteredAppareils.length > 0 ? filteredAppareils.length.toString() : '');
            worksheet.getCell('M' + filterline).value = worksheet
                .getCell('M' + filterline)
                .text.concat(' ', filteredStatusChantier.length > 0 ? filteredStatusChantier.length.toString() : '');
            worksheet.getCell('O' + filterline).value = worksheet
                .getCell('O' + filterline)
                .text.concat(' ', filteredStatusLogement.length > 0 ? filteredStatusLogement.length.toString() : '');
            worksheet.getCell('Q' + (filterline + 1)).value = worksheet
                .getCell('Q' + (filterline + 1))
                .text.concat('', datePassages);
            // Insert filters into new worksheet
            this.createFilterWorksheet(
                workbook,
                filteredResidences,
                filteredAddresses,
                filteredDwellings,
                filteredAppareils,
                filteredStatusChantier,
                filteredStatusLogement
            );
        }

        // Insert installations values
        const columnsTab: Row = worksheet.addRow(columns);
        let countLines = 0;
        let uniqueComplementStatus: string[] = [];
        data.forEach((value) => {
            if (value.installations && value.installations.length > 0) {
                //pour chaque installation on récupère celle de la derniere tranche
                let installations = new Array<Installation>();
                const ids = new Set(value.installations.map((i) => i.id));
                ids.forEach((id) => {
                    const maxPeriodId =
                        value.visits && value.visits.length > 0
                            ? Math.max(
                                  ...value.visits.map((m) => {
                                      if (m.periodId && m.periodId.trim().length > 0) {
                                          return Number(m.periodId);
                                      }
                                      m.periodId = '000000000';
                                      return 0;
                                  })
                              )
                            : 0;
                    const filteredInstallations = value.installations.find((i) => {
                        if (i.id === id) {
                            i.periodId = maxPeriodId.toString().padStart(9, '0');
                            return true;
                        } else return false;
                    });
                    if (filteredInstallations) {
                        installations.push(filteredInstallations);
                    }
                });
                installations.forEach((installation) => {
                    try {
                        const rowValue = this.buildDetailsLine(value, installation);
                        if (rowValue) {
                            this.addLine(worksheet, rowValue, countLines);
                            this.getUniqueComplementStatus(uniqueComplementStatus, rowValue[15] + '');
                        }
                    } catch (e) {}
                });
            } else {
                const rowValue = this.buildDetailsLine(value, undefined);
                this.addLine(worksheet, rowValue, countLines);
                this.getUniqueComplementStatus(uniqueComplementStatus, rowValue[15] + '');
            }
        });

        let maxWidthStatusComplement: number = this.listUtil.getListElementMaxPositifLength(
            uniqueComplementStatus,
            22 + columnComplement
        );
        worksheetColumns[15] = { key: 'P', width: maxWidthStatusComplement };

        worksheet.columns = worksheetColumns;

        // formatting...
        worksheetColumns
            .map((col) => `${col.key}1`)
            .forEach((key) => {
                worksheet.getCell(key).style = {
                    fill: excelUtil.backgroundTitleFill,
                    font: excelUtil.titleFontBigSize,
                };
            });

        const titleHeaderLine = 5;
        const tableHeaderLine = 13;

        ['A' + titleHeaderLine, 'E' + titleHeaderLine].forEach((key) => {
            worksheet.getCell(key).font = excelUtil.headerTitleFont;
        });

        ['A' + tableHeaderLine, 'B' + tableHeaderLine, 'C' + tableHeaderLine, 'D' + tableHeaderLine].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 13, bold: true, color: { argb: 'FFFFFF' }, italic: true },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '5f6368' }, bgColor: { argb: '5f6368' } },
            };
        });
        [
            'E' + tableHeaderLine,
            'F' + tableHeaderLine,
            'G' + tableHeaderLine,
            'H' + tableHeaderLine,
            'I' + tableHeaderLine,
            'J' + tableHeaderLine,
            'K' + tableHeaderLine,
        ].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 13, bold: true, color: { argb: 'FFFFFF' } },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '00003c' }, bgColor: { argb: '00003c' } },
            };
        });
        ['L' + tableHeaderLine, 'M' + tableHeaderLine, 'N' + tableHeaderLine].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 13, bold: true, color: { argb: 'FFFFFF' } },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '003372' }, bgColor: { argb: '003372' } },
            };
        });
        ['O' + tableHeaderLine, 'P' + tableHeaderLine, 'X' + tableHeaderLine].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 13, bold: true, color: { argb: 'FFFFFF' } },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '0071ff' }, bgColor: { argb: '0071ff' } },
            };
        });
        [
            'Q' + tableHeaderLine,
            'R' + tableHeaderLine,
            'Q' + (tableHeaderLine - 1),
            'R' + (tableHeaderLine - 1),
        ].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 13, bold: true, color: { argb: '000000' } },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '1beb83' }, bgColor: { argb: '1beb83' } },
            };
        });

        [
            'S' + tableHeaderLine,
            'T' + tableHeaderLine,
            'S' + (tableHeaderLine - 1),
            'T' + (tableHeaderLine - 1),
        ].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 13, bold: true, color: { argb: 'FFFFFF' } },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F78766' }, bgColor: { argb: 'F78766' } },
            };
        });
        [
            'U' + tableHeaderLine,
            'V' + tableHeaderLine,
            'Y' + tableHeaderLine,
            'AA' + tableHeaderLine,
            'AC' + tableHeaderLine,
            'AE' + tableHeaderLine,
            'AG' + tableHeaderLine,
            'AI' + tableHeaderLine,
            'AK' + tableHeaderLine,
            'AM' + tableHeaderLine,
        ].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 13, bold: true, color: { argb: '000000' } },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '80b8f8' }, bgColor: { argb: '80b8f8' } },
            };
        });
        [
            'W' + tableHeaderLine,
            'Z' + tableHeaderLine,
            'AB' + tableHeaderLine,
            'AD' + tableHeaderLine,
            'AF' + tableHeaderLine,
            'AH' + tableHeaderLine,
            'AJ' + tableHeaderLine,
            'AL' + tableHeaderLine,
            'AN' + tableHeaderLine,
        ].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 13, bold: true, color: { argb: '000000' } },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'f08c6d' }, bgColor: { argb: 'f08c6d' } },
            };
        });

        const headers = [
            'A6',
            'B6',
            'E6',
            'F6',
            'G6',
            'H6',
            'I6',
            'J6',
            'K6',
            'L6',
            'M6',
            'N6',
            'O6',
            'P6',
            'Q6',
            'R6',
            'A7',
            'B7',
            'E7',
            'F7',
            'G7',
            'H7',
            'I7',
            'J7',
            'K7',
            'L7',
            'M7',
            'N7',
            'O7',
            'P7',
            'Q7',
            'R7',
            'A8',
            'B8',
            'E8',
            'F8',
            'G8',
            'H8',
            'I8',
            'J8',
            'K8',
            'L8',
            'M8',
            'N8',
            'O8',
            'P8',
            'Q8',
            'R8',
        ];

        if (minLineNumberForHeaderBlocks < maxLineNumberForHeaderBlocks) {
            for (let i = minLineNumberForHeaderBlocks + 1; i <= maxLineNumberForHeaderBlocks; i++) {
                headers.push(`E${i}`);
                headers.push(`F${i}`);
                headers.push(`G${i}`);
                headers.push(`H${i}`);
                headers.push(`I${i}`);
                headers.push(`J${i}`);
                headers.push(`K${i}`);
                headers.push(`L${i}`);
                headers.push(`M${i}`);
                headers.push(`N${i}`);
                headers.push(`O${i}`);
                headers.push(`P${i}`);
                headers.push(`Q${i}`);
                headers.push(`R${i}`);
            }
        }
        headers.forEach((key) => {
            worksheet.getCell(key).style = {
                font: excelUtil.headerValueFont,
                fill: excelUtil.backgroundHeaderValueFill,
            };
        });

        worksheet.mergeCells('Q' + (tableHeaderLine - 1) + ':R' + (tableHeaderLine - 1));
        worksheet.mergeCells('S' + (tableHeaderLine - 1) + ':T' + (tableHeaderLine - 1));
        worksheet.getCell('Q' + (tableHeaderLine - 1)).value = this.translate.instant(
            'excel.installations.columns.dataPose'
        );
        worksheet.getCell('S' + (tableHeaderLine - 1)).value = this.translate.instant(
            'excel.installations.columns.dataDepose'
        );
        worksheet.getCell('Q' + (tableHeaderLine - 1)).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('Q' + (tableHeaderLine - 1)).border = {
            bottom: { style: 'thin', color: { argb: 'FFFFFF' } },
        };
        worksheet.getCell('S' + (tableHeaderLine - 1)).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('S' + (tableHeaderLine - 1)).border = {
            bottom: { style: 'thin', color: { argb: 'FFFFFF' } },
        };

        columnsTab.height = 50;
        columnsTab.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

        this.createSeparator(worksheet);

        // add ista logo
        const istaLogo = workbook.addImage({
            base64: excelUtil.istaLogo,
            extension: 'png',
        });
        worksheet.addImage(istaLogo, {
            tl: { col: 0, row: 1 },
            ext: { width: 80, height: 37 },
        });

        // Header and footer
        this.addHeaderAndFooter(worksheet);
    }

    private createSeparator(worksheet: Worksheet) {
        let separator = [];
        //changer i pour avoir un séparateur dynamique
        for (let i = 13; i <= 14; i++) {
            separator.push('Y' + i);
        }

        separator.forEach((key) => {
            worksheet.getCell(key).border = {
                left: { style: 'medium', color: { argb: '0A2864' } },
            };
        });
    }

    private getUniqueComplementStatus(uniqueArray: string[], value: string): string[] {
        if (value != '-' && uniqueArray.indexOf(value) === -1) {
            uniqueArray.push(value);
        }
        return uniqueArray;
    }

    private createFilterWorksheet(
        workbook: Workbook,
        filteredResidences: ReadonlyArray<string>,
        filteredAddresses: ReadonlyArray<string>,
        filteredDwellings: ReadonlyArray<string>,
        filteredAppareils: ReadonlyArray<string>,
        filteredStatusChantier: ReadonlyArray<string>,
        filteredStatusLogement: ReadonlyArray<string>
    ): void {
        const nbResidences = filteredResidences.length;
        const nbAddresses = filteredAddresses.length;
        const nbDwellings = filteredDwellings.length;
        const nbAppareils = filteredAppareils.length;
        const nbStatusChantier = filteredStatusChantier.length;
        const nbStatusLogement = filteredStatusLogement.length;
        const maxLines: number = Math.max(
            nbDwellings,
            nbResidences,
            nbAddresses,
            nbAppareils,
            nbStatusChantier,
            nbStatusLogement
        );

        const worksheetName = this.translate.instant('excel.installations.worksheet.names.filters');
        const worksheet: Worksheet = this.createWorksheet(
            workbook,
            worksheetName,
            'portrait',
            maxLines + excelUtil.headerFilterlines
        );

        // Define static data
        const title = `${this.translate.instant('excel.installations.headers.appliedFilters')} :`;
        const columns = [
            this.translate.instant('excel.installations.headers.properties'),
            this.translate.instant('excel.installations.headers.addresses'),

            this.translate.instant('excel.installations.headers.dwellings'),
            this.translate.instant('excel.installations.headers.typeAppareils'),
            this.translate.instant('excel.installations.headers.statusChantiers'),
            this.translate.instant('excel.installations.headers.statusDwellings'),
        ];

        // Define worksheet's columns
        const columnWidth = 35;

        let maxLengthResidences = this.listUtil.getListElementMaxPositifLength(filteredResidences, columnWidth);
        let maxLengthAddress = this.listUtil.getListElementMaxPositifLength(filteredAddresses, columnWidth);
        let maxLengthDwellings = this.listUtil.getListElementMaxPositifLength(filteredDwellings, columnWidth);
        let maxLengthAppareils = this.listUtil.getListElementMaxPositifLength(filteredAppareils, columnWidth);
        let maxLengthStatusLogement = this.listUtil.getListElementMaxPositifLength(filteredStatusLogement, columnWidth);
        let maxLengthStatusChantier = this.listUtil.getListElementMaxPositifLength(filteredStatusChantier, columnWidth);

        worksheet.columns = [
            { key: 'A', width: maxLengthResidences },
            { key: 'B', width: maxLengthAddress },
            { key: 'C', width: maxLengthDwellings },
            { key: 'D', width: maxLengthAppareils },
            { key: 'E', width: maxLengthStatusLogement },
            { key: 'F', width: maxLengthStatusChantier },
        ];

        worksheet.addRow([title]);
        worksheet.addRow([]);

        const columnsTab: Row = worksheet.addRow(columns);
        const baseLineNumber = 4;

        for (let i = 0; i < maxLines; i++) {
            let residence = null;
            let addresses = null;
            let dwelling = null;
            let appareils = null;
            let statusChantier = null;
            let statusLogement = null;

            if (i < nbResidences) {
                residence = filteredResidences[i];
            }

            if (i < nbAddresses) {
                addresses = filteredAddresses[i];
            }

            if (i < nbDwellings) {
                dwelling = filteredDwellings[i];
            }
            if (i < nbAppareils) {
                appareils = filteredAppareils[i];
            }
            if (i < nbStatusChantier) {
                statusChantier = filteredStatusChantier[i];
            }
            if (i < nbStatusLogement) {
                statusLogement = filteredStatusLogement[i];
            }
            worksheet.addRow([residence, addresses, dwelling, appareils, statusChantier, statusLogement]);
            const lineNumber = baseLineNumber + i;
            [
                `A${lineNumber}`,
                `B${lineNumber}`,
                `C${lineNumber}`,
                `D${lineNumber}`,
                `E${lineNumber}`,
                `F${lineNumber}`,
            ].forEach((key) => {
                worksheet.getCell(key).style = {
                    font: excelUtil.headerValueFont,
                    fill: excelUtil.backgroundHeaderValueFill,
                };
            });
        }

        // Formating data...
        ['A1', 'B1', 'C1', 'D1', 'E1', 'F1'].forEach((key) => {
            worksheet.getCell(key).style = {
                fill: excelUtil.backgroundTitleFill,
                font: excelUtil.titleFont,
            };
        });

        ['A3', 'B3', 'C3', 'D3', 'E3', 'F3'].forEach((key) => {
            worksheet.getCell(key).font = excelUtil.headerTitleFont;
        });

        this.addHeaderAndFooter(worksheet);
    }

    private createSummaryWorksheet(
        workbook: Workbook,
        customer: Customer,
        dataSummary: ReadonlyArray<WorksiteSynthesisLine>
    ): void {
        const worksheetName = `${this.translate.instant('excel.installations.worksheet.names.summary')}`;
        const creationDate = this.translate.instant('excel.installations.documentCreationDate');
        // Define static data
        const titleSummary = this.translate.instant('excel.installations.titleSummary');
        const columnsSummary = [
            this.translate.instant('excel.installations.summaryColumns.propertyName'),
            this.translate.instant('excel.installations.summaryColumns.propertyAddress'),
            this.translate.instant('excel.installations.summaryColumns.siteNumber'),
            this.translate.instant('excel.installations.summaryColumns.period'),
            this.translate.instant('excel.installations.summaryColumns.deviceType'),
            this.translate.instant('excel.installations.summaryColumns.fluid'),
            this.translate.instant('excel.installations.summaryColumns.planned'),
            this.translate.instant('excel.installations.summaryColumns.posed'),
            this.translate.instant('excel.installations.summaryColumns.posedRate'),
            this.translate.instant('excel.installations.summaryColumns.notEquipable'),
            this.translate.instant('excel.installations.summaryColumns.notEquipableRate'),
            this.translate.instant('excel.installations.summaryColumns.remainsToDo'),
            this.translate.instant('excel.installations.summaryColumns.remainsToDoRate'),
        ];

        const columnComplement = 0.78; // https://github.com/exceljs/exceljs/issues/744

        let adressess = dataSummary.map((i) => i.propertyAddress);

        let maxSizeAdress = this.listUtil.getListElementMaxPositifLength(adressess, 28 + columnComplement);

        const worksheetColumns = [
            { key: 'A', width: 28 + columnComplement },
            { key: 'B', width: maxSizeAdress + columnComplement },
            { key: 'C', width: 12 + columnComplement },
            { key: 'D', width: 24 + columnComplement },
            { key: 'E', width: 18 + columnComplement },
            { key: 'F', width: 10 + columnComplement },
            { key: 'G', width: 10 + columnComplement },
            { key: 'H', width: 10 + columnComplement },
            { key: 'I', width: 10 + columnComplement },
            { key: 'J', width: 10 + columnComplement },
            { key: 'K', width: 10 + columnComplement },
            { key: 'L', width: 10 + columnComplement },
            { key: 'M', width: 10 + columnComplement },
        ];

        const worksheet: Worksheet = this.createWorksheet(
            workbook,
            worksheetName,
            'landscape',
            dataSummary.length + 13
        );

        // Define worksheet's columns
        worksheet.columns = worksheetColumns;

        // insert data...
        worksheet.addRow([titleSummary]);
        worksheet.addRow([creationDate, moment().format('DD/MM/YYYY')]);
        worksheet.addRow([]);

        worksheet.addRow([`${this.translate.instant('excel.installations.headers.intendedFor')} :`, null, null]);

        worksheet.addRow([customer.name]);
        worksheet.addRow([customer.address, null, null, null, null]);
        const city: string = new Array(customer.postalCode, customer.city).join(' ');
        worksheet.addRow([city, null, null, null, null]);
        worksheet.addRow([]);

        const intendedForLineStart = 5;
        const intendedForLineEnd = 7;
        const tableHeaderLine = 9;
        const filterDescriptionLine = 4;
        const filterDescriptionCell = 'B' + filterDescriptionLine;

        // Insert values
        const columnsTab: Row = worksheet.addRow(columnsSummary);

        let countWorksitePlanned = 0;
        let countWorksiteInstalled = 0;
        let countWorksiteNonInstallable = 0;
        let countWorksiteNotInstalledYet = 0;

        dataSummary.forEach((value) => {
            const rowValue = this.buildSummaryLine(value);
            const row: Row = worksheet.addRow(rowValue);
            countWorksitePlanned = countWorksitePlanned + value.worksitePlanned;
            countWorksiteInstalled = countWorksiteInstalled + value.worksiteInstalled;
            countWorksiteNonInstallable = countWorksiteNonInstallable + value.worksiteNonInstallable;
            countWorksiteNotInstalledYet = countWorksiteNotInstalledYet + value.worksiteNotInstalledYet;
            // We need to define borders for each cell of row
            for (let i = 1; i <= rowValue.length; i++) {
                row.getCell(i).border = {
                    top: { style: 'thin', color: { argb: '318CE7' } },
                    left: { style: 'thin', color: { argb: '318CE7' } },
                    bottom: { style: 'thin', color: { argb: '318CE7' } },
                    right: { style: 'thin', color: { argb: '318CE7' } },
                };
                row.getCell(i).alignment = { vertical: 'middle', horizontal: 'center' };
            }
        });

        let summaryRow: Row;
        if (dataSummary.length > 0 && countWorksitePlanned > 0) {
            summaryRow = worksheet.addRow([
                this.translate.instant('excel.installations.summaryColumns.total'),
                null,
                null,
                null,
                null,
                null,
                countWorksitePlanned,
                countWorksiteInstalled,
                Math.round((countWorksiteInstalled / countWorksitePlanned) * 100) + '%',
                countWorksiteNonInstallable,
                Math.round((countWorksiteNonInstallable / countWorksitePlanned) * 100) + '%',
                countWorksiteNotInstalledYet,
                Math.round((countWorksiteNotInstalledYet / countWorksitePlanned) * 100) + '%',
            ]);
            summaryRow.font = { name: 'TheSansE5-ExtraLight', size: 10, bold: true };
            summaryRow.alignment = { vertical: 'middle', horizontal: 'center' };
        }

        // formatting...
        worksheetColumns
            .map((col) => `${col.key}1`)
            .forEach((key) => {
                worksheet.getCell(key).style = {
                    fill: {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '00003c' },
                        bgColor: { argb: '00003c' },
                    },
                    font: {
                        name: 'TheSansE5-ExtraBold',
                        size: 22,
                        bold: true,
                        color: { argb: 'FFFFFF' },
                        italic: true,
                    },
                };
            });

        ['A' + tableHeaderLine, 'B' + tableHeaderLine].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 10, bold: true, color: { argb: 'FFFFFF' } },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '00003c' }, bgColor: { argb: '00003c' } },
            };
        });

        ['C' + tableHeaderLine].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 10, bold: true, color: { argb: 'FFFFFF' }, italic: true },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '5f6368' }, bgColor: { argb: '5f6368' } },
            };
        });

        ['D' + tableHeaderLine, 'E' + tableHeaderLine, 'F' + tableHeaderLine, 'G' + tableHeaderLine].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 10, bold: true, color: { argb: 'FFFFFF' } },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '0071ff' }, bgColor: { argb: '0071ff' } },
            };
        });
        ['H' + tableHeaderLine, 'I' + tableHeaderLine].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 10, bold: true, color: { argb: '000000' } },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '1beb83' }, bgColor: { argb: '1beb83' } },
            };
        });
        ['J' + tableHeaderLine, 'K' + tableHeaderLine].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 10, bold: true, color: { argb: 'FFFFFF' } },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' }, bgColor: { argb: '000000' } },
            };
        });
        ['L' + tableHeaderLine, 'M' + tableHeaderLine].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 10, bold: true, color: { argb: 'FFFFFF' } },
                alignment: { vertical: 'middle', horizontal: 'center' },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'e73c06' }, bgColor: { argb: 'e73c06' } },
            };
        });

        //bold blue: intendedFor and filter description
        ['A2', 'A4', filterDescriptionCell].forEach((key) => {
            worksheet.getCell(key).font = excelUtil.headerTitleFont;
        });

        //gris: header
        let headers = [];
        for (let i = intendedForLineStart; i <= intendedForLineEnd; i++) {
            headers.push('A' + i);
        }

        headers.forEach((key) => {
            worksheet.getCell(key).style = {
                font: excelUtil.headerValueFont,
                fill: excelUtil.backgroundHeaderValueFill,
            };
        });

        worksheet.mergeCells(filterDescriptionCell, 'L' + filterDescriptionLine);
        worksheet.getCell(filterDescriptionCell).value =
            `${this.translate.instant('excel.installations.headers.appliedFilters')} : ${this.translate.instant('excel.installations.headers.appliedFiltersDescription')}`;
        worksheet.getCell(filterDescriptionCell).alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };

        columnsTab.height = 30;
        columnsTab.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        const istaLogo = workbook.addImage({
            base64: excelUtil.istaLogo,
            extension: 'png',
        });
        worksheet.addImage(istaLogo, 'M2:M3');
        this.addHeaderAndFooter(worksheet);
    }

    private addHeaderAndFooter(worksheet: Worksheet): void {
        const headerFooterStyle = '&K' + excelUtil.primaryColor + '&"TheSansE5-ExtraLight"&10';
        // moncompte.ista.fr in center header with ista primary color and fontfamily Calibri size 11
        worksheet.headerFooter.oddHeader = `&C${headerFooterStyle}moncompte.ista.fr`;
        // date in footer left, document name in center footer, page number in footer right.
        // ALL with ista primary color and fontfamily Calibri size 11
        const footerLeft = `&L${headerFooterStyle}&D`;
        const footerCenter = `&C${headerFooterStyle}&F`;
        const footerRight = `&R${headerFooterStyle}&P/&N`;
        worksheet.headerFooter.oddFooter = `${footerLeft}${footerCenter}${footerRight}`;
    }

    private createHelpWorksheet(workbook: Workbook): void {
        const worksheetName = this.translate.instant('excel.installations.worksheet.names.help');
        const worksheet: Worksheet = this.createWorksheet(workbook, worksheetName, 'portrait', 11);

        // Define static data
        const columns = [
            this.translate.instant('excel.installations.helpColumns.status'),
            null,
            this.translate.instant('excel.installations.helpColumns.definition'),
        ];

        worksheet.columns = [
            { key: 'A', width: 26 },
            { key: 'B', width: 6 },
            { key: 'C', width: 150 },
        ];

        const columnsTab: Row = worksheet.addRow(columns);

        worksheet.addRow([
            this.translate.instant('excel.installations.helpColumns.pose'),
            null,
            this.translate.instant('excel.installations.helpColumns.poseDescription'),
        ]);
        worksheet.addRow([
            this.translate.instant('excel.installations.helpColumns.nonEquipable'),
            null,
            this.translate.instant('excel.installations.helpColumns.nonEquipableDescription'),
        ]);
        worksheet.addRow([
            this.translate.instant('excel.installations.helpColumns.controleQualiteEnCours'),
            null,
            this.translate.instant('excel.installations.helpColumns.controleQualiteEnCoursDescription'),
        ]);
        worksheet.addRow([
            this.translate.instant('excel.installations.helpColumns.planifie'),
            null,
            this.translate.instant('excel.installations.helpColumns.planifieDescription'),
        ]);
        worksheet.addRow([
            this.translate.instant('excel.installations.helpColumns.aPlanifie'),
            null,
            this.translate.instant('excel.installations.helpColumns.aPlanifieDescription'),
        ]);
        worksheet.addRow([
            this.translate.instant('excel.installations.helpColumns.restAPoser'),
            null,
            this.translate.instant('excel.installations.helpColumns.restAPoserDescription'),
        ]);

        // Formating data...
        ['A1', 'B1', 'B5'].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 11, bold: true, color: { argb: 'FFFFFF' } },
                alignment: { vertical: 'middle', horizontal: 'center' },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '0071f0' }, bgColor: { argb: '0071f0' } },
            };
        });

        ['C1'].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 11, bold: true },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '80b8f8' }, bgColor: { argb: '80b8f8' } },
            };
        });

        ['B2'].forEach((key) => {
            worksheet.getCell(key).style = {
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '1beb83' }, bgColor: { argb: '1beb83' } },
            };
        });
        ['B3'].forEach((key) => {
            worksheet.getCell(key).style = {
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' }, bgColor: { argb: '000000' } },
            };
        });
        ['B4'].forEach((key) => {
            worksheet.getCell(key).style = {
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'AAAAAA' }, bgColor: { argb: 'AAAAAA' } },
            };
        });
        ['B6'].forEach((key) => {
            worksheet.getCell(key).style = {
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F2AF00' }, bgColor: { argb: 'F2AF00' } },
            };
        });
        ['B7'].forEach((key) => {
            worksheet.getCell(key).style = {
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E63F0C' }, bgColor: { argb: 'E63F0C' } },
            };
        });
        worksheet.addRow([]);
        worksheet.addRow([this.translate.instant('excel.installations.helpColumns.helpDescription1')]);
        worksheet.addRow([this.translate.instant('excel.installations.helpColumns.helpDescription2')]);
        worksheet.addRow([this.translate.instant('excel.installations.helpColumns.helpDescription3')]);
        ['A9'].forEach((key) => {
            worksheet.getCell(key).font = excelUtil.headerTitleFont;
        });
        this.addHeaderAndFooter(worksheet);
    }

    private buildDetailsLine(value: NewInstallationLine, installation: Installation | undefined): (string | number)[] {
        const filtredVisits = installation
            ? value.visits
                  .filter((visit) => visit.id.startsWith(installation.periodId))
                  .map((status) => status.installationsStatuses)
                  .reduce((pre, cur) => pre.concat(cur), [])
                  .filter((s) => s.installationId === installation.id)
            : [];

        const line = [];
        line.push(value.propertyId);
        const dwellingIstaReference = value.dwellingId.startsWith(value.propertyId)
            ? value.dwellingId.substring(value.propertyId.length)
            : value.dwellingId;
        line.push(dwellingIstaReference);
        line.push(value.worksiteId);
        line.push(installation ? installation.meterId.slice(-2) : '-');

        line.push(this.formatingCell(value.propertyName));
        line.push(this.formatingCell(value.dwellingCustomerReference));

        line.push(this.formatingCell(value.address));

        line.push(this.formatingCell(value.zip + ' ' + value.city));
        line.push(this.formatingCell(value.dwellingLocation));
        line.push(this.formatingCell(value.owner));
        line.push(this.formatingCell(value.tenant));

        line.push(this.formatingCell(installation ? installation.location : ''));
        line.push(ProductsConstants.getMeterLabel(value.productCode, value.fluidCode));
        line.push(this.getLabelFluid(installation ? installation.meterType : ''));

        if (installation && installation.meterStatus) {
            line.push(this.translate.instant('datatable.worksite.status.' + installation.meterStatus));
            switch (installation.meterStatus) {
                case WorksiteDwellingStatus.IN_PROGRESS:
                    line.push(this.translate.instant('excel.installations.status.status1'));
                    break;
                case WorksiteDwellingStatus.NOT_INSTALLED_YET:
                    line.push(this.translate.instant('excel.installations.status.status2'));
                    break;
                case WorksiteDwellingStatus.FINISHED_REMAINING_INSTALLATION:
                    line.push(this.translate.instant('excel.installations.status.statusDoneIndividualInstallation'));
                    break;
                default:
                    line.push('-');
                    break;
            }
        } else if (value.dwellingStatus === WorksiteDwellingStatus.IN_PROGRESS) {
            line.push(this.translate.instant('datatable.worksite.status.' + value.dwellingStatus));
            line.push(this.translate.instant('excel.installations.status.status1'));
        } else if (value.dwellingStatus === WorksiteDwellingStatus.NOT_INSTALLED_YET) {
            line.push(this.translate.instant('datatable.worksite.status.' + value.dwellingStatus));
            line.push(this.translate.instant('excel.installations.status.status2'));
        } else if (
            value.dwellingStatus === WorksiteDwellingStatus.FINISHED &&
            installation &&
            installation.installable === false
        ) {
            line.push(this.translate.instant('excel.installations.status.statusNonEquipable'));
            line.push(this.formatingCell(installation.notInstalledLabel));
        } else if (
            value.dwellingStatus.startsWith(WorksiteDwellingStatus.FINISHED) &&
            installation &&
            installation.installed
        ) {
            line.push(this.translate.instant('datatable.worksite.status.FINISHED'));
            if (value.dwellingStatus === WorksiteDwellingStatus.FINISHED_REMAINING_INSTALLATION) {
                line.push(this.translate.instant('excel.installations.status.statusDoneIndividualInstallation'));
            } else {
                line.push('-');
            }
        } else {
            line.push(this.translate.instant('datatable.worksite.status.' + value.dwellingStatus));
            line.push('-');
        }

        if (
            installation &&
            installation.meterStatus &&
            installation.installed &&
            (installation.meterStatus.startsWith(WorksiteDwellingStatus.FINISHED) ||
                installation.meterStatus === WorksiteDwellingStatus.NOT_EQUIPABLE)
        ) {
            if (installation.meterStatus === WorksiteDwellingStatus.NOT_EQUIPABLE) line.push('-');
            else line.push(this.getFormatingDate(value.lastVisitDate));
            line.push(this.formatingCell(installation.serialNumber));
        } else if (
            ((value.dwellingStatus.startsWith(WorksiteDwellingStatus.FINISHED) ||
                value.dwellingStatus === WorksiteDwellingStatus.NOT_EQUIPABLE) &&
                installation &&
                installation.installed) ||
            (value.dwellingStatus === WorksiteDwellingStatus.IN_PROGRESS &&
                installation &&
                installation.serialNumber &&
                installation.serialNumber.length > 0)
        ) {
            if (value.dwellingStatus === WorksiteDwellingStatus.NOT_EQUIPABLE) line.push('-');
            else line.push(this.getFormatingDate(value.lastVisitDate));
            line.push(this.formatingCell(installation.serialNumber));
        } else {
            line.push('-');
            line.push('-');
        }

        line.push(this.formatingCell(installation ? installation.oldSerialNumber : ''));
        line.push(installation && installation.index !== null ? installation.index : '-');

        line.push(value.visitsCount == null ? '-' : value.visitsCount);
        line.push(this.getFormatingDate(value.lastVisitDate));

        if (filtredVisits.length > 0) {
            line.push(this.formatingCell(filtredVisits[filtredVisits.length - 1].notInstalledLabel));
        } else {
            line.push('-');
        }

        if (value.nextVisitDate && value.nextVisitDate.length > 0) {
            line.push(this.getFormatingDate(this.formatingCell(value.nextVisitDate)));
        } else {
            line.push('-');
        }

        filtredVisits.forEach((visit, index, array) => {
            line.push(visit.visitDate ? this.getFormatingDate(this.formatingCell(visit.visitDate)) : '-');
            line.push(this.formatingCell(visit.notInstalledLabel));
        });

        // Add Empty value
        for (let i = line.length; i < 40; i++) {
            line.push('-');
        }
        return line;
    }

    private addLine(sheet: Worksheet, rowValue: ReadonlyArray<string | number>, countLines: number): void {
        const row = sheet.addRow(rowValue);
        countLines++;

        // We need to define borders for each cell of row
        for (let i = 1; i <= rowValue.length; i++) {
            row.getCell(i).border = {
                top: { style: 'thin', color: { argb: '318CE7' } },
                left: { style: 'thin', color: { argb: '318CE7' } },
                bottom: { style: 'thin', color: { argb: '318CE7' } },
                right: { style: 'thin', color: { argb: '318CE7' } },
            };

            if (
                i === 17 ||
                i === 25 ||
                i === 27 ||
                i === 29 ||
                i === 31 ||
                i === 33 ||
                i === 35 ||
                i === 37 ||
                i === 39
            ) {
                row.getCell(i).border = {
                    top: { style: 'thin', color: { argb: '318CE7' } },
                    left: { style: 'medium', color: { argb: '0A2864' } },
                    bottom: { style: 'thin', color: { argb: '318CE7' } },
                    right: { style: 'thin', color: { argb: '318CE7' } },
                };
            } else if (i === 20) {
                row.getCell(i).border = {
                    top: { style: 'thin', color: { argb: '318CE7' } },
                    left: { style: 'thin', color: { argb: '318CE7' } },
                    bottom: { style: 'thin', color: { argb: '318CE7' } },
                    right: { style: 'medium', color: { argb: '0A2864' } },
                };
            }

            row.getCell(i).alignment = { vertical: 'middle', horizontal: 'center' };
        }
    }

    private buildSummaryLine(value: WorksiteSynthesisLine): (string | number)[] {
        const line = [];
        line.push(this.formatingCell(value.propertyName));
        line.push(this.formatingCell(value.propertyAddress));

        line.push(this.formatingCell(value.worksiteId));
        line.push(this.formatingCell(value.worksitePeriodExcel));
        line.push(this.formatingCell(value.worksiteMeterTypeExcel));
        line.push(this.formatingCell(value.worksiteFluid));
        line.push(value.worksitePlanned);

        line.push(value.worksiteInstalled);
        line.push(value.worksiteInstalledRate + '%');

        line.push(value.worksiteNonInstallable);
        line.push(value.worksiteNonInstallableRate + '%');

        line.push(value.worksiteNotInstalledYet);
        line.push(value.worksiteNotInstalledYetRate + '%');

        return line;
    }

    public getLabelFluid(v: string): string {
        const test = this.fluidType.find((x) => x.fluid === v);
        const label = test ? test.fluid : '-';
        return label;
    }

    public formatingCell(strValue: string): string {
        if (strValue == null || strValue === '') {
            return '-';
        } else {
            return strValue;
        }
    }

    public getFormatingDate(strValue: string): Date | string {
        if (!strValue || strValue == null || strValue === '') {
            return '-';
        }

        return new Date(strValue);
    }
}
