import { ElementRef, Inject, Injectable, QueryList } from '@angular/core';
import { AuthorizationService, Customer, SessionStorage } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { DwellingDetails } from '@shared/model/dwelling-details.model';
import html2canvas from 'html2canvas';
import moment from 'moment';

import PdfUtils from '../util/pdf-utils';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake/build/vfs_fonts.js');
//pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Injectable({
    providedIn: 'root',
})
export class PdfService {
    content: Array<object> = []; // regroupe les datas du PDF

    constructor(
        private sStorage: SessionStorage,
        @Inject(TranslateService) private translate: TranslateService,
        private authorizationService: AuthorizationService
    ) {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        this.translate.setDefaultLang('fr');
    }

    generateDwellingFormPdf(
        elemetsToScreen: QueryList<ElementRef>,
        dwelling: DwellingDetails,
        orientation: 'portrait' | 'landscape'
    ): void {
        this.content = []; // reinit si click +1 fois
        let customerNumbers: ReadonlyArray<string> = [];
        let customer = this.sStorage.getCustomer();
        if (this.authorizationService.isExternalAccountType()) {
            customerNumbers = this.sStorage.getExternalUserInfo().customerNumbers;
        } else {
            const selectedCustomers = sessionStorage.getItem('customersSelected') as string;
            if (selectedCustomers !== null) {
                const customerList = JSON.parse(selectedCustomers) as Array<Customer>;
                customerNumbers = customerList.map((c) => c.number);
                customer = customerList[0];
            }
        }

        // On ajoute le tableau de la 1ere page dans le content
        this.content.push(this.buildDwellingFormTable(customer, customerNumbers, dwelling, this.translate));

        // On genere les screenshots et on les ajoute dans le content
        const promises = elemetsToScreen.map((element) => {
            return html2canvas(element.nativeElement).then((canvas) => {
                return this.content.push(this.buildImage(canvas.toDataURL(), 500));
            });
        });

        // On attend que les screenshots soit généré et ajouté puis on édite le fichier PDF
        Promise.all(promises).then(() => {
            const docDefinition = this.buildDocDefnition(
                orientation,
                this.translate.instant('dwelling.sheet'),
                this.translate.instant('dwelling.activity-reporting'),
                this.translate.instant('reporting.editedBy')
            );
            const date = new Date();
            moment(date).format('YYYY MM DD');
            const pdfName =
                moment(date).format('YYYY MM DD') +
                '_ista_fiche_logement_rapport_interventions_' +
                dwelling.customerReference +
                '.pdf';
            pdfMake.createPdf(docDefinition).download(pdfName);
        });
    }

    private buildDocDefnition(
        orientation: string,
        firstHeaderTitle: string,
        secondHeaderTitle: string,
        reportingFooterEditedBy: string
    ): object {
        return {
            pageSize: 'A4',
            pageOrientation: orientation,
            // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
            pageMargins: [40, 90, 40, 60],

            header: PdfUtils.header(firstHeaderTitle, secondHeaderTitle),
            footer: (currentPage: object, pageCount: object): object => {
                return PdfUtils.footer(reportingFooterEditedBy, currentPage.toString(), pageCount.toString());
            },
            content: this.content,
        };
    }

    private buildImage(data: string, size: number): object {
        return { image: data, width: size, alignment: 'center' };
    }

    private buildDwellingFormTable(
        customer: Customer,
        customerNumbers: ReadonlyArray<string>,
        dwelling: DwellingDetails,
        translate: TranslateService
    ): object {
        return {
            table: {
                widths: [140, '*', 60, '*'],
                body: [
                    [
                        {
                            text: translate.instant('reporting.client-code').toUpperCase(),
                            color: '#00003c',
                            fontSize: 11,
                            bold: true,
                        },
                        {
                            text: customerNumbers.join('\n'),
                            fillColor: '#E6E9EF',
                            color: '#00003c',
                        },
                        {
                            text: translate.instant('reporting.for').toUpperCase(),
                            color: '#00003c',
                            fontSize: 11,
                            bold: true,
                        },
                        {
                            text: this.authorizationService.isExternalAccountType()
                                ? customer.name.concat(
                                      '\n',
                                      customer.address,
                                      '\n',
                                      customer.city.concat(' ', customer.postalCode)
                                  )
                                : customer.name,
                            fillColor: '#E6E9EF',
                            color: '#00003c',
                        },
                    ],
                    ['', '', '', ''],
                    ['', '', '', ''],
                    ['', '', '', ''],
                    ['', '', '', ''],
                    [
                        {
                            text: translate.instant('dwelling.sheet'),
                            color: '#1beb83',
                            fontSize: 11,
                        },
                        '',
                        '',
                        '',
                    ],
                    ['', '', '', ''],
                    [
                        {
                            text: translate.instant('dwelling.reference'),
                            color: '#00003c',
                            fontSize: 11,
                            bold: true,
                            fillColor: '#E6E9EF',
                        },
                        {
                            text: dwelling.customerReference,
                            fillColor: '#E6E9EF',
                            color: '#00003c',
                        },
                        '',
                        '',
                    ],
                    [
                        {
                            text: '',
                            fillColor: '#E6E9EF',
                        },
                        {
                            text: '',
                            fillColor: '#E6E9EF',
                        },
                        '',
                        '',
                    ],
                    [
                        {
                            text: translate.instant('dwelling.owner'),
                            color: '#00003c',
                            fillColor: '#E6E9EF',
                            fontSize: 11,
                            bold: true,
                        },
                        {
                            text: dwelling.owner === '' ? '-' : dwelling.owner,
                            fillColor: '#E6E9EF',
                            color: '#00003c',
                        },
                        '',
                        '',
                    ],
                    [
                        {
                            text: '',
                            fillColor: '#E6E9EF',
                        },
                        {
                            text: '',
                            fillColor: '#E6E9EF',
                        },
                        '',
                        '',
                    ],
                    [
                        {
                            text: translate.instant('dwelling.tenant'),
                            color: '#00003c',
                            fillColor: '#E6E9EF',
                            fontSize: 11,
                            bold: true,
                        },
                        {
                            text: dwelling.tenant,
                            fillColor: '#E6E9EF',
                            color: '#00003c',
                        },
                        '',
                        '',
                    ],
                    [
                        {
                            text: '',
                            fillColor: '#E6E9EF',
                        },
                        {
                            text: '',
                            fillColor: '#E6E9EF',
                        },
                        '',
                        '',
                    ],
                    [
                        {
                            text: translate.instant('dwelling.residence'),
                            color: '#00003c',
                            fillColor: '#E6E9EF',
                            fontSize: 11,
                            bold: true,
                        },
                        {
                            text: dwelling.property.name,
                            fillColor: '#E6E9EF',
                            color: '#00003c',
                        },
                        '',
                        '',
                    ],
                    [
                        {
                            text: '',
                            fillColor: '#E6E9EF',
                        },
                        {
                            text: '',
                            fillColor: '#E6E9EF',
                        },
                        '',
                        '',
                    ],
                    [
                        {
                            text: translate.instant('dwelling.location'),
                            color: '#00003c',
                            fillColor: '#E6E9EF',
                            fontSize: 11,
                            bold: true,
                        },
                        {
                            text: dwelling.location,
                            fillColor: '#E6E9EF',
                            color: '#00003c',
                        },
                        '',
                        '',
                    ],
                    [
                        {
                            text: '',
                            fillColor: '#E6E9EF',
                        },
                        {
                            text: '',
                            fillColor: '#E6E9EF',
                        },
                        '',
                        '',
                    ],
                    [
                        {
                            text: translate.instant('dwelling.address'),
                            color: '#00003c',
                            fillColor: '#E6E9EF',
                            fontSize: 11,
                            bold: true,
                        },
                        {
                            text: dwelling.address,
                            fillColor: '#E6E9EF',
                            color: '#00003c',
                        },
                        '',
                        '',
                    ],
                    [
                        {
                            text: '',
                            fillColor: '#E6E9EF',
                        },
                        {
                            text: '',
                            fillColor: '#E6E9EF',
                        },
                        '',
                        '',
                    ],
                    [
                        {
                            text: translate.instant('dwelling.city'),
                            color: '#00003c',
                            fillColor: '#E6E9EF',
                            fontSize: 11,
                            bold: true,
                        },
                        {
                            text: dwelling.city,
                            fillColor: '#E6E9EF',
                            color: '#00003c',
                        },
                        '',
                        '',
                    ],
                    ['', '', '', ''],
                    ['', '', '', ''],
                    ['', '', '', ''],
                    ['', '', '', ''],
                    [
                        {
                            text: translate.instant('dwelling.report'),
                            color: '#1beb83',
                            fontSize: 11,
                        },
                        '',
                        '',
                        '',
                    ],
                    ['', '', '', ''],
                ],
            },
            layout: 'noBorders',
        };
    }
}
