import { RedirectLandingPage } from '../enum/redirect-landing-page.enum';

export function isAuthorizedRedirectingPath(path: string): boolean {
    const availableUrls = [
        RedirectLandingPage.ACTIVITIES_LANDING_PAGE.valueOf(),
        RedirectLandingPage.ACTIVITIES_LIST_PAGE.valueOf(),
        RedirectLandingPage.ACTIVITIES_DETAILED_LIST_PAGE.valueOf(),
        RedirectLandingPage.WORKSITES_LANDING_PAGE.valueOf(),
        RedirectLandingPage.WORKSITES_LIST_PAGE.valueOf()
    ];

    const availableUrlWithoutIds = [
        RedirectLandingPage.ACTIVITIES_DWELLING_PAGE.valueOf(),
        RedirectLandingPage.WORKSITES_DWELLING_PAGE.valueOf()
    ];

    return availableUrls.filter(url => url === path).length > 0 ||
        availableUrlWithoutIds.filter(url => path.substring(0, path.lastIndexOf('/')) === url).length > 0;
}
