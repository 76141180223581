import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { Reference } from '@ista/shared-ui';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Dwelling } from '../../../../core/model/dwelling.model';
import { DwellingService } from '../../../../core/service/dwelling.service';
import { NewInstallationLine } from '../model/new-installation-line.model';
import { WorksiteDwellingDetails } from '../model/worksite-dwelling-details.model';
import { DwellingWorksiteOverview, MeterWorksiteOverview } from '../model/worksite-overview.model';

export enum OverviewMode {
    DWELLING = 'DWELLING',
    METER = 'METER',
}

@Injectable({
    providedIn: 'root',
})
export class WorksiteDwellingService implements DwellingService {
    baseUrl: string;
    newBaseUrl: string;

    constructor(
        private httpClient: HttpClient,
        @Inject('CONTEXT_API') private contextApi: string
    ) {
        this.baseUrl = `${contextApi}/worksites/dwellings`;
        this.newBaseUrl = `${contextApi}/worksites/overview`;
    }

    fetch(): Observable<ReadonlyArray<Dwelling>> {
        return this.httpClient.get<ReadonlyArray<Dwelling>>('assets/data/dwellings.json');
    }

    fetchById(id: Readonly<string>): Observable<Readonly<Dwelling>> {
        return this.fetch().pipe(
            map((dwellings) => {
                const dwelling = dwellings.find((d) => d.id === id);
                if (dwelling) {
                    return dwelling;
                }
                throw new Error('Not found');
            })
        );
    }

    fetchDwellingsDetails(
        propertiesNumbers: ReadonlyArray<string>,
        dwellingsIds: ReadonlyArray<string>,
        metersIds: ReadonlyArray<string>,
        date: string,
        remainingInstallations?: boolean
    ): Observable<ReadonlyArray<WorksiteDwellingDetails>> {
        let url = `${this.baseUrl}`;
        const urlEnd = `/details?`;

        let filterUrl = '';

        if (dwellingsIds.length > 0) {
            url += `/${dwellingsIds}`;
        }
        if (propertiesNumbers.length > 0) {
            filterUrl += (filterUrl === '' ? '' : '&') + `propertiesIds=${propertiesNumbers}`;
        }
        if (metersIds.length > 0) {
            filterUrl += (filterUrl === '' ? '' : '&') + `metersIds=${metersIds}`;
        }
        if (remainingInstallations !== undefined && remainingInstallations !== null) {
            filterUrl += (filterUrl === '' ? '' : '&') + `remainingInstallations=${remainingInstallations}`;
        }
        if (date !== undefined && date !== '') {
            filterUrl += (filterUrl === '' ? '' : '&') + `date=${date}`;
        }
        url += urlEnd;
        url += filterUrl;

        if (isDevMode()) {
            console.log('Using url to fetch worksite dwelling details : ' + url);
        }
        return this.httpClient.get<ReadonlyArray<WorksiteDwellingDetails>>(url);
    }

    fetchDwellingsDetailsByView(
        viewType: OverviewMode,
        propertiesNumbers: ReadonlyArray<string>,
        date: string,
        remainingInstallations?: boolean
    ): Observable<ReadonlyArray<NewInstallationLine>> {
        const urlStart = `${this.newBaseUrl}`;
        const urlEnd = `/flat?`;

        let filterUrl = '';

        if (viewType.length > 0) {
            filterUrl += (filterUrl === '' ? '' : '&') + `view=${viewType}`;
        }
        if (propertiesNumbers.length > 0) {
            filterUrl += (filterUrl === '' ? '' : '&') + `propertiesIds=${propertiesNumbers}`;
        }
        if (date !== undefined && date !== '') {
            filterUrl += (filterUrl === '' ? '' : '&') + `date=${date}`;
        }
        if (remainingInstallations !== undefined && remainingInstallations !== null) {
            filterUrl += (filterUrl === '' ? '' : '&') + `remainingInstallations=${remainingInstallations}`;
        }
        const url = urlStart + urlEnd + filterUrl;

        if (isDevMode()) {
            console.log('Using url to fetch worksite dwelling details : ' + url);
        }
        return this.httpClient.get<ReadonlyArray<NewInstallationLine>>(url);
    }

    /**
     * Returns a flat overwiew of worksites depending params.
     *
     * @param dateParam - Date from witch data should be retrieved
     * @param propertiesIdsParam - List of properties ids separated by comma
     * @returns An observable of ReadonlyArray<Worksite>
     */
    fetchDwellingWorksitesOverview(
        date: string,
        propertiesIds?: ReadonlyArray<string>,
        remainingInstallations?: boolean
    ): Promise<ReadonlyArray<NewInstallationLine>> {
        const urlStart = `${this.newBaseUrl}`;
        const urlEnd = `/flat?view=DWELLING`;
        let filterUrl = '';

        if (propertiesIds && propertiesIds.length > 0) {
            filterUrl += (filterUrl === '' ? '' : '&') + `propertiesIds=${propertiesIds}`;
        }

        if (date !== undefined && date !== '') {
            filterUrl += (filterUrl === '' ? '' : '&') + `date=${date}`;
        }

        if (remainingInstallations !== undefined && remainingInstallations !== null) {
            filterUrl += (filterUrl === '' ? '' : '&') + `remainingInstallations=${remainingInstallations}`;
        }

        const url = urlStart + urlEnd + filterUrl;

        if (isDevMode()) {
            console.log('Using url to fetch worksite dwelling details : ' + url);
        }

        return firstValueFrom(this.httpClient.get<ReadonlyArray<NewInstallationLine>>(url));
    }

    /**
     * Returns a flat overwiew of worksites depending params.
     *
     * @param viewParam - view mode : OverviewMode
     * @param dateParam - Date from witch data should be retrieved
     * @param propertiesIdsParam - List of properties ids separated by comma
     * @returns An observable of ReadonlyArray<Worksite>
     */
    fetchDwellingAndMetersWorksiteOverview(
        view: OverviewMode,
        date: string,
        propertiesIds?: ReadonlyArray<string>,
        remainingInstallations?: boolean
    ): Promise<ReadonlyArray<DwellingWorksiteOverview>> | Promise<ReadonlyArray<MeterWorksiteOverview>> {
        const urlStart = `${this.newBaseUrl}`;
        const urlEnd = `/flat?`;
        let filterUrl = '';

        if (view.length > 0) {
            filterUrl += (filterUrl === '' ? '' : '&') + `view=${view}`;
        }
        if (propertiesIds && propertiesIds.length > 0) {
            filterUrl += (filterUrl === '' ? '' : '&') + `propertiesIds=${propertiesIds}`;
        }
        if (date !== undefined && date !== '') {
            filterUrl += (filterUrl === '' ? '' : '&') + `date=${date}`;
        }
        if (remainingInstallations !== undefined && remainingInstallations !== null) {
            filterUrl += (filterUrl === '' ? '' : '&') + `remainingInstallations=${remainingInstallations}`;
        }
        const url = urlStart + urlEnd + filterUrl;
        if (isDevMode()) {
            console.log('calling fetchWorksitesOverview url : ' + url);
        }

        return view === OverviewMode.DWELLING
            ? firstValueFrom(this.httpClient.get<ReadonlyArray<DwellingWorksiteOverview>>(url))
            : firstValueFrom(this.httpClient.get<ReadonlyArray<MeterWorksiteOverview>>(url));
    }

    fetchReferences(): Observable<ReadonlyArray<Reference>> {
        return this.httpClient.get<ReadonlyArray<Reference>>('assets/data/dwellings-references.json');
    }
}
