import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivityStatus } from '@extern/activities/enum/activity-status.enum';
import { formatDate } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';

import { ActivityVisit } from '../../model/activity-visit.model';
import { Reporting } from '../../model/reporting.model';

export const nonResolvedReasonDiscard = [
    'AN',
    'AO',
    'AP'
];

@Component({
    selector: 'app-activity-item-details',
    templateUrl: './activity-item-details.component.html',
    styleUrls: ['./activity-item-details.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityItemDetailsComponent implements OnInit {
    @Input() activity: Reporting;
    visits: Array<ActivityVisit>;
    passage: string;
    defaultDatePattern = 'DD/MM/YYYY';


    constructor(
        private translate: TranslateService) { }


    ngOnInit(): void {
        this.translate.setDefaultLang('fr');
        this.getVisits();
    }


    getVisits(): void {
        // passedVisit check is the activity is scheduled but not passed and take off from the list
        this.visits = this.activity.visits.filter(v => this.passedVisit(v));
        if (this.activity.planificationDate && this.activity.status === ActivityStatus.PLANNED) {
            this.visits = this.visits.filter(v => v.planificationDate !== this.activity.planificationDate);
        }
        // demanded by PO to invert ordre to show the visits.
        this.visits.reverse();
    }

    private passedVisit(v: ActivityVisit): boolean {
        if (v.planificationDate && v.planificationDate !== '') {
            const today = new Date();
            const datePanification = new Date(v.planificationDate);
            if (today < datePanification) {
                return false;
            }
        }
        // find all Non Resolved Reason to be discarded (array in dwelling.utils)
        return nonResolvedReasonDiscard.find(x => x === v.notResolvedReasonCode) === undefined;
    }

    private getTranslate(translateKey: string): string {
        let response = '';
        this.translate.get(translateKey).subscribe((translateValue: string) => {
            response = translateValue;
        });
        return response;
    }


    public findPassages(): string {
        let passage;
        let resp = '';
        if (this.visits.length > 1) {
            passage = 'dwellingDetail.passagePlur';
        } else {
            passage = 'dwellingDetail.passageSing';
        }
        resp = this.visits.length + ' ' + this.getTranslate(passage);
        return resp;
    }
    public transformDate(dateToTranform: string): string {
        return formatDate(dateToTranform, this.defaultDatePattern);
    }
    public aPlanifier(): boolean {
        if (this.activity.status === ActivityStatus.TO_PLAN && this.visits.length === 1 && this.visits[0].planificationDate === '') {
            return true;
        } else {
            return false;
        }
    }
    public getTextStatut(planificationDate: string): string {
        if (this.activity.status === ActivityStatus.FINISHED) {
            return this.getTranslate('dwellingDetail.statusRealise');
        } else if ([ActivityStatus.TO_PLAN, ActivityStatus.HELP_US].includes(this.activity.status)) {
            if (planificationDate === '') {
                return this.getTranslate('dwellingDetail.statusAPlanifier');
            }
        } else if (this.activity.status === ActivityStatus.PLANNED) {
            return this.getTranslate('dwellingDetail.inProgressComment');
        }
        return this.getTranslate('dwellingDetail.noMotif');
    }

    public getClassStatut(): string {
        if (this.activity.status === ActivityStatus.FINISHED) {
            return 'statutVert';
        } else {
            return 'statutOrange';
        }
    }
    public getTitleStatut(planificationDate: string): string {
        if (this.activity.status === ActivityStatus.FINISHED) {
            return this.getTranslate('dwellingDetail.statusRealise');
        } else if (this.activity.status === ActivityStatus.TO_PLAN) {
            if (planificationDate === '') {
                return this.getTranslate('dwellingDetail.tooltipAPlanifier');
            }
        } else if (this.activity.status === ActivityStatus.PLANNED) {
            return this.getTranslate('dwellingDetail.inProgressComment');
        }
        return this.getTranslate('dwellingDetail.noMotifNonRes');
    }
}
