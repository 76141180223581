import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OopsRoutingModule } from './oops-routing.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';


@NgModule({
    imports: [
        CommonModule,
        OopsRoutingModule
    ],
    declarations: [NotFoundComponent]
})
export class OopsModule { }
