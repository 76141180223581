import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from '@ista/shared-ui';

import { RoleGuard } from '../../../core/guard/role.guard';
import { TemplateComponent } from '../components/template/template.component';
import { ActivitiesDashboardComponent } from './pages/activities-dashboard/activities-dashboard.component';
import { ActivitiesDetailComponent } from './pages/activities-detail/activities-detail.component';
import { ActivitiesComponent } from './pages/activities/activities.component';
import { DwellingDetailComponent } from './pages/dwelling-detail/dwelling-detail.component';

const routes: Routes = [
    {
        path: 'mes-interventions', component: TemplateComponent, children: [

            {
                path: 'tableau-de-bord',
                component: ActivitiesDashboardComponent,
                canActivate: [RoleGuard],
                data: { roles: [Role.ROLE_CUSTOMER] }
            },
            {
                path: '',
                redirectTo: 'tableau-de-bord',
                pathMatch: 'full'
            },
            {
                path: 'liste', children: [
                    {
                        path: '',
                        component: ActivitiesComponent,
                        canActivate: [RoleGuard],
                        data: { roles: [Role.ROLE_CUSTOMER] }
                    },
                    {
                        path: 'detaillee',
                        component: ActivitiesDetailComponent,
                        canActivate: [RoleGuard],
                        data: { roles: [Role.ROLE_CUSTOMER] }
                    },
                    {
                        path: 'logement/:id',
                        component: DwellingDetailComponent,
                        canActivate: [RoleGuard],
                        data: { roles: [Role.ROLE_CUSTOMER] }
                    }
                ]
            }
        ]
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ActivitiesRoutingModule { }
