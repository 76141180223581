export enum WorksiteStatus {
    TO_PLAN = 'TO_PLAN',
    PLANNED = 'PLANNED',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    HELP_US = 'HELP_US',
    NOT_EQUIPABLE = 'NOT_EQUIPABLE',
}

export enum PropertyWorksiteStatus {
    FINISHED = 'FINISHED',
    IN_PROGRESS = 'IN_PROGRESS',
    PLANNED = 'PLANNED',
    TO_PLAN = 'TO_PLAN',
}


export enum MetersWorksiteStatus {
    FINISHED = 'FINISHED',
    IN_PROGRESS = 'IN_PROGRESS',
    PLANNED = 'PLANNED',
    TO_PLAN = 'TO_PLAN',
    NOT_EQUIPABLE = 'NOT_EQUIPABLE',
    NOT_INSTALLED_YET = 'NOT_INSTALLED_YET',
}
