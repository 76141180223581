import { Component, Inject, isDevMode, OnInit } from '@angular/core';
import { DefaultLandingPage } from '@ista/shared-ui';

import { environment } from '../../../../environments/environment';
import { RedirectLandingPage } from '../../../core/enum/redirect-landing-page.enum';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    landingPage = new DefaultLandingPage(RedirectLandingPage.EXTERN_LANDING_PAGE, RedirectLandingPage.INTERN_LANDING_PAGE);
    firstTabKey = 'external';
    secondTabKey = 'internal';
    activeTab = this.secondTabKey;
    externalResetUrl: string;
    showTabs = false;

    constructor(@Inject('RESET_BASE_URL') private resetBaseUrl: string) {
        /* activer les deux modes (interne & externe) en dev */
        if (!environment.production && isDevMode()) {
            this.showTabs = true;
            this.activeTab = this.firstTabKey;
        }
    }

    ngOnInit(): void {
        this.externalResetUrl = this.resetBaseUrl + '/user/auth/request-password-reset';
    }
}
