import { Injectable, Inject, isDevMode } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User, UserGroup} from '@ista/shared-ui';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    constructor(private httpClient: HttpClient, @Inject('CONTEXT_API') private contextApi: string) { }

    /**
     * Fetch Ista users
     */
    public fetchIstaUsers(): Observable<Array<User>> {
        const url = this.contextApi + '/user/internal';
        if (isDevMode()) {
            console.log('Using url to fetch ISTA users : ' + url);
        }
        return this.httpClient.get<Array<User>>(url);
    }

    public getUserGroupCount(): Observable< Array<UserGroup>> {
        const url = this.contextApi + '/management/usergroup/internal/user';
        if (isDevMode()) {
            console.log('Using url to get users count : ' + url);
        }
        return this.httpClient.get< Array<UserGroup>>(url);
    }


    public getManagementGroupCount(): Observable< Array<UserGroup>> {
        const url = this.contextApi + '/management/usergroup/internal/management';
        if (isDevMode()) {
            console.log('Using url to get managements count : ' + url);
        }
        return this.httpClient.get< Array<UserGroup>>(url);
    }

    public findInternalUsersByFullTextSearch(filter: string): Observable<Array<User>> {
        const url = this.contextApi + '/user/internal/search?filter=' + filter;
        if (isDevMode()) {
            console.log('Using url to get managements count : ' + url);
        }
        return this.httpClient.get<Array<User>>(url);
    }
}
