<div class="showScrollWhenNeeded">
    <div class="d-flex flex-row flex-wrap container-fluid menu-title pl-0">
        <div class="row ml-2">
            <div class="col-*">
                <h1 class="form-title" style="margin-top: 4px;">{{'dashboard.title' | translate}}</h1>
            </div>
            <div [tooltip]="showFilterTooltip()" class="col-* filterButton" containerClass="tooltipClass" placement="auto" [ngClass]=" filterIsActive == true ? 'active' : ''">
                <ista-hide-filters (toggle)="toggleFilter()" [filterSessionStorage]="filterSessionStorage"
                    [groupFilters]="gFiltres.refresh" [ignoreFilters]="['dwellings', 'columns']">
                </ista-hide-filters>
            </div>
            <div>
                <span class="maintitle fontUsed" style="position: relative; top: -8px;">
                    {{'dashboard.activities.today' | translate}} {{getActualDate()}}
                </span>
            </div>
        </div>
    </div>
    <div class="container-fluid p-0">
        <div class="p-2">
            <div class="d-flex flex-row flex-wrap container-fluid" style="padding-left: 20px !important;">
                <div class="container-fluid p-0">
                    <div class="row">
                        <div [ngClass]="showFilter? 'col-12 visible': 'col-0 hidden'" class="filter">
                            <app-group-filters #gFiltres (refresh)="refreshData($event)"
                                [activeService]="filterSessionStorage" [addressesReferences]="addressFilterData"
                                [propertiesReferences]="propertyFilterData" type="activityDash">
                            </app-group-filters>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-row flex-wrap justify-content-center container-fluid" (onResize)="handleResize($event)"
            style="margin-top: 2%">
            <div class="row justify-content-center m-0 p-0" [ngStyle]="{'width': graphBlockWidth + 'px'}">
                <app-activity-synthesis [newGraphData]="newGraphData" [showSpinner]="showSpinner" class="chartcomp">
                </app-activity-synthesis>
            </div>
        </div>
    </div>
</div>
