import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ChartModule } from 'angular-highcharts';

import { SharedModule } from '../../../shared/shared.module';
import { DashboardResumeComponent } from './components/dashboard-resume/dashboard-resume.component';
import { InstallationUnrecycledDataComponent } from './components/installation-unrecycled-data/installation-unrecycled-data.component';
import { InstallationsListComponent } from './components/installations-list/installations-list.component';
import { ProgressWorksiteTableComponent } from './components/progress-worksite-table/progress-worksite-table.component';
import { WorksiteSyntheseGraphsComponent } from './components/worksite-synthese-graphs/worksite-synthese-graphs.component';
import { InstallationsDashboardComponent } from './pages/installations-dashboard/installations-dashboard.component';
import { InstallationsComponent } from './pages/installations/installations.component';
import { WorksitesRoutingModule } from './worksites-routing.module';

@NgModule({
    imports: [
        CommonModule,
        WorksitesRoutingModule,
        ChartModule,
        TranslateModule,
        SharedModule
    ],
    declarations: [
        InstallationsComponent,
        InstallationsDashboardComponent,
        ProgressWorksiteTableComponent,
        DashboardResumeComponent,
        WorksiteSyntheseGraphsComponent,
        InstallationsListComponent,
        InstallationUnrecycledDataComponent
    ],
    exports: [
        CommonModule,
        FormsModule
    ]

})
export class WorksitesModule { }
