<div #containersBlock (onResize)="handleResize($event)" (resize)="resized()" style="position: relative; height: 100%">
    <div #filtersBlock id="filtersBlock">
        <div class="menu-title">
            <div class="row ml-2">
                <div class="col-5 ml-0 p-0 m-0">
                    <div class="row ml-0 p-0 m-0">
                        <div class="col-*">
                            <h1 class="form-title" style="margin-top: 4px">
                                {{ 'sidebar.menu.menuitem.installations' | translate }}
                            </h1>
                        </div>
                        <div
                            [tooltip]="showFilterTooltip()"
                            class="col-* filterButton"
                            containerClass="tooltipClass"
                            placement="auto"
                            [ngClass]="filterIsActive == true ? 'active' : ''"
                        >
                            <ista-hide-filters
                                [filterSessionStorage]="filterSessionStorage"
                                (toggle)="toggleFilter()"
                                [groupFilters]="gFiltres.refresh"
                                [ignoreFilters]="['columns']"
                            >
                            </ista-hide-filters>
                        </div>
                        <div
                            id="select-columns"
                            style="margin-left: 10px"
                            [tooltip]="'filters.columns' | translate"
                            containerClass="tooltipClass"
                            placement="right"
                        >
                            <ista-select-columns
                                [data]="columnsFilterData"
                                dataName="columns"
                                [filterSessionStorage]="filterSessionStorage"
                                validateButtonText="{{ 'filters.validate' | translate }}"
                                unselectAllButtonText="{{ 'filters.unselectAll' | translate }}"
                                selectAllButtonText="{{ 'filters.selectAll' | translate }}"
                                (refresh)="prepareColumns()"
                            >
                            </ista-select-columns>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ista-spinner
            *ngIf="showSpinner"
            class="center-text"
            [showSpinner]="showSpinner"
            style="z-index: 100"
            [data]="lines.length"
            message="{{ 'filters.noActivityForTheSelection' | translate }}"
            loading="{{ 'template.loading' | translate }}"
        >
        </ista-spinner>
        <div class="flex-row ml-2 p-0 group-filters" style="padding-left: 20px !important">
            <div class="row">
                <div class="filter" [ngClass]="showFilter ? 'col-11 visible' : 'col-0 hidden'">
                    <app-group-filters
                        #gFiltres
                        type="worksite"
                        [propertiesReferences]="propertyFilterData"
                        [addressesReferences]="addressFilterData"
                        [dwellingsReferences]="dwellingFilterData"
                        [columns]="columnsFilterData"
                        [activeService]="filterSessionStorage"
                        (refresh)="refreshData($event)"
                        (changeShowFilter)="changeShowFilter($event)"
                    >
                    </app-group-filters>
                </div>
                <div *ngIf="!showSpinner" [ngClass]="showFilter ? 'col-1' : 'col-12'">
                    <div class="ml-auto alert group-filters">
                        <div *ngIf="!excellReady; then display_spinner; else display_excel_icon"></div>
                        <ng-template #display_spinner>
                            <div
                                class="flex-row align-items-end flex-column"
                                [ngStyle]="{ height: showFilter ? 'auto' : '2rem' }"
                                style="display: flex; flex-direction: row; color: #006ef0"
                                (click)="(false); $event.stopPropagation()"
                            >
                                <div class="spinnerText">{{ 'excel.installations.spinner' | translate }}</div>
                                <span containerClass="customClass" id="exportGroup">
                                    <i class="fas fa-spinner fa-pulse" style="color: #006ef0"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template #display_excel_icon>
                            <div
                                class="flex-row align-items-end flex-column"
                                [ngStyle]="{ height: showFilter ? 'auto' : '2rem' }"
                            >
                                <ista-export-data
                                    id="exportGroup"
                                    (export)="exportList($event)"
                                    title="{{ 'export.installations.excel.list' | translate }}"
                                    [showExcel]="true"
                                    class="ml-auto round-button"
                                >
                                </ista-export-data>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div *ngIf="!showSpinner && tableIst.isSorted" class="ml-auto alert group-filters">
                        <div class="d-flex align-items-end flex-column">
                            <div id="resetSortButton" class="buttonTri">
                                <button
                                    type="button"
                                    class="btn buttonClass secondaryButton"
                                    (click)="tableIst.resetSort()"
                                >
                                    {{ 'datatable.buttonSort' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wrapper">
        <app-installations-list
            #tableIst
            [lines]="lines"
            [columns]="columnsFiltred"
            [showSpinner]="showSpinner"
            [isPrincipal]="true"
            [events]="eventsSubject.asObservable()"
        >
        </app-installations-list>
    </div>
</div>
