<div class="detail responsive-text">
    <div class="container-fluid">
        <div class="d-flex align-items-start  p-2 ">
            <div class="d-flex flex-column px-2   mx-4 ">
                <div class="serie blue text-left">
                    <span *ngIf="activity.meter !== undefined">{{'dwelling.nMeter' | translate}}</span>
                </div>
                <div class="serie blue text-left">
                    <span *ngIf="activity.meter !== undefined">{{activity.meter.serialNumber}}</span>
                    <span *ngIf="activity.meter === undefined"> {{'dwellingDetail.undefined' | translate}}</span>
                </div>
            </div>
            <div *ngIf="aPlanifier()" class="d-flex flex-column align-items-start px-4  mx-0">
                <div *ngIf="activity.customerActionRequired || activity.remainingInstallation" class=" mt-2 p-1"
                    style="margin-left: 45px">
                    <span>{{'dwellingDetail.aPlanifier' | translate}}</span>
                </div>
                <div *ngIf="!activity.customerActionRequired && !activity.remainingInstallation" class=" mt-2 p-1"
                    style="margin-left: 45px">
                    <span>{{'dwellingDetail.aPlanifierIsta' | translate}}</span>
                </div>
            </div>

            <div *ngIf="!aPlanifier() && visits.length>0" class="d-flex flex-column align-items-start px-4  mx-5">
                <div class="passages pl-4 pb-1">
                    <span>{{findPassages()}}</span>
                </div>
                <div class="my-2">
                    <div *ngFor="let v of visits ">
                        <div class="d-flex align-items-center  m-0 p-0">
                            <div class="pictos ">
                                <!-- PUT HERE THE PICTOS
                                      <i  class="bookmark glyphicon glyphicon-bookmark mx-1" style="font-size:14px"></i>-->
                            </div>
                            <div class="date blue mx-2 ">
                                <span *ngIf="v.accomplishmentDate && v.accomplishmentDate!=='' "
                                    class="font-small">{{transformDate(v.accomplishmentDate)}}</span>
                                <span
                                    *ngIf="(!v.accomplishmentDate || v.accomplishmentDate==='') && activity.status !== 'PLANNED'"
                                    [tooltip]="'dwellingDetail.noDate' | translate" containerClass="tooltipClass"
                                    class="font-small">{{'dwellingDetail.noDateText' | translate}}</span>
                                <span
                                    *ngIf="(!v.accomplishmentDate || v.accomplishmentDate==='') && activity.status === 'PLANNED'">{{transformDate(v.planificationDate)}}</span>
                            </div>
                            <div *ngIf="v.notResolvedReason"
                                class="statut statutOrange statut border-bottom border-white mx-2 p-1">
                                <span [tooltip]="v.notResolvedReason" containerClass="tooltipClass">
                                    {{v.notResolvedReason }}
                                </span>
                            </div>
                            <div *ngIf="!v.notResolvedReason"
                                class=" statut {{getClassStatut()}} border-bottom border-white mx-2 p-1 ">
                                <span containerClass="tooltipClass"
                                    [tooltip]="getTitleStatut(v.accomplishmentDate)">{{getTextStatut(v.accomplishmentDate)}}</span>
                            </div>
                            <div class="comment ml-4">
                                <span [tooltip]="v.comment" containerClass="tooltipClass"
                                    placement="auto">{{v.comment}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
