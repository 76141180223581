import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, isDevMode, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InternRoutes } from '@core/enum/intern-routes.enum';
import { InstallationReport } from '@extern/worksites/model/installation-report.model';
import { InstallationLineService } from '@extern/worksites/service/installation-line.service';
import { InstallationReportService } from '@extern/worksites/service/installation-report.service';
import { WorksiteColorService } from '@extern/worksites/service/worksite-color.service';
import { AuthorizationService, formatDate } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@siemens/ngx-datatable';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { ExternRoutes } from 'src/app/core/enum/extern-routes.enum';
import { Column } from 'src/app/core/model/column.model';

import { ProductsConstants } from '../../../../../shared/constants/products.const';
import { WorksiteDwellingStatus } from '../../enum/worksite-period-status.enum';
import { Installation } from '../../model/installation.model';
import { NewInstallationLine } from '../../model/new-installation-line.model';

@Component({
    selector: 'app-installations-list',
    templateUrl: './installations-list.component.html',
    styleUrls: ['./installations-list.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstallationsListComponent implements OnInit, OnDestroy, AfterViewChecked {
    constructor(
        private router: Router,
        private translate: TranslateService,
        private isInternal: AuthorizationService,
        private installationReportService: InstallationReportService,
        private worksiteColor: WorksiteColorService,
        private installationLineService: InstallationLineService,
        private renderer: Renderer2,
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    @Input() lines: ReadonlyArray<NewInstallationLine>;
    @Input() isPrincipal: boolean;
    @Input() showSpinner: boolean;
    @ViewChild('tableIst') tableIst: DatatableComponent;
    @ViewChild('wrapper') divToMeasureElement: ElementRef;
    @ViewChild('autoShownModal', { static: false }) autoShownModal: ModalDirective;
    emptyMessages = {
        emptyMessage: `<span class="empty">${this.translate.instant('filters.noWorksiteForTheSelection')}</span>`,
    };
    modalRef: BsModalRef;
    columnMode = ColumnMode;
    selectionType = SelectionType;
    coordinates = { x: '0', y: '0' };
    modalSize = '0px';
    selected: NewInstallationLine;
    @Input() columns: Column[];
    isSorted = false;
    isModalShown = false;
    defaultDatePattern = 'DD/MM/YYYY';
    writeDate = ['datatable.worksite.dates.from', 'datatable.worksite.dates.to'];
    config = {
        backdrop: false,
        ignoreBackdropClick: false,
        class: 'my-modal',
    };
    listBlockStyle: string;
    eventsSubscription: Subscription;
    listBlockHeight: number;
    @Input() events: Observable<number>;
    isReportModalShown = false;
    newBaseUrl: string;

    ngOnDestroy(): void {
        if (this.isPrincipal) {
            this.eventsSubscription.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.translate.setDefaultLang('fr');
        if (this.isPrincipal) {
            this.eventsSubscription = this.events.subscribe((height) => {
                this.listBlockHeight = height;
                this.recalculate();
            });
        }
        this.isReportModalShown = false;
        this.installationReportService.setDisplayForDownloadedReports(this.lines);
    }

    ngAfterViewChecked(): void {
        if (this.tableIst) {
            this.tableIst.columnMode = this.columnMode.force;
        }
    }

    showModal(): void {
        this.isModalShown = true;
    }

    hideModal(): void {
        if (this.tableIst.selected && this.tableIst.selected.length === 1) {
            const installations = this.tableIst.selected[0].installations as ReadonlyArray<Installation>;
            let lastVisitDate = this.tableIst.selected[0].lastVisitDate.split('-').join('');
            let updateCachedData = false;
            installations.forEach((installation) => {
                if (installation.pdaStatus === 'INT') {
                    updateCachedData = true;
                    if (installation.pdaOldSerialNumber && installation.pdaOldSerialNumber.length > 2) {
                        installation.oldSerialNumber = installation.pdaOldSerialNumber;
                    }
                    if (installation.pdaSerialNumber) installation.serialNumber = installation.pdaSerialNumber;
                    if (installation.pdaLocation) installation.location = installation.pdaLocation;
                    installation.pdaStatus = '';
                    if (
                        installation.pdaLastVisitDate &&
                        installation.pdaLastVisitDate.split('-').join('') > lastVisitDate
                    ) {
                        lastVisitDate = installation.pdaLastVisitDate.split('-').join('');
                    }
                }
            });
            if (updateCachedData) {
                this.tableIst.selected[0].lastVisitDate =
                    lastVisitDate.substring(0, 4) +
                    '-' +
                    lastVisitDate.substring(4, 6) +
                    '-' +
                    lastVisitDate.substring(6);
                this.installationLineService.updateCachedDataByDwelling(this.tableIst.selected[0]);
            }
        }
        this.setElementsDisabled(false);
        this.isModalShown = false;
    }

    setElementsDisabled(disable: boolean) {
        const hideFiltersElement = document.getElementById('hide-filters');
        const selectColumnsElement = document.getElementById('select-columns');
        const groupElements = document.getElementsByClassName('filter');
        const groupFiltersElement =
            groupElements && groupElements.length === 1 ? (groupElements[0] as HTMLElement) : null;
        const exportGroupElement = document.getElementById('exportGroup');
        const resetSortElement = document.getElementById('resetSortButton');
        const elementsList = [
            hideFiltersElement,
            selectColumnsElement,
            groupFiltersElement,
            exportGroupElement,
            resetSortElement,
        ];

        elementsList.forEach((element) => {
            if (element) {
                if (disable) {
                    this.renderer.setStyle(element, 'pointer-events', 'none');
                    this.renderer.setStyle(element, 'opacity', '0.3');
                } else {
                    this.renderer.setStyle(element, 'pointer-events', '');
                    this.renderer.setStyle(element, 'opacity', '');
                }
            }
        });
    }

    onSelectTest(event: any): void {
        if (this.divToMeasureElement) {
            this.modalSize = this.divToMeasureElement.nativeElement.offsetWidth - 30 + 'px';
            const size = this.divToMeasureElement.nativeElement.offsetHeight;
        }
        if (this.isPrincipal && event.row.dwellingStatus === 'IN_PROGRESS') {
            if (
                event.type === 'click' &&
                event.cellIndex === 9 &&
                event.row.lastVisitResult === 'O' &&
                this.formatLastVisitResult(event.row).length === 0
            ) {
                this.selected = event.row;
                this.coordinates.y = this.getModalYcoordinate(event);
                this.tableIst.selected = [this.selected];
                this.isModalShown = true;
                this.setElementsDisabled(true);
            }
        }
    }

    getModalYcoordinate(event: any): string {
        const clientZoneHeight = window.innerHeight;
        const footerElement = document.getElementById('footer');
        const footerHeight = footerElement ? footerElement.offsetHeight : 0;
        const selectedRowIndex = this.tableIst.bodyComponent.getRowIndex(event.row); // row index selected by display order
        let yCoordinate = 0;
        if (selectedRowIndex + 1 > this.tableIst.rowCount - 4) {
            // Case for the 4 last lines
            yCoordinate = event.event.y - (this.tableIst.rowHeight as number) * 1.4 - 230;
        } else if (event.event.y <= clientZoneHeight - (footerHeight + 5) - 230) {
            // bottom table coordinate - modal height
            // Case where we can display the modal after the selected line
            yCoordinate = event.event.y;
        } else {
            // Case where we have to scroll the list in order to display the modal
            const selectedRowHeight = this.tableIst.bodyComponent.getRowHeight(this.tableIst.rows[selectedRowIndex]);
            const currentPageRatio =
                (this.tableIst.bodyComponent.offsetY + selectedRowHeight * 4.5) /
                selectedRowHeight /
                this.tableIst.pageSize;
            this.tableIst.bodyComponent.updateOffsetY(currentPageRatio);
            yCoordinate = event.event.y - selectedRowHeight * 4;
        }
        return yCoordinate + 'px';
    }

    recalculate(): void {
        this.listBlockStyle = `height: ${this.listBlockHeight}px;`;
        this.tableIst.recalculate();
        this.changeDetectorRef.detectChanges();
    }

    getStyle(): string {
        return this.isModalShown ? 'grayout' : '';
    }

    onSelect(selected: NewInstallationLine): void {
        if (selected.dwellingStatus === WorksiteDwellingStatus.NOT_INSTALLED_YET) {
            this.router.navigate([
                this.isInternal.isInternalAccountType()
                    ? InternRoutes.DWELLING_ROUTE_INSTALLATIONS
                    : ExternRoutes.DWELLING_ROUTE_INSTALLATIONS,
                selected.dwellingId,
            ]);
        }
    }

    resetSort(): void {
        this.tableIst.sorts = [];
        this.tableIst.rows = [...this.tableIst.rows];
        this.isSorted = false;
    }
    viewSort(): void {
        this.isSorted = true;
    }

    public transformDate(dateToTranform: string): string {
        return formatDate(dateToTranform, this.defaultDatePattern);
    }

    getCursorStyle(status: string): string {
        return status === WorksiteDwellingStatus.NOT_INSTALLED_YET ? 'pointer' : 'auto';
    }

    toggleExpandRow(row: NewInstallationLine): void {
        this.tableIst.rowDetail.toggleExpandRow(row);
    }

    public getSizeProductTotal(p: string, value: Array<Installation>): number {
        return value.filter((v) => v.meterType === p).length;
    }

    public getSizeProductDone(p: string, value: Array<Installation>): number {
        return value.filter((v) => v.meterType === p && v.installed).length;
    }

    public findProducts(value: Array<Installation>): Array<string> {
        return value.map((x) => x.meterType).filter((x, i, a) => a.indexOf(x) === i);
    }

    public getLabelProduct(v: string): string {
        return ProductsConstants.getLabelProduct(v);
    }

    public getMeterLabel(productCode: string, meterFluid: string): string {
        return ProductsConstants.getMeterLabel(productCode, meterFluid);
    }

    public getStatusBackgroundColor(status: string): string {
        return this.worksiteColor.getColor(status as WorksiteDwellingStatus);
    }

    public getDwellingIstaReference(row: NewInstallationLine): String {
        return row.dwellingId.startsWith(row.propertyId)
            ? row.dwellingId.substring(row.propertyId.length)
            : row.dwellingId;
    }

    public formatLastVisitResult(row: NewInstallationLine): any {
        const productType = ProductsConstants.getMeterType(row.productCode, row.fluidCode);
        let lastVisitResultList: Array<string> = [];
        if (
            row.dwellingStatus === WorksiteDwellingStatus.IN_PROGRESS &&
            this.isPrincipal &&
            row.installations.map((i) => i.pdaStatus).filter((s) => s === 'PDA' || s === 'INT').length > 0
        ) {
            row.lastVisitResult = 'O';
            return [];
        }
        row.installations.forEach((installation) => {
            let visitResult = '';
            if (installation.location && installation.location.trim().length > 0)
                visitResult += installation.location.trim();
            if (productType) visitResult += visitResult.length === 0 ? productType : ' - ' + productType;
            if (installation.serialNumber && installation.serialNumber.trim().length > 0) {
                visitResult += ' : ' + installation.serialNumber.trim();
            } else if (installation.notInstalledLabel && installation.notInstalledLabel.trim().length > 0) {
                visitResult += ' : ' + installation.notInstalledLabel.trim();
            }
            if (visitResult.length > 0) {
                lastVisitResultList.push(visitResult);
            }
        });
        return lastVisitResultList;
    }

    public getSizeDone(value: Array<Installation>): number {
        return new Set(value.filter((v) => v.installed).map((v) => v.meterId)).size;
    }

    getAddress(row: NewInstallationLine): string {
        return row.address.concat(' ', row.zip, ' ', row.city);
    }

    flexGrow(col: string): number {
        switch (col) {
            case 'doneVisits':
            case 'nextVisitSlot':
                return 3;
            case 'property.name':
            case 'address':
            case 'dwelling.owner':
            case 'datatable.worksite.columns.nextVisit':
                return 2;
            default:
                return 5;
        }
    }

    fetchInstallationsReport(row: NewInstallationLine): void {
        // To display the spinner
        row.report.available = undefined;
        Promise.resolve(
            this.installationReportService.fetchReportByDwellingWorksite(row.dwellingId, row.worksiteId).toPromise()
        )
            .then((promise) => {
                const reportFetched = promise;
                if (reportFetched !== undefined) {
                    reportFetched.available = true;
                    reportFetched.downloaded = false;
                    row.report = reportFetched;
                    this.downloadInstallationReport(
                        row.report,
                        row.report.filename as string,
                        row.report.link as string
                    );
                    if (isDevMode()) {
                        console.log('Installation report found: ' + row.report.id);
                    }
                } else {
                    row.report.available = false;
                    row.report.dwelling = row.dwellingId;
                    row.report.worksiteId = row.worksiteId;
                    this.isReportModalShown = true;
                    const storedDownloadedReports = sessionStorage.getItem('downloadedReports') as string;
                    let downloadedReports: Array<InstallationReport> = [];
                    if (storedDownloadedReports.length !== 0) {
                        downloadedReports = JSON.parse(storedDownloadedReports);
                    }
                    downloadedReports.push(row.report);
                    sessionStorage.setItem('downloadedReports', JSON.stringify(downloadedReports));
                    if (isDevMode()) {
                        console.log('Installation report not found.');
                    }
                }
            })
            .catch(() => {
                row.report.available = false;
                this.isReportModalShown = true;
                if (isDevMode()) {
                    console.log('Installation report data not found.');
                }
            });
    }

    downloadInstallationReport(report: InstallationReport, fileName: string, link: string): void {
        this.installationReportService.downloadReport(link).subscribe(
            (reportData) => {
                const anchor = document.createElement('a');
                anchor.href = window.URL.createObjectURL(reportData);
                anchor.download = fileName;
                document.body.appendChild(anchor);
                anchor.click();
                anchor.remove();

                URL.revokeObjectURL(anchor.href);
            },
            () => {
                this.isReportModalShown = true;
                if (isDevMode()) {
                    console.log('Error while downloading the installaton report: ' + report.id);
                }
            },
            () => {
                report.downloaded = true;
                const storedDownloadedReports = sessionStorage.getItem('downloadedReports') as string;
                let downloadedReports: Array<InstallationReport> = [];
                if (storedDownloadedReports.length !== 0) {
                    downloadedReports = JSON.parse(storedDownloadedReports);
                }
                downloadedReports.push(report);
                sessionStorage.setItem('downloadedReports', JSON.stringify(downloadedReports));
                if (isDevMode()) {
                    console.log('Installation report downloaded: ' + report.id);
                }
            }
        );
    }

    hideReportModal(): void {
        this.isReportModalShown = false;
    }

    hasRelevantStatusForReport(row: NewInstallationLine): boolean {
        return this.installationReportService.hasRelevantStatusForReport(row);
    }

    setDisplayForDownloadedReports(): void {
        this.installationReportService.setDisplayForDownloadedReports(this.lines);
    }
}
