import { Component, Input, OnChanges, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectLandingPage } from '@core/enum/redirect-landing-page.enum';
import { Entry } from '@core/model/entry.model';
import { ActivityStatus } from '@extern/activities/enum/activity-status.enum';
import { ActivityColorService } from '@extern/activities/service/activity-color.service';
import { ActivityFilterSessionStorageService } from '@extern/activities/service/activity-filter-session-storage.service';
import { AuthorizationService } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';

@Component({
    selector: 'app-activity-synthesis',
    templateUrl: './activity-synthesis.component.html',
    styleUrls: ['./activity-synthesis.component.css'],
})
export class ActivitySynthesisComponent implements OnInit, OnChanges {
    @Input() newGraphData: ReadonlyArray<
        Entry<
            ActivityStatus,
            ReadonlyArray<{
                propertyId: string;
                dwellingId: string;
            }>
        >
    > = [];
    @Input() showSpinner: boolean;

    statusesByProperties: Array<{ color: string; value: number }> = [];
    statusesByDwellings: Array<{ color: string; value: number }> = [];

    values: Array<{ name: string; y: number; color: string }> = [];
    synthesis: Chart;
    selection = 0;
    newEndpoint = true;

    public constructor(
        private router: Router,
        private translate: TranslateService,
        private activityColorService: ActivityColorService,
        private filterSessionStorage: ActivityFilterSessionStorageService,
        private renderer: Renderer2,
        private isInternal: AuthorizationService
    ) {}

    ngOnInit(): void {
        this.translate.setDefaultLang('fr');
        this.selection = this.filterSessionStorage.getFiltersPropertyIds('properties').length;
        this.initChart(this.router, this.translate, this.filterSessionStorage, this.isInternal);
    }

    ngOnChanges(): void {
        this.selection = this.filterSessionStorage.getFiltersPropertyIds('properties').length;
        this.initChart(this.router, this.translate, this.filterSessionStorage, this.isInternal);
        this.resizeSynthesisBlock();
    }

    initChart(
        router: Router,
        translate: TranslateService,
        filterSessionStorage: ActivityFilterSessionStorageService,
        isInternal: AuthorizationService
    ): void {
        this.values = [];
        if (!this.newGraphData) {
            return;
        }
        this.constructSynthesis();
        const showLoading = this.showSpinner;
        const statuses = this.extractStatuses();
        const clientZoneHeight = window.innerHeight;
        this.synthesis = new Chart({
            chart: {
                type: 'column',
                height: clientZoneHeight * 0.7,
                style: {
                    fontFamily: 'TheSansE5-SemiLight',
                },
                marginTop: 100,
                marginBottom: 40,
                marginLeft: 65,
                spacingBottom: 25,
                events: {
                    click(): void {
                        router.navigate([
                            (isInternal.isInternalAccountType() ? 'interne' : '') +
                                RedirectLandingPage.ACTIVITIES_LIST_PAGE,
                        ]);
                    },
                    load(): void {
                        showLoading
                            ? this.showLoading(
                                // '<i class="fas fa-spinner fa-pulse" style="color: rgb(0, 113, 240); font-size: 40px;"></i>'
                                ''
                              )
                            : this.hideLoading();
                    },
                },
            },
            title: {
                text: '',
                useHTML: true,
                style: {},
            },
            subtitle: {},
            xAxis: {
                categories: statuses.map((status) =>
                    translate.instant(`dashboard.activities.chart.legend.${status.toLowerCase()}`)
                ),
                lineWidth: 0,
                labels: {
                    useHTML: true,
                    style: {
                        color: '#526378',
                        fontSize: '12px',
                    },
                },
            },
            yAxis: {
                labels: {
                    enabled: false,
                },
                gridLineWidth: 0,
                visible: false,
                floor: 0,
                minRange: 20,
            },
            loading: {
                labelStyle: {
                    display: 'block',
                    top: '30%',
                    opacity: 1,
                    left: '-18px',
                },
                style: {
                    display: 'block',
                    top: '30%',
                    left: '-18px',
                    opacity: 1,
                },
            },
            legend: {
                useHTML: true,
                align: 'left',
                layout: 'vertical',
            },
            tooltip: {
                backgroundColor: '#1E293B',
                borderRadius: 10,
                borderWidth: 1,
                borderColor: '#1E293B',
                style: {
                    border: '1px solid #1E293B',
                    background: '#1E293B',
                    color: '#F4F7F9',
                    padding: '10px 10px 10px 10px',
                    fontFamily: 'TheSansE5-Plain',
                    fontSize: '12px',
                },
                useHTML: true,
                formatter(): string {
                    const tooltipPrefix = `<div [tooltip]="tooltip">`;
                    const tooltipSuffix = `</div>`;
                    let statusMsg = '';
                    statusMsg += translate.instant(
                        `dashboard.activities.chart.point.tooltip.${this.point.name.toLowerCase()}`
                    );
                    return tooltipPrefix.concat(statusMsg).concat(tooltipSuffix);
                },
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    showInLegend: false,
                    data: this.values,
                    colorByPoint: true,
                    type: 'column',
                },
            ],
            plotOptions: {
                series: {
                    stacking: 'normal',
                    cursor: 'pointer',
                    point: {
                        events: {
                            click(): void {
                                filterSessionStorage.setFiltersProperty('statuses', [
                                    { name: `activity.status.${this.name}`, ids: [this.name] },
                                ]);
                                router.navigate([
                                    (isInternal.isInternalAccountType() ? 'interne' : '') +
                                        RedirectLandingPage.ACTIVITIES_LIST_PAGE,
                                ]);
                            },
                        },
                    },
                },
                column: {
                    dataLabels: {
                        enabled: true,
                        overflow: 'allow',
                        crop: false,
                        color: '#526378',
                        y: -30,
                        verticalAlign: 'top',
                        style: {
                            fontSize: '20px',
                        },
                    },
                    pointWidth: 40,
                },
            },
        });
    }

    constructSynthesis(): void {
        this.values = [];
        this.statusesByProperties = [];
        this.statusesByDwellings = [];
        this.extractStatuses().forEach((status) => {
            const matchingReferences = this.newGraphData.filter((x) => x.key === status)[0];
            const statusColor = this.activityColorService.getColorByCode((ActivityStatus as any)[status]);
            this.values.push({
                name: status,
                y: matchingReferences ? matchingReferences.value.length : 0,
                color: statusColor,
            });
            this.statusesByProperties.push({
                color: statusColor,
                value: matchingReferences
                    ? [...new Set(matchingReferences.value.map((ref) => ref.propertyId))].length
                    : 0,
            });
            this.statusesByDwellings.push({
                color: statusColor,
                value: matchingReferences
                    ? [...new Set(matchingReferences.value.map((ref) => ref.dwellingId))].length
                    : 0,
            });
        });
    }

    redirectToList(): void {
        this.router.navigate([
            (this.isInternal.isInternalAccountType() ? 'interne' : '') + RedirectLandingPage.ACTIVITIES_LANDING_PAGE,
        ]);
    }

    private extractStatuses(): string[] {
        return Object.keys(ActivityStatus).filter((item) => isNaN(Number(item)));
    }

    resizeSynthesisBlock(): void {
        const synthesisBlockElement = document.getElementById('synthesisId');
        const synthesisXaxisElement = synthesisBlockElement?.children[0].firstElementChild;
        if (synthesisXaxisElement?.children) {
            for (var i = 0; i < synthesisXaxisElement?.children.length; i++) {
                const subChild = synthesisXaxisElement?.children.item(i) as HTMLElement;
                if (subChild && subChild.children.length === 6) {
                    const firstElement = subChild.children[0] as HTMLElement;
                    if (firstElement.style.transform.includes('rotate(')) {
                        const synthesisRef = this.synthesis.ref$;
                        synthesisRef.subscribe((chartObject) => {
                            const chartOptions = chartObject.options;
                            if (chartOptions) {
                                const chart = chartOptions.chart;
                                if (chart) {
                                    if (firstElement.style.transform.includes('rotate(-45deg)'))
                                        chart.marginBottom = 80;
                                    else chart.marginBottom = 40;
                                }
                                chartObject.redraw(true);
                            }
                        });
                        break;
                    }
                }
            }
        }
        if (synthesisXaxisElement) this.renderer.setStyle(synthesisXaxisElement, 'text-align', '');
    }
}
