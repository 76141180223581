export enum FilterName {
    DWELLINGS = 'dwellings',
    PROPERTIES = 'properties',
    ADDRESSES = 'addresses',
    STATUSES = 'statuses',
    WORKSITE_STATUSES = 'worksiteStatuses',
    DATES = 'dates',
    PRODUCTS = 'products',
    PERIODS = 'periods',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    COLUMNS = 'columns',
    COLUMNS_DETAILS = 'columnsDetails',
    SERIAL_NUMBERS = 'serialNumbers',
    ACTIVITY_TYPES = 'activityTypes',
    REASONS = 'reasons',
    SUBJECTS = 'subjects',
    VISITS_COUNT = 'visitsCount'
}


