<ngx-datatable id="table2" #table2 class="material expandable table linefeed" [rows]="rows" [messages]="messages"
    [columnMode]="ColumnMode.force" [footerHeight]="0" [rowHeight]="40" [scrollbarV]="true" (sort)="viewSort()"
    (onResize)="handleResize($event)" [loadingIndicator]="showSpinner">
    <ngx-datatable-column *ngFor="let col of columns" [name]="col.name" [prop]="col.prop" [width]="col.width"
        [sortable]="col.sortable" [comparator]="col.comparator">
        <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
            <div class="d-inline-block datatable-header-cell-wrapper" style="height: 40px;white-space: initial; ">
                <span class="datatable-header-cell-label draggable" (click)="sort()">{{column.name | translate}}</span>
            </div>
            <ista-interrogation-point *ngIf="column.prop === 'propertyAddress'" class="interrogationPointPosition" placement="right"
                htmlText="{{'installations.dashboard.worksiteTable.tooltip.propertyAddress' | translate}}">
            </ista-interrogation-point>
            <ista-interrogation-point *ngIf="column.prop === 'status'" placement="left"
                htmlText="{{'installations.dashboard.tableChantiers.tooltip.status' | translate}}">
            </ista-interrogation-point>
            <ista-interrogation-point *ngIf="column.prop === 'worksitePeriod'" placement="right"
                htmlText="{{'installations.dashboard.tableChantiers.tooltip.worksitePeriod' | translate}}">
            </ista-interrogation-point>
            <ista-interrogation-point *ngIf="column.prop === 'worksitePlanned'" placement="right"
                text="{{'installations.dashboard.worksiteTable.tooltip.worksitePlanned' | translate}}">
            </ista-interrogation-point>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <div *ngIf="row !== undefined" style="cursor: pointer;" (click)="onClickOnePropertyLine(row)">
                <div *ngIf="col.prop === 'worksitePeriod'">
                    <div *ngIf="value === ''" style="margin-left: 80px;">-</div>
                    <div *ngIf="value !== ''">{{value}}</div>
                </div>
                <div *ngIf="col.prop !== 'worksitePeriod' &&  col.prop !== 'worksitePlanned'">
                    <div *ngIf="value === ''" style="margin-left: 15px;">-</div>
                    <div *ngIf="value !== ''">{{value}}</div>
                </div>
                <div *ngIf="col.prop === 'worksitePlanned'">
                    <div>{{value}}
                        <span
                            *ngIf="(row.worksiteType === 'NE' || row.worksiteType === 'NA') && (row.worksiteDone !== row.worksitePlanned)">
                            <ng-container *ngIf="row.worksitePlanned > 1">{{'installations.dashboard.estimated' |
                                translate}}</ng-container>
                            <ng-container *ngIf="row.worksitePlanned === 1">{{'installations.dashboard.oneEstimated' |
                                translate}}</ng-container>
                        </span>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer></ngx-datatable-footer>
</ngx-datatable>