import { Injectable } from '@angular/core';
import { SortService } from '@ista/shared-ui';
import { InstallationDashboardStatusSortedEnum } from '@extern/worksites/enum/installation-dashboard-status-enum-sorted';
import { WorksiteSynthesisLine } from '../model/worksite-synthesis-line.model';
import { InstallationDashboardTypeFluidSortedEnum } from '../enum/installation-dashboard-type-fluid-enum-sorted';

export enum InstallationDashboardOrderCriterias {
    STATUS = 'STATUS',
    PERIODE_START_DATE = 'PERIODE START DATE',
    PERIODE_END_DATE = 'PERIODE END DATE',
    TYPE_APPAREIL = 'TYPE APPAREIL',
}

@Injectable({
    providedIn: 'root'
})
export class InstallationDashboardSortingService extends SortService<WorksiteSynthesisLine> {

    defaultDatePattern = 'YYYYMMDD';

    constructor() {
        super();
        this.priorities.set(InstallationDashboardOrderCriterias.STATUS, this.ONE);
        this.priorities.set(InstallationDashboardOrderCriterias.PERIODE_START_DATE, this.TWO);
        this.priorities.set(InstallationDashboardOrderCriterias.PERIODE_END_DATE, this.THREE);
        this.priorities.set(InstallationDashboardOrderCriterias.TYPE_APPAREIL, this.FOUR);

    }

    isSortable(line: WorksiteSynthesisLine): boolean {
        return line !== undefined && line !== null;
    }

    getValue(criteria: string, line: WorksiteSynthesisLine): number {

        if (!criteria || !line) {
            return this.ZERO;
        } else if (criteria === InstallationDashboardOrderCriterias.STATUS) {
            return this.getStatusValue(line);
        } else if (criteria === InstallationDashboardOrderCriterias.PERIODE_START_DATE) {
            return this.comparestartDates(line.periodeModel.startDate) * 100;
        } else if (criteria === InstallationDashboardOrderCriterias.PERIODE_END_DATE) {
            return this.comparestartDates(line.periodeModel.endDate);
        } else if (criteria === InstallationDashboardOrderCriterias.TYPE_APPAREIL) {
            return this.compareTypeAppareil(line);
        }

        return this.ZERO;
    }

    compareTypeAppareil(line: WorksiteSynthesisLine): number {
        return InstallationDashboardTypeFluidSortedEnum.getPositionFromLabel(line.worksiteMeterTypeEnum);
    }

    comparestartDates(date: string): number {
        return this.getDateValueDescending(date);
    }

    private getStatusValue(line: WorksiteSynthesisLine): number {
        const position = InstallationDashboardStatusSortedEnum.getPositionFromLabel(line.statusEnum);
        return Math.pow(position, this.MIN_COEF);

    }

}
