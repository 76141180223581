import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-extern-logout',
    templateUrl: './extern-logout.component.html',
    styleUrls: ['./extern-logout.component.css']
})
export class ExternLogoutComponent {

    expired = false;
    constructor(activatedroute: ActivatedRoute) {
        activatedroute.data.subscribe(data => {
            this.expired = data.expired;
        });
    }

}
