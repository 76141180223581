import { Component, HostListener, OnInit } from '@angular/core';
import { AuthenticationService, Error, HandleErrorService, HelpStatus, SidebarItem, UserInfo, UserService } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';

import { RedirectLandingPage } from '../../../../core/enum/redirect-landing-page.enum';
import { SelfcareIndexedDbService } from '../../../../core/service/selfcare-indexed-db.service';
import { SelfcareSessionStorage } from '../../../../core/service/selfcare-session-storage.service';

@Component({
    selector: 'app-intern-template',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.css']
})
export class TemplateComponent implements OnInit {

    fullName: string;
    userInfos: UserInfo;
    displayedAuthority = '';
    userConnectedAs = 'user.connectedAs';
    error = false;
    basePath = '';
    errorMessage = '';
    defaultItems: Array<SidebarItem> = [];

    constructor(
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        private handleErrorService: HandleErrorService,
        private sessionStorage: SelfcareSessionStorage,
        private selfcareIndexedDbService: SelfcareIndexedDbService) {
    }

    ngOnInit(): void {
        this.sidebarItems();
        const sideBarShowItems = sessionStorage.getItem('sidebar_show_items');
        if (sideBarShowItems === null) {
            sessionStorage.setItem('sidebar_show_items', 'false');
        }
        this.userService.fetchUserInfos().subscribe(info => this.fullName = info.firstName.concat(' ', info.lastName));
        this.getError();
        this.sessionStorage.watchStorage().subscribe(event => {
            if (event === 'sidebarChanges') {
                this.sidebarItems()
            }
        });
    }

    sidebarItems(): void {
        this.defaultItems = [
            {
                id: 'selection',
                label: this.translate.instant('sidebar.menu.menuitem.selection'),
                iconClass: 'edit',
                uri: RedirectLandingPage.INTERN_LANDING_PAGE,
                displayed: true,
                active: true
            },
            {
                id: 'dashboard',
                label: this.translate.instant('sidebar.menu.menuitem.dashboard'),
                iconClass: 'build',
                uri: 'interne' + RedirectLandingPage.ACTIVITIES_LANDING_PAGE,
                displayed: true,
                active: false
            },
            {
                id: 'activities',
                label: this.translate.instant('sidebar.menu.menuitem.activities'),
                iconClass: './assets/images/activities.svg',
                uri: 'interne' + RedirectLandingPage.ACTIVITIES_LIST_PAGE,
                displayed: !this.sessionStorage.isDetailedActivitiesView(),
                active: false
            },
            {
                id: 'activitiesDetailed',
                label: this.translate.instant('sidebar.menu.menuitem.activities'),
                iconClass: './assets/images/activities.svg',
                uri: 'interne' + RedirectLandingPage.ACTIVITIES_DETAILED_LIST_PAGE,
                displayed: this.sessionStorage.isDetailedActivitiesView(),
                active: false
            },
            {
                id: 'installationsDashboard',
                label: this.translate.instant('sidebar.menu.menuitem.installationsDashboard'),
                iconClass: 'home_repair_service',
                uri: 'interne' + RedirectLandingPage.WORKSITES_LANDING_PAGE,
                displayed: true,
                active: false
            },
            {
                id: 'installations',
                label: this.translate.instant('sidebar.menu.menuitem.installations'),
                iconClass: './assets/images/installations.svg',
                uri: 'interne' + RedirectLandingPage.WORKSITES_LIST_PAGE,
                displayed: true,
                active: false
            },
        ];
    }

    // Handle HTTP Errors
    public getError(): void {
        this.handleErrorService.observableError().subscribe(x => {
            this.error = x.message === '' || x.status === 404 ? false : true;
            this.errorMessage = x.message;
        });
    }

    // Handle HTTP Errors
    @HostListener('document:click', ['$event'])
    documentClick(event: MouseEvent): void {
        this.handleErrorService.handleError(new Error(''));
        this.errorMessage = '';
    }

    // Handle HTTP Errors
    ngOnChanges(): void {
        this.getError();
    }

    logout(): void {
        this.authenticationService.logout(true);
        this.fullName = '';
        this.sessionStorage.clear();
        sessionStorage.clear();
    }

    refresh(): void {
        this.selfcareIndexedDbService.remove();
        window.location.reload();
    }

    showHideHelp(): void {
        const newHelpStatus = this.sessionStorage.getHelpStatus() === HelpStatus.SHOW ? HelpStatus.HIDE : HelpStatus.SHOW;
        this.sessionStorage.setHelpStatus(newHelpStatus);
    }
}
