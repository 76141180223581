import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { InstallationDashboardSortingService } from '@extern/worksites/service/installation-dashboard-sorting.service';
import { Customer } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { ListUtilService } from '@shared/util/list-util.service';
import { Row, Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';

import { WorksiteSynthesisLine } from '../../features/extern/worksites/model/worksite-synthesis-line.model';
import * as excelUtil from '../util/excel-util';

@Injectable({
    providedIn: 'root',
})
export class ExcelInstallationDashboardService {
    constructor(
        private translate: TranslateService,
        private sortingService: InstallationDashboardSortingService,
        private listUtil: ListUtilService,
        @Inject(LOCALE_ID) public locale: string
    ) {}

    public generateExcelFromInstallationsList(
        customer: Customer,
        data: ReadonlyArray<WorksiteSynthesisLine>,
        filteredResidences: ReadonlyArray<string>,
        filteredProducts: ReadonlyArray<string>,
        filteredStatuses: ReadonlyArray<string>,
        filteredAddresses: ReadonlyArray<string>,
        filteredPeriod: string
    ): void {
        const sortedData = this.sortingService.sort(data);

        const worksheetName = `${this.translate.instant('excel.installations.worksheet.names.summary')}`;

        const title = this.translate.instant('excel.installationsDashboard.title');
        const creationDate = this.translate.instant('excel.installationsDashboard.documentCreationDate');

        const columnsDataHeaders = [
            null,
            null,
            this.translate.instant('excel.installationsDashboard.columns.residences'),
            this.translate.instant('excel.installationsDashboard.columns.adresse'),
            this.translate.instant('excel.installationsDashboard.columns.worksiteNumber'),
            this.translate.instant('excel.installationsDashboard.columns.worksiteStatus'),
            this.translate.instant('excel.installationsDashboard.columns.visitsDates'),
            this.translate.instant('excel.installationsDashboard.columns.productType'),
            this.translate.instant('excel.installationsDashboard.columns.fluid'),
            this.translate.instant('excel.installationsDashboard.columns.expected'),
            this.translate.instant('excel.installationsDashboard.columns.done'),
            this.translate.instant('excel.installationsDashboard.columns.rate'),
        ];

        const columnComplement = 0.78; // https://github.com/exceljs/exceljs/issues/744

        const worksheetColumns = [
            { key: 'A', width: 21.67 + columnComplement },
            { key: 'B', width: 3.11 + columnComplement },
            { key: 'C', width: 30 + columnComplement },
            { key: 'D', width: 40 + columnComplement },
            { key: 'E', width: 12 + columnComplement },
            { key: 'F', width: 12 + columnComplement },
            { key: 'G', width: 22 + columnComplement },
            { key: 'H', width: 12 + columnComplement },
            { key: 'I', width: 12 + columnComplement },
            { key: 'J', width: 11.11 + columnComplement },
            { key: 'K', width: 6.67 + columnComplement },
            { key: 'L', width: 11.11 + columnComplement },
            { key: 'M', width: 10.78 + columnComplement },
            { key: 'N', width: 10.78 + columnComplement },
            { key: 'O', width: 10.78 + columnComplement },
        ];

        // Create the workbook
        const workbook: Workbook = new Workbook();

        // From the workbook, create a new sheet
        const worksheet: Worksheet = this.createWorksheet(
            workbook,
            worksheetName,
            'landscape',
            sortedData.length + excelUtil.headerlines
        );

        // Define worksheet's columns
        worksheet.columns = worksheetColumns;

        const maxLinesForSelectedFilters = 5;

        const showSelectedFilters: boolean =
            filteredResidences.length <= maxLinesForSelectedFilters &&
            filteredProducts.length <= maxLinesForSelectedFilters &&
            filteredStatuses.length <= maxLinesForSelectedFilters &&
            filteredAddresses.length <= maxLinesForSelectedFilters;
        // One, insert data...
        worksheet.addRow([title]);
        worksheet.addRow([creationDate, null, moment().format('DD/MM/YYYY')]);
        worksheet.addRow([]);

        worksheet.addRow([
            `${this.translate.instant('excel.installations.headers.intendedFor')} :`,
            null,
            `${this.translate.instant('excel.installations.headers.filters')} :`,
            null,
        ]);

        worksheet.addRow([
            customer.name,
            null,
            `${this.translate.instant('excel.installationsDashboard.filtresHeaders.residences')}: ${filteredResidences.length === 0 ? 'toutes' : ''}`,
            `${this.translate.instant('excel.installationsDashboard.filtresHeaders.addresses')}: ${filteredAddresses.length === 0 ? 'toutes' : ''}`,
            null,
            `${this.translate.instant('excel.installationsDashboard.filtresHeaders.productType')}: ${filteredProducts.length === 0 ? 'tous' : ''}`,
            null,
            null,
            `${this.translate.instant('excel.installationsDashboard.filtresHeaders.status')}: ${filteredStatuses.length === 0 ? 'tous' : ''}`,
            null,
            null,
            `${this.translate.instant('excel.installationsDashboard.filtresHeaders.visitsDates')}: ${filteredPeriod === null ? 'toutes' : ''}`,
            null,
            null,
            null,
            null,
        ]);

        worksheet.addRow([
            customer.address,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
        ]);
        const city: string = new Array(customer.postalCode, customer.city).join(' ');
        worksheet.addRow([city]);

        worksheet.addRow([]);
        worksheet.addRow([]);

        // Insert filters values
        const filterValueStartIndex = 6;
        const minLineNumberForHeaderBlocks = 6;
        let maxLineNumberForHeaderBlocks = minLineNumberForHeaderBlocks;
        if (showSelectedFilters) {
            const nbResidences = filteredResidences.length;
            const nbAddress = filteredAddresses.length;
            const nbProducts = filteredProducts.length;
            const nbStatus = filteredStatuses.length;
            const maxLines: number = Math.max(nbResidences, nbAddress, nbProducts, nbStatus);

            for (let i = filterValueStartIndex; i < maxLines + filterValueStartIndex; i++) {
                worksheet.getCell(`C${i}`).value = '';
                worksheet.getCell(`D${i}`).value = '';
                worksheet.getCell(`F${i}`).value = '';
                worksheet.getCell(`I${i}`).value = '';
                worksheet.getCell(`L${i}`).value = '';
            }

            filteredResidences.forEach((value, index, array) => {
                const cellNumber = filterValueStartIndex + index;
                worksheet.getCell(`C${cellNumber}`).value = value;
                maxLineNumberForHeaderBlocks =
                    cellNumber > maxLineNumberForHeaderBlocks ? cellNumber : maxLineNumberForHeaderBlocks;
            });

            filteredAddresses.forEach((value, index, array) => {
                const cellNumber = filterValueStartIndex + index;
                worksheet.getCell(`D${cellNumber}`).value = value;
                maxLineNumberForHeaderBlocks =
                    cellNumber > maxLineNumberForHeaderBlocks ? cellNumber : maxLineNumberForHeaderBlocks;
            });

            filteredProducts.forEach((value, index, array) => {
                const cellNumber = filterValueStartIndex + index;
                worksheet.getCell(`F${cellNumber}`).value = value;
                maxLineNumberForHeaderBlocks =
                    cellNumber > maxLineNumberForHeaderBlocks ? cellNumber : maxLineNumberForHeaderBlocks;
            });

            filteredStatuses.forEach((value, index, array) => {
                const cellNumber = filterValueStartIndex + index;
                worksheet.getCell(`I${cellNumber}`).value = value;
                maxLineNumberForHeaderBlocks =
                    cellNumber > maxLineNumberForHeaderBlocks ? cellNumber : maxLineNumberForHeaderBlocks;
            });

            worksheet.getCell(`L${filterValueStartIndex}`).value = filteredPeriod;
            worksheet.addRow([]);
            if (maxLines < 5) {
                worksheet.addRow([]);
            }
        } else {
            worksheet.getCell('C5').value = worksheet
                .getCell('C5')
                .text.concat(' ', filteredResidences.length > 0 ? filteredResidences.length.toString() : '');
            worksheet.getCell('D5').value = worksheet
                .getCell('D5')
                .text.concat(' ', filteredAddresses.length > 0 ? filteredAddresses.length.toString() : '');
            worksheet.getCell('F5').value = worksheet
                .getCell('F5')
                .text.concat(' ', filteredProducts.length > 0 ? filteredProducts.length.toString() : '');
            worksheet.getCell('I5').value = worksheet
                .getCell('I5')
                .text.concat(' ', filteredStatuses.length > 0 ? filteredStatuses.length.toString() : '');
            worksheet.getCell('L5').value = worksheet.getCell('L5').text.concat(' ', filteredPeriod);

            // Insert filters into new worksheet
            this.createFilterWorksheet(
                workbook,
                filteredResidences,
                filteredAddresses,
                filteredProducts,
                filteredStatuses,
                filteredPeriod
            );
            worksheet.addRow([]);
            worksheet.addRow([]);
        }

        // Insert installations values
        const columnsTab: Row = this.fillTableValues(worksheet, columnsDataHeaders, sortedData);

        //color table footer
        this.colorTableFooter(worksheet);

        // color table header
        this.colorTableHeader(worksheetColumns, worksheet, minLineNumberForHeaderBlocks, maxLineNumberForHeaderBlocks);

        columnsTab.height = 28.8;
        columnsTab.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

        // Three, add ista logo
        const istaLogo = workbook.addImage({
            base64: excelUtil.istaLogo,
            extension: 'png',
        });
        worksheet.addImage(istaLogo, 'O2:O3');

        // Five, Header and footer
        this.addHeaderAndFooter(worksheet);

        // Four, export file
        const filename = `${moment().format('DD_MM_YYYY')}_${this.translate.instant('excel.installationsDashboard.filename')}_${customer.name}.xlsx`;
        this.downloadWorkbook(workbook, filename);
    }

    private fillTableValues(
        worksheet: Worksheet,
        columnsDataHeaders: any[],
        sortedData: readonly WorksiteSynthesisLine[]
    ) {
        const columnsTab: Row = worksheet.addRow(columnsDataHeaders);
        let totalPlaned = 0;
        let totalDone = 0;

        for (const value of sortedData) {
            totalPlaned += value.worksitePlanned;
            totalDone += value.worksiteDone;

            const rowValue = this.buildExcelLine(value);
            const row: Row = worksheet.addRow(rowValue);

            //bordures personnalisés
            for (let i = 3; i <= rowValue.length; i++) {
                row.getCell(i).border = {
                    top: { style: 'thin', color: { argb: excelUtil.colorBlack } },
                    left: { style: 'thin', color: { argb: excelUtil.colorBlack } },
                    bottom: { style: 'thin', color: { argb: excelUtil.colorBlack } },
                    right: { style: 'thin', color: { argb: excelUtil.colorBlack } },
                };

                if (i === 3) {
                    row.getCell(i).border = {
                        top: { style: 'thin', color: { argb: excelUtil.colorWhite } },
                        left: { style: 'thin', color: { argb: excelUtil.colorBlack } },
                        bottom: { style: 'thin', color: { argb: excelUtil.colorWhite } },
                        right: { style: 'thin', color: { argb: excelUtil.colorBlack } },
                    };
                } else if (i >= 4 && i <= 10) {
                    row.getCell(i).border = {
                        top: { style: 'thin', color: { argb: excelUtil.colorWhite } },
                        left: { style: 'thin', color: { argb: excelUtil.colorWhite } },
                        bottom: { style: 'thin', color: { argb: excelUtil.colorWhite } },
                        right: { style: 'thin', color: { argb: excelUtil.colorBlack } },
                    };
                } else if (i >= 5 && i <= 10) {
                    row.getCell(i).border = {
                        top: { style: 'thin', color: { argb: excelUtil.colorWhite } },
                        left: { style: 'thin', color: { argb: excelUtil.colorWhite } },
                        bottom: { style: 'thin', color: { argb: excelUtil.colorWhite } },
                        right: { style: 'thin', color: { argb: excelUtil.colorWhite } },
                    };
                } else if (i === 11) {
                    row.getCell(i).border = {
                        top: { style: 'thin', color: { argb: excelUtil.colorWhite } },
                        left: { style: 'thin', color: { argb: excelUtil.colorBlack } },
                        bottom: { style: 'thin', color: { argb: excelUtil.colorWhite } },
                        right: { style: 'thin', color: { argb: excelUtil.colorWhite } },
                    };
                } else if (i === 12) {
                    row.getCell(i).border = {
                        top: { style: 'thin', color: { argb: excelUtil.colorWhite } },
                        left: { style: 'thin', color: { argb: excelUtil.colorWhite } },
                        bottom: { style: 'thin', color: { argb: excelUtil.colorWhite } },
                        right: { style: 'thin', color: { argb: excelUtil.colorBlack } },
                    };
                }

                row.getCell(i).font = { size: 11 };
                if (i <= 4) {
                    row.getCell(i).alignment = { vertical: 'middle', horizontal: 'left' };
                } else {
                    row.getCell(i).alignment = { vertical: 'middle', horizontal: 'center' };
                }
            }
        }

        const totalRate = Math.floor((totalDone / totalPlaned) * 100);
        const lineTotal = [
            null,
            null,
            'TOTAL',
            null,
            null,
            null,
            null,
            null,
            null,
            totalPlaned,
            totalDone,
            totalRate + '%',
        ];

        const rowTotal = worksheet.addRow(lineTotal);
        for (let i = 3; i <= lineTotal.length; i++) {
            rowTotal.getCell(i).font = { size: 10, bold: true };
            rowTotal.getCell(i).alignment = { vertical: 'middle', horizontal: 'center' };

            if (i > 3 && i < 10) {
                rowTotal.getCell(i).border = {
                    top: { style: 'thin', color: { argb: excelUtil.colorBlack } },
                    left: { style: 'thin', color: { argb: excelUtil.colorWhite } },
                    bottom: { style: 'thin', color: { argb: excelUtil.colorBlack } },
                    right: { style: 'thin', color: { argb: excelUtil.colorWhite } },
                };
            } else {
                rowTotal.getCell(i).border = {
                    top: { style: 'thin', color: { argb: excelUtil.colorBlack } },
                    left: { style: 'thin', color: { argb: excelUtil.colorBlack } },
                    bottom: { style: 'thin', color: { argb: excelUtil.colorBlack } },
                    right: { style: 'thin', color: { argb: excelUtil.colorBlack } },
                };
            }
        }
        return columnsTab;
    }

    private colorTableFooter(worksheet: Worksheet) {
        worksheet.addRow([]);
        const rowFooter = worksheet.addRow([
            null,
            null,
            `${this.translate.instant('excel.installationsDashboard.footer.footer1')}`,
        ]);
        rowFooter.getCell(3).style = {
            font: { size: 10, bold: true, color: { argb: excelUtil.primaryColor }, italic: true },
        };

        worksheet.addRow([]);
        const rowFooter2 = worksheet.addRow([
            null,
            null,
            `${this.translate.instant('excel.installationsDashboard.footer.footer2')}`,
        ]);
        rowFooter2.getCell(3).style = {
            font: { size: 10, bold: true, color: { argb: excelUtil.primaryColor }, italic: true },
        };

        const rowFooter3 = worksheet.addRow([
            null,
            null,
            `${this.translate.instant('excel.installationsDashboard.footer.footer3')}`,
        ]);
        rowFooter3.getCell(3).style = {
            font: { size: 10, color: { argb: excelUtil.primaryColor }, italic: true },
        };
    }

    private colorTableHeader(
        worksheetColumns: { key: string; width: number }[],
        worksheet: Worksheet,
        minLineNumberForHeaderBlocks: number,
        maxLineNumberForHeaderBlocks: number
    ) {
        //color title
        worksheetColumns
            .map((col) => `${col.key}1`)
            .forEach((key) => {
                worksheet.getCell(key).style = {
                    fill: excelUtil.backgroundTitleFill,
                    font: excelUtil.titleFontBigSize,
                };
            });

        // residence
        ['C12', 'D12'].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 11, bold: true, color: { argb: excelUtil.colorWhite } },
                fill: {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: excelUtil.primaryColor },
                    bgColor: { argb: excelUtil.primaryColor },
                },
            };
        });

        //worksite number
        ['E12'].forEach((key) => {
            worksheet.getCell(key).style = {
                font: {
                    name: 'TheSansE5-ExtraBold',
                    size: 11,
                    italic: true,
                    bold: true,
                    color: { argb: excelUtil.colorWhite },
                },
                fill: {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '5f6368' },
                    bgColor: { argb: excelUtil.primaryColor },
                },
            };
        });

        ['F12', 'G12', 'H12', 'I12', 'J12'].forEach((key) => {
            worksheet.getCell(key).style = {
                font: {
                    name: 'TheSansE5-ExtraBold',
                    size: 11,
                    bold: true,
                    color: { argb: excelUtil.colorWhite },
                    italic: false,
                },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '0071ff' }, bgColor: { argb: '0071ff' } },
            };
        });

        ['K12', 'L12'].forEach((key) => {
            worksheet.getCell(key).style = {
                font: { name: 'TheSansE5-ExtraBold', size: 11, bold: true, color: { argb: excelUtil.colorBlack } },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '1beb83' }, bgColor: { argb: '1beb83' } },
            };
        });

        const headers = [
            'A5',
            'C5',
            'D5',
            'E5',
            'F5',
            'G5',
            'H5',
            'I5',
            'J5',
            'K5',
            'L5',
            'M5',
            'N5',
            'O5',
            'A6',
            'C6',
            'D6',
            'E6',
            'F6',
            'G6',
            'H6',
            'I6',
            'J6',
            'K6',
            'L6',
            'M6',
            'N6',
            'O6',
            'A7',
            'C7',
            'D7',
            'E7',
            'F7',
            'G7',
            'H7',
            'I7',
            'J7',
            'K7',
            'L7',
            'M7',
            'N7',
            'O7',
        ];

        if (minLineNumberForHeaderBlocks < maxLineNumberForHeaderBlocks) {
            for (let i = minLineNumberForHeaderBlocks + 1; i <= maxLineNumberForHeaderBlocks; i++) {
                headers.push(`C${i}`);
                headers.push(`D${i}`);
                headers.push(`E${i}`);
                headers.push(`F${i}`);
                headers.push(`G${i}`);
                headers.push(`H${i}`);
                headers.push(`I${i}`);
                headers.push(`J${i}`);
                headers.push(`K${i}`);
                headers.push(`L${i}`);
                headers.push(`M${i}`);
                headers.push(`N${i}`);
                headers.push(`O${i}`);
            }
        }
        headers.forEach((key) => {
            worksheet.getCell(key).style = {
                font: excelUtil.headerValueFont,
                fill: excelUtil.backgroundHeaderValueFill,
            };
        });

        /*  columnsTab.height = 28.80;
          columnsTab.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

          // Three, add ista logo
          const istaLogo = workbook.addImage({
              base64: excelUtil.istaLogo,
              extension: 'png'
          });
          worksheet.addImage(istaLogo, 'O2:O3');

          // Five, Header and footer
          this.addHeaderAndFooter(worksheet);

          // Four, export file
          const filename = `${moment().format('YYYY_MM_DD')}_${this.translate.instant('excel.installationsDashboard.filename')}_${customer.name}.xlsx`;
          this.downloadWorkbook(workbook, filename);*/
    }

    private createWorksheet(
        workbook: Workbook,
        name: string,
        orientation: 'portrait' | 'landscape',
        rows: number
    ): Worksheet {
        return workbook.addWorksheet(name, excelUtil.getWorksheetOptions(orientation, rows));
    }

    private addHeaderAndFooter(worksheet: Worksheet): void {
        const headerFooterStyle = '&K' + excelUtil.primaryColor + '&"TheSansE5-ExtraLight"&11';
        // moncompte.ista.fr in center header with ista primary color and fontfamily Calibri size 11
        worksheet.headerFooter.oddHeader = `&C${headerFooterStyle}moncompte.ista.fr`;
        // date in footer left, document name in center footer, page number in footer right.
        // ALL with ista primary color and fontfamily Calibri size 11
        const footerLeft = `&L${headerFooterStyle}&D`;
        const footerCenter = `&C${headerFooterStyle}&F`;
        const footerRight = `&R${headerFooterStyle}&P/&N`;
        worksheet.headerFooter.oddFooter = `${footerLeft}${footerCenter}${footerRight}`;
    }

    private downloadWorkbook(workbook: Workbook, filename: string): void {
        // tslint:disable-next-line:no-any
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            saveAs(blob, filename);
        });
    }

    private createFilterWorksheet(
        workbook: Workbook,
        filteredResidences: readonly string[],
        filteredAddresses: readonly string[],
        filteredProducts: readonly string[],
        filteredStatuses: readonly string[],
        filteredPeriod: string
    ): void {
        const nbResidences = filteredResidences.length;
        const nbAddresses = filteredAddresses.length;
        const nbProducts = filteredProducts.length;
        const nbStatus = filteredStatuses.length;

        const maxLines: number = Math.max(nbProducts, nbAddresses, nbResidences, nbStatus);

        const worksheetName = this.translate.instant('excel.installations.worksheet.names.filters');
        const worksheet: Worksheet = this.createWorksheet(
            workbook,
            worksheetName,
            'portrait',
            maxLines + excelUtil.headerFilterlines
        );

        // Define static data
        const title = `${this.translate.instant('excel.installations.headers.appliedFilters')} :`;

        const columns = [
            this.translate.instant('excel.installationsDashboard.filtresHeaders.residences'),
            this.translate.instant('excel.installationsDashboard.filtresHeaders.addresses'),
            this.translate.instant('excel.installationsDashboard.filtresHeaders.productType'),
            this.translate.instant('excel.installationsDashboard.filtresHeaders.status'),
        ];

        // Define worksheet's columns
        const columnWidth = 35;

        let maxLengthResidences = this.listUtil.getListElementMaxPositifLength(filteredResidences, columnWidth);
        let maxLengthAddress = this.listUtil.getListElementMaxPositifLength(filteredAddresses, columnWidth);
        let maxLengthProducts = this.listUtil.getListElementMaxPositifLength(filteredProducts, columnWidth);
        let maxLengthStatus = this.listUtil.getListElementMaxPositifLength(filteredStatuses, columnWidth);

        worksheet.columns = [
            { key: 'A', width: maxLengthResidences },
            { key: 'B', width: maxLengthAddress },
            { key: 'C', width: maxLengthProducts },
            { key: 'D', width: maxLengthStatus },
        ];

        worksheet.addRow([title]);
        worksheet.addRow([]);

        const columnsTab: Row = worksheet.addRow(columns);
        const baseLineNumber = 4;

        for (let i = 0; i < maxLines; i++) {
            let residence = null;
            let addresses = null;
            let product = null;
            let status = null;

            if (i < nbResidences) {
                residence = filteredResidences[i];
            }

            if (i < nbAddresses) {
                addresses = filteredAddresses[i];
            }

            if (i < nbProducts) {
                product = filteredProducts[i];
            }
            if (i < nbStatus) {
                status = filteredStatuses[i];
            }

            worksheet.addRow([residence, addresses, product, status]);

            const lineNumber = baseLineNumber + i;
            [`A${lineNumber}`, `B${lineNumber}`, `C${lineNumber}`, `D${lineNumber}`].forEach((key) => {
                worksheet.getCell(key).style = {
                    font: excelUtil.headerValueFont,
                    fill: excelUtil.backgroundHeaderValueFill,
                };
            });
        }

        // Formating data...
        /* ['A3', 'B3','C3', 'D3', 'E3'].map(key => {
             worksheet.getCell(key).style = {
                 fill: excelUtil.backgroundTitleFill,
                 font: excelUtil.titleFont,
             };
         });*/

        ['A3', 'B3', 'C3', 'D3', 'E3', 'F3'].forEach((key) => {
            worksheet.getCell(key).font = excelUtil.headerTitleFont;
        });

        this.addHeaderAndFooter(worksheet);
    }

    private buildExcelLine(value: WorksiteSynthesisLine): (string | number | null)[] {
        const line = [];
        line.push(null);
        line.push(null);

        line.push(this.formatingCell(value.propertyName));
        line.push(this.formatingCell(value.propertyAddress));
        line.push(this.formatingCell(this.formatingCell(value.worksiteId))); //numero de chantier
        line.push(this.formatingCell(this.formatingCell(value.status)));
        line.push(this.formatingCell(value.worksitePeriodExcel));
        line.push(this.formatingCell(value.worksiteMeterTypeExcel));
        line.push(this.formatingCell(value.worksiteFluid));

        line.push(value.worksitePlanned);
        line.push(value.worksiteDone);
        line.push(this.formatingCell(value.worksiteDoneRate));

        return line;
    }

    public formatingCell(strValue: string): string {
        if (strValue == null || strValue === '') {
            return '-';
        } else {
            return strValue;
        }
    }
}
