export class NewActivityDetails {
    id: string;
    activityId: string;
    propertyId: string;
    propertyName: string;
    address: string;
    zip: string;
    city: string;
    fullAddress: string;
    dwellingId: string;
    dwellingCustomerReference: string;
    dwellingLocation: string;
    beep: string;
    owner: string;
    tenant: string;
    view: string;
    type: string;
    meterId: string;
    newSerialNumber: string;
    meterLocation: string;
    productCode: string;
    fluidCode: string;
    meterStatus: string;
    reason: string;
    subject: string;
    visitsCount: number;
    creationDate: string;
    planificationDate: string;
}
