import { Injectable } from '@angular/core';
import { MeterType } from '../../../../shared/enum/meter-type.enum';
import { Installation } from '../model/installation.model';
import { ProductsConstants } from '@shared/constants/products.const';
import { WorksiteDwellingStatus } from '../enum/worksite-period-status.enum';

@Injectable({
    providedIn: 'root'
})
export abstract class InstallationComparatorService {

    constructor() { }

    static products =
        [{ type: 1, meterType: MeterType.EC, label: 'compteurs EC' },
        { type: 1, meterType: MeterType.EF, label: 'compteurs EF' },
        { type: 2, meterType: MeterType.RFC, label: 'répartiteurs' },
        { type: 3, meterType: MeterType.CET, label: 'CET' },
        { type: 4, meterType: MeterType.DAAF, label: 'DAAF' },
        { type: 5, meterType: MeterType.SONDE, label: 'sondes' }];

    /**
     * Sort numeric item then alpha ones
     */
    static alphaComparator(propA: string, propB: string, rowA: any, rowB: any, sortDirection: string): number {

        const a = propA.replace(/^[^a-z]+/ig, '');
        const b = propB.replace(/^[^a-z]+/ig, '');
        if (propA.trim() === '' || propB.trim() === '') {
            if (sortDirection === 'desc') {
                return propA.trim() === '' ? -1 : 1;
            } else {
                return propA.trim() === '' ? 1 : -1;
            }
        } else {
            if (a === '' || b === '') {
                return a === '' ? 1 : -1;
            } else {
                return (a > b) ? 1 : ((b > a) ? -1 : 0);
            }
        }
    }

    static statusComparator(propA: string, propB: string, rowA: any, rowB: any, sortDirection: string): number {
        let a = 0;
        let b = 0;
        switch (propA) {
            case WorksiteDwellingStatus.NOT_INSTALLED_YET:
                a = 2; break;
            case WorksiteDwellingStatus.NOT_EQUIPABLE:
                a = 1; break;
            case WorksiteDwellingStatus.TO_PLAN:
                a = 3; break;
            case WorksiteDwellingStatus.FINISHED:
            case WorksiteDwellingStatus.FINISHED_REMAINING_INSTALLATION:
                a = 4; break;
            case WorksiteDwellingStatus.IN_PROGRESS:
                a = 5; break;
            case WorksiteDwellingStatus.PLANNED:
                a = 6; break;
        }
        switch (propB) {
            case WorksiteDwellingStatus.NOT_INSTALLED_YET:
                b = 2; break;
            case WorksiteDwellingStatus.NOT_EQUIPABLE:
                b = 1; break;
            case WorksiteDwellingStatus.TO_PLAN:
                b = 3; break;
            case WorksiteDwellingStatus.FINISHED:
            case WorksiteDwellingStatus.FINISHED_REMAINING_INSTALLATION:
                b = 4; break;
            case WorksiteDwellingStatus.IN_PROGRESS:
                b = 5; break;
            case WorksiteDwellingStatus.PLANNED:
                b = 6; break;
        }
        return (a > b) ? 1 : ((b > a) ? -1 : 0);
    }

    static productsComparator(propA: Array<Installation>, propB: Array<Installation>, rowA: any, rowB: any, sortDirection: string): number {
        const a = ProductsConstants.getMeterLabel(rowA.productCode, rowA.fluidCode);
        const b = ProductsConstants.getMeterLabel(rowB.productCode, rowB.fluidCode);
        return (a < b) ? 1 : ((a > b) ? -1 : 0);
    }

    static datesComparator(propA: any, propB: any, rowA: any, rowB: any, sortDirection: string): number {
        let returnValue: number;
        if (propA.length === 0 || propB.length === 0) {
            if (sortDirection === 'desc') {
                return propA.length === 0 ? -1 : 1;
            } else {
                return propA.length === 0 ? 1 : -1;
            }
        } else {
            returnValue = (propA > propB) ? 1 : ((propB > propA) ? -1 : 0);
        }
        return InstallationComparatorService.reverseOrder(returnValue);
    }

    private static reverseOrder(originOrder: number): number {

        let reversedOrder = 0;
        if (originOrder === 1) {
            reversedOrder = -1;
        } else if (originOrder === -1) {
            reversedOrder = 1;
        }

        return reversedOrder;
    }

    static adressComparator(propA: string, propB: string, rowA: any, rowB: any, sortDirection: string): number {
        if (propA.trim() === '' || propB.trim() === '') {
            if (sortDirection === 'desc') {
                return propA.trim() === '' ? -1 : 1;
            } else {
                return propA.trim() === '' ? 1 : -1;
            }
        } else {
            const a = propA.toLocaleLowerCase().trim();
            const b = propB.toLocaleLowerCase().trim();
            const reA = /[^a-zA-Z]/g;
            const reN = /[^0-9]/g;
            const aA = a.replace(reA, '');
            const bA = b.replace(reA, '');
            if (aA === bA) {
                let aN = parseInt(a.replace(reN, ''), 10);
                let bN = parseInt(b.replace(reN, ''), 10);
                aN = aN ? aN : 0;
                bN = bN ? bN : 0;
                return aN === bN ? 0 : aN > bN ? 1 : -1;
            } else {
                return aA > bA ? 1 : -1;
            }
        }
    }

    static comparatorNotNull(propA: string, propB: string, rowA: any, rowB: any, sortDirection: string): number {
        if (propA.trim() === '' || propB.trim() === '') {
            if (sortDirection === 'desc') {
                return propA.trim() === '' ? -1 : 1;
            } else {
                return propA.trim() === '' ? 1 : -1;
            }
        } else {
            const a = propA.toLocaleLowerCase().trim();
            const b = propB.toLocaleLowerCase().trim();
            return (a > b) ? 1 : ((b > a) ? -1 : 0);
        }
    }

    static alphanumericComparator(propA: string, propB: string, rowA: any, rowB: any, sortDirection: string): number {
        if (propA.trim() === '' || propB.trim() === '') {
            if (sortDirection === 'desc') {
                return propA.trim() === '' ? -1 : 1;
            } else {
                return propA.trim() === '' ? 1 : -1;
            }
        }
        const a = propA.toLocaleLowerCase().trim();
        const b = propB.toLocaleLowerCase().trim();
        const codeA = a.charCodeAt(0);
        const codeB = b.charCodeAt(0);
        if (sortDirection === 'desc') {
            if (codeA > 96) {
                return codeB > 96 ? (a > b) ? 1 : ((b > a) ? -1 : 0) : 1;
            } else {
                return codeB > 96 ? -1 : (a > b) ? 1 : ((b > a) ? -1 : 0);
            }
        } else {
            if (codeA > 96) {
                return codeB > 96 ? (a > b) ? 1 : ((b > a) ? -1 : 0) : -1;
            } else {
                return codeB > 96 ? 1 : (a > b) ? 1 : ((b > a) ? -1 : 0);
            }
        }
    }

}
