import { Injectable } from '@angular/core';
import { SortService } from '@ista/shared-ui';
import { ActivityStatus } from '@extern/activities/enum/activity-status.enum';
import { Reporting } from '../model/reporting.model';


export enum ReportingOrderCriterias {
    CUSTOMER_ACTION_REQUIRED = 'RETOUR NEGATIF FAUTE CLIENT OUI',
    STATUS = 'STATUT',
    CREATION_DATE = 'DATE DE CREATION DU SIGNALEMENT LA PLUS ANCIENNE',
    VISITS_COUNT = 'NOMBRE DE PASSAGES'
}

@Injectable({
    providedIn: 'root'
})
export class ActivitySortingService extends SortService<Reporting> {

    private TO_PLAN = 10000000;
    private PLANNED = 100000;
    private FINISHED = 0;
    private ACTION_REQUIRED = 5000000;

    constructor() {
        super();
        this.priorities.set(ReportingOrderCriterias.STATUS, this.ONE);
        this.priorities.set(ReportingOrderCriterias.CUSTOMER_ACTION_REQUIRED, this.TWO);
        this.priorities.set(ReportingOrderCriterias.CREATION_DATE, this.THREE);
        this.priorities.set(ReportingOrderCriterias.VISITS_COUNT, this.FOUR);
    }

    isSortable(sortable: Reporting): boolean {
        return sortable !== undefined && sortable !== null;
    }
    getValue(criteria: string, activity: Reporting): number {
        if (!criteria || !activity) {
            return this.ZERO;
        } else if (criteria === ReportingOrderCriterias.STATUS) {
            return this.getStatusValue(activity.status);
        } else if (criteria === ReportingOrderCriterias.CUSTOMER_ACTION_REQUIRED) {
            return this.getActionRequiredValue(activity.customerActionRequired);
        } else if (criteria === ReportingOrderCriterias.CREATION_DATE) {
            return this.getCreationDateValue(activity.creationDate);
        } else if (criteria === ReportingOrderCriterias.VISITS_COUNT) {
            return activity.visits ? activity.visits.length : this.ZERO;
        }
        return this.ZERO;
    }

    private getStatusValue(status: ActivityStatus): number {
        switch (status) {
            case ActivityStatus.TO_PLAN:
                return this.TO_PLAN;
            case ActivityStatus.PLANNED:
                return this.PLANNED;
            case ActivityStatus.FINISHED:
                return this.FINISHED;
            default:
                return this.ZERO;
        }
    }

    private getActionRequiredValue(customerActionRequired: boolean): number {
        return customerActionRequired === true ? this.ACTION_REQUIRED : this.ZERO;
    }

    private getCreationDateValue(date: string): number {
        if (!date) {
            return this.ZERO;
        }
        const createDate = new Date(date);
        const diff = Math.abs(createDate.getTime() - Date.now());
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        return diffDays;
    }
}
