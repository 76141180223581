import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-activity-synthesis-footer',
    templateUrl: './activity-synthesis-footer.component.html',
    styleUrls: ['./activity-synthesis-footer.component.css'],
})
export class ActivitySynthesisFooterComponent {
    @Input() title: string;
    @Input() entries: Array<{ color: string; value: number }>;
    @Input() correctionStyle: boolean;
    @Input() showSpinner: boolean;
}
