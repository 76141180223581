<table class="container p-3 rowStyle">
    <tbody>
        <tr>
            <td class="col-sm title justify-content-start">
                <span>
                    <ng-container> {{'installations.dashboard.resume.header' | translate}} </ng-container>
                </span>
                <ista-interrogation-point class="interrogation"
                    text="{{'installations.dashboard.resume.bubble' | translate}}" placement="right">
                </ista-interrogation-point>
            </td>
            <td class="col-sm rectangle justify-content-center" *ngFor="let col of resume">
                <div class="resume" style="width: auto;">
                    <span *ngIf="showSpinner === false">{{col.value}}</span>
                    <span *ngIf="showSpinner === true">
                        <!-- <i class="fas fa-spinner fa-pulse"></i> -->
                        <ista-spinner 
                            customSpinnerClass="spinner-only-small no-translation"
                            style="position: relative; display: flex; top: 5px; left: -20px; height: 0px;"
                            [showSpinner]="true" 
                            [data]="1"
                            [spinnerDiameter]="20"
                            spinnerColor="#fff"></ista-spinner>
                    </span>
                    <span style="margin-left: 5px;">{{col .key}}</span>
                </div>
            </td>
        </tr>
    </tbody>
</table>
