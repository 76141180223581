<ista-template [error]="error" [sidebarItems]="defaultItems" [basePath]="basePath" [fullName]="fullName"
    showHelpText="{{'header.help.show' | translate}}" hideHelpText="{{'header.help.hide' | translate}}"
    welcomeText="{{'header.welcome' | translate}}" [helpStatus]="getHelpStatus()" (logoutEvent)="logout()"
    (refreshEvent)="refresh()" (helpStatusChange)="showHideHelp()" [showHelpButton]="true" colorClass="white"
    imgColor="blue" faq="{{'footer.faq' | translate}}" mentions="{{'footer.mentions' | translate}}"
    conditions="{{'footer.conditions' | translate}} " contact="{{'footer.contact' | translate}}"
    userGuide="{{'footer.userGuide' | translate}} "
    userGuideLink="https://www.ista.com/fileadmin/twt_customer/countries/content/France/Documents/Guides/Guide_utilisateurs-Mes-chantiers-Mes_interventions.pdf"
    errorMessage="{{errorMessage| translate}}">
</ista-template>
<ng-template #template>
    <div class="modal-body">
        <ista-selection dialogTitle="{{'selectionDialog.dialogTitle' | translate}}"
            textTitle="{{'selectionDialog.title' | translate}}" text="{{'selectionDialog.text' | translate}}"
            noSelectionText="{{'selectionDialog.noSelectionText' | translate}}"
            labelReturn="{{'selectionDialog.returnText' | translate}}"
            labelSelection="{{'selectionDialog.selectionText' | translate}}"
            displayAllLink="{{'selectionDialog.displayAllText' | translate}}"
            message="{{'filters.noFound'  | translate}}" loading="{{'selectionDialog.loadingText' | translate}}"
            filterName="{{'filters.properties'  | translate}}" validateButtonText="{{'filters.validate'  | translate}}"
            unselectAllButtonText="{{'filters.unselectAll' | translate}}"
            selectAllButtonText="{{'filters.selectAll' | translate}}"
            zoneRechercheText="{{'filters.zoneRecherche' | translate}}" tooltipText="{{'filters.tooltip' | translate}}"
            allSelectedText="{{'filters.allselected' | translate}}" notFoundText="{{'filters.noFound' | translate}}"
            [propertiesReferences]="propertyFilterData" [activeService]="sessionStorageService"
            [showSpinner]="showSelectionSpinner" [showNoSelection]="showNoSelectionText"
            [showReturn]="showSelectionReturn" (click)="onClickInSelection($event)">
        </ista-selection>
    </div>
</ng-template>