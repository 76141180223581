<div class="showScrollWhenNeeded">
    <div class="px-2 form-title">
        <h1 style="display:inline;">{{'intern.selection.title' | translate}}</h1>
    </div>
    <div class="containers">
        <div class="d-flex flex-row ml-0 p-0 align-items-center">
            <div class="container-fluid">
                <div class="row bottom-space">
                    <div class="col-sm-5 full-margin">
                        <h2 class="form-title">{{'intern.selection.client.title' | translate}}</h2>
                        <h3>{{'intern.selection.client.description' | translate}}</h3>
                        <form [formGroup]="customerSearchForm" (ngSubmit)="onSubmitCustomerForm()"
                            class="form-horizontal" #formDir="ngForm">
                            <div class="form-inline">
                                <div class="col-sm-8 input-group input-group-lg text-area">
                                    <input class="form-control input-lg" id="customerCode" type="text"
                                        formControlName="customerCode"
                                        placeholder="{{'intern.selection.client.placeholder' | translate}}"
                                        minlength="7" maxlength="7" required #customerTextArea>
                                </div>
                                <div class="col-sm-4 input-group input-group-lg">
                                    <button class="buttonClass secondaryButton btn m-1" style="margin-right: 0 !important;" (click)="formDir.resetForm({})" type="button">
                                        {{'intern.selection.client.erase' | translate}}
                                    </button>
                                    <button class="buttonClass primaryButton btn m-1" (click)="customerTextArea.value = ''"
                                        [disabled]="!customerSearchForm.valid" type="submit">
                                        {{'intern.selection.client.validate' | translate}}
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div class="notFound"
                            *ngIf="customerSearchForm.controls['customerCode'].getError('notFound') === true">
                            {{'intern.selection.client.notFound' | translate}}
                        </div>
                        <button class="selected white btn m-1 align-middle customer-elements"
                            *ngFor="let customer of customerList" (click)="removeCustomer(customer.number)">
                            <span>{{ customer.number }} {{ customer.name }}</span>
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <div class="col-sm-5 full-margin">
                        <div *ngIf="customerList.length > 0">
                            <h2>{{'intern.selection.uex.title' | translate}}</h2>
                            <h3>{{'intern.selection.uex.description' | translate}}</h3>
                            <app-group-filters type="uex" [propertiesReferences]="propertyFilterDataFinal"
                                [activeService]="uexFilterSessionStorageService">
                            </app-group-filters>
                        </div>
                    </div>
                </div>
                <div class="row full-margin">
                    <div class="col-5" *ngIf="customerList.length > 0">
                        <form (ngSubmit)="onSubmitValidateForm()">
                            <div class="row">
                                <button class="buttonClass primaryButton btn mb-3 responsive-text fixed-width"
                                    (click)="setRedirectionUrl('mes-interventions')" type="submit">
                                    {{'intern.selection.validateActivities' | translate}}
                                </button>
                            </div>
                            <div class="row">
                                <button class="buttonClass primaryButton btn mb-3 responsive-text fixed-width"
                                    (click)="setRedirectionUrl('mes-chantiers')" type="submit">
                                    {{'intern.selection.validateWorksites' | translate}}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row full-margin">
                    <div class="warn-block responsive-text fixed-width" *ngIf="customerList.length > 0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
                        </svg>
                        {{'intern.selection.validateWarning' | translate}}
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
