import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorksiteDwellingDetails } from '../model/worksite-dwelling-details.model';
import { WorksiteVisit } from '../model/worksite-visit.model';

@Injectable({
    providedIn: 'root'
})
export class WorksiteVisitService {

    baseUrl: string;

    constructor(private httpClient: HttpClient, @Inject('CONTEXT_API') private contextApi: string) {
        this.baseUrl = `${contextApi}/worksites/visits`;
    }

    fetch(): Observable<ReadonlyArray<WorksiteVisit>> {
        return this.httpClient.get<ReadonlyArray<WorksiteVisit>>('assets/data/visits.json');
    }

    fetchByWorksiteId(worksites: ReadonlyArray<string>, propertiesNumbers: ReadonlyArray<string>): Observable<ReadonlyArray<WorksiteVisit>> {
        let filterUrl = this.baseUrl;
        if (propertiesNumbers.length > 0) {
            filterUrl += `?propertiesIds=${propertiesNumbers}`;
        }
        if (worksites.length > 0) {
            filterUrl +=  (propertiesNumbers.length > 0 ? '&' : '?') + `worksitesIds=${worksites}`;
        }
        return this.httpClient.get<ReadonlyArray<WorksiteVisit>>(filterUrl);
    }

    fetchByVisitIds(visitsId: ReadonlyArray<string>): Observable<ReadonlyArray<WorksiteVisit>> {
        const visits$ = this.fetch();
        return visits$.pipe(
            map(visits =>
                ([] as WorksiteVisit[]).concat(
                    ...visits.filter(visit => visitsId.includes(visit.id))
                )
            )
        );
    }

    fetchByDwellingsIds(dwellingsIds: ReadonlyArray<string>): Observable<ReadonlyArray<WorksiteVisit>> {
        const details$ = this.httpClient.get<ReadonlyArray<WorksiteDwellingDetails>>('assets/data/dwellings-details.json');
        return details$.pipe(
            map(details =>
                ([] as WorksiteVisit[]).concat(
                    ...details.filter(detail => dwellingsIds.includes(detail.id))
                        .map(dwelling => dwelling.visits)
                )
            )
        );
    }
}
