<div (onResize)="tableSig.recalculate()" class="wrapper">
    <ngx-datatable #tableSig (resize)="recalculate()" (onResize)="recalculate()" (scroll)="recalculate()"
        (sort)="viewSort()" [columnMode]="ColumnMode.flex" [footerHeight]="33" [headerHeight]="35"
        [loadingIndicator]="showSpinner" (activate)="onSelect($event)" [messages]="emptyMessages" [rowHeight]="48"
        [rows]="completeRows" [scrollbarH]="true" [scrollbarV]="true" [selectAllRowsOnPage]="false"
        [sorts]="currentTableSort" [style]="listBlockStyle" class="material expandable table">

        <ngx-datatable-row-detail #myDetailRow [rowHeight]="40">
            <ng-template let-expanded="expanded" let-row="row" ngx-datatable-row-detail-template>
                <div style="padding-left:35px; font-size: 14px;">
                    <div style="display: inline-block; padding-right: 30px;">
                        <strong>{{'datatable.activities.columns.propertyNumber'| translate}}</strong>:
                        {{row.propertyId}}
                    </div>
                    <div style="display: inline-block; padding-right: 30px;">
                        <strong>{{'datatable.activities.columns.dwellingIstaReference'| translate}}</strong>:
                        {{row.beep}}
                    </div>
                </div>
            </ng-template>
        </ngx-datatable-row-detail>

        <ngx-datatable-column [canAutoResize]="false" [draggable]="false" [resizeable]="false" [sortable]="false"
            [width]="20">
            <ng-template let-expanded="expanded" let-row="row" ngx-datatable-cell-template>
                <a (click)="toggleExpandRow(row)" [class.datatable-icon-down]="expanded"
                    [class.datatable-icon-right]="!expanded"></a>
            </ng-template>
        </ngx-datatable-column>


        <ngx-datatable-column *ngFor="let col of columns" [comparator]="col.comparator" [flexGrow]="flexGrow(col.prop)"
            [name]="col.name" [prop]="col.prop" [resizeable]="true" [sortable]="col.sortable" [width]="col.width">

            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <div class="d-inline-block datatable-header-cell-wrapper" style="height: 5rem;">
                    <span (click)="sort()" class="datatable-header-cell-label draggable">{{column.name}}</span>
                </div>
                <ista-interrogation-point *ngIf="column.prop === 'status.nbItemsToPlan'"
                    htmlText="{{'datatable.activities.columns.tooltip.toPlan' | translate}}" placement="right">
                </ista-interrogation-point>
                <ista-interrogation-point *ngIf="column.prop === 'status.nbItemsPlanned'" placement="right"
                    text="{{'datatable.activities.columns.tooltip.planned' | translate}}">
                </ista-interrogation-point>
                <ista-interrogation-point *ngIf="column.prop === 'status.nbItemsFinished'" placement="right"
                    text="{{'datatable.activities.columns.tooltip.finished' | translate}}">
                </ista-interrogation-point>
            </ng-template>

            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div *ngIf="row !== undefined">
                    <div *ngIf="col.prop === 'address' " class="mx-auto">
                        <div style="text-align: center; ">
                            {{getAddress(row)}}
                        </div>
                    </div>
                    <div *ngIf="['status.nbItemsPlanned', 'status.nbItemsInProgress', 'status.nbItemsNotEquippable', 'status.nbItemsFinished', 'status.nbItemsToPlanNoAction', 'status.nbItemsHelpUs'].includes(col.prop)"
                        class="mx-auto">
                        <div *ngIf="value > 0" style="text-align: center; ">
                            <button [style.background-color]="getColor(col.prop)" class="button radius">{{value}}</button>
                            <!-- <ui-tag-badge [ngClass]="'button radius' + ' positiveStatus'">{{value}}</ui-tag-badge> -->
                        </div>
                        <div *ngIf="value===0" style="text-align: center; ">-</div>
                    </div>
                    <div
                        *ngIf="!['address', 'status.nbItemsToPlan', 'status.nbItemsPlanned', 'status.nbItemsInProgress', 'status.nbItemsNotEquippable', 'status.nbItemsFinished', 'status.nbItemsToPlanNoAction', 'status.nbItemsHelpUs'].includes(col.prop)">
                        <div *ngIf="value == ''" style="margin-left:15px;">-</div>
                        <div *ngIf="value != ''">{{value}}</div>
                    </div>
                </div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template let-curPage="curPage" let-isVisible="isVisible" let-offset="offset" let-pageSize="pageSize"
                let-rowCount="rowCount" let-selectedCount="selectedCount" ngx-datatable-footer-template>
                <div class="page-count">
                    {{rowCount.toLocaleString()}} {{'datatable.activities.footer' |translate}}
                </div>
                <datatable-pager (change)="tableSig.onFooterPage($event)" [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)" [page]="curPage"
                    [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerNextIcon]="'datatable-icon-skip'"
                    [pagerPreviousIcon]="'datatable-icon-prev'" [pagerRightArrowIcon]="'datatable-icon-right'"
                    [size]="pageSize">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>
</div>