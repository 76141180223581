<div #containersBlock (onResize)="handleResize($event)" (resize)="resized()" style="position: relative; height: 100%;">
    <div #filtersBlock id='filtersBlock'>
        <div class="menu-title">
            <div class="row ml-2">
                <div class="col-5 ml-0 p-0 m-0" style="margin-right: 15px !important;">
                    <div class="row ml-0 p-0 m-0">
                        <div class="col-*">
                            <h1 class="form-title" style="margin-top: 4px;">{{'sidebar.menu.menuitem.activities' | translate}}</h1>
                        </div>
                        <div [tooltip]="showFilterTooltip()" class="col-* filterButton" containerClass="tooltipClass" placement="auto" [ngClass]=" filterIsActive == true ? 'active' : ''">
                            <ista-hide-filters [filterSessionStorage]="filterSessionStorage" (toggle)="toggleFilter()"
                                [groupFilters]="gFiltres.refresh" [ignoreFilters]="['columns']">
                            </ista-hide-filters>
                        </div>
                        <div class="col-*" style="margin-left: 10px;" [tooltip]="'filters.columns'| translate"
                            containerClass="tooltipClass" placement="auto">
                            <ista-select-columns [data]="columnsFilterData" dataName="columns"
                                [filterSessionStorage]="filterSessionStorage"
                                validateButtonText="{{'filters.validate'  | translate}}"
                                unselectAllButtonText="{{'filters.unselectAll' | translate}}"
                                selectAllButtonText="{{'filters.selectAll' | translate}}" (refresh)="prepareColumns()">
                            </ista-select-columns>
                        </div>
                    </div>
                </div>

                <div class="col-* mr-auto">
                    <div *ngIf="!matchMedia.matches" class="row">
                        <button class="buttonClass primaryButton btn mb-2 font-medium fixed-width button-rounded-left" style="margin-right: 0px !important;">
                            {{'datatable.activitiesDetailled.switchViewButton' | translate}}
                        </button>
                        <button class="buttonClass secondaryButton btn mb-2 font-medium fixed-width button-rounded-right"
                            (click)="switchView()">
                            {{'datatable.activities.switchViewButton' | translate}}
                        </button>
                    </div>
                    <div *ngIf="matchMedia.matches" class="row">
                        <button class="buttonClass primaryButton btn mb-2 font-medium fixed-width button-rounded-left" style="margin-right: 0px !important;">
                            {{'datatable.activitiesDetailled.switchViewButtonShort' | translate}}
                        </button>
                        <button class="buttonClass secondaryButton btn mb-2 font-medium fixed-width button-rounded-right"
                            (click)="switchView()">
                            {{'datatable.activities.switchViewButtonShort' | translate}}
                        </button>
                    </div>
                </div>
            </div>

        </div>
        <ista-spinner *ngIf="showSpinner" class="center-text" [showSpinner]="showSpinner" style="z-index: 100;"
            [data]="lines.length" customSpinnerClass="xsmall" customSpinnerTitleStyle="font-family: 'TheSansE5-SemiLight';" message="{{'filters.noActivityForTheSelection' | translate}}"
            loading="{{'template.loading' | translate}}">
        </ista-spinner>
        <div class="flex-row ml-2 p-0 group-filters" style="padding-left: 20px !important;">
            <div class="row">
                <div class="filter" [ngClass]="showFilter? 'col-11 visible': 'col-0 hidden'">
                    <app-group-filters #gFiltres type='activity' [propertiesReferences]="propertyFilterData"
                        [addressesReferences]="addressFilterData" [dwellingsReferences]="dwellingFilterData"
                        [activeService]="filterSessionStorage" (refresh)="refreshData($event)">
                    </app-group-filters>
                </div>
                <!-- <div *ngIf="!showSpinner" [ngClass]="showFilter ? 'col-1': 'col-12'">
                    <div class="ml-auto alert group-filters">
                        <div class="flex-row align-items-end flex-column"
                            [ngStyle]="{'height': showFilter ? 'auto' : '2rem' }">
                            <ista-export-data id="exportGroup" (export)="exportDwellings($event)" [showExcel]="true"
                                title="{{'export.activities.excel.list' | translate}}">
                            </ista-export-data>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="row">
                <div class="col-12">
                    <div *ngIf="!showSpinner && table.isSorted" class="ml-auto alert group-filters">
                        <div class="d-flex align-items-end flex-column">
                            <div class="buttonTri"><button type="button" class="btn buttonClass secondaryButton"
                                    (click)="table.resetSort()">
                                    {{'datatable.buttonSort' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wrapper">
        <app-dwelling-list #table [rows]="lines" [showSpinner]="showSpinner" [listBlockHeight]="listBlockHeight"
            [events]="eventsSubject.asObservable()" [filterSessionStorage]="filterSessionStorage"
            [columns]="columnsFiltred">
        </app-dwelling-list>
    </div>
</div>
