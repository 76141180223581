import { Injectable } from '@angular/core';
import { ActivityType } from '@extern/activities/enum/activity-type.enum';
import { DwellingActivityOverview } from '@extern/activities/model/activity-overview.model';
import { NewActivityDetails } from '@extern/activities/model/new-activity-details.model';
import { DwellingWorksiteOverview } from '@extern/worksites/model/worksite-overview.model';
import { Reference } from '@ista/shared-ui';
import { DwellingDetails } from '@shared/model/dwelling-details.model';

import { Dwelling } from '../model/dwelling.model';
import { Property } from '../model/property.model';

@Injectable({
    providedIn: 'root',
})
export class FilterReferencesService {
    preparePropertyData(properties: ReadonlyArray<Property>): ReadonlyArray<Reference> {
        const propertyFilterData = new Array<Reference>();
        const proprtiesNames = properties
            .map((property) => property.name)
            .filter((value, index, self) => self.indexOf(value) === index);
        proprtiesNames.forEach((propertyName) =>
            propertyFilterData.push({
                name: propertyName !== '' ? propertyName : '-',
                ids: properties
                    .filter((property) => property.name === propertyName)
                    .map((property) => property.id)
                    .filter((value, index, self) => self.indexOf(value) === index),
            })
        );
        return propertyFilterData;
    }

    prepareAddressData(dwellings: ReadonlyArray<DwellingDetails>): ReadonlyArray<Reference> {
        const addressFilterData: Array<Reference> = [];
        const addresses = dwellings
            .map((d) => {
                const dwellingAddress = this.normalizeDwellingAddress(d);
                return dwellingAddress === '-' ? this.normalizePropertyAddress(d.property) : dwellingAddress;
            })
            .filter((value, index, self) => self.indexOf(value) === index);

        addresses.forEach((address) =>
            addressFilterData.push({
                name: address,
                ids: dwellings
                    .filter((d) => {
                        const dwellingAddress = this.normalizeDwellingAddress(d);
                        return dwellingAddress === '-'
                            ? this.normalizePropertyAddress(d.property) === address
                            : address === dwellingAddress;
                    })
                    .map((dwelling) => dwelling.id)
                    .filter((value, index, self) => self.indexOf(value) === index),
            })
        );
        return addressFilterData;
    }

    newPrepareAddressData(dwellings: ReadonlyArray<DwellingWorksiteOverview>): ReadonlyArray<Reference> {
        const addressFilterData: Array<Reference> = [];
        const addresses = dwellings
            .map((d) => {
                return this.newNormalizeDwellingAddress(d);
            })
            .filter((value, index, self) => self.indexOf(value) === index);

        addresses.forEach((address) =>
            addressFilterData.push({
                name: address,
                ids: dwellings
                    .filter((d) => {
                        const dwellingAddress = this.newNormalizeDwellingAddress(d);
                        return dwellingAddress === '-'
                            ? this.newNormalizeDwellingAddress(d) === address
                            : address === dwellingAddress;
                    })
                    .map((dwelling) => dwelling.dwellingId)
                    .filter((value, index, self) => self.indexOf(value) === index),
            })
        );
        return addressFilterData;
    }

    prepareDwellingData(dwellings: ReadonlyArray<Dwelling>): ReadonlyArray<Reference> {
        const dwellingFilterData = new Array<Reference>();
        const dwellingReferences = dwellings
            .map((p) => p.customerReference)
            .filter((value, index, self) => self.indexOf(value) === index);
        dwellingReferences.forEach((dwellingReference) =>
            dwellingFilterData.push({
                name: dwellingReference !== '' ? dwellingReference : '-',
                ids: dwellings
                    .filter((dwelling) => dwelling.customerReference === dwellingReference)
                    .map((dwelling) => dwelling.id)
                    .filter((value, index, self) => self.indexOf(value) === index),
            })
        );
        return dwellingFilterData;
    }

    newPrepareActivityAddressData(activities: ReadonlyArray<DwellingActivityOverview>): ReadonlyArray<Reference> {
        const addressFilterData: Array<Reference> = [];
        const addresses = activities
            .map((a) => {
                const activityAddress = this.normalizeAddress(a.fullAddress);
                return activityAddress === '-' ? this.newNormalizeDwellingAddress(a) : activityAddress;
            })
            .filter((value, index, self) => self.indexOf(value) === index);
        addresses.forEach((address) =>
            addressFilterData.push({
                name: address,
                ids: activities
                    .filter((a) => {
                        const activityAddress = this.normalizeAddress(a.fullAddress);
                        return activityAddress === '-'
                            ? this.newNormalizeDwellingAddress(a) === address
                            : address === activityAddress;
                    })
                    .map((activity) => activity.dwellingId)
                    .filter((value, index, self) => self.indexOf(value) === index),
            })
        );
        return addressFilterData;
    }

    public prepareActivityAddressData(activities: ReadonlyArray<NewActivityDetails>): ReadonlyArray<Reference> {
        const addressFilterData: Array<Reference> = [];
        const addresses = activities
            .map((a) => {
                return this.normalizeAddress(a.fullAddress);
            })
            .filter((value, index, self) => self.indexOf(value) === index);
        addresses.forEach((address) =>
            addressFilterData.push({
                name: address,
                ids: activities
                    .filter((activity) => {
                        const activityAddress = this.normalizeAddress(activity.fullAddress);
                        return address === activityAddress;
                    })
                    .map((activity) => activity.dwellingId)
                    .filter((value, index, self) => self.indexOf(value) === index),
            })
        );
        return addressFilterData;
    }

    prepareActivityDwellingData(activities: ReadonlyArray<NewActivityDetails>): ReadonlyArray<Reference> {
        const dwellingFilterData = new Array<Reference>();
        const dwellingReferences = activities
            .map((activity) => activity.dwellingCustomerReference)
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort();
        dwellingReferences.forEach((reference) =>
            dwellingFilterData.push({
                name: reference !== '' ? reference : '-',
                ids: activities
                    .filter((activity) => activity.dwellingCustomerReference === reference)
                    .map((activity) => activity.dwellingId)
                    .filter((value, index, self) => self.indexOf(value) === index),
            })
        );
        return dwellingFilterData;
    }

    prepareActivityMetersData(activities: ReadonlyArray<NewActivityDetails>): ReadonlyArray<Reference> {
        const meterFilterData = new Array<Reference>();
        const serialNumbers = activities
            .map((a) => a.newSerialNumber)
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort();
        serialNumbers.forEach((serialNumber) =>
            meterFilterData.push({
                name: serialNumber !== '' ? serialNumber : '-',
                ids: activities
                    .filter((activity) => activity.newSerialNumber === serialNumber)
                    .map((activity) => activity.id)
                    .filter((value, index, self) => self.indexOf(value) === index),
            })
        );
        return meterFilterData;
    }

    prepareActivityReasonData(activities: ReadonlyArray<NewActivityDetails>): ReadonlyArray<Reference> {
        const reasonFilterData = new Array<Reference>();
        const reasons = activities
            .map((activity) => activity.reason)
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort();
        reasons.forEach((reason) =>
            reasonFilterData.push({
                name: reason !== ('' && null) ? reason : '-',
                ids: activities
                    .filter((activity) => activity.reason === reason)
                    .map((activity) => activity.id)
                    .filter((value, index, self) => self.indexOf(value) === index),
            })
        );
        return reasonFilterData;
    }

    prepareActivitySubjectData(activities: ReadonlyArray<NewActivityDetails>): ReadonlyArray<Reference> {
        const subjectFilterData = new Array<Reference>();
        const subjects = activities
            .filter((activity) => activity.type.toUpperCase() === ActivityType.MAINTENANCE)
            .map((activity) => activity.subject)
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort();
        subjects.forEach((subject) =>
            subjectFilterData.push({
                name: subject !== ('' && null) ? subject : '-',
                ids: activities
                    .filter(
                        (activity) =>
                            activity.type.toUpperCase() === ActivityType.MAINTENANCE && activity.subject === subject
                    )
                    .map((activity) => activity.id)
                    .filter((value, index, self) => self.indexOf(value) === index),
            })
        );
        return subjectFilterData;
    }

    private normalizeDwellingAddress(dwelling: Dwelling): string {
        let address = '-';
        if (dwelling.fullAddress && dwelling.fullAddress !== '') {
            address = dwelling.fullAddress.trim();
        }
        return address;
    }

    private newNormalizeDwellingAddress(dwelling: DwellingWorksiteOverview | DwellingActivityOverview): string {
        let address = '-';
        if (dwelling.address && dwelling.address !== '') {
            address = dwelling.address.trim() + ' ' + dwelling.zip.trim() + ' ' + dwelling.city.trim();
        }
        return address;
    }

    private normalizePropertyAddress(property: Property): string {
        let address = '-';
        if (property.fullAddress && property.fullAddress !== '') {
            address = property.fullAddress.trim();
        }
        return address;
    }

    private normalizeAddress(fullAddress: string): string {
        let address = '-';
        if (fullAddress && fullAddress !== '') {
            address = fullAddress.trim();
        }
        return address;
    }
}
