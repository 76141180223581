import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { User, UserGroup } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode, DatatableComponent } from '@siemens/ngx-datatable';
import moment from 'moment';
import { Column } from 'src/app/core/model/column.model';
import { UsersService } from 'src/app/core/service/users.service';

@Component({
    selector: 'app-ista-users-list',
    templateUrl: './ista-users-list.component.html',
    styleUrls: ['./ista-users-list.component.css'],
})
export class IstaUsersListComponent {
    data: User[] = [];
    columns: Array<Column> = [
        new Column(this.translateHeader('user.firstname'), 'firstName'),
        new Column(this.translateHeader('user.lastname'), 'lastName'),
        new Column(this.translateHeader('user.login'), 'login'),
        new Column(this.translateHeader('user.mail'), 'mail'),
        new Column(this.translateHeader('user.group'), 'groups'),
        new Column(this.translateHeader('user.lastmodification'), 'accountLastModified'),
        new Column(this.translateHeader('user.creationdate'), 'accountCreated'),
    ];
    filtredData: User[] = [];
    defaultDatePattern = 'DD/MM/YYYY HH:mm:ss';
    userCount = 0;
    managementCount = 0;
    loadingIndicator = true;
    searchTerm = '';
    columnMode = ColumnMode;

    @ViewChild('table') table: DatatableComponent;

    constructor(
        private usersService: UsersService,
        private translate: TranslateService
    ) {
        this.fetchData();
        this.getUsersCount();
        this.getManagementCount();
    }

    fetchData(): void {
        this.usersService.fetchIstaUsers().subscribe(
            (users: Array<User>) => {
                this.updateFoundUsers(users, this.defaultDatePattern);
                setTimeout(() => {
                    this.loadingIndicator = false;
                }, 1500);
            },
            (error: HttpErrorResponse) => {
                console.error('An error occured when trying to fetch users : Details are : ' + error.message);
            }
        );
    }

    getUsersCount(): void {
        this.usersService.getUserGroupCount().subscribe(
            (userGroups: Array<UserGroup>) => {
                this.userCount = userGroups[0].memberCount;
            },
            (error: HttpErrorResponse) => {
                console.error(
                    'An error occured when trying to get userGroup user group count: Details are : ' + error.message
                );
            }
        );
    }

    getManagementCount(): void {
        this.usersService.getManagementGroupCount().subscribe(
            (userGroups: Array<UserGroup>) => {
                this.managementCount = userGroups[0].memberCount;
            },
            (error: HttpErrorResponse) => {
                console.error(
                    'An error occured when trying to get userGroup management group count : Details are : ' +
                        error.message
                );
            }
        );
    }

    updateFoundUsers(users: Array<User>, datePattern: string): void {
        if (users != undefined) {
            users.forEach((item) => {
                if (item.accountCreated) {
                    item.accountCreated = moment(item.accountCreated).format(datePattern);
                    item.accountCreated = this.transformData(item.accountCreated);
                }

                if (item.accountLastModified) {
                    item.accountLastModified = moment(item.accountLastModified).format(datePattern);
                    item.accountCreated = this.transformData(item.accountCreated);
                }
                if (item.firstName) {
                    item.firstName = this.transformData(item.firstName);
                }
                if (item.lastName) {
                    item.lastName = this.transformData(item.lastName);
                }
                if (item.login) {
                    item.login = this.transformData(item.login);
                }
                if (item.mail) {
                    item.mail = this.transformData(item.mail);
                }
            });
        }
        this.filtredData = users;
        this.data = users;
    }

    updateFilter(): void {
        this.usersService.findInternalUsersByFullTextSearch(this.searchTerm).subscribe(
            (users: Array<User>) => {
                this.updateFoundUsers(users, this.defaultDatePattern);
                setTimeout(() => {
                    this.loadingIndicator = false;
                }, 1500);
            },
            (error: HttpErrorResponse) => {
                console.error('An error occured when trying to fetch users : Details are : ' + error.message);
            }
        );
    }

    translateHeader(title: string): string {
        let titleTranslated = '';
        this.translate.get([title]).subscribe((translation) => {
            titleTranslated = translation[title];
        });
        return titleTranslated;
    }

    transformData(value: string): string {
        if (!this.searchTerm) {
            return value;
        }
        const args = this.searchTerm.split(',');
        if (args && value) {
            args.forEach((arg) => {
                const startIndex = value.toLowerCase().indexOf(arg.toLowerCase());
                if (startIndex !== -1) {
                    const endLength = arg.length;
                    const matchingString = value.substr(startIndex, endLength);
                    value = value.replace(matchingString, '<mark>' + matchingString + '</mark>');
                }
            });
        }
        return value;
    }
    onResized(): void {
        this.table.recalculate();
        this.table.recalculateDims();
        this.table.recalculateColumns();
        this.table.offset = 0;
    }
}
