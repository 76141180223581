export enum RedirectLandingPage {
    INTERN_LANDING_PAGE = '/interne/selection',
    ACTIVITIES_LANDING_PAGE = '/mes-interventions/tableau-de-bord',
    ACTIVITIES_LIST_PAGE = '/mes-interventions/liste',
    ACTIVITIES_DETAILED_LIST_PAGE = '/mes-interventions/liste/detaillee',
    ACTIVITIES_DWELLING_PAGE = '/mes-interventions/liste/logement',
    WORKSITES_LANDING_PAGE = '/mes-chantiers/tableau-de-bord',
    WORKSITES_LIST_PAGE = '/mes-chantiers/liste',
    WORKSITES_DWELLING_PAGE = '/mes-chantiers/liste/logement',
    EXTERN_LANDING_PAGE = ACTIVITIES_LANDING_PAGE,
    NULL = ''

}
