import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { OverviewMode } from '@extern/worksites/service/worksite-dwelling.service';
import { firstValueFrom } from 'rxjs';

import { DwellingActivityOverview, MeterActivityOverview } from '../model/activity-overview.model';

@Injectable({
    providedIn: 'root',
})
export class ActivityOverviewService {
    baseUrl: string;

    constructor(
        private httpClient: HttpClient,
        @Inject('CONTEXT_API') private contextApi: string
    ) {
        this.baseUrl = `${contextApi}/signalements`;
    }

    /**
     * Returns a flat overwiew of activites depending params.
     *
     * @param viewParam - view mode : OverviewMode
     * @param dateParam - Date from witch data should be retrieved
     * @param propertiesIdsParam - List of properties ids separated by comma
     * @returns An observable of ReadonlyArray<Worksite>
     */
    fetchActivitiesOverview(
        view: OverviewMode,
        date: string,
        propertiesIds?: ReadonlyArray<string>
    ): Promise<ReadonlyArray<DwellingActivityOverview>> | Promise<ReadonlyArray<MeterActivityOverview>> {
        const urlStart = `${this.baseUrl}`;
        const urlEnd = `/overview/flat?`;
        let filterUrl = '';

        if (view.length > 0) {
            filterUrl += (filterUrl === '' ? '' : '&') + `view=${view}`;
        }
        if (propertiesIds && propertiesIds.length > 0) {
            filterUrl += (filterUrl === '' ? '' : '&') + `propertiesIds=${propertiesIds}`;
        }
        if (date !== undefined && date !== '') {
            filterUrl += (filterUrl === '' ? '' : '&') + `date=${date}`;
        }
        const url = urlStart + urlEnd + filterUrl;

        if (isDevMode()) {
            console.log('calling fetchActivitiesOverview url : ' + url);
        }

        return view === OverviewMode.DWELLING
            ? firstValueFrom(this.httpClient.get<ReadonlyArray<DwellingActivityOverview>>(url))
            : firstValueFrom(this.httpClient.get<ReadonlyArray<MeterActivityOverview>>(url));
    }
}
