import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { FilterSessionStorageService } from '@ista/shared-ui';
import { UexFilters } from '../model/uex-filters.model';

@Injectable({
    providedIn: 'root'
})
export class UexFilterSessionStorageService extends FilterSessionStorageService<UexFilters> {

    constructor(@Inject(PLATFORM_ID) platformId: string, @Inject('DB_NAME_PREFIX') dbName: string) {
        super(platformId, 'uex_filters', UexFilters, dbName);
    }
}
