import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService, AuthorizationService, TokenManagerService } from '@ista/shared-ui';
import { Observable, of } from 'rxjs';

import { isAuthorizedRedirectingPath } from '../util/redirect.util';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard  {

    constructor(
        private authenticationService: AuthenticationService,
        private authorizationService: AuthorizationService,
        private tokenManagerService: TokenManagerService,
        private router: Router) { }

    canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        const isTokenValid = this.tokenManagerService.isValidAuthentication();
        const requestedUrl = state.url;

        if (isDevMode()) {
            console.log(`Current user is requesting url : ${requestedUrl}\n`);
            console.log(`Token is : ${(isTokenValid ? 'valid' : 'not valid')}\n`);
        }
        const isInternalAccount = this.authorizationService.isInternalAccountType();
        const roles = this.authorizationService.getUserRoles();
        const cannotAccessModule = !this.authorizationService.isAuthorizedToAccessAtLeastOneModule();

        if (!isTokenValid || cannotAccessModule) {
            if (!isAuthorizedRedirectingPath(requestedUrl)) {
                this.authenticationService.logout(true);
            } else {
                this.authenticationService.logout(true, requestedUrl);
            }
            return of(false);
        }

        const isRoleAuthorizedForThisRoute = roles.some(r => router.data.roles.includes(r));
        if (state.url.includes('interne')) {
            const isAuthorized = isInternalAccount && isRoleAuthorizedForThisRoute;
            if (!isAuthorized) {
                this.router.navigate(['interne/selection']);
            }
            return of(isAuthorized);
        }

        if (isInternalAccount) {
            if (!state.url.includes('interne')) {
                this.router.navigate(['interne/selection']);
            }
        }
        return of(true);
    }
}
