import { Filters, Reference } from '@ista/shared-ui';

export class WorksiteFilters extends Filters {

    products: ReadonlyArray<Reference>;
    statuses: ReadonlyArray<Reference>;
    worksiteStatuses: ReadonlyArray<Reference>;
    startDate: string;
    endDate: string;
    columns: ReadonlyArray<Reference>;

    public constructor() {
        super();
        this.products = [];
        this.statuses = [];
        this.worksiteStatuses = [];
        this.startDate = '';
        this.endDate = '';
        this.columns = [];
    }
}
