import { Component } from '@angular/core';
import { DefaultLandingPage } from '@ista/shared-ui';

import { RedirectLandingPage } from '../../../core/enum/redirect-landing-page.enum';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.css']
})
export class AuthComponent {
    landingPage = new DefaultLandingPage(RedirectLandingPage.EXTERN_LANDING_PAGE);
}
