import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { RedirectLandingPage } from '@core/enum/redirect-landing-page.enum';
import { Column } from '@core/model/column.model';
import { Property } from '@core/model/property.model';
import { MessageService } from '@core/service/message.service';
import { PropertyService } from '@core/service/property.service';
import { SelfcareSessionStorage } from '@core/service/selfcare-session-storage.service';
import { GlobalVariables } from '@core/util/global-variables';
import { ActivityType } from '@extern/activities/enum/activity-type.enum';
import { MeterDetails } from '@extern/activities/model/meter-details.model';
import { NewActivityDetails } from '@extern/activities/model/new-activity-details.model';
import { NewDwellingActivities } from '@extern/activities/model/new-dwelling-activities.model';
import { ActivityDwellingService } from '@extern/activities/service/activity-dwelling.service';
import { TemplateComponent } from '@extern/components/template/template.component';
import { NewInstallationLine } from '@extern/worksites/model/new-installation-line.model';
import { OverviewMode, WorksiteDwellingService } from '@extern/worksites/service/worksite-dwelling.service';
import { AuthorizationService, Reference } from '@ista/shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { ProductsConstants } from '@shared/constants/products.const';
import { FilterName } from '@shared/enum/filter-name.enum';
import { StorageName } from '@shared/enum/storage-name.enum';
import { handlePropertySelection } from '@shared/service/property-selection.service';
import { firstValueFrom, Subject, Subscription } from 'rxjs';
import { FilterReferencesService } from '@core/service/filter-references.service';
import { GroupFiltersComponent } from 'src/app/shared/components/group-filters/group-filters.component';
import { ExcelService } from '@shared/service/excel.service';

import { ActivityStatus } from '../../enum/activity-status.enum';
import { ActivityFilters } from '../../model/activity-filters.model';
import { ActivityFilterSessionStorageService } from '../../service/activity-filter-session-storage.service';

@Component({
    templateUrl: './activities-detail.component.html',
    styleUrls: ['./activities-detail.component.css'],
})
export class ActivitiesDetailComponent implements OnInit, OnDestroy, AfterViewChecked {
    @ViewChild('filtersBlock', { static: false }) filtersBlock: ElementRef;
    @ViewChild('containersBlock', { static: false }) containersBlock: ElementRef;
    @ViewChild('gFiltres') gFiltres: GroupFiltersComponent<ActivityFilters>;
    propertyList: ReadonlyArray<Property>;
    lines: ReadonlyArray<NewActivityDetails> = [];
    emptyList: ReadonlyArray<NewActivityDetails> = [];
    dataLines: ReadonlyArray<NewActivityDetails> = [];
    newDataLines: ReadonlyArray<NewActivityDetails> = [];
    loading = false;
    message: any;
    messagesSubscription: Subscription;
    showSpinner = true;
    defaultDatePattern = 'DD/MM/YYYY';
    activityLimitDate: Date;
    properties: ReadonlyArray<string>;
    propertyFilterData: ReadonlyArray<Reference>;
    addressFilterData: ReadonlyArray<Reference>;
    dwellingFilterData: ReadonlyArray<Reference>;
    serialNumberFilterData: ReadonlyArray<Reference>;
    reasonFilterData: ReadonlyArray<Reference>;
    subjectFilterData: ReadonlyArray<Reference>;
    showFilter = true;
    listBlockHeight: number;
    columns: Array<Column>;
    columnsFilterData: ReadonlyArray<Reference>;
    columnsFiltred: Column[];
    fixedColumns = [
        { name: 'datatable.activities.columns.propertyName', ids: ['propertyName'] },
        { name: 'datatable.activities.columns.dwellingCustomerReference', ids: ['dwellingCustomerReference'] },
        { name: 'datatable.activities.columns.location', ids: ['meterLocation'] },
        { name: 'datatable.activities.columns.subject', ids: ['subject'] },
        { name: 'datatable.activities.columns.status', ids: ['meterStatus'] },
    ];
    eventsSubject: Subject<number> = new Subject<number>();
    metersDetails: ReadonlyArray<MeterDetails>;
    matchMedia: MediaQueryList;
    previousMatchMediaStatus: boolean;
    filterIsActive = false;

    constructor(
        public globalVariable: GlobalVariables,
        public mainTemplate: TemplateComponent,
        private router: Router,
        private messageService: MessageService,
        private globalVariables: GlobalVariables,
        private filterReferencesService: FilterReferencesService,
        private worksiteDwellingService: WorksiteDwellingService,
        private propertyService: PropertyService,
        private sStorage: SelfcareSessionStorage,
        public filterSessionStorage: ActivityFilterSessionStorageService,
        private excelService: ExcelService,
        private translate: TranslateService,
        private isInternal: AuthorizationService,
        private activityDwellingService: ActivityDwellingService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    checkFilters(): void {
        const filters = this.filterSessionStorage.getFilters();
        this.filterIsActive = !(
            !filters ||
            (filters &&
                filters.addresses.length === 0 &&
                filters.properties.length === 0 &&
                filters.dwellings.length === 0 &&
                filters.products.length === 0 &&
                filters.serialNumbers.length === 0 &&
                filters.activityTypes.length === 0 &&
                filters.reasons.length === 0 &&
                filters.subjects.length === 0 &&
                filters.statuses.length === 0 &&
                filters.visitsCount.length === 0 &&
                filters.dates.length === 0
            )
        );
    }

    ngOnInit(): void {
        this.globalVariable.urlForCurrentView = [];
        this.translate.setDefaultLang('fr');
        if (this.isInternal.isInternalAccountType()) {
            this.getProperties();
        } else {
            if (this.mainTemplate.setSelectionDisplayed()) {
                this.showSpinner = false;
            } else {
                this.getProperties();
            }
            this.messagesSubscription = this.messageService.getMessage().subscribe((message) => {
                if (message) {
                    this.message = message;
                    if (
                        this.message.text === 'refresh ActivitiesDeteilsComponent' ||
                        this.message.text === 'btn-return'
                    ) {
                        this.getProperties();
                        this.messageService.clearMessage();
                    }
                }
            });
        }
        this.matchMedia = window.matchMedia('(max-width: 1150px)');
        this.createColumns();
        this.initFilterColumns();
        this.activityLimitDate = this.globalVariables.activityLimitDate();
        if (this.matchMedia.matches) {
            this.showFilter = false;
        }
    }

    prepareColumns(): void {
        const columnsFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.COLUMNS_DETAILS);
        this.columnsFiltred = [...this.columns.filter((col) => columnsFilter.includes(col.prop))];
        if (this.columnsFiltred.length === 0) {
            this.columnsFiltred = [...this.columns];
        }
    }

    initFilterColumns(): void {
        const filterData = new Array<Reference>();
        this.columns.forEach((col) => {
            filterData.push({ name: this.translate.instant(col.name), ids: [col.prop] });
        });
        this.columnsFilterData = filterData;
        if (this.matchMedia.matches) {
            this.filterSessionStorage.setFiltersProperty(FilterName.COLUMNS_DETAILS, this.fixedColumns);
            this.showFilter = false;
        } else {
            this.filterSessionStorage.setFiltersProperty(FilterName.COLUMNS_DETAILS, this.columnsFilterData);
        }
    }

    createColumns(): void {
        this.columns = [
            new Column(
                this.translate.instant('datatable.activities.columns.propertyName'),
                'propertyName',
                true,
                135,
                this.comparatorNotNull.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.propertyAddress'),
                'address',
                true,
                135,
                this.comparatorAdress.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.dwellingCustomerReference'),
                'dwellingCustomerReference',
                true,
                100,
                this.alphanumericComparator.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.location'),
                'meterLocation',
                true,
                105,
                this.alphanumericComparator.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.meterType'),
                'productCode',
                true,
                125,
                this.alphanumericComparator.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.meterSerialNumber'),
                'newSerialNumber',
                true,
                105,
                this.alphanumericComparator.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.activityType'),
                'type',
                true,
                160,
                this.alphanumericComparator.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.reason'),
                'reason',
                true,
                165,
                this.alphanumericComparator.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.subject'),
                'subject',
                true,
                170,
                this.alphanumericComparator.bind(this)
            ),
            new Column(this.translate.instant('datatable.activities.columns.visits'), 'visitsCount', true, 155),
            new Column(
                this.translate.instant('datatable.activities.columns.date'),
                'creationDate',
                true,
                130,
                this.dateComparator.bind(this)
            ),
            new Column(
                this.translate.instant('datatable.activities.columns.status'),
                'meterStatus',
                true,
                130,
                this.statusComparator.bind(this)
            ),
        ];
    }

    toggleFilter(): void {
        this.showFilter = !this.showFilter;
        this.resized();
    }

    showFilterTooltip(): string {
        return this.showFilter
            ? this.translate.instant('filters.showFilters')
            : this.translate.instant('filters.hideFilters');
    }

    resized(): void {
        this.listBlockHeight =
            this.containersBlock.nativeElement.offsetHeight - this.filtersBlock.nativeElement.offsetHeight;
        this.eventsSubject.next(this.listBlockHeight);
    }

    handleResize(event: ResizeObserverEntry): void {
        this.listBlockHeight = event.contentRect.height;
        this.eventsSubject.next(this.listBlockHeight);
        if (this.previousMatchMediaStatus !== this.matchMedia.matches) {
            this.previousMatchMediaStatus = this.matchMedia.matches;
            this.initFilterColumns();
            this.prepareColumns();
        }
        if (event.contentRect.width > 1064) {
            this.showFilter = true;
        } else if (this.previousMatchMediaStatus) this.showFilter = false;
    }

    getProperties(): void {
        const list = this.isInternal.isExternalAccountType()
            ? []
            : sessionStorage.getItem('internal_client_properties')
              ? sessionStorage.getItem('internal_client_properties')?.split(',')
              : [];
        this.propertyService.fetchByIds(list).subscribe((properties) => {
            this.propertyList = properties as Array<Property>;
            this.refreshData('');
        });
    }

    fetchData(
        viewType: string,
        propertiesNumbers: ReadonlyArray<string>,
        date: string
    ): Promise<ReadonlyArray<NewDwellingActivities>> {
        this.lines = this.emptyList;
        return firstValueFrom(
            this.activityDwellingService.fetchDwellingsDetailsByView(viewType, propertiesNumbers, date)
        );
    }

    fetchWorksiteData(
        viewType: OverviewMode,
        propertiesNumbers: ReadonlyArray<string>,
        date: string,
        remainingInstallations?: boolean
    ): Promise<ReadonlyArray<NewInstallationLine>> {
        this.lines = this.emptyList;
        return firstValueFrom(
            this.worksiteDwellingService.fetchDwellingsDetailsByView(
                viewType,
                propertiesNumbers,
                date,
                remainingInstallations
            )
        );
    }

    refreshData(message: string): void {
        const date = this.filterSessionStorage.getFiltersProperty<string>(FilterName.DATES);
        let limitDate = this.activityLimitDate.toISOString().split('T')[0];
        if (date === limitDate) {
            const strCurrentFilters = (this.sStorage.getItem('activity_filters') || '').toString();
            if (strCurrentFilters !== '') {
                const currentFilters = JSON.parse(strCurrentFilters);
                currentFilters.Dates = '';
                this.sStorage.setItem('activity_filters', JSON.stringify(currentFilters));
            }
        }
        if (date && date !== '' && new Date(limitDate) < new Date(date)) {
            limitDate = date;
        }
        const propertiesFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.PROPERTIES);
        let propertiesNumbers: ReadonlyArray<string> = [];
        propertiesNumbers = handlePropertySelection(
            this.filterSessionStorage,
            StorageName.ACTIVITY_STORAGE_NAME,
            this.isInternal.isInternalAccountType()
        );
        if (this.isInternal.isInternalAccountType() && propertiesNumbers.length === 0) {
            propertiesNumbers =
                propertiesFilter.length > 0
                    ? propertiesFilter
                    : sessionStorage.getItem('internal_client_properties')
                      ? (sessionStorage.getItem('internal_client_properties')?.split(',') as ReadonlyArray<string>)
                      : [];
        }
        if (message === 'filters.properties') {
            this.dataLines = [];
        }
        if (this.dataLines.length === 0) {
            this.showSpinner = true;
            Promise.all([
                this.fetchData(OverviewMode.METER, propertiesNumbers, limitDate),
                this.fetchWorksiteData(OverviewMode.METER, propertiesNumbers, limitDate, true),
            ]).then((promiseArray) => {
                const lines = promiseArray[0] ? promiseArray[0] : [];
                const worksites = promiseArray[1] ? promiseArray[1] : [];
                this.dataLines = this.completeActivityDetails(lines, worksites, limitDate);
                this.prepareList();
                this.showSpinner = false;
                this.changeDetectorRef.detectChanges();
            });
        } else {
            this.prepareList();
        }
        this.checkFilters();
    }

    completeActivityDetails(
        activityLines: readonly any[],
        worksiteLines: readonly any[],
        limitDate: string
    ): ReadonlyArray<NewActivityDetails> {
        const activityLinesForMaintenance = Array.from(
            (
                activityLines.map((line) => {
                    return {
                        id: line.signalementId + '_' + line.meterId,
                        activityId: line.signalementId,
                        propertyId: line.propertyId,
                        propertyName: line.propertyName ? line.propertyName : '',
                        address: line.address,
                        zip: line.zip,
                        city: line.city,
                        fullAddress: line.address + ' ' + line.zip + ' ' + line.city,
                        dwellingId: line.dwellingId,
                        dwellingCustomerReference: line.dwellingCustomerReference,
                        dwellingLocation: line.dwellingLocation,
                        beep: line.beep,
                        owner: line.owner ? line.owner : '',
                        tenant: line.tenant ? line.tenant : '',
                        view: line.view,
                        type: ActivityType.MAINTENANCE as string,
                        meterId: line.meterId,
                        newSerialNumber: line.newSerialNumber,
                        meterLocation: line.meterLocation,
                        productCode: line.productCode,
                        fluidCode: line.fluidCode,
                        meterStatus: line.meterStatus,
                        reason:
                            line.reason && line.reason.length > 0
                                ? line.reason.charAt(0).toUpperCase() + line.reason.slice(1)
                                : '',
                        subject: line.subject,
                        visitsCount: line.visitsCont ? line.visitsCont : 0,
                        creationDate: line.creationDate,
                        planificationDate: line.planificationDate,
                    };
                }) as any[]
            )
                .reduce((pre, cur) => pre.set(cur.id, cur), new Map())
                .values()
        ) as ReadonlyArray<NewActivityDetails>;
        const activityLinesStillToBeInstalled = Array.from(
            (
                worksiteLines.map((line) => {
                    const meterId = line.meterId.startsWith(line.worksiteId + '_')
                        ? line.meterId.substring(line.worksiteId.length + 1)
                        : line.meterId;
                    return {
                        id: line.worksiteId + '_' + meterId,
                        activityId: line.worksiteId,
                        propertyId: line.propertyId,
                        propertyName: line.propertyName ? line.propertyName : '',
                        address: line.address,
                        zip: line.zip,
                        city: line.city,
                        fullAddress: line.address + ' ' + line.zip + ' ' + line.city,
                        dwellingId: line.dwellingId,
                        dwellingCustomerReference: line.dwellingCustomerReference,
                        dwellingLocation: line.dwellingLocation,
                        beep: line.dwellingId.startsWith(line.propertyId)
                            ? line.dwellingId.substring(line.propertyId.length)
                            : line.dwellingId,
                        owner: line.owner ? line.owner : '',
                        tenant: line.tenant ? line.tenant : '',
                        view: line.view,
                        type: ActivityType.INSTALLATION as string,
                        meterId: meterId,
                        newSerialNumber: line.newSerialNumber,
                        meterLocation: line.meterLocation,
                        productCode: line.productCode,
                        fluidCode: line.fluidCode,
                        meterStatus: line.meterStatus,
                        reason:
                            line.notInstalledReason && line.notInstalledReason.length > 0
                                ? line.notInstalledReason.charAt(0).toUpperCase() + line.notInstalledReason.slice(1)
                                : '',
                        subject: line.subject ? line.subject : '',
                        visitsCount: line.visitsCount ? line.visitsCount : 0,
                        creationDate: line.periodCreationDate,
                        planificationDate: line.planificationDate,
                    };
                }) as any[]
            )
                .reduce((pre, cur) => pre.set(cur.id, cur), new Map())
                .values()
        ) as ReadonlyArray<NewActivityDetails>;
        const allActivityDetails = activityLinesForMaintenance.concat(activityLinesStillToBeInstalled);
        return allActivityDetails;
    }

    prepareList(): void {
        const propertiesFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.PROPERTIES);
        const addressesFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.ADDRESSES);
        const dwellingsFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.DWELLINGS);
        const productFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.PRODUCTS);
        const serialNumberFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.SERIAL_NUMBERS);
        const activityTypeFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.ACTIVITY_TYPES);
        const reasonFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.REASONS);
        const subjectFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.SUBJECTS);
        const statusesFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.STATUSES);
        const visitCountsFilter = this.filterSessionStorage.getFiltersPropertyIds(FilterName.VISITS_COUNT);
        const dateFilter = this.filterSessionStorage.getFiltersProperty<string>(FilterName.DATES);
        this.prepareFiltersData(propertiesFilter, addressesFilter, dwellingsFilter);
        const filteredLines = this.applyFilters(
            propertiesFilter,
            addressesFilter,
            dwellingsFilter,
            productFilter,
            serialNumberFilter,
            activityTypeFilter,
            reasonFilter,
            subjectFilter,
            statusesFilter,
            visitCountsFilter,
            dateFilter
        );
        const sortedStatus = [
            ActivityStatus.HELP_US.toString(),
            ActivityStatus.TO_PLAN.toString(),
            ActivityStatus.PLANNED.toString(),
            ActivityStatus.IN_PROGRESS.toString(),
            ActivityStatus.NOT_EQUIPABLE.toString(),
            ActivityStatus.FINISHED.toString(),
        ];
        this.lines = (filteredLines as Array<NewActivityDetails>).sort(
            (a, b) =>
                sortedStatus.indexOf(a.meterStatus) - sortedStatus.indexOf(b.meterStatus) ||
                Number.parseInt(b.creationDate.split('-').join('')) -
                    Number.parseInt(a.creationDate.split('-').join(''))
        );
        this.prepareColumns();
    }

    prepareFiltersData(
        propertiesFilter: ReadonlyArray<string>,
        addressesFilter: ReadonlyArray<string>,
        dwellingsFilter: ReadonlyArray<string>
    ): void {
        const properties = this.propertyList;
        this.propertyFilterData = this.filterReferencesService.preparePropertyData(
            properties.filter((p) =>
                addressesFilter && addressesFilter.length > 0
                    ? addressesFilter.map((id) => id.substring(0, 8)).includes(p.id)
                    : true
            )
        );

        this.addressFilterData = this.filterReferencesService.prepareActivityAddressData(
            this.dataLines.filter((d) =>
                propertiesFilter && propertiesFilter.length > 0 ? propertiesFilter.includes(d.propertyId) : true
            )
        );

        this.dwellingFilterData = this.filterReferencesService.prepareActivityDwellingData(
            this.dataLines.filter((l) => {
                let result = true;
                if (propertiesFilter && propertiesFilter.length > 0) {
                    result = result && propertiesFilter.includes(l.propertyId);
                }
                if (addressesFilter && addressesFilter.length > 0) {
                    result = result && addressesFilter.includes(l.dwellingId);
                }
                return result;
            })
        );

        this.serialNumberFilterData = this.filterReferencesService.prepareActivityMetersData(
            this.dataLines.filter((l) => {
                let result = true;
                if (propertiesFilter && propertiesFilter.length > 0) {
                    result = result && propertiesFilter.includes(l.propertyId);
                }
                if (addressesFilter && addressesFilter.length > 0) {
                    result = result && addressesFilter.includes(l.dwellingId);
                }
                if (dwellingsFilter && dwellingsFilter.length > 0) {
                    result = result && dwellingsFilter.includes(l.dwellingId);
                }
                return result;
            })
        );

        this.reasonFilterData = this.filterReferencesService.prepareActivityReasonData(
            this.dataLines.filter((l) => {
                let result = true;
                if (propertiesFilter && propertiesFilter.length > 0) {
                    result = result && propertiesFilter.includes(l.propertyId);
                }
                if (addressesFilter && addressesFilter.length > 0) {
                    result = result && addressesFilter.includes(l.dwellingId);
                }
                if (dwellingsFilter && dwellingsFilter.length > 0) {
                    result = result && dwellingsFilter.includes(l.dwellingId);
                }
                return result;
            })
        );

        this.subjectFilterData = this.filterReferencesService.prepareActivitySubjectData(
            this.dataLines.filter((l) => {
                let result = true;
                if (propertiesFilter && propertiesFilter.length > 0) {
                    result = result && propertiesFilter.includes(l.propertyId);
                }
                if (addressesFilter && addressesFilter.length > 0) {
                    result = result && addressesFilter.includes(l.dwellingId);
                }
                if (dwellingsFilter && dwellingsFilter.length > 0) {
                    result = result && dwellingsFilter.includes(l.dwellingId);
                }
                return result;
            })
        );
    }

    applyFilters(
        propertiesFilter: ReadonlyArray<string>,
        addressesFilter: ReadonlyArray<string>,
        dwellingsFilter: ReadonlyArray<string>,
        productFilter: ReadonlyArray<string>,
        serialNumberFilter: ReadonlyArray<string>,
        activityTypeFilter: ReadonlyArray<string>,
        reasonFilter: ReadonlyArray<string>,
        subjectFilter: ReadonlyArray<string>,
        statusesFilter: ReadonlyArray<string>,
        visitCountFilter: ReadonlyArray<string>,
        dateFilter: string | undefined
    ): ReadonlyArray<NewActivityDetails> {
        let lines = this.dataLines;
        if (propertiesFilter && propertiesFilter.length > 0) {
            lines = lines.filter((l) => propertiesFilter.includes(l.propertyId));
        }
        if (addressesFilter && addressesFilter.length > 0) {
            lines = lines.filter((l) => addressesFilter.includes(l.dwellingId));
        }
        if (dwellingsFilter && dwellingsFilter.length > 0) {
            lines = lines.filter((l) => dwellingsFilter.includes(l.dwellingId));
        }
        if (productFilter && productFilter.length > 0) {
            lines = lines.filter((l) =>
                productFilter.includes(ProductsConstants.getMeterType(l.productCode, l.fluidCode))
            );
        }
        if (serialNumberFilter && serialNumberFilter.length > 0) {
            lines = lines.filter((l) => serialNumberFilter.includes(l.id));
        }
        if (activityTypeFilter && activityTypeFilter.length > 0) {
            lines = lines.filter((l) => activityTypeFilter.includes(l.type.toUpperCase()));
        }
        if (reasonFilter && reasonFilter.length > 0) {
            lines = lines.filter((l) => reasonFilter.includes(l.id));
        }
        if (subjectFilter && subjectFilter.length > 0) {
            lines = lines.filter((l) => subjectFilter.includes(l.id));
        }
        if (statusesFilter && statusesFilter.length > 0) {
            lines = lines.filter((l) => statusesFilter.includes(l.meterStatus));
        }
        if (dateFilter && dateFilter !== '') {
            lines = lines.filter((l) => l.creationDate >= dateFilter);
        }
        if (visitCountFilter && visitCountFilter.length > 0) {
            lines = lines.filter(
                (l) =>
                    visitCountFilter.filter((visit) =>
                        Number(visit) < 4 ? Number(visit) === l.visitsCount : l.visitsCount >= Number(visit)
                    ).length > 0
            );
        }
        return lines;
    }

    exportMeters(source: string): void {
        if (source === 'excel') {
            const customer = this.sStorage.getCustomer();
            const filters = this.gFiltres.getSelectedFilters();
            // this.exportMetersInExcelFormat(customer, this.lines, filters);
            throw new Error('Method not implemented.');
        }
    }

    // public exportMetersInExcelFormat(
    //     customer: Customer,
    //     dwellings: ReadonlyArray<ActivityDwellingDetails>,
    //     filters: ReadonlyArray<Reference>): void {
    //     const filteredResidences: Array<string> = this.extractFilterValues(
    //         this.translate.instant('filters.' + FilterName.PROPERTIES), filters);
    //     const filteredDwellings: Array<string> = this.extractFilterValues(
    //         this.translate.instant('filters.' + FilterName.DWELLINGS), filters);
    //     const filteredStatus: Array<string> = this.extractFilterValues(
    //         this.translate.instant('filters.activity.' + FilterName.STATUSES), filters);
    //     const filteredDates: Array<string> = this.extractFilterValues(
    //         this.translate.instant('filters.activity.' + FilterName.DATES), filters);
    //     const filteredAdresses: Array<string> = this.extractFilterValues(
    //         this.translate.instant('filters.' + FilterName.ADDRESSES), filters);

    //     let date: string = (filteredDates.length > 0) ? filteredDates[0] : '01/01/2019';
    //     date = (date.length > 0) ? dateUtils.formatDate(date, 'DD/MM/YYYY') : date;
    //     this.excelService.generateExcelFromDwellingsList(customer, dwellings,
    //         filteredResidences, filteredDwellings, filteredStatus, date, filteredAdresses);
    // }

    statusComparator(propA: string, propB: string): number {
        const ascSortedStatus = ['to_plan', 'help_us', 'in_progress', 'NOT_EQUIPABLE', 'planned', 'finished'];
        return ascSortedStatus.indexOf(propA.toLowerCase()) < ascSortedStatus.indexOf(propB.toLowerCase())
            ? -1
            : ascSortedStatus.indexOf(propA.toLowerCase()) > ascSortedStatus.indexOf(propB.toLowerCase())
              ? 1
              : 0;
    }

    dateComparator(propA: string, propB: string): number {
        const formatedDateA = propA.split('/').reverse().join('');
        const formatedDateB = propB.split('/').reverse().join('');
        return Number.parseInt(formatedDateA) > Number.parseInt(formatedDateB)
            ? -1
            : Number.parseInt(formatedDateA) < Number.parseInt(formatedDateB)
              ? 1
              : 0;
    }

    alphanumericComparator(propA: string, propB: string, rowA: any, rowB: any, sortDirection: string): number {
        if (propA.trim() === '' || propB.trim() === '') {
            if (sortDirection === 'desc') {
                return propA.trim() === '' ? -1 : 1;
            } else {
                return propA.trim() === '' ? 1 : -1;
            }
        }
        const a = propA.toLocaleLowerCase().trim();
        const b = propB.toLocaleLowerCase().trim();
        const codeA = a.charCodeAt(0);
        const codeB = b.charCodeAt(0);
        if (sortDirection === 'desc') {
            if (codeA > 96) {
                return codeB > 96 ? (a > b ? 1 : b > a ? -1 : 0) : 1;
            } else {
                return codeB > 96 ? -1 : a > b ? 1 : b > a ? -1 : 0;
            }
        } else {
            if (codeA > 96) {
                return codeB > 96 ? (a > b ? 1 : b > a ? -1 : 0) : -1;
            } else {
                return codeB > 96 ? 1 : a > b ? 1 : b > a ? -1 : 0;
            }
        }
    }

    comparatorNotNull(propA: string, propB: string, rowA: any, rowB: any, sortDirection: string): number {
        if (propA.trim() === '' || propB.trim() === '') {
            if (sortDirection === 'desc') {
                return propA.trim() === '' ? -1 : 1;
            } else {
                return propA.trim() === '' ? 1 : -1;
            }
        } else {
            const a = propA.toLocaleLowerCase().trim();
            const b = propB.toLocaleLowerCase().trim();
            return a > b ? 1 : b > a ? -1 : 0;
        }
    }

    comparatorAdress(propA: string, propB: string, rowA: any, rowB: any, sortDirection: string): number {
        if (propA.trim() === '' || propB.trim() === '') {
            if (sortDirection === 'desc') {
                return propA.trim() === '' ? -1 : 1;
            } else {
                return propA.trim() === '' ? 1 : -1;
            }
        } else {
            const a = propA.toLocaleLowerCase().trim();
            const b = propB.toLocaleLowerCase().trim();
            const reA = /[^a-zA-Z]/g;
            const reN = /[^0-9]/g;
            const aA = a.replace(reA, '');
            const bA = b.replace(reA, '');
            if (aA === bA) {
                let aN = parseInt(a.replace(reN, ''), 10);
                let bN = parseInt(b.replace(reN, ''), 10);
                aN = aN ? aN : 0;
                bN = bN ? bN : 0;
                return aN === bN ? 0 : aN > bN ? 1 : -1;
            } else {
                return aA > bA ? 1 : -1;
            }
        }
    }

    switchView(): void {
        sessionStorage.removeItem('activity-list-sorted-columns');
        sessionStorage.removeItem('activity-list-selected-row-info');
        this.sStorage.setDetailedActivitiesView(false);
        this.router.navigate([
            (this.isInternal.isInternalAccountType() ? '/interne' : '') +
                RedirectLandingPage.ACTIVITIES_LIST_PAGE.valueOf(),
        ]);
    }

    ngAfterViewChecked(): void {
        this.resized();
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        if (this.messagesSubscription) {
            this.messagesSubscription.unsubscribe();
        }
    }

    private extractFilterValues(filterName: string, filters: ReadonlyArray<Reference>): Array<string> {
        const values: Array<string> = [];
        filters
            .filter((filter) => filter.name.includes(filterName))
            .map((filter) => filter.ids)
            .forEach((filter) => filter.forEach((value) => values.push(value)));
        return values;
    }

    getDwellingIstaReference(row: NewDwellingActivities): String {
        return row.dwellingId.startsWith(row.propertyId)
            ? row.dwellingId.substring(row.propertyId.length)
            : row.dwellingId;
    }
}
