// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

declare global {
    interface Window {
        env: any;
    }
}


export const environment = {
    production: false,
    CONTEXT_API: `/${window["env"]["apiUrl"]}/api` || '/selfcare/api',
    TOKEN_KEY: 'access_token',
    REFRESH_TOKEN_KEY: 'refresh_token',
    ISP_BASE_URL: 'https://fr-dsp.preprod.isdp.ista.net',
    LOGOUT_REDIRECT_URL: 'redirection-mon-compte-ista',
    INTERNAL_REDIRECT_URL: 'interne/login',
    DB_NAME_PREFIX: 'mes-suivis-',
    DB_DURATION: 360,
    APPLICATION_URL: 'www.ista-services.fr',
    APPLICATION_HREF_URL: 'https://www.ista-services.fr',
    DEMO_CUSTOMER_NUMBERS: '800597541, 800597527',
    DEMO_BASE_HREF: `/${window["env"]["demoBaseHref"]}` || 'mes-suivis-demo',
    INTERNAL_LOGIN_REDIRECT_URL: 'https://keycloak-preprod.ista.com/realms/dsp-webconso-fr/protocol/openid-connect/auth?' +
        'client_id=fr-ista-services-internal&response_type=code&kc_idp_hint=oidc_azure&scope=email%20profile%20roles' +
        '&state=internal-login-workflow'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
