<div class="items">
    <div class="item">
        <div class="firstBlock">
            <div (click)="showOrHideDetail(activity)" class="backTo">
                <span *ngIf="activity.showDetail ? false : true">&nbsp;</span>
                <mat-icon style="margin: 1rem" *ngIf="activity.showDetail ? false : true"
                    >keyboard_arrow_right</mat-icon
                >
                <mat-icon style="margin: 1rem" *ngIf="!activity.showDetail ? false : true"
                    >keyboard_arrow_down</mat-icon
                >
                <span *ngIf="activity.showDetail ? false : true">&nbsp;</span>
            </div>

            <div *ngIf="activity.meter">
                <img
                    [src]="identifyMeterIcon(activity.meter.product)"
                    [tooltip]="activity.meter.productLabel"
                    alt=""
                    class="space-btw-items-2x"
                    containerClass="tooltipClass"
                    placement="auto"
                />
            </div>
            <div *ngIf="activity.meter" class="meter">
                <span
                    [tooltip]="activity.meter.productLabel"
                    class="space-btw-items-2x blue-font"
                    containerClass="tooltipClass"
                    placement="auto"
                    >{{ activity.meter.product }}</span
                >
            </div>
            <!--  <div *ngIf="activity.customerActionRequired === true && identifyColor(activity.status) === 'red'">
                 <img src="../../../../../assets/images/passages_selfcare.png" alt="" width="20" height="20" class="space-btw-items">
             </div> -->
            <div *ngIf="activity.meter" class="location">
                <span class="space-btw-items blue-font">{{ activity.meter.location }}</span>
            </div>
        </div>
        <div class="type">
            <span class="space-btw-items bold blue-font">{{
                'detailedActivitiesPage.activityType.' + activity.type.toLowerCase() | translate
            }}</span>
        </div>
        <div class="space-btw-items">
            <div [tooltip]="hasTooltip()" containerClass="tooltipClass" placement="auto">
                <div [style.background-color]="identifyColor(activity)" class="statusButton rounded-pill">
                    <div>{{ 'activity.status.' + activity.status | translate }}</div>
                </div>
            </div>
            <div class="font-small">
                <span *ngIf="activity.planificationDate" class="blue-font">Le </span
                ><span class="blue-font">{{ transformDate(activity.planificationDate) }}</span>
            </div>
        </div>
        <div><span class="space-btw-items blue-font">pour</span></div>
        <div class="space-btw-items">
            <div class="subjectButton radius dark-blue pl-2">
                <div>{{ activity.subject }}</div>
            </div>
            <div class="text-sm-left font-small">
                <span class="activityId blue-font"
                    >{{ 'dwelling.nActivity' | translate }} {{ formatGlobalId(activity) }}</span
                >
            </div>
        </div>
    </div>
    <div *ngIf="activity.showDetail" class="activity-detail">
        <app-activity-item-details [activity]="activity"></app-activity-item-details>
    </div>
    <hr />
</div>
